import React from 'react';
import styled from 'styled-components';
import { TextXSMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';

const BadgeContainer = styled(View)`
  background-color: ${colors.neutral700};
  border-radius: ${CornerRadius.xs}px;
  padding-left: ${Spacing.sm}px;
  padding-right: ${Spacing.sm}px;
  padding-top: 1px;
  padding-bottom: 1px;
  justify-content: center;
`;

export const CustomBadge = () => {
  return (
    <BadgeContainer>
      <TextXSMedium style={{ color: colors.white }}>Custom</TextXSMedium>
    </BadgeContainer>
  );
};

export const ClientCustomBadge = () => {
  return (
    <BadgeContainer>
      <TextXSMedium style={{ color: colors.white }}>Client Custom</TextXSMedium>
    </BadgeContainer>
  );
};
