import { MuscleHeatmapData, MuscleHeatmapKey } from 'hevy-shared';
export const getMuscleHeatmapOverlays = (data: MuscleHeatmapData) => {
  const maxValue = Math.max(...Object.values(data));
  const getImages = getMuscleHeatmapImagesMale;
  return Object.entries(data)
    .filter(e => e[1] !== 0)
    .map(([muscleGroup, muscleUsage]) => ({
      ...getImages(muscleGroup as MuscleHeatmapKey),
      opacity: muscleUsage / maxValue,
    }));
};

const getMuscleHeatmapImagesMale = (muscleGroup: MuscleHeatmapKey) => {
  switch (muscleGroup) {
    case 'abdominals':
      return {
        front: '/images/body_heatmap/male_front_abdominals.png',
        back: '/images/body_heatmap/male_back_abdominals.png',
      };
    case 'shoulders':
      return {
        front: '/images/body_heatmap/male_front_shoulder.png',
        back: '/images/body_heatmap/male_back_shoulder.png',
      };
    case 'chest':
      return {
        front: '/images/body_heatmap/male_front_chest.png',
        back: '/images/body_heatmap/male_back_chest(empty).png',
      };
    case 'lower_back':
      return {
        front: '/images/body_heatmap/male_front_lower_back(empty).png',
        back: '/images/body_heatmap/male_back_lower_back.png',
      };
    case 'biceps':
      return {
        front: '/images/body_heatmap/male_front_biceps.png',
        back: '/images/body_heatmap/male_back_biceps.png',
      };
    case 'triceps':
      return {
        front: '/images/body_heatmap/male_front_triceps.png',
        back: '/images/body_heatmap/male_back_triceps.png',
      };
    case 'upper_back':
      return {
        front: '/images/body_heatmap/male_front_upper-back.png',
        back: '/images/body_heatmap/male_back_upper-back.png',
      };
    case 'quadriceps':
      return {
        front: '/images/body_heatmap/male_front_quadriceps.png',
        back: '/images/body_heatmap/male_back_quadriceps.png',
      };
    case 'calves':
      return {
        front: '/images/body_heatmap/male_front_calves.png',
        back: '/images/body_heatmap/male_back_calves.png',
      };
    case 'lats':
      return {
        front: '/images/body_heatmap/male_front_lats.png',
        back: '/images/body_heatmap/male_back_lats.png',
      };
    case 'hamstrings':
      return {
        front: '/images/body_heatmap/male_front_hamstrings(empty).png',
        back: '/images/body_heatmap/male_back_hamstrings.png',
      };
    case 'glutes':
      return {
        front: '/images/body_heatmap/male_front_glutes(empty).png',
        back: '/images/body_heatmap/male_back_glutes.png',
      };
    case 'abductors':
      return {
        front: '/images/body_heatmap/male_front_abductors.png',
        back: '/images/body_heatmap/male_back_abductors(empty).png',
      };
    case 'adductors':
      return {
        front: '/images/body_heatmap/male_front_adductors.png',
        back: '/images/body_heatmap/male_back_adductors.png',
      };
    case 'traps':
      return {
        front: '/images/body_heatmap/male_front_traps.png',
        back: '/images/body_heatmap/male_back_traps.png',
      };
    case 'forearms':
      return {
        front: '/images/body_heatmap/male_front_forearms.png',
        back: '/images/body_heatmap/male_back_forearms.png',
      };
    case 'neck':
      return {
        front: '/images/body_heatmap/male_front_neck.png',
        back: '/images/body_heatmap/male_back_neck.png',
      };
  }
};
