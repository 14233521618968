import { Program } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';

const MY_PROGRAMS_FOLDER_ID = Number.MAX_SAFE_INTEGER;

export class MoveProgramToFolderViewModel {
  programToMove: Program;
  selectedFolderId?: number | undefined;
  isMovingProgram = false;
  searchText = '';
  targetCoachId: string;

  constructor(programToMove: Program, targetCoachId: string) {
    this.programToMove = programToMove;
    this.selectedFolderId = programToMove.folder_id ?? MY_PROGRAMS_FOLDER_ID;
    this.targetCoachId = targetCoachId;
    makeAutoObservable(this);
  }

  get programFolders() {
    return [
      { title: localStorageStores.programFolders.DEFAULT_FOLDER_NAME, id: MY_PROGRAMS_FOLDER_ID },
      ...localStorageStores.programFolders
        .foldersForMember(this.targetCoachId)
        .slice()
        .sort((p1, p2) => {
          return (p1.index ?? 0) - (p2.index ?? 0);
        })
        .map(folder => {
          return { title: folder.title, id: folder.id };
        }),
    ].filter(f => {
      return !this.searchText || f.title.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  get isSearching() {
    return !!this.searchText;
  }

  get programToMoveCurrentFolderId() {
    return this.programToMove.folder_id ?? MY_PROGRAMS_FOLDER_ID;
  }

  get isMoveEnabled() {
    return (
      this.selectedFolderId !== undefined &&
      this.selectedFolderId !== this.programToMoveCurrentFolderId
    );
  }

  onFolderSelected = (folderId: number) => {
    this.selectedFolderId = folderId;
  };

  onSearchChanged = (searchText: string) => {
    this.searchText = searchText;
  };

  onMovePressed = async () => {
    if (!this.selectedFolderId) return;
    this.isMovingProgram = true;
    try {
      await localStorageStores.programs.updateProgramLocation(
        this.programToMove,
        0,
        localStorageStores.account.id, // For now this only works for "My Library"
        this.selectedFolderId === MY_PROGRAMS_FOLDER_ID ? undefined : this.selectedFolderId,
      );
    } catch (error) {
      toast.error('Unable to move program, please try again.');
    }
    this.isMovingProgram = false;
  };
}
