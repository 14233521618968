/**
 * Takes a File object and returns a usable url from the passed file. Useful
 * for APIs that require the url of a file.
 */

export const getUrlFromFile = async (file: File): Promise<{ url: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const url = reader.result;

      if (typeof url !== 'string') {
        reject();
        return;
      }

      resolve({ url });
    });
    reader.readAsDataURL(file);
  });
};

export const getImageFileDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise(resolve => {
    const fr = new FileReader();

    fr.onload = function () {
      const img = new Image();

      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };

      if (typeof fr.result !== 'string') return;

      img.src = fr.result;
    };

    fr.readAsDataURL(file);
  });
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

interface PixelCrop {
  width: number;
  height: number;
  x: number;
  y: number;
}

export const getCroppedImgJpeg = async (
  imageUrl: string,
  pixelCrop: PixelCrop,
): Promise<string> => {
  const image = await createImage(imageUrl);

  // create an off-screen canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const width = image.width;
  const height = image.height;

  canvas.width = image.width;
  canvas.height = image.height;

  // draw source image into the off-screen canvas:
  ctx?.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    width,
    height,
  );

  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL('image/jpeg', 1.0);
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

interface Size {
  width: number;
  height: number;
}

export const resizeImage = (base64Str: string, size: Size): Promise<string> => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const MAX_WIDTH = size.width;
      const MAX_HEIGHT = size.height;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export const formatProfilePicForSocial = (imageUrl: string | null | undefined) => {
  if (imageUrl?.match(/^https?:\/\/[^.]*\.googleusercontent\.com/)) {
    /**
     * the user has a Google profile pic, which is probably too low resolution to display in a preview.
     * in this case, return `null` so the caller can fall back to the appropriate asset.
     */
    return null;
  }

  return imageUrl ?? null;
};
