import { captureException } from '@sentry/nextjs';
import dayjs from 'dayjs';
import { Workout } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { log } from 'utils/log';

export class Workouts {
  workouts: {
    [workoutId: string]: Workout;
  } = {};

  oldestWorkoutFetchDateForClientId: {
    [clientId: string]: string;
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  clearData = () => {
    this.workouts = {};
  };

  fetchWorkout = async (workoutId: string): Promise<Workout> => {
    try {
      const response = await API.getClientWorkout(workoutId);

      this.workouts[workoutId] = response.data;
      return this.workouts[workoutId];
    } catch (e) {
      captureException(e);
      throw e;
    }
  };

  getClientWorkout = async (workoutId: string): Promise<Workout> => {
    if (this.workouts[workoutId]) {
      return this.workouts[workoutId];
    }

    const workout = await this.fetchWorkout(workoutId);

    return workout;
  };

  workoutsForClient = (clientId: string): Workout[] => {
    return Object.values(this.workouts).filter(w => w.user_id === clientId);
  };

  getClientWorkoutsPaged = async ({
    clientId,
    limit,
    offset,
  }: {
    clientId: string;
    limit?: number;
    offset?: number;
  }): Promise<Workout[]> => {
    try {
      const response = await API.getClientsWorkoutsPaged(clientId, {
        limit: limit,
        offset: offset,
      });

      const workouts = response.data.workouts;

      // Store the workouts in the workouts dictionary
      for (const workout of workouts) {
        this.workouts[workout.id] = workout;
      }

      return workouts;
    } catch (e) {
      captureException(e);
      throw e;
    }
  };

  /**
   * Returns the workouts for a given user, sorted by start time descending
   * If the last workout in the list is before the start date, fetches workouts from the server
   * Until the last workout is after the start date
   */
  getClientWorkouts = async ({
    clientId,
    startDate,
    endDate,
    onProgressUpdate,
  }: {
    clientId: string;
    startDate: string;
    endDate?: string;
    onProgressUpdate?: (progress: number) => void;
  }): Promise<Workout[]> => {
    const oldestWorkoutDateForClient = this.oldestWorkoutFetchDateForClientId[clientId];

    if (
      !oldestWorkoutDateForClient ||
      dayjs(oldestWorkoutDateForClient).isAfter(dayjs(startDate))
    ) {
      const workoutsEndDate = oldestWorkoutDateForClient ?? dayjs().toISOString(); // today

      let workouts: Workout[] = [];
      try {
        const workoutsResult = await API.getClientWorkoutsBatch({
          clientId: clientId,
          startDate: startDate,
          endDate: workoutsEndDate,
        });
        workouts = workouts.concat(workoutsResult.data);

        let lastIndex = workouts.length > 0 ? workouts[workouts.length - 1].index : undefined;
        while (!!lastIndex) {
          const result = await API.getClientWorkoutsBatch({
            clientId: clientId,
            startDate: startDate,
            endDate: workoutsEndDate,
            index: lastIndex,
          });

          workouts = workouts.concat(result.data);
          lastIndex =
            result.data.length > 0 ? result.data[result.data.length - 1].index : undefined;

          if (!!onProgressUpdate && workouts.length > 0) {
            onProgressUpdate(
              (workouts[0].start_time - workouts[workouts.length - 1].start_time) /
                (dayjs(workoutsEndDate).unix() - dayjs(startDate).unix()),
            );
          }
        }

        workouts.forEach(workout => {
          this.workouts[workout.id] = workout;
        });

        // Store the oldest workout fetch date for this client after fetching is finished
        this.oldestWorkoutFetchDateForClientId[clientId] = startDate;
      } catch (e) {
        captureException(e);
        log('Error fetching workouts', e);
        throw e;
      }
    }

    const workoutsAfterFetching = Object.values(this.workouts).filter(w => {
      const workoutDate = dayjs.unix(w.start_time);
      return (
        (!endDate || workoutDate.isBefore(dayjs(endDate))) &&
        workoutDate.isAfter(dayjs(startDate)) &&
        w.user_id === clientId
      );
    });

    return workoutsAfterFetching;
  };
}
