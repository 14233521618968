import { BodyMeasurement, WeightUnit } from 'hevy-shared';
import { userWeight } from 'utils/units';

export type BodyMeasurementWithImage = BodyMeasurement & { picture_url: string };

export const measurementToString = (measurement: BodyMeasurementWithImage, unit: WeightUnit) => {
  if (measurement.weight_kg) {
    return `${userWeight(measurement.weight_kg, unit).toFixed(1)} ${unit}`;
  }
  return '-';
};
