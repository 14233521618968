export const colors = {
  //Brand Colors
  primary25: '#F3F9FE',
  primary50: '#E8F2FD',
  primary100: '#D1E6FB',
  primary200: '#A2CCF7',
  primary300: '#74B3F2',
  primary400: '#4599EE',
  primary500: '#1780EA',
  primary600: '#1266BB',
  primary700: '#0E4D8C',
  primary800: '#09335E',
  primary900: '#051A2F',
  neutral50: '#F9FAFB',
  neutral100: '#F3F4F6',
  neutral150: '#ECEDF0',
  neutral200: '#E5E7EB',
  neutral300: '#D2D6DB',
  neutral400: '#9DA4AE',
  neutral500: '#6C737F',
  neutral600: '#4D5761',
  neutral700: '#384250',
  neutral800: '#1F2A37',
  neutral900: '#111927',
  declinedClientStatusPillBackground: '#FCE1DF',
  error500: '#F04438',
  warningYellow: '#FEF0C7',
  transparent: 'transparent',
  sideMenuBackground: '#1E2022',
  sideMenuActiveNavigationItem: '#25282A',
  sideMenuLinesAndBorders: '#2D2E2F',
  white: '#ffffff',
  black: '#000000', //!! Should be used for Hevy logo only
  red: '#ff0000',
  supersets: [
    { bold: '#A720F4', light: '#FAF0FF' },
    { bold: '#43C616', light: '#F2FAF2' },
    { bold: '#DD1818', light: '#FFEEEE' },
    { bold: '#E2B335', light: '#FFFBE0' },
    { bold: '#E26AE9', light: '#FEF0FF' },
    { bold: '#4A4A4A', light: '#F8F8F8' },
    { bold: '#FF8787', light: '#FFEEEE' },
    { bold: '#203CF4', light: '#F3F5FF' },
    { bold: '#28C2D3', light: '#E8F5F7' },
    { bold: '#A2B139', light: '#F3F6DD' },
  ],
};
