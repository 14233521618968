import '../styles.css';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { bootstrap } from 'utils/bootstrap';
import { Observer } from 'mobx-react-lite';
import { View } from 'styleguide/View';
import { ModalManager } from 'components/Modals/ModalManager';
import { AppNavigationWrapper } from 'components/NavBar/AppNavigationWrapper';
import { useRouter } from 'next/router';
import { amplitudePageView } from 'utils/amplitude';
import { localStorageStores } from 'state/localStorageStores';
import { processURLQueryParams } from 'utils/processURLQueryParams';
import Head from 'next/head';

Modal.setAppElement('#__next');

const fixAutofillUndefined = () => {
  if (typeof window === 'undefined' || !window) return;

  // This is to try to avoid ending up with this error: https://hevy.sentry.io/issues/4836261415/?referrer=tag-distribution-meter,
  // which seems to only happen on Instagram and Facebook browsers (https://stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler).
  (window as any)._AutofillCallbackHandler =
    (window as any)._AutofillCallbackHandler ||
    function () {
      // Do nothing
    };
};

interface Props {
  Component: any;

  // pageProps are injected (partly) by getServerSideProps. See pageUtils.ts
  pageProps: any;
}

let typedWord = '';
const handleGlobalKeyPress = (event: KeyboardEvent) => {
  const keyPressed = event.key;

  // Check if the pressed key is a single letter
  if (/^[A-Za-z]$/.test(keyPressed)) {
    typedWord += keyPressed.toLowerCase();

    if (typedWord === 'hevybros') {
      localStorageStores.betaFeatures.areBetaFeaturesEnabled = true;
    }
  } else {
    typedWord = '';
  }
};

function MyApp({ Component, pageProps }: Props) {
  fixAutofillUndefined();

  useEffect(() => {
    bootstrap();
    window.onkeydown = handleGlobalKeyPress;
  }, []);

  const router = useRouter();

  useEffect(() => {
    amplitudePageView(router.asPath);
    processURLQueryParams(router.query);
  }, [router]);

  return (
    <>
      <Head>
        {/* Ensure Safari doesn't auto-zoom on input focus */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Head>
      <Observer>
        {() => (
          <View>
            <ModalManager />
            <Toaster position="top-center" reverseOrder={false} />
            <AppNavigationWrapper isAuthenticated={!!pageProps.isAuthenticated}>
              <Component {...pageProps} />
            </AppNavigationWrapper>
          </View>
        )}
      </Observer>
    </>
  );
}

export default MyApp;
