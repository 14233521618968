import API from 'utils/API';
import { PushNotifications } from 'utils/globals/pushNotifications';

class PushAggregatorClass {
  requestPermissionAndRegister = async () => {
    await PushNotifications.requestNotificationPermission();
    if (PushNotifications.pushNotificationPermission === 'granted') {
      await this.registerPush();
    }
  };

  registerPush = async () => {
    await API.postRegisterBrowserPush(await PushNotifications.getPushSubscription());
  };

  unregisterPush = async () => {
    await API.postUnregisterBrowserPush(await PushNotifications.getPushSubscription());
  };
}

export const PushAggregator = new PushAggregatorClass();
