import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';

const CollapseButtonContainer = styled(View)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

interface CollapseChevronProps {
  isCollapsed: boolean;
  onClick: () => void;
}

export const CollapseChevron = ({ isCollapsed, onClick }: CollapseChevronProps) => {
  return (
    <CollapseButtonContainer
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <motion.div
        initial={{ scale: 1 }}
        animate={{
          rotate: isCollapsed ? -180 : 0,
          offsetRotate: 'auto',
          scale: 1,
        }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CustomizableIcon type="chevron-down" tint={colors.neutral900} />
      </motion.div>
    </CollapseButtonContainer>
  );
};
