'use client';
import { ProgramSelectionCard } from 'components/ProgramSelectionCard';
import { Program } from 'hevy-shared';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine, VerticalDividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextMD, TextSMMedium, TextXSRegular } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { AssignProgramViewModel } from './AssignProgramViewModel';
import { SearchInput } from 'styleguide/Inputs';
import { CloseButton } from 'components/CloseButton';
import { EmptyState } from 'components/EmptyState';
import { FlexRow } from 'styleguide/Row';
import { useWindowSize } from 'utils/useSize';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { Toggle } from 'components/Toggle';
import { ProgramCalendar } from 'components/ProgramScheduling/ProgramCalendar/ProgramCalendar';
import { ProgramDurationSelector } from 'components/ProgramScheduling/ProgramDurationSelector';
import { ProgramStartDateSelector } from 'components/ProgramScheduling/ProgramStartDateSelector';
import { HideOnPhoneDiv, ShowOnPhoneDiv } from 'components/mobileVsDesktopShowHideHelpers';

const Container = styled(View)`
  width: 996px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
  margin: ${Spacing.lg}px;
  margin-bottom: 0px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
    margin-bottom: 0px;
  }
`;

const MiddleContainer = styled(FlexRow)`
  overflow-y: auto;
  height: 100%;
  min-height: 30vh;
  align-items: flex-start;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const MobileScheduleContainer = styled(View)`
  padding: ${Spacing.md}px;
  padding-top: 0px;
  gap: ${Spacing.md}px;
  width: 100%;
  box-sizing: border-box; // to include padding in width and height
  @media (min-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const ProgramsContainer = styled(View)`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-left: ${Spacing.md}px;
  padding-right: ${Spacing.md}px;
  box-sizing: border-box; // to include padding in width and height
  min-height: 516px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    min-height: unset;
  }
`;

const ScheduleContainer = styled(View)`
  min-width: 332px;
  height: 100%;
  box-sizing: border-box; // to include padding in width and height
  padding: ${Spacing.lg}px;
  gap: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  margin: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
  }
`;

const NoProgramsContainer = styled(View)`
  align-items: center;
  justify-content: center;
  padding: ${Spacing.lg}px;
`;

const SearchInputContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px ${Spacing.md}px;
  }
`;

const ErrorText = styled(TextXSRegular)`
  color: ${colors.red};
`;

export interface AssignProgramModalProps {
  programs: Program[];
  vm: AssignProgramViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const AssignProgramModal = observer(({ isOpen, onClose, vm }: AssignProgramModalProps) => {
  const windowSize = useWindowSize();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <TextLG>
            {vm.programToReplace
              ? `Replace "${vm.programToReplace.title}" with...`
              : 'Program Templates'}
          </TextLG>
          <CloseButton onClick={onClose} />
        </UpperContainer>
        <SearchInputContainer>
          <SearchInput
            style={{ backgroundColor: colors.neutral100 }}
            value={vm.searchText}
            unfocusedBorderColor="transparent"
            onChange={vm.onSearchChanged}
            placeholder={'Search programs'}
          />
        </SearchInputContainer>
        <HideOnPhoneDiv>
          <DividerLine />
        </HideOnPhoneDiv>

        <MiddleContainer>
          <MobileScheduleContainer>
            <View>
              <FlexRow style={{ justifyContent: 'space-between' }}>
                <TextMD>Schedule</TextMD>
                <Toggle isOn={vm.isSchedulingEnabled} onClick={vm.onScheduleToggle} />
              </FlexRow>
              {vm.scheduleError && <ErrorText>{vm.scheduleError}</ErrorText>}
            </View>
            {vm.isSchedulingEnabled && (
              <FlexRow style={{ justifyContent: 'center', gap: Spacing.md }}>
                <View style={{ gap: Spacing.sm, flex: 1 }}>
                  <TextSMMedium>Duration</TextSMMedium>
                  <ProgramDurationSelector
                    selectedDuration={vm.selectedProgramDuration}
                    onSelectDuration={vm.onChangeDuration}
                  ></ProgramDurationSelector>
                </View>
                <View style={{ gap: Spacing.sm, flex: 1 }}>
                  <TextSMMedium>Start Date</TextSMMedium>
                  <ProgramStartDateSelector
                    startDate={vm.selectedProgramStartDate}
                    onSelectStartDate={vm.onChangeStartDate}
                    durationDays={vm.selectedProgramDuration}
                    firstWeekday={vm.coachFirstWeekday}
                  ></ProgramStartDateSelector>
                </View>
              </FlexRow>
            )}
          </MobileScheduleContainer>
          <ShowOnPhoneDiv style={{ width: '100%' }}>
            <DividerLine />
          </ShowOnPhoneDiv>
          <ProgramsContainer>
            {vm.filteredPrograms?.length === 0 ? (
              <NoProgramsContainer>
                <EmptyState
                  title={'No Programs Found'}
                  subtitle={
                    vm.isSearching
                      ? 'No programs matched your search'
                      : 'Create a workout program to get started'
                  }
                  iconType="folder"
                ></EmptyState>
                <PrimaryButton
                  iconType="plus"
                  style={{ marginTop: Spacing.md }}
                  title="Create Workout Program"
                  onClick={vm.createProgramClicked}
                />
              </NoProgramsContainer>
            ) : (
              <View style={{ marginBottom: Spacing.sm }}>
                {vm.programFolders.map(folder => (
                  <View key={folder.id}>
                    <FlexRow
                      style={{
                        marginBottom: Spacing.md,
                        marginTop:
                          windowSize.width < PHONE_WIDTH_BREAKPOINT ? Spacing.md : Spacing.lg,
                        gap: Spacing.sm,
                      }}
                    >
                      <CustomizableIcon type="folder-solid" tint={colors.neutral400} />
                      <TextMD>{folder.title}</TextMD>
                    </FlexRow>
                    <View
                      style={{
                        gap:
                          windowSize.width < PHONE_WIDTH_BREAKPOINT
                            ? Spacing.sm + Spacing.xs
                            : Spacing.md,
                      }}
                    >
                      {vm.programsForFolder(folder.id).map(p => (
                        <ProgramSelectionCard
                          key={p.id}
                          program={p}
                          onSelectProgram={vm.onSelectProgram}
                          isSelected={vm.selectedProgram?.id === p.id}
                        />
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            )}
          </ProgramsContainer>
          <HideOnPhoneDiv style={{ height: '100%' }}>
            <VerticalDividerLine />
          </HideOnPhoneDiv>
          <ScheduleContainer>
            {vm.selectedProgram ? (
              <>
                <FlexRow style={{ justifyContent: 'space-between' }}>
                  <TextMD>Schedule</TextMD>
                  <Toggle isOn={vm.isSchedulingEnabled} onClick={vm.onScheduleToggle} />
                </FlexRow>
                {vm.isSchedulingEnabled && (
                  <>
                    <View style={{ gap: Spacing.sm }}>
                      <TextSMMedium>Duration</TextSMMedium>
                      <ProgramDurationSelector
                        selectedDuration={vm.selectedProgramDuration}
                        onSelectDuration={vm.onChangeDuration}
                      ></ProgramDurationSelector>
                    </View>
                    <View style={{ gap: Spacing.sm }}>
                      <TextSMMedium>Start Date</TextSMMedium>
                      <ProgramCalendar
                        durationDays={vm.selectedProgramDuration}
                        selectedDate={vm.selectedProgramStartDate}
                        onSelectDate={vm.onChangeStartDate}
                        firstWeekday={vm.coachFirstWeekday}
                      ></ProgramCalendar>
                    </View>
                  </>
                )}
              </>
            ) : (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <EmptyState title="Select a Program" iconType="notepad" />
              </View>
            )}
          </ScheduleContainer>
        </MiddleContainer>
        <DividerLine />
        {vm.filteredPrograms.length !== 0 && (
          <LowerContainer>
            <PrimaryButton
              title={'Copy Program to Client'}
              onClick={vm.assignProgramClicked}
              enabled={vm.isAssignProgramButtonEnabled}
              loading={vm.isAssigningProgram}
              style={{ width: windowSize.width < PHONE_WIDTH_BREAKPOINT ? '100%' : 'auto' }}
            />
            <SecondaryButton
              title={'Create New Program'}
              onClick={vm.createProgramClicked}
              loading={vm.isCreatingProgram}
              style={{ width: windowSize.width < PHONE_WIDTH_BREAKPOINT ? '100%' : 'auto' }}
            />
          </LowerContainer>
        )}
      </Container>
    </Modal>
  );
});
