import { CoachInviteSearchUser, CoachRole } from 'hevy-shared';

export const isACoachInviteSearchUser = (
  result: CoachInviteSearchUser | string,
): result is CoachInviteSearchUser => {
  return (result as CoachInviteSearchUser).username !== undefined;
};

export interface EmailInvitee {
  type: 'email';
  email: string;
}

export interface UserInvitee {
  type: 'user';
  userData: CoachInviteSearchUser;
}

export type Invitee = UserInvitee | EmailInvitee;

export type CoachInvitee = Invitee & { role: CoachRole };

export interface InviteError {
  invitee: Invitee | CoachInvitee;
  error: string;
}
