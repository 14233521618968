import React, { useState } from 'react';
import { ProfileImage } from 'components/ProfileImage';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { HevyOptionMenu } from 'components/HevyMenu/HevyMenu';
import { HevyMenuItem } from 'components/HevyMenu/HevyMenuItem';
import { TextXSRegular } from 'styleguide/Texts';

const Container = styled(View)<{ source: 'widget' | 'screen' }>`
  max-width: ${props => (props.source === 'widget' ? '100% ' : '80%')};
  gap: ${Spacing.xs}px;
`;

interface ChatMessageContainerProps {
  isSender: boolean;
  source: 'widget' | 'screen';
  clientProfilePic?: string;
  children: React.ReactNode;
  messageTimestamp?: string;
  isLastMessageOfMessageBlock?: boolean;
  onDeleteMessageClick?: () => void;
}

export const ChatMessageContainer = ({
  isSender,
  source,
  children,
  clientProfilePic,
  isLastMessageOfMessageBlock,
  onDeleteMessageClick,
  messageTimestamp,
}: ChatMessageContainerProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const profilePicDiameter = 32;

  if (isSender) {
    return (
      <Container
        style={{
          alignSelf: 'flex-end',
          marginBottom: isLastMessageOfMessageBlock ? Spacing.sm : 0,
        }}
        source={source}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <FlexRow style={{ alignItems: 'flex-end', gap: Spacing.sm, justifyContent: 'flex-end' }}>
          {!!onDeleteMessageClick && (
            <HevyOptionMenu style={{ marginLeft: Spacing.sm, opacity: isHovering ? 100 : 0 }}>
              <HevyMenuItem
                onClick={onDeleteMessageClick}
                title="Delete Message"
                iconType="delete"
              ></HevyMenuItem>
            </HevyOptionMenu>
          )}
          {children}
        </FlexRow>
        {!!messageTimestamp && (
          <FlexRow style={{ justifyContent: 'flex-end' }}>
            <TextXSRegular>{messageTimestamp}</TextXSRegular>
          </FlexRow>
        )}
      </Container>
    );
  }
  return (
    <Container
      style={{
        alignSelf: 'flex-start',
        marginBottom: isLastMessageOfMessageBlock ? Spacing.sm : 0,
      }}
      source={source}
    >
      <FlexRow style={{ alignItems: 'flex-end', gap: Spacing.sm }}>
        {!!isLastMessageOfMessageBlock ? (
          <ProfileImage diameter={profilePicDiameter} source={clientProfilePic} />
        ) : (
          <View style={{ width: 32, flexShrink: 0 }}></View>
        )}
        {children}
      </FlexRow>
      {!!messageTimestamp && (
        <FlexRow style={{ justifyContent: 'flex-end' }}>
          <TextXSRegular>{messageTimestamp}</TextXSRegular>
        </FlexRow>
      )}
    </Container>
  );
};
