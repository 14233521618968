import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { Message } from 'hevy-shared';
import React from 'react';
import styled from 'styled-components';
import { CircleNumber } from 'styleguide/CircleNumber';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { getShortRelativeDate } from 'utils/dateAndTimeUtils';

const Container = styled(FlexRow)`
  justify-content: center;
  padding: ${Spacing.md}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
`;

interface ClientChatCellProps {
  clientId: string;
  clientName: string;
  clientProfilePic?: string;
  lastMessage?: Message;
  unreadMessages: number;
  isSelected?: boolean;
  onClick: () => void;
}

export const ClientChatCell = ({
  clientId,
  clientName,
  clientProfilePic,
  lastMessage,
  isSelected,
  onClick,
  unreadMessages,
}: ClientChatCellProps) => {
  const [isHovering, setIsHovering] = React.useState(false);

  let lastMessageText: string | undefined;
  switch (lastMessage?.type) {
    case 'text':
      lastMessageText = lastMessage.text;
      break;
    case 'image':
      lastMessageText = 'sent an image';
      break;
    case 'video':
      lastMessageText = 'sent a video';
      break;
  }

  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ backgroundColor: isSelected || isHovering ? colors.neutral100 : colors.white }}
      onClick={onClick}
    >
      <ProfileImage diameter={44} source={clientProfilePic} />
      <View style={{ flex: 1, minWidth: 0 }}>
        <FlexRow
          style={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: Spacing.xs,
          }}
        >
          <TextSMMedium>{clientName}</TextSMMedium>
          {!!lastMessage && (
            <TextXSRegular style={{ color: colors.neutral400, textAlign: 'right' }}>
              {getShortRelativeDate(dayjs().diff(dayjs(lastMessage?.created_at)) / 1000)}
            </TextXSRegular>
          )}
        </FlexRow>
        {!!lastMessage && lastMessageText && (
          <FlexRow style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <TextSMRegular
              style={{
                maxLines: 1,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                paddingTop: Spacing.xs,
              }}
            >
              {lastMessage?.sender_user_id !== clientId
                ? `You: ${lastMessageText} `
                : lastMessageText}
            </TextSMRegular>
            {unreadMessages > 0 && (
              <CircleNumber style={{ marginLeft: Spacing.xs }} number={unreadMessages} />
            )}
          </FlexRow>
        )}
      </View>
    </Container>
  );
};
