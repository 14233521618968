import React from 'react';
import styled from 'styled-components';
import { TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';
import { ChatMessageContainer } from './ChatMessageContainer';

const ChatBubbleContainer = styled(FlexRow)<{ maxWidth: number | undefined }>`
  padding: ${Spacing.sm}px ${Spacing.md}px;
  max-width: 360px;
  justify-content: center;
  max-width: ${props => props.maxWidth}px;
  position: relative;
`;

const ClientChatBubbleContainer = styled(ChatBubbleContainer)<{
  isFirstMessageOfBlock?: boolean;
}>`
  border-radius: ${props => (props.isFirstMessageOfBlock ? 16 : 2)}px 18px 18px 2px;
  background: ${colors.neutral150};
  gap: ${Spacing.sm}px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CoachChatBubbleContainer = styled(ChatBubbleContainer)<{ isFirstMessageOfBlock?: boolean }>`
  border-radius: 18px ${props => (props.isFirstMessageOfBlock ? 18 : 2)}px 2px 18px;
  background: ${colors.primary500};
  gap: ${Spacing.xs}px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const MessageText = styled(TextSMRegular)<{ color: string }>`
  color: ${props => props.color};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  min-width: 0;
  margin-right: 32px;
`;

const MessageTimestamp = styled(TextXSRegular)<{ color: string }>`
  color: ${props => props.color};
  position: absolute;
  right: ${Spacing.sm}px;
  bottom: ${Spacing.xs}px;
`;

interface TextChatBubbleProps {
  message: string;
  source: 'widget' | 'screen';
  timestamp: string;
  isSender: boolean;
  profilePic?: string;
  maxWidth?: number;
  isLastMessageOfMessageBlock?: boolean;
  isFirstMessageOfMessageBlock?: boolean;
  onDeleteMessageClick?: () => void;
}

export const TextChatBubble = ({
  message,
  source,
  isSender,
  profilePic,
  timestamp,
  maxWidth,
  isLastMessageOfMessageBlock,
  isFirstMessageOfMessageBlock,
  onDeleteMessageClick,
}: TextChatBubbleProps) => {
  return isSender ? (
    <CoachTextChatBubble
      onDeleteMessageClick={onDeleteMessageClick}
      message={message}
      timestamp={timestamp}
      maxWidth={maxWidth}
      isFirstMessageOfMessageBlock={isFirstMessageOfMessageBlock}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
      source={source}
    />
  ) : (
    <ClientTextChatBubble
      message={message}
      profilePic={profilePic}
      timestamp={timestamp}
      maxWidth={maxWidth}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
      isFirstMessageOfMessageBlock={isFirstMessageOfMessageBlock}
      source={source}
    />
  );
};

interface ClientTextChatBubbleProps {
  message: string;
  timestamp: string;
  source: 'widget' | 'screen';
  profilePic?: string;
  maxWidth?: number;
  isLastMessageOfMessageBlock?: boolean;
  isFirstMessageOfMessageBlock?: boolean;
}

export const ClientTextChatBubble = ({
  message,
  source,
  timestamp,
  profilePic,
  maxWidth,
  isLastMessageOfMessageBlock,
  isFirstMessageOfMessageBlock,
}: ClientTextChatBubbleProps) => {
  return (
    <ChatMessageContainer
      isSender={false}
      clientProfilePic={profilePic}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
      source={source}
    >
      <ClientChatBubbleContainer
        maxWidth={maxWidth}
        isFirstMessageOfBlock={isFirstMessageOfMessageBlock}
      >
        <MessageText color={colors.neutral900}>{message.trim()}</MessageText>
        <MessageTimestamp color={colors.neutral500}>{timestamp}</MessageTimestamp>
      </ClientChatBubbleContainer>
    </ChatMessageContainer>
  );
};

interface ClientChatBubbleProps {
  children: React.ReactNode;
  timestamp: string;
  source: 'widget' | 'screen';
  profilePic?: string;
  maxWidth?: number;
  isLastMessageOfMessageBlock?: boolean;
}
export const ClientChatBubble = ({
  children,
  source,
  timestamp,
  profilePic,
  maxWidth,
  isLastMessageOfMessageBlock,
}: ClientChatBubbleProps) => {
  return (
    <ChatMessageContainer
      isSender={false}
      source={source}
      clientProfilePic={profilePic}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
      messageTimestamp={timestamp}
    >
      <ClientChatBubbleContainer maxWidth={maxWidth}>{children}</ClientChatBubbleContainer>
    </ChatMessageContainer>
  );
};

interface CoachChatTextBubbleProps {
  message: string;
  source: 'widget' | 'screen';
  timestamp: string;
  maxWidth?: number;
  isFirstMessageOfMessageBlock?: boolean;
  isLastMessageOfMessageBlock?: boolean;
  onDeleteMessageClick?: () => void;
}

export const CoachTextChatBubble = ({
  message,
  source,
  timestamp,
  maxWidth,
  isFirstMessageOfMessageBlock,
  isLastMessageOfMessageBlock,
  onDeleteMessageClick,
}: CoachChatTextBubbleProps) => {
  return (
    <ChatMessageContainer
      onDeleteMessageClick={onDeleteMessageClick}
      isSender={true}
      source={source}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
    >
      <CoachChatBubbleContainer
        maxWidth={maxWidth}
        isFirstMessageOfBlock={isFirstMessageOfMessageBlock}
      >
        <MessageText color={colors.white}>{message.trim()}</MessageText>
        <MessageTimestamp color={colors.primary200}>{timestamp}</MessageTimestamp>
      </CoachChatBubbleContainer>
    </ChatMessageContainer>
  );
};
