import { Stats } from 'components/WorkoutCell/components/Stats';
import { WorkoutSocialPreview } from 'components/WorkoutSocialPreview';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextMD, TextMDRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Exercise } from './components/Exercise';
import { UserHeader } from './components/UserHeader';
import { WorkoutDetailViewModel } from './WorkoutDetailViewModel';
import { observer } from 'mobx-react-lite';
import { DividerLine } from 'styleguide/DividerLine';
import { modal } from '../ModalManager';
import { CloseButton } from 'components/CloseButton';
import { zIndexes } from 'styleguide/zIndex';
import { WorkoutActions } from './components/WorkoutActions';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(View)`
  padding: ${Spacing.lg}px;
  padding-bottom: 0;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
    padding-bottom: 0;
  }
`;

const LowerContainer = styled(View)`
  padding: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
  }
`;

export interface WorkoutDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  vm: WorkoutDetailViewModel;
}

export const WorkoutDetailModal = observer(({ isOpen, onClose, vm }: WorkoutDetailModalProps) => {
  if (!vm.metaData) return <></>;

  return (
    <Modal overlayStyle={{ zIndex: zIndexes.modalOverlay }} isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <UserHeader
              id={vm.metaData.user_id}
              username={vm.metaData.username}
              date={vm.metaData.date}
              profileImage={vm.metaData.profileImage}
              onClick={onClose}
            />
            <CloseButton onClick={onClose} />
          </View>

          <TextMD style={{ marginTop: Spacing.md }}>{vm.metaData.title}</TextMD>
          {!!vm.metaData.description && (
            <TextMDRegular style={{ color: colors.neutral500, marginTop: Spacing.sm }}>
              {vm.metaData.description}
            </TextMDRegular>
          )}
          <Stats stats={vm.metaData.stats} />
          <WorkoutSocialPreview
            style={{ marginTop: Spacing.md, marginBottom: Spacing.md }}
            likeCount={vm.metaData.likeCount}
            likeImages={vm.metaData.likeImages}
            commentCount={vm.metaData.commentCount}
            onCommentClick={() => {
              modal.openCommentModal({ workoutId: vm.workoutId });
            }}
          />
          <WorkoutActions
            isLiked={vm.metaData.isLiked}
            onLike={vm.onLikeWorkout}
            onShare={vm.onShareWorkout}
            onComment={() => {
              modal.openCommentModal({ workoutId: vm.workoutId });
            }}
          />

          <DividerLine />
        </UpperContainer>

        <LowerContainer>
          {vm.exercises.map(e => (
            <Exercise
              key={e.key}
              image={e.image}
              title={e.title}
              supersetId={e.supersetId}
              notes={e.notes}
              secondColumnHeader={e.secondColumnHeader}
              sets={e.sets}
              exerciseDetailURL={e.exerciseDetailURL}
            />
          ))}
        </LowerContainer>
      </Container>
    </Modal>
  );
});
