import { makeAutoObservable } from 'mobx';
import {
  CreateCustomExerciseRequest,
  customExerciseTemplateToExerciseTemplate,
  Equipment,
  ExerciseTemplate,
  ExerciseType,
  MuscleGroup,
} from 'hevy-shared';
import API from 'utils/API';
import exerciseData from 'state/static/exerciseData.json';
import { captureException } from '@sentry/nextjs';

const CUSTOM_EXERCISES_LOCAL_STORAGE_KEY = 'CUSTOM_EXERCISES_LOCAL_STORAGE_KEY';

export class ExerciseTemplates {
  private _customExercises: ExerciseTemplate[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get exercises(): ExerciseTemplate[] {
    const allExercises = [...this._customExercises, ...this.bundledTemplates];
    return allExercises
      .filter(e => e.is_archived === false)
      .sort(function (a, b) {
        const textA = a.title.toUpperCase();
        const textB = b.title.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  }

  private cachedBundledExercises?: ExerciseTemplate[];
  get bundledTemplates(): ExerciseTemplate[] {
    if (this.cachedBundledExercises) return this.cachedBundledExercises;

    this.cachedBundledExercises = Object.values(exerciseData).map(rawExerciseToExerciseTemplate);
    return this.cachedBundledExercises;
  }

  get customExercises(): ExerciseTemplate[] {
    return this._customExercises;
  }

  createCustomExercise = async (exercise: CreateCustomExerciseRequest) => {
    const response = await API.postCustomExerciseTemplate(exercise);

    this._customExercises.push({
      id: response.data.id,
      title: exercise.title,
      priority: 0,
      muscle_group: exercise.muscle_group,
      other_muscles: exercise.other_muscles,
      exercise_type: exercise.exercise_type,
      equipment_category: exercise.equipment_category,
      is_custom: true,
      is_archived: false,
    });
  };

  getExercise = (templateId: string) => {
    const maybeBundledExercise = getBundledExercise(templateId);

    return maybeBundledExercise
      ? maybeBundledExercise
      : this._customExercises.find(e => e.id === templateId);
  };

  hydrate = () => {
    const customExercisesJson = window.localStorage.getItem(CUSTOM_EXERCISES_LOCAL_STORAGE_KEY);
    if (customExercisesJson) {
      this._customExercises = JSON.parse(customExercisesJson);

      window.localStorage.setItem(
        CUSTOM_EXERCISES_LOCAL_STORAGE_KEY,
        JSON.stringify(this._customExercises),
      );
    }
  };

  clearData = () => {
    this._customExercises = [];
    window.localStorage.removeItem(CUSTOM_EXERCISES_LOCAL_STORAGE_KEY);
  };

  isFetching = false;
  hasFetched = false;
  fetch = async () => {
    if (this.isFetching) {
      return;
    }
    this.isFetching = true;
    try {
      const result = await API.getCustomExerciseTemplates();
      this._customExercises = result.data.map(customExerciseTemplateToExerciseTemplate);
      window.localStorage.setItem(
        CUSTOM_EXERCISES_LOCAL_STORAGE_KEY,
        JSON.stringify(this._customExercises),
      );
    } catch (error) {
      captureException(error);
      throw error;
    } finally {
      this.hasFetched = true;
      this.isFetching = false;
    }
  };
}

const getBundledExercise = (id: string): ExerciseTemplate | undefined => {
  //@ts-ignore
  const bundledExercise = exerciseData[id];
  if (!bundledExercise) {
    return undefined;
  }
  return rawExerciseToExerciseTemplate(bundledExercise);
};

const rawExerciseToExerciseTemplate = (bundledExercise: any): ExerciseTemplate => {
  return {
    id: bundledExercise.id,
    title: bundledExercise.title,
    es_title: bundledExercise.es_title,
    de_title: bundledExercise.de_title,
    fr_title: bundledExercise.fr_title,
    priority: bundledExercise.priority,
    muscle_group: bundledExercise.muscle_group as MuscleGroup,
    //@ts-ignore
    other_muscles: (bundledExercise.other_muscles || []) as MuscleGroup[],
    exercise_type: bundledExercise.exercise_type as ExerciseType,
    equipment_category: bundledExercise.equipment_category as Equipment,
    //@ts-ignore
    url: bundledExercise.url,
    //@ts-ignore
    media_type: bundledExercise.media_type,
    //@ts-ignore
    manual_tag: bundledExercise.manual_tag,
    //@ts-ignore
    aka: bundledExercise.aka,
    thumbnail_url: bundledExercise.thumbnail,
    is_custom: false,
    is_archived: !!bundledExercise.is_archived,
    instructions: bundledExercise.exercise_instructions,
  };
};
