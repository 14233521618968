import { captureException } from '@sentry/nextjs';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { CoachsClientData } from 'hevy-shared';
import dayjs from 'dayjs';
import { Client } from 'types/client';

export class Clients {
  private _clients: {
    [username: string]: Client;
  } = {};

  clientWorkoutHistory: { [user_id: string]: { workout_end_unix: number }[] } = {};

  constructor() {
    makeAutoObservable(this);
  }

  clearData = () => {
    this._clients = {};
  };

  isFetchingClients = false;
  fetch = async () => {
    if (this.isFetchingClients) {
      return;
    }
    try {
      this.isFetchingClients = true;
      const response = await API.getClients();

      this.updateModel(response.data);
    } catch (e) {
      captureException(e);
      throw e;
    } finally {
      this.isFetchingClients = false;
    }
  };

  isLoadingWorkoutHistory = false;
  fetchClientWorkoutHistory = async () => {
    if (this.isLoadingWorkoutHistory) {
      return;
    }
    this.isLoadingWorkoutHistory = true;
    try {
      if (this.allClients.length !== 0) {
        this.clientWorkoutHistory = (
          await API.getClientsWorkoutHistory(
            dayjs().startOf('day').subtract(7, 'days').unix(),
            dayjs().endOf('day').unix(),
          )
        ).data;
      }
    } catch {
      throw new Error('Failed to fetch client workout history');
    } finally {
      this.isLoadingWorkoutHistory = false;
    }
  };

  get isWorkoutHistoryEmpty() {
    return Object.keys(this.clientWorkoutHistory).length === 0;
  }

  updateModel = (clients: CoachsClientData[]) => {
    this._clients = {};
    clients.forEach(client => {
      this._clients[client.username] = {
        ...client,
        coachId: client.coach_id,
        createdAt: client.user_created_at,
        coachingStarted: client.coaching_started_at,
        profilePic: client.profile_pic ?? undefined,
        fullName: client.full_name ?? undefined,
        notes: client.notes ?? undefined,
        userPreferences: {
          weightUnit: client.user_preferences?.weight_unit ?? 'kg',
          distanceUnitShort: client.user_preferences?.distance_unit === 'miles' ? 'mi' : 'km',
          defaultRestTimerSeconds: client.user_preferences?.default_rest_timer_seconds ?? 0,
          distanceUnit: client.user_preferences?.distance_unit ?? 'kilometers',
        },
        customExercises: client.custom_exercises,
        lastWorkoutDate:
          client.last_workout_at !== '1969-12-31T22:00:00.000Z' // last_workout_at can not be null but it can be 1969-12-31T22:00:00.000Z
            ? client.last_workout_at
            : undefined,
        isSampleUser: client.is_sample_user,
        configuration: client.client_configuration,
      };
    });
  };

  clientForUserId = (userId?: string): Client | undefined => {
    return Object.values(this._clients).find(client => {
      return client.id === userId;
    });
  };

  clientForUsername = (username: string): Client | undefined => {
    if (!username) {
      return undefined;
    }
    return this._clients[username];
  };

  /**
   * Returns all clients of ALL COACHES in the team.
   * !! Including sample clients, so be careful when using this method
   */
  get allClients() {
    return Object.values(this._clients);
  }

  get nonSampleClients() {
    return Object.values(this._clients).filter(client => {
      return !client.isSampleUser;
    });
  }

  get nonSampleClientsCount() {
    return this.nonSampleClients.length;
  }

  sampleClientForCoachId = (coachId: string): Client | undefined => {
    return this.nonSampleClients.find(client => {
      return client.coachId === coachId;
    });
  };

  reassignClientsToCoach = async (clientIds: string[], coachId: string, keepProgram: boolean) => {
    await API.postReassignClients({
      targetClientIds: clientIds,
      targetCoachId: coachId,
      keepProgram,
    });
    for (const clientId of clientIds) {
      const client = this.clientForUserId(clientId);
      if (!client) continue;
      client.coachId = coachId;
    }
  };

  /**
   * Returns all non-sample clients for a given coach.
   */
  clientsForCoachId = (coachId: string): Client[] => {
    return this.allClientsForCoachId(coachId).filter(client => !client.isSampleUser);
  };

  /**
   * Returns all clients for a given coach.
   * !! Including sample clients, so be careful when using this method
   */
  allClientsForCoachId = (coachId: string): Client[] => {
    return this.allClients.filter(client => {
      return client.coachId === coachId;
    });
  };
}
