import React from 'react';
import { getMuscleHeatmapOverlays } from './utils';
import styled from 'styled-components';
import { MuscleHeatmapData } from 'hevy-shared';

const HeatmapContainer = styled.div`
  position: relative;
  width: 100%;
  justify-content: space-around;
  display: flex;
`;

const ImageContainer = styled.div`
  position: absolute;
  height: 100%;
  // The ratio of the heatmap images, to make sure we take the correct vertical space
  aspect-ratio: 0.42105263;
`;

const HeatmapImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const MuscleHeatmap = ({
  data,
  side,
  height,
}: {
  data: MuscleHeatmapData;
  side: 'back' | 'front';
  height: number;
}) => {
  const images = getMuscleHeatmapOverlays(data);
  return (
    <HeatmapContainer style={{ height: height }}>
      <ImageContainer>
        <HeatmapImage
          src={
            side === 'back'
              ? '/images/body_heatmap/male_back_base.png'
              : '/images/body_heatmap/male_front_base.png'
          }
        />
      </ImageContainer>
      {images.map((image, index) => (
        <ImageContainer key={index}>
          <HeatmapImage
            src={side === 'back' ? image.back : image.front}
            style={{ opacity: image.opacity }}
          />
        </ImageContainer>
      ))}
    </HeatmapContainer>
  );
};
