import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';

class BackendReachabilityClass {
  backendReachabilityState: 'reachable' | 'unreachable' | 'maintenance-mode' = 'reachable';
  constructor() {
    makeAutoObservable(this);
  }

  handleRequestFulfilled = () => {
    this.backendReachabilityState = 'reachable';
  };

  handleRequestFailed = (error: AxiosError) => {
    if (!error.response) {
      // No response: unable to get a response at all from the backend (DNS failure, network failure, etc.)
      this.backendReachabilityState = 'unreachable';
      return;
    }

    if (
      error.response?.status === 503 &&
      typeof error.response?.data === 'string' &&
      (error.response!.data as string).includes('maintenance')
    ) {
      // 503 response code with "maintenance" in the body: we're definitely in maintenance mode
      this.backendReachabilityState = 'maintenance-mode';
    } else {
      // We got a response that isn't maintenenace mode, so we're reachable (even if it's a non-200 response)
      this.backendReachabilityState = 'reachable';
    }
  };
}

export const BackendReachability = new BackendReachabilityClass();
