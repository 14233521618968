import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';

const EXERCISE_TEMPLATE_CUSTOMIZATIONS_LOCAL_STORAGE_KEY =
  'EXERCISE_TEMPLATE_CUSTOMIZATIONS_LOCAL_STORAGE_KEY';

export interface VideoAttachment {
  type: 'youtube' | 'mp4_video';
  url: string;
}

interface ExerciseTemplateCustomization {
  exercise_template_id: string;
  instructions?: string;
  video?: VideoAttachment;
}
export class ExerciseTemplateCustomizations {
  public exerciseTemplateCustomizations: ExerciseTemplateCustomization[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const exerciseTemplateCustomizationsJSON = window.localStorage.getItem(
      EXERCISE_TEMPLATE_CUSTOMIZATIONS_LOCAL_STORAGE_KEY,
    );
    if (exerciseTemplateCustomizationsJSON) {
      this.exerciseTemplateCustomizations = JSON.parse(exerciseTemplateCustomizationsJSON);
    }
  };

  clearData = () => {
    this.exerciseTemplateCustomizations = [];
    window.localStorage.removeItem(EXERCISE_TEMPLATE_CUSTOMIZATIONS_LOCAL_STORAGE_KEY);
  };

  getExerciseCustomization = (templateId: string) => {
    return this.exerciseTemplateCustomizations.find(e => {
      return e.exercise_template_id === templateId;
    });
  };

  isFetching = false;
  hasFetched = false;
  fetch = async () => {
    if (this.isFetching) {
      return;
    }
    this.isFetching = true;
    try {
      const result = await API.getExerciseCustomizations();
      this.exerciseTemplateCustomizations = result.data.map(c => {
        return {
          exercise_template_id: c.exercise_template_id,
          instructions: c.instructions,
          video:
            c.video_url_type && c.video_url
              ? { type: c.video_url_type, url: c.video_url }
              : undefined,
        };
      });
      window.localStorage.setItem(
        EXERCISE_TEMPLATE_CUSTOMIZATIONS_LOCAL_STORAGE_KEY,
        JSON.stringify(this.exerciseTemplateCustomizations),
      );
    } catch (error) {
      captureException(error);
      throw error;
    } finally {
      this.hasFetched = true;
      this.isFetching = false;
    }
  };
}
