import styled from 'styled-components';
import { TextXSMedium } from './Texts';
import { View } from './View';
import { Spacing, CornerRadius } from './spacing';
import React from 'react';

const StatusPillContainer = styled.div<{ backgroundColor: string }>`
  padding-left: ${Spacing.sm}px;
  padding-right: ${Spacing.sm}px;
  border-radius: ${CornerRadius.xs}px;
  background-color: ${props => props.backgroundColor};
  flex: 0;
  flex-shrink: 1;
`;

export const TextPill = ({
  text,
  backgroundColor,
  textColor,
  containerStyle,
}: {
  text: string;
  backgroundColor: string;
  textColor: string;
  containerStyle?: React.CSSProperties;
}) => {
  return (
    <View style={{ width: 'fit-content', ...containerStyle }}>
      <StatusPillContainer backgroundColor={backgroundColor}>
        <TextXSMedium style={{ color: textColor }}>{text}</TextXSMedium>
      </StatusPillContainer>
    </View>
  );
};
