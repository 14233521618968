export interface Env {
  env: string;
  apiKey: string;
  apiUrl: string;
  chatApiKey: string;
  chatServerHttpUrl: string;
  chatServerWsUrl: string;
  tiktokPixelToken: string;
  vapidPublicKey: string;
  hevyCoachBaseUrl: string;
  hevyWebBaseUrl: string;
}

export const env: Env = {
  env: process.env.NEXT_PUBLIC_ENV || '',
  apiKey: process.env.NEXT_PUBLIC_API_KEY || '',
  apiUrl: process.env.NEXT_PUBLIC_API_URL || '',
  chatApiKey: process.env.NEXT_PUBLIC_HEVY_CHAT_API_KEY || '',
  chatServerHttpUrl: process.env.NEXT_PUBLIC_HEVY_CHAT_HTTP_SERVER_URL || '',
  chatServerWsUrl: process.env.NEXT_PUBLIC_HEVY_CHAT_WS_SERVER_URL || '',
  tiktokPixelToken: process.env.NEXT_PUBLIC_TIKTOK_PIXEL_TOKEN || '',
  vapidPublicKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY || '',
  hevyCoachBaseUrl: process.env.NEXT_PUBLIC_HEVY_COACH_BASE_URL || 'https://app.hevycoach.com',
  hevyWebBaseUrl: process.env.NEXT_PUBLIC_HEVY_WEB_BASE_URL || 'https://hevy.com',
};

if (process.env.NEXT_PUBLIC_ENV !== 'production') {
  (global as any).env = env;
}
