import dayjs from 'dayjs';
import React from 'react';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { TextSMMedium, TextSMRegular, TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { PictureType } from '../ProgressPictureModalViewModel';
import { colors } from 'styleguide/colors';
import { CircleView } from 'styleguide/CircleView';
import {
  BodyMeasurementWithImage,
  measurementToString,
} from 'screens/ClientDetail/Tabs/ProgressPictures/utils';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';

const EmptyState = ({ pictureType }: { pictureType?: PictureType }) => {
  return (
    <View
      style={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: Spacing.md,
      }}
    >
      <CircleView
        diameter={48}
        style={{
          backgroundColor: colors.neutral100,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomizableIcon type="camera" tint={colors.neutral400} />
      </CircleView>
      <TextSMRegular style={{ margin: Spacing.sm }}>
        Select a {pictureType === 'after' ? 'after' : 'before'} picture to compare
      </TextSMRegular>
    </View>
  );
};

const PictureOverlay = styled(View)`
  position: absolute;
  bottom: ${Spacing.md}px;
  right: ${Spacing.md}px;
  border-radius: ${CornerRadius.sm}px;
  background-color: ${colors.black}6E;
  padding: ${Spacing.xs / 2}px ${Spacing.sm}px;
`;

const Picture = styled.img`
  width: 100%;
  aspect-ratio: 3/4;
`;

export const LargeProgressPicture = ({
  isActive,
  measurement,
  pictureType,
  onClick,
}: {
  isActive: boolean;
  measurement?: BodyMeasurementWithImage;
  pictureType?: PictureType;
  onClick?: () => void;
}) => {
  const activeBorder = `2px solid ${colors.primary500}`;
  return (
    <View style={{ width: '100%', height: '100%' }} onClick={onClick}>
      <View
        style={{
          borderRadius: CornerRadius.md,
          overflow: 'hidden',
          padding: 2,
          border: isActive ? activeBorder : undefined,
          height: '100%',
        }}
      >
        <View style={{ borderRadius: CornerRadius.sm, overflow: 'hidden', height: '100%' }}>
          {!!measurement ? (
            <View style={{ position: 'relative' }}>
              {!!pictureType && (
                <PictureOverlay>
                  <TextSMMedium style={{ color: colors.white }}>
                    {pictureType === 'after' ? 'After' : 'Before'}
                  </TextSMMedium>
                </PictureOverlay>
              )}
              <Picture src={measurement.picture_url} />
            </View>
          ) : (
            <EmptyState pictureType={pictureType} />
          )}
        </View>
      </View>
      <TextMD style={{ paddingTop: Spacing.sm, minHeight: 24 }}>
        {!!measurement && dayjs(measurement.date).format('DD MMM, YYYY')}
      </TextMD>
      <TextSMRegular style={{ minHeight: 20 }}>
        {!!measurement && measurementToString(measurement, CoachUnitsAggregator.weightUnit)}
      </TextSMRegular>
    </View>
  );
};

const LowerContainer = styled(View)`
  padding: ${Spacing.md}px;
  gap: ${Spacing.md}px;
`;

export const MobileLargeProgressPicture = ({
  measurement,
  pictureType,
  onClickChangePicture,
}: {
  measurement?: BodyMeasurementWithImage;
  pictureType?: PictureType;
  onClickChangePicture: (type?: PictureType) => void;
}) => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        borderLeft: pictureType === 'after' ? `1px solid ${colors.neutral150}` : undefined,
      }}
    >
      <View style={{ overflow: 'hidden', flex: 1 }}>
        {!!measurement ? (
          <View style={{ position: 'relative' }}>
            {!!pictureType && (
              <PictureOverlay>
                <TextSMMedium style={{ color: colors.white }}>
                  {pictureType === 'after' ? 'After' : 'Before'}
                </TextSMMedium>
              </PictureOverlay>
            )}
            <Picture src={measurement.picture_url} />
          </View>
        ) : (
          <EmptyState pictureType={pictureType} />
        )}
      </View>
      <LowerContainer>
        <View style={{ minHeight: 44 }}>
          <TextMD>{!!measurement && dayjs(measurement.date).format('DD MMM, YYYY')}</TextMD>
          <TextSMRegular>
            {!!measurement && measurementToString(measurement, CoachUnitsAggregator.weightUnit)}
          </TextSMRegular>
        </View>

        {!measurement ? (
          <PrimaryButton
            title="Select"
            onClick={() => onClickChangePicture(pictureType)}
            style={{ width: '100%' }}
          />
        ) : (
          <SecondaryButton
            title="Change"
            onClick={() => onClickChangePicture(pictureType)}
            style={{ width: '100%' }}
          />
        )}
      </LowerContainer>
    </View>
  );
};
