import { loadScript } from './loadScriptPromise';
import { env } from 'env';

export const initAppleLogin = async () => {
  try {
    await loadScript(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    );
  } catch (error) {
    return;
  }
  (window as any)?.AppleID?.auth.init({
    clientId: 'com.hevyapp.web',
    scope: 'email',
    redirectURI: env.hevyCoachBaseUrl,
    usePopup: true,
  });
};
