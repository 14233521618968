export interface ExerciseInstructionsStep {
  index?: number;
  description: string;
}

export const rawInstructionsToIndexedSteps = (rawInstructions: string) => {
  const instructionsByLine: string[] = rawInstructions
    .split('\n')
    .map(l => l.trim())
    .filter(l => l.length > 0);

  const instructionsByStep: ExerciseInstructionsStep[] = instructionsByLine.map(s => {
    const parsedStep = s.match(/^([0-9]*)\.(.*)$/);

    if (!parsedStep) {
      // this line doesn't contain a number, so just return it unchanged
      return { description: s };
    }

    const index = Number(parsedStep[1]) || undefined; // if it's NaN or 0, convert it to undefined
    const description = parsedStep[2]?.trim() ?? s;

    return { index, description };
  });

  return instructionsByStep;
};
