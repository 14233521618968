'use client';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CreateRoutineCard, RoutineSelectionCard } from './RoutineSelectionCard';
import { SelectRoutineViewModel } from './SelectRoutineViewModel';
import { SearchInput } from 'styleguide/Inputs';
import { HevyTooltip } from 'styleguide/HevyTooltip';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(View)`
  margin: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
  }
`;

const MiddleContainer = styled(View)`
  height: 50vh;
  overflow-y: auto;
  padding: ${Spacing.md}px 0px;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
  }
`;

const NoRoutinesContainer = styled(View)`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: ${Spacing.lg}px 0px;
`;

const RoutinesContainer = styled(View)`
  width: 100%;
  gap: ${Spacing.md}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    gap: ${Spacing.sm + Spacing.xs}px;
  }
`;

const ButtonContainer = styled(View)`
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    width: 100%;
  }
`;

export interface SelectRoutineModalProps {
  vm: SelectRoutineViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const SelectRoutineModal = observer(({ isOpen, onClose, vm }: SelectRoutineModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <TextLG>Routines</TextLG>
            <CloseButton onClick={onClose} />
          </FlexRow>
        </UpperContainer>
        <DividerLine />
        {vm.routines.length === 0 ? (
          <NoRoutinesContainer>
            <DoubleCircledIcon containerStyle={{ marginBottom: Spacing.lg }} type="dumbbell" />
            <TextSMMedium>No Routines</TextSMMedium>
            <TextSMRegular style={{ color: colors.neutral500 }}>
              You don't have any routines in your Program Library
            </TextSMRegular>
            <PrimaryButton
              style={{ marginTop: Spacing.md }}
              title={'Create Routine'}
              onClick={vm.onCreateRoutinePress}
              iconType="plus"
              loading={vm.isCreatingRoutine}
            />
          </NoRoutinesContainer>
        ) : (
          <View>
            <MiddleContainer>
              <View
                style={{
                  gap: Spacing.md,
                  marginLeft: Spacing.md,
                  marginRight: Spacing.md,
                }}
              >
                <CreateRoutineCard
                  onClick={vm.onCreateRoutinePress}
                  isLoading={vm.isCreatingRoutine}
                />
                <TextSMRegular style={{ color: colors.neutral500 }}>
                  Or import from library
                </TextSMRegular>
              </View>
              {vm.isloading ? (
                <View style={{ alignSelf: 'center' }}>
                  <ClipLoader size={20} color={colors.primary500} loading={vm.isloading} />
                </View>
              ) : (
                <>
                  <View
                    style={{
                      position: 'sticky',
                      top: -(Spacing.md + 1),
                      paddingTop: Spacing.md,
                      backgroundColor: colors.white,
                    }}
                  >
                    <View
                      style={{
                        marginLeft: Spacing.md,
                        marginRight: Spacing.md,
                        paddingBottom: Spacing.md,
                      }}
                    >
                      <SearchInput
                        value={vm.searchText}
                        placeholder={'Search routine'}
                        onChange={vm.onSearchTextChanged}
                        style={{
                          backgroundColor: colors.neutral100,
                          width: 'calc(100% - 2px)',
                        }}
                      ></SearchInput>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: Spacing.md,
                      marginLeft: Spacing.md,
                      marginRight: Spacing.md,
                    }}
                  >
                    {vm.filteredRoutines.length === 0 ? (
                      <NoRoutinesContainer>
                        <DoubleCircledIcon
                          containerStyle={{ marginBottom: Spacing.lg }}
                          type="dumbbell"
                        />
                        <TextSMMedium>No Routines</TextSMMedium>
                        <TextSMRegular style={{ color: colors.neutral500 }}>
                          {`Couldn't find "${vm.searchText}"`}
                        </TextSMRegular>
                      </NoRoutinesContainer>
                    ) : (
                      <RoutinesContainer>
                        {vm.filteredRoutines.map(routine => {
                          return (
                            <RoutineSelectionCard
                              key={routine.id}
                              routine={routine}
                              onSelectRoutine={vm.onSelectRoutine}
                              isSelected={vm.isRoutineSelected(routine.id)}
                            />
                          );
                        })}
                      </RoutinesContainer>
                    )}
                  </View>
                </>
              )}
            </MiddleContainer>
            <DividerLine />
            <LowerContainer>
              <HevyTooltip
                enabled={vm.isOverRoutineLimit}
                direction="left"
                text={'Programs can have no more than 50 routines'}
              >
                <ButtonContainer>
                  <PrimaryButton
                    title={
                      vm.selectedRoutineIds.length > 1
                        ? 'Copy Routines to Program'
                        : 'Copy Routine to Program'
                    }
                    onClick={vm.onAddRoutinesButtonPress}
                    enabled={vm.isSelectRoutineButtonEnabled}
                  />
                </ButtonContainer>
              </HevyTooltip>
            </LowerContainer>
          </View>
        )}
      </Container>
    </Modal>
  );
});
