import { ParsedUrlQuery } from 'querystring';
import { setAmplitudeUserProps } from './amplitude';
import { fireAndForget } from './async';
import API from './API';

const processedQueryParams: { [paramName: string]: boolean } = {};
const interestingParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclic',
];

const googleId = 'G-WSXQNS98TX';

let hasTrackedGclid = false;

const readGtagValue = async (key: string): Promise<string | void> => {
  return new Promise<string | void>(resolve => {
    const gtag = (window as any)?.gtag;
    if (!gtag) {
      resolve();
    }

    gtag('get', googleId, key, (value: string) => {
      resolve(value);
    });
  });
};

export const processURLQueryParams = async (queryParams: ParsedUrlQuery) => {
  if (typeof window === 'undefined') {
    return;
  }

  const marketingAndAdProperties: { [param: string]: string | string[] } = {};

  for (const paramKey of interestingParams) {
    const paramValue = queryParams[paramKey];
    if (!!paramValue && !processedQueryParams[paramKey]) {
      processedQueryParams[paramKey] = true;
      marketingAndAdProperties[`hevy_coach_url_${paramKey}`] = paramValue;
    }
  }

  const gclid = await readGtagValue('gclic');
  if (!!gclid && !hasTrackedGclid) {
    hasTrackedGclid = true;
    marketingAndAdProperties[`hevy_coach_gclid`] = gclid;
    marketingAndAdProperties[`hevy_coach_paidAcquisitionSource`] = 'GoogleAds';
    fireAndForget([
      API.postFeedback(
        'We got a GCLID!',
        `This isn't user feedback, but this user has a GCLID defined and likely came from a Google Ad.`,
      ),
    ]);
  }

  setAmplitudeUserProps(marketingAndAdProperties);
};
