import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { ClickableCard } from 'styleguide/ClickableCard';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextMD, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { StyledCheckbox } from 'styleguide/Inputs';
import { localStorageStores } from 'state/localStorageStores';
import { CoachsShallowLibraryRoutine } from 'hevy-shared';
import { FlexRow } from 'styleguide/Row';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const SelectionCard = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
  }
`;

const RoutineTitleText = styled(TextMD)`
  text-align: left;
`;

interface RoutineSelectionCardProps {
  routine: CoachsShallowLibraryRoutine;
  onSelectRoutine: (id: string) => void;
  isSelected: boolean;
}

const CreateRoutineButton = styled(View)`
  background-color: ${colors.white};
  cursor: pointer;
  border-style: dashed;
  border-radius: ${CornerRadius.sm}px;
  border-width: 1px;
  border-color: ${colors.neutral300};
  width: calc(100% - 2px);
  align-items: center;
`;

export const CreateRoutineCard = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <CreateRoutineButton
      style={{
        ...(isHovering && {
          backgroundColor: colors.neutral50,
          cursor: isLoading ? 'default' : 'pointer',
        }),
      }}
      onClick={isLoading ? undefined : onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <SelectionCard>
        <View style={{ marginTop: Spacing.xs }}>
          {isLoading ? (
            <View style={{ height: 80, justifyContent: 'center' }}>
              <MediumHevyActivityIndicator />
            </View>
          ) : (
            <View style={{ gap: Spacing.md, alignItems: 'center' }}>
              <TextMD style={{ textAlign: 'center' }}>Create New Routine From Scratch</TextMD>
              <PrimaryButton disableHovering={true} iconType="plus" title="Create New Routine" />
            </View>
          )}
        </View>
      </SelectionCard>
    </CreateRoutineButton>
  );
};

export const RoutineSelectionCard = (props: RoutineSelectionCardProps) => {
  const routineProgram = props.routine.program_id
    ? localStorageStores.programs.programWithId(props.routine.program_id)
    : undefined;
  const programFolderTitle = routineProgram?.folder_id
    ? localStorageStores.programFolders.folderWithId(routineProgram.folder_id)?.title ??
      localStorageStores.programFolders.DEFAULT_FOLDER_NAME
    : localStorageStores.programFolders.DEFAULT_FOLDER_NAME;

  return (
    <ClickableCard style={{ padding: 0 }} onClick={() => props.onSelectRoutine(props.routine.id)}>
      <SelectionCard>
        <FlexRow style={{ gap: Spacing.md }}>
          <StyledCheckbox
            checked={props.isSelected}
            onChange={() => props.onSelectRoutine(props.routine.id)}
          />
          <View>
            {routineProgram && (
              <TextXSRegular style={{ textAlign: 'left' }}>
                From: {programFolderTitle} / {routineProgram.title}
              </TextXSRegular>
            )}
            <RoutineTitleText>{props.routine.title}</RoutineTitleText>
          </View>
        </FlexRow>
      </SelectionCard>
    </ClickableCard>
  );
};
