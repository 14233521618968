import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React, { CSSProperties, useState } from 'react';
import { Menu, MenuProps } from '@szhsin/react-menu';
import { observer } from 'mobx-react-lite';
import { Icon } from 'styleguide/Icon';
import { CornerRadius } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';

export const HevyMenu = observer((props: MenuProps) => (
  <Menu transition={true} arrow position="auto" portal={true} viewScroll="close" {...props}>
    {props.children}
  </Menu>
));

type HevyOptionMenuProps = Omit<MenuProps, 'menuButton'> & {
  children: React.ReactNode;
  style?: CSSProperties;
  // Lower case to avoid React warning
  secondarystyle?: boolean;
};

export const HevyOptionMenu = (props: HevyOptionMenuProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <HevyMenu
      {...props}
      menuButton={
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: CornerRadius.sm,
            ...(props.secondarystyle
              ? {
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.neutral200}`,
                  height: 38,
                  width: 38,
                }
              : undefined),
            ...props.style,
            backgroundColor: isHovering
              ? colors.neutral100
              : props.secondarystyle
              ? colors.white
              : props.style?.backgroundColor,
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <Icon
            style={{ cursor: 'pointer', alignItems: 'center', height: 30, width: 30 }}
            type="verticalDots"
          />
        </div>
      }
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        e.syntheticEvent.preventDefault();
      }}
    >
      {props.children}
    </HevyMenu>
  );
};
