import React from 'react';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import ReactModal from 'react-modal';
import { colors } from './colors';
import { zIndexes } from './zIndex';

const defaultContentStyle: CSSProperties = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  maxHeight: '85vh',
  margin: 0,
  padding: 0,
  maxWidth: '90%',
  display: 'flex',
  backgroundColor: colors.white,
};

const defaultOverlayStyle: CSSProperties = {
  backgroundColor: 'rgba(16, 18, 19, 0.3)',
  zIndex: zIndexes.modal,
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  children: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  overlayStyle?: CSSProperties;
  contentStyle?: CSSProperties;
}

export const Modal = observer(
  ({
    isOpen,
    onClose,
    children,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    overlayStyle,
    contentStyle,
    onAfterOpen,
    onAfterClose,
  }: ModalProps) => {
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} // true by default
        shouldCloseOnEsc={shouldCloseOnEsc} // true by default
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
        style={{
          content: {
            ...defaultContentStyle,
            ...contentStyle,
          },
          overlay: { ...defaultOverlayStyle, ...overlayStyle },
        }}
      >
        {children}
      </ReactModal>
    );
  },
);
