import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextSMMedium } from 'styleguide/Texts';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { Client } from 'types/client';
import { ProfileImage } from 'components/ProfileImage';
import { CloseButton } from 'components/CloseButton';
import { Message } from 'hevy-shared';
import { WidgetClientChatView } from './components/WidgetClientChatView';
import { zIndexes } from 'styleguide/zIndex';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { ChatViewModel } from './ChatViewModel';

const Container = styled(View)`
  position: relative;
  z-index: ${zIndexes.chatWidgets};
  bottom: 0px;
  z-index: 2;
  border-top-left-radius: ${CornerRadius.md}px;
  border-top-right-radius: ${CornerRadius.md}px;
  border: 1px solid ${colors.neutral150};
  background-color: ${colors.white};
  width: 360px;
  align-items: center;
  box-shadow: 0px 4px 16px -2px rgba(17, 25, 39, 0.1); // colors.neutral900
`;

const Header = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.sm + Spacing.xs}px;
  cursor: pointer;
`;

interface ClientChatWidgetProps {
  conversationId: string;
  client: Client;
  chatVm: ChatViewModel;
  messages: Message[];
  onClose: () => void;
  onSend: (message: string) => Promise<void>;
  isFetchingOlderMessages: boolean;
  onScrollToTop: () => void;
  setMessagesContainerRef: (ref: HTMLDivElement) => void;
  onDeleteMessageClick: (messageId: string) => void;
  onSendFile: (file: File) => Promise<void>;
  imageBeingUploaded?: string;
  videoBeingUploaded?: string;
  isFileBeingProcessed: boolean;
  updateUnsentMessage: (message: string) => void;
  isLoadingConversations: boolean;
}

export const ClientChatWidget = ({
  client,
  messages,
  chatVm,
  onClose,
  onSend,
  isFetchingOlderMessages,
  onScrollToTop,
  setMessagesContainerRef,
  onDeleteMessageClick,
  onSendFile,
  imageBeingUploaded,
  videoBeingUploaded,
  updateUnsentMessage,
  isLoadingConversations,
}: ClientChatWidgetProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(false);
  }, [client]);

  return (
    <Container>
      <View style={{ width: '100%' }}>
        <Header
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <FlexRow style={{ gap: Spacing.sm }}>
            <ProfileImage diameter={32} source={client.profilePic} />
            <TextSMMedium>{client.fullName || client.username}</TextSMMedium>
          </FlexRow>
          <FlexRow style={{ gap: Spacing.md }}>
            <CloseButton onClick={onClose} style={{ padding: 0 }} />
          </FlexRow>
        </Header>
        <DividerLine />

        {isLoadingConversations ? (
          <View style={{ alignSelf: 'center', padding: Spacing.lg }}>
            <MediumHevyActivityIndicator />
          </View>
        ) : (
          <>
            {!isCollapsed && (
              <WidgetClientChatView
                messages={messages}
                isFetchingOlderMessages={isFetchingOlderMessages}
                onScrollToTop={onScrollToTop}
                setMessagesContainerRef={setMessagesContainerRef}
                client={client}
                onSend={onSend}
                style={{ maxHeight: 550 }}
                bodyStyle={{ minHeight: 250 }}
                onDeleteMessageClick={onDeleteMessageClick}
                onSendFile={onSendFile}
                videoBeingUploaded={videoBeingUploaded}
                imageBeingUploaded={imageBeingUploaded}
                chatVm={chatVm}
                updateUnsentMessage={updateUnsentMessage}
                textBubbleMaxWidth={244}
              ></WidgetClientChatView>
            )}
          </>
        )}
      </View>
    </Container>
  );
};
