import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { observer } from 'mobx-react-lite';
import RSelect, { components, GroupBase, MenuProps, Props } from 'react-select';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import fonts from 'styleguide/fonts';
import dayjs from 'dayjs';
import { CardNoPadding } from 'styleguide/Card';
import { DividerLine } from 'styleguide/DividerLine';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { ProgramCalendar } from './ProgramCalendar/ProgramCalendar';
import { Weekday } from 'hevy-shared';

interface SchedulingOption {
  label: string;
  value: string | null;
}
const getStartDateOption = (startDate: string | null): SchedulingOption => {
  return {
    label: dayjs(startDate).format('MMM D, YYYY'),
    value: startDate ?? dayjs().format('YYYY-MM-DD'), // Default to today
  };
};

const Container = styled(CardNoPadding)`
  position: absolute;
  right: 0;
  z-index: 1;
  box-shadow: 0px 4px 16px -2px rgba(16, 24, 40, 0.1);
  display: flex;
  flex-direction: column;
`;

const UpperContainer = styled(FlexRow)`
  align-items: flex-start;
`;

const CalendarContainer = styled(View)`
  gap: ${Spacing.md}px;
  flex: 1;
  padding: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

interface SelectorViewProps {
  durationDays: number | null;
  startDate: string | null;
  firstWeekday: Weekday;
}

const ProgramSchedulingView = (props: MenuProps<SchedulingOption, false> & SelectorViewProps) => {
  const { durationDays, startDate, firstWeekday } = props;

  return (
    <components.Menu {...props}>
      <Container>
        <UpperContainer>
          <CalendarContainer>
            <TextMD>Start Date</TextMD>
            <ProgramCalendar
              selectedDate={startDate}
              durationDays={durationDays}
              onSelectDate={date => props.setValue(getStartDateOption(date), 'select-option')}
              firstWeekday={firstWeekday}
            />
          </CalendarContainer>
        </UpperContainer>
        <DividerLine />
      </Container>
    </components.Menu>
  );
};

type SchedulingSelectProps = Props<SchedulingOption, false, GroupBase<SchedulingOption>>;

interface SchedulingSelectorProps extends SchedulingSelectProps {
  durationDays: number | null;
  startDate: string | null;
  backgroundColor?: string;
  onSelectStartDate: (value: string | null) => void;
  firstWeekday: Weekday;
}

export const ProgramStartDateSelector = observer((props: SchedulingSelectorProps) => {
  const { durationDays, startDate, onSelectStartDate, firstWeekday } = props;
  return (
    <RSelect
      onChange={newValue => onSelectStartDate((newValue as SchedulingOption).value)}
      backgroundColor={colors.white}
      value={{
        label: dayjs(props.startDate).format('MMM D, YYYY'),
        value: startDate,
      }}
      closeMenuOnSelect={false}
      isSearchable={false}
      styles={{
        placeholder: styles => ({
          ...styles,
          ...{ justifySelf: 'center', color: colors.neutral400 },
        }),
        control: styles => ({
          ...styles,
          ...controlStyle,
        }),
        valueContainer: styles => ({
          ...styles,
          ...{ justifySelf: 'center', padding: 0 },
        }),
        singleValue: styles => ({
          ...styles,
          ...{ color: colors.neutral900, marginLeft: Spacing.md },
        }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: styles => ({
          ...styles,
          ...{ position: 'absolute', right: 0 },
        }),
      }}
      components={{
        Menu: props => {
          return (
            <ProgramSchedulingView
              {...props}
              durationDays={durationDays}
              startDate={startDate}
              firstWeekday={firstWeekday}
            />
          );
        },
        DropdownIndicator: props => {
          return (
            <components.DropdownIndicator {...props}>
              <CustomizableIcon type="chevron-down" tint={colors.neutral900}></CustomizableIcon>
            </components.DropdownIndicator>
          );
        },
      }}
      {...props}
    />
  );
});

const controlStyle: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  height: '40px',
  minHeight: 'auto',
  borderColor: colors.neutral150,
  backgroundColor: colors.white,
  fontFamily: fonts.regular.family,
  fontWeight: fonts.regular.weight,
  fontSize: '14px',
  cursor: 'pointer',
  position: 'relative',
};
