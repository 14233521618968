import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { View } from 'styleguide/View';
import { TextSMMedium } from 'styleguide/Texts';
import { UpgradePlanViewModel } from './UpgradePlanViewModel';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { UpgradePlanView } from './components/UpgradePlanView';
import { PlanFooterContent } from './components/PlanFooterContent';
import { CloseButton } from 'components/CloseButton';
import { CoachReviews } from 'components/CoachReviews';
import { FAQs } from 'components/FAQ/FAQs';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1038px;
  border-radius: ${CornerRadius.md}px;
`;

export interface UpgradePlanModalProps {
  vm: UpgradePlanViewModel;
  isOpen: boolean;
  onClose: () => void;
  isDismissable: boolean;
  title: string;
}
export const UpgradePlanModal = observer(
  ({ vm, isOpen, onClose, isDismissable, title }: UpgradePlanModalProps) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={isDismissable}
        shouldCloseOnEsc={isDismissable}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContainer>
          <View
            style={{
              padding: Spacing.lg,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextSMMedium>{title}</TextSMMedium>
            {isDismissable && <CloseButton onClick={onClose} />}
          </View>
          <DividerLine />
          <View style={{ overflowY: 'auto', padding: Spacing.md }}>
            <UpgradePlanView vm={vm} />
            <CoachReviews />
            <FAQs />
          </View>
          <DividerLine />
          <PlanFooterContent />
        </ModalContainer>
      </Modal>
    );
  },
);
