import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { CoachsPlan, HevyCoachDefaultPlan, Plan } from 'hevy-shared';
import dayjs from 'dayjs';
import { fireAndForget } from 'utils/async';

const PLAN_LOCAL_STORAGE_KEY = 'PLAN_LOCAL_STORAGE_KEY';
const AVAILABLE_PLANS_LOCAL_STORAGE_KEY = 'AVAILABLE_PLANS_LOCAL_STORAGE_KEY';

export class Plans {
  private _currentPlan?: CoachsPlan;
  private _availablePlans: Plan[] = [];

  get currentPlan(): CoachsPlan | undefined {
    return this._currentPlan;
  }

  set currentPlan(newPlan: CoachsPlan | undefined) {
    this._currentPlan = newPlan;
    window.localStorage.setItem(PLAN_LOCAL_STORAGE_KEY, JSON.stringify(this._currentPlan));
  }

  constructor() {
    makeAutoObservable(this);
  }

  get hasActivePlan() {
    return (
      this.currentPlan?.status === 'active' ||
      this.currentPlan?.status === 'past_due' ||
      this.currentPlan?.status === 'will_pause'
    );
  }

  /**
   * This is the number of clients that the user can have on their account on the free plan.
   * This can change in the future, so we should not hardcode this value anywhere else.
   * Just use this getter to check if the user is over the limit.
   */
  get freePlanClientLimit() {
    return HevyCoachDefaultPlan.maximum_clients;
  }

  get clientLimit(): number {
    if (!this.hasActivePlan || !this.currentPlan?.maximum_clients) {
      return this.freePlanClientLimit;
    }
    return this.currentPlan.maximum_clients;
  }

  get availablePlans(): Plan[] {
    return this._availablePlans;
  }

  get isPaidPaddlePlan() {
    return this.currentPlan?.type === 'paid_paddle';
  }

  get isEligibleToStartFreeTrial() {
    return this.currentPlan?.type === 'none' && !this.currentPlan.user_previously_started_trial;
  }

  get isOnFreePlan() {
    return this.currentPlan?.maximum_clients === this.freePlanClientLimit;
  }

  get isPlanExpiring() {
    return this.currentPlan?.status === 'will_pause';
  }

  get isPlanExpired() {
    return (
      this.currentPlan?.status === 'paused' ||
      (this.currentPlan?.status === 'will_pause' &&
        dayjs(this.currentPlan?.renewal_date).isBefore(dayjs()))
    );
  }

  get isHavingTroubleWithPayment() {
    return this.currentPlan?.status === 'past_due';
  }

  get isTrialing() {
    return this._currentPlan?.type === 'trial';
  }

  get trialDaysLeft() {
    if (!this.isTrialing) {
      return;
    }

    return dayjs(this.currentPlan?.renewal_date).diff(dayjs(), 'day');
  }

  get trialExpiryDate() {
    if (!this.isTrialing) {
      return;
    }
    return this.currentPlan?.renewal_date;
  }

  startTrial = async () => {
    if (!this.isEligibleToStartFreeTrial) {
      throw new Error('NotEligibleToStartFreeTrial');
    }
    await API.startFreeTrial();
    await this.fetch();
  };

  hasFetched = false;
  fetch = async () => {
    await fireAndForget([this.fetchCurrentPlan(), this.fetchAvailablePlans()]);
    this.hasFetched = true;
  };

  fetchCurrentPlan = async () => {
    try {
      const result = await API.getPlan();
      this.currentPlan = result.data.current_plan;

      window.localStorage.setItem(PLAN_LOCAL_STORAGE_KEY, JSON.stringify(this._currentPlan));
    } catch (error) {
      this.currentPlan = {
        id: 'unknwon',
        renewal_period: 'lifetime',
        type: 'beta',
        status: 'active',
        maximum_clients: 999,
        user_previously_started_trial: true,
        title: 'Unknown',
      };
      captureException(error);
      throw error;
    }
  };

  fetchAvailablePlans = async () => {
    try {
      const result = await API.getAvailablePlans();
      this._availablePlans = result.data.available_plans;
      window.localStorage.setItem(
        AVAILABLE_PLANS_LOCAL_STORAGE_KEY,
        JSON.stringify(this._availablePlans),
      );
    } catch (error) {
      captureException(error);
      throw error;
    }
  };

  hydrate = () => {
    const planJSON = window.localStorage.getItem(PLAN_LOCAL_STORAGE_KEY);
    if (planJSON) {
      this.currentPlan = JSON.parse(planJSON);
    }
  };

  clearData = () => {
    window.localStorage.removeItem(PLAN_LOCAL_STORAGE_KEY);
    this.currentPlan = undefined;
  };
}
