import { ControlledMenu, MenuDirection, useHover, useMenuState } from '@szhsin/react-menu';
import { TextXSMedium } from './Texts';
import { colors } from './colors';
import React, { useRef } from 'react';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { CornerRadius, Spacing } from './spacing';

export const HevyTooltip = ({
  text,
  direction,
  delay,
  enabled = true,
  menuStyle,
  children,
  style,
}: {
  text?: string;
  direction?: MenuDirection;
  delay?: number;
  enabled?: boolean;
  menuStyle?: React.CSSProperties;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle, {
    openDelay: delay || 300,
    closeDelay: delay || 300,
  });

  return (
    <div style={style}>
      <div ref={ref} {...anchorProps}>
        {children}
      </div>
      {enabled && !!text && (
        <ControlledMenu
          arrowStyle={{ backgroundColor: colors.neutral900 }}
          menuStyle={{
            padding: Spacing.sm,
            textAlign: 'center',
            borderRadius: CornerRadius.sm,
            backgroundColor: colors.neutral900,
            boxShadow: 'none',
            textShadow: 'none',
            maxWidth: '40vw',
            ...menuStyle,
          }}
          {...hoverProps}
          {...menuState}
          anchorRef={ref}
          onClose={() => toggle(false)}
          captureFocus={false}
          align="center"
          arrow
          direction={direction}
        >
          <TextXSMedium style={{ color: colors.white }}>{text}</TextXSMedium>
        </ControlledMenu>
      )}
    </div>
  );
};
