import { modal } from 'components/Modals/ModalManager';
import { ProfileImage } from 'components/ProfileImage';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import { SearchInput } from 'styleguide/Inputs';
import { TextSMMedium, TextSMRegular, TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { NavBarSearchResult, NavBarUserSearchViewModel } from './NavBarSearchViewModel';
import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import { memoryStores } from 'state/memoryStores';
import { autorun } from 'mobx';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { FlexRow } from 'styleguide/Row';

const SearchResultsContainer = styled.div`
  overflow-y: auto;
  min-width: min(332px, 90vw);
  max-width: 90vw;
  max-height: min(300px, 90vh);
`;
const SearchResultsInnerContainer = styled.div`
  margin-left: ${Spacing.sm}px;
  margin-right: ${Spacing.sm}px;
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
`;

const NavBarSearchResult = ({ result }: { result: NavBarSearchResult }) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const hoverBackgroundColor = colors.neutral100;

  const link =
    result.type === 'client' ? `/clients/${result.client.id}` : `/programs/${result.program.id}`;

  const pluralizeRoutines = (count: number) => {
    if (count === 1) {
      return 'routine';
    }
    return 'routines';
  };

  return (
    <Link
      href={link}
      style={{ textDecoration: 'none' }}
      onClick={() => {
        modal.isMobileNavBarModalOpen = false;
      }}
    >
      <FlexRow
        style={{
          padding: Spacing.sm,
          backgroundColor: isHovering ? hoverBackgroundColor : undefined,
          borderRadius: CornerRadius.sm,
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {result.type === 'client' ? (
          <>
            <ProfileImage diameter={48} source={result.client.profilePic} />
            <View style={{ marginLeft: Spacing.sm }}>
              <TextSMMedium>{result.client.username}</TextSMMedium>
              {result.client.fullName && <TextSMRegular>{result.client.fullName}</TextSMRegular>}
            </View>
          </>
        ) : (
          <>
            <SingleCircledIcon
              diameter={48}
              containerStyle={{ backgroundColor: colors.primary50 }}
              type="folder"
            />
            <View style={{ marginLeft: Spacing.sm, flex: 1 }}>
              <TextSMMedium
                style={{
                  flex: 1,
                  flexWrap: 'wrap',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                }}
              >
                {result.program.title}
              </TextSMMedium>
              <TextSMRegular>
                {memoryStores.libraryRoutines.sortedRoutinesForProgram(result.program.id).length}{' '}
                {pluralizeRoutines(
                  memoryStores.libraryRoutines.sortedRoutinesForProgram(result.program.id).length,
                )}
              </TextSMRegular>
            </View>
          </>
        )}
      </FlexRow>
    </Link>
  );
};

const SearchResultsDropdown = observer(
  ({ vm, hideSearchResults }: { vm: NavBarUserSearchViewModel; hideSearchResults: () => void }) => {
    return (
      <SearchResultsContainer>
        <SearchResultsInnerContainer>
          {vm.results.length === 0 && (
            <>
              <TextMD style={{ flexWrap: 'wrap', flex: 1 }}>Can't find "{vm.searchString}"</TextMD>
              <TextSMRegular style={{ flex: 1 }}>
                Search for a username, email, or program title
              </TextSMRegular>
            </>
          )}
          {vm.results.map(result => {
            return (
              <View
                key={result.type === 'client' ? result.client.username : result.program.id}
                onClick={hideSearchResults}
              >
                <NavBarSearchResult result={result} />
              </View>
            );
          })}
        </SearchResultsInnerContainer>
      </SearchResultsContainer>
    );
  },
);

const Container = styled.div`
  padding: 0px ${Spacing.sm}px;
`;

export const NavBarSearch = observer(({ vm }: { vm: NavBarUserSearchViewModel }) => {
  const menuRef = React.useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  autorun(() => {
    vm.showMenu ? toggleMenu(true) : toggleMenu(false);
  });
  return (
    <Container>
      <SearchInput
        style={{
          marginBottom: Spacing.md,
          marginTop: Spacing.md,
          maxWidth: 240,
        }}
        unfocusedBorderColor={colors.sideMenuLinesAndBorders}
        focusedBorderColor={colors.neutral500}
        value={vm.searchString}
        placeholder={'Search'}
        onChange={vm.onSearchStringChanged}
        inputColor={colors.white}
      />
      <View
        ref={(ref: any) => {
          menuRef.current = ref;
        }}
      />
      <ControlledMenu
        menuStyle={{ padding: 0, margin: 0, overflow: 'hidden', borderRadius: CornerRadius.md }}
        portal={true}
        anchorRef={menuRef}
        captureFocus={false}
        direction="bottom"
        {...menuState}
      >
        <SearchResultsDropdown
          hideSearchResults={() => {
            vm.searchString = '';
          }}
          vm={vm}
        />
      </ControlledMenu>
    </Container>
  );
});
