import { ProfileImage } from 'components/ProfileImage';
import { CommentInput } from 'components/WorkoutCell/components/CommentInput';
import { Comments } from 'components/WorkoutCell/components/Comments';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CommentModalViewModel } from './CommentModalViewModel';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { UserHeader } from '../WorkoutDetail/components/UserHeader';
import { zIndexes } from 'styleguide/zIndex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${CornerRadius.md}px;
  width: 600px;
  max-height: 600px;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const TopContainer = styled(View)`
  padding: ${Spacing.md}px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.md}px;
  overflow-y: auto;
`;

const BottomContainer = styled(View)`
  padding: ${Spacing.md}px;
`;

export interface CommentModalProps {
  vm: CommentModalViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const CommentModal = observer(({ vm, isOpen, onClose }: CommentModalProps) => {
  const onBottomRefChange = (node: HTMLDivElement) => {
    vm.setCommentsContainerRef(node);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} overlayStyle={{ zIndex: zIndexes.modalOverlay + 1 }}>
      <Container>
        <TopContainer>
          <UserHeader
            id={vm.data.user_id}
            username={vm.data.username}
            date={vm.data.date}
            profileImage={vm.data.profileImage}
            onClick={onClose}
          />
          <TextMD style={{ marginTop: Spacing.md }}>{vm.data.title}</TextMD>
        </TopContainer>
        <DividerLine />

        <MiddleContainer>
          {vm.comments.length > 0 ? (
            <View>
              <Comments
                comments={vm.comments}
                onCommentDelete={vm.onCommentDelete}
                disableTruncateComments={true}
              />
              <div ref={onBottomRefChange} />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                padding: Spacing.lg * 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProfileImage
                style={{ marginBottom: Spacing.md }}
                diameter={50}
                source={vm.profileImage}
              />
              <TextMD>Be the first to comment</TextMD>
            </View>
          )}
        </MiddleContainer>
        <DividerLine />

        <BottomContainer>
          <CommentInput
            style={{ marginBottom: 0, marginTop: 0 }}
            onSend={vm.onCommentSend}
            profilePic={vm.profileImage}
          />
        </BottomContainer>
      </Container>
    </Modal>
  );
});
