import React from 'react';
import NextLink from 'next/link';
import styled, { CSSProperties } from 'styled-components';

interface Props {
  href: string;
  children: React.ReactNode;
  style?: CSSProperties;
  target?: string;
  onClick?: () => void;
}

const A = styled.a`
  text-decoration: none;
`;

export const Link = ({ href, children, style, target, onClick }: Props) => {
  return (
    <NextLink href={href} passHref={true} legacyBehavior={true} target={target}>
      {
        <A style={style} onClick={onClick} target={target}>
          {children}
        </A>
      }
    </NextLink>
  );
};
