import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { TextLG, TextSMRegular } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
  padding: ${Spacing.lg}px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export interface OkModalProps {
  title: string;
  subtitle: string;
  submitButtonTitle: string;
  isOpen: boolean;
  onClose: () => void;
}

export const OkModal = ({ title, subtitle, submitButtonTitle, isOpen, onClose }: OkModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <TextLG style={{ marginBottom: Spacing.sm }}>{title}</TextLG>
          <TextSMRegular style={{ color: colors.neutral500 }}>{subtitle}</TextSMRegular>
        </UpperContainer>
        <LowerContainer>
          <PrimaryButton style={styles.button} title={submitButtonTitle} onClick={onClose} />
        </LowerContainer>
      </Container>
    </Modal>
  );
};

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
  },
};
