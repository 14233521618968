import { Bar } from 'recharts';

export class CustomBar extends Bar {
  static defaultProps = {
    ...Bar.defaultProps,
    //custom props
    dataKey: 'value',
    isAnimationActive: true,
  };
}
