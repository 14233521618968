import React, { useState } from 'react';
import { ExerciseDetailViewModel } from './ExerciseDetailViewModel';
import { Modal } from 'styleguide/Modal';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { TextLG, TextSMMedium, TextSMRegular, TextXLMedium } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ExerciseHeaderProps } from 'components/ExerciseHeader/types';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { DividerLine, VerticalDividerLine } from 'styleguide/DividerLine';
import { FlexRow } from 'styleguide/Row';
import { ExercisePlaceholderSvg } from 'components/ExercisePlaceholderSvg';
import { CardNoPadding } from 'styleguide/Card';
import { TabDescriptor, Tabs } from 'components/Tabs';
import { useDeviceSize } from 'utils/useSize';
import { PagedExerciseHistoryWorkoutsView } from 'components/ExerciseHistoryWorkouts/PagedExerciseHistoryWorkoutsView';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { ExerciseInstructionsView } from './Components/ExerciseInstructionsView';
import { ClientStatisticsView } from './Components/ClientStatisticsView';
import { modal } from '../ModalManager';
import { ClientCustomBadge, CustomBadge } from 'components/CustomBadge';
import { ExerciseTemplateType } from 'types/exerciseTemplateType';

const LowerContainer = styled(View)`
  flex-direction: row-reverse;
  align-items: center;
  padding: ${Spacing.md}px;
  gap: ${Spacing.md}px;
`;

export interface ExerciseDetailModalProps {
  vm: ExerciseDetailViewModel;
  isOpen: boolean;
  onClose: () => void;
}

const UpperContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${Spacing.xs}px;
  gap: ${Spacing.xs}px;
  flex-wrap: wrap;
`;

const MediaContainer = styled(CardNoPadding)`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  @media (max-width: 700px) {
    width: 100%;
    justify-self: center;
  }
  aspect-ratio: 1.6 / 1;
  justify-content: center;
  overflow: hidden;
  margin-bottom: ${Spacing.md}px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const renderImageModalContent = (imageUrl: string) => {
  return () => (
    <StyledImage
      onClick={() => (modal.isGenericModalOpen = false)}
      src={imageUrl}
      alt={''}
      width={0}
      height={0}
      sizes="100vw"
      style={{
        height: '80vh',
        width: '80vw',
        objectFit: 'contain',
      }}
    />
  );
};

const ExerciseTemplateInfo = ({
  title,
  infos,
  media,
  exerciseTemplateType,
}: ExerciseHeaderProps & {
  exerciseTemplateType: ExerciseTemplateType;
}) => {
  return (
    <View style={{ padding: Spacing.lg }}>
      <FlexRow style={{ flexWrap: 'wrap', gap: Spacing.sm, paddingBottom: Spacing.md }}>
        <TextXLMedium style={{ marginRight: Spacing.sm }}>{title}</TextXLMedium>
        {exerciseTemplateType === 'client-custom' ? (
          <ClientCustomBadge />
        ) : (
          exerciseTemplateType === 'coach-custom' && <CustomBadge />
        )}
      </FlexRow>
      {media?.type === 'video' && (
        <MediaContainer>
          <video key={media?.url} loop muted autoPlay playsInline width="100%" controls>
            <source src={media.url} type="video/mp4" />
          </video>
        </MediaContainer>
      )}
      {media?.type === 'image' && (
        <MediaContainer>
          <StyledImage
            key={media.url}
            src={media?.url}
            onClick={() => {
              modal.openGenericModal(renderImageModalContent(media.url));
            }}
          />
        </MediaContainer>
      )}
      {!media && (
        <MediaContainer style={{ alignItems: 'center' }}>
          <ExercisePlaceholderSvg diameter={150} />
        </MediaContainer>
      )}
      <View style={{ marginRight: Spacing.md }}>
        {infos.map(i => (
          <InfoContainer key={i.key}>
            <TextSMMedium>{i.key}</TextSMMedium>
            <TextSMRegular>{i.value}</TextSMRegular>
          </InfoContainer>
        ))}
      </View>
    </View>
  );
};

const MobileExerciseTemplateInfo = ({ infos, media }: ExerciseHeaderProps) => {
  return (
    <View>
      {media?.type === 'video' && (
        <MediaContainer>
          <video key={media?.url} loop muted autoPlay playsInline width="100%" controls>
            <source src={media.url} type="video/mp4" />
          </video>
        </MediaContainer>
      )}
      {media?.type === 'image' && (
        <MediaContainer>
          <StyledImage
            key={media.url}
            src={media?.url}
            onClick={() => {
              modal.openGenericModal(renderImageModalContent(media.url));
            }}
          />
        </MediaContainer>
      )}
      {!media && (
        <MediaContainer style={{ alignItems: 'center' }}>
          <ExercisePlaceholderSvg diameter={150} />
        </MediaContainer>
      )}
      <View style={{ marginRight: Spacing.md }}>
        {infos.map(i => (
          <InfoContainer key={i.key}>
            <TextSMMedium>{i.key}</TextSMMedium>
            <TextSMRegular>{i.value}</TextSMRegular>
          </InfoContainer>
        ))}
      </View>
    </View>
  );
};

const ModalNavigationArea = ({
  onClose,
  tabs,
  onTabSelected,
  selectedTabIdentifier,
}: {
  onClose: () => void;
  tabs: TabDescriptor[];
  onTabSelected: (tabId: string) => void;
  selectedTabIdentifier: string;
}) => {
  const [isHoveringClose, setIsHoveringClose] = useState(false);
  return (
    <FlexRow style={{ justifyContent: 'space-between', paddingRight: Spacing.md }}>
      {tabs.length === 0 ? (
        <TextSMMedium
          style={{ paddingLeft: Spacing.lg, marginTop: Spacing.md, marginBottom: Spacing.md }}
        >
          Exercise Details
        </TextSMMedium>
      ) : (
        <Tabs
          selectedTabIdentifier={selectedTabIdentifier}
          tabs={tabs}
          onTabClicked={onTabSelected}
          tabButtonContainerStyle={{ marginLeft: Spacing.lg }}
          containerStyle={{ paddingBottom: 0, paddingTop: Spacing.md }}
        />
      )}
      <View
        onClick={() => {
          onClose();
          setIsHoveringClose(false);
        }}
        style={{
          padding: Spacing.xs,
          borderRadius: CornerRadius.xs,
          cursor: 'pointer',
          backgroundColor: isHoveringClose ? colors.neutral100 : 'transparent',
        }}
        onMouseEnter={() => setIsHoveringClose(true)}
        onMouseLeave={() => setIsHoveringClose(false)}
      >
        <CustomizableIcon type="cross" tint={colors.neutral900}></CustomizableIcon>
      </View>
    </FlexRow>
  );
};

const SelectedContentContainer = styled(View)`
  padding: ${Spacing.lg}px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(90vh - 190px);
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

export const MobileLayout = observer(
  ({ vm, onClose }: { onClose: () => void; vm: ExerciseDetailViewModel }) => {
    const [isHoveringClose, setIsHoveringClose] = useState(false);
    const {
      exerciseTemplate,
      customExerciseInstructions,
      exerciseInstructions,
      videoAttachment,
    } = vm;
    return (
      <View style={{ maxHeight: PHONE_VIEW_HEIGHT, width: '100%' }}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexRow
            style={{
              flexWrap: 'wrap',
              gap: Spacing.sm,
              paddingTop: Spacing.md,
              paddingBottom: Spacing.md,
              paddingLeft: Spacing.md,
            }}
          >
            <TextLG style={{ marginRight: Spacing.sm }}>{exerciseTemplate.title}</TextLG>
            {vm.exerciseTemplateType === 'client-custom' ? (
              <ClientCustomBadge />
            ) : (
              exerciseTemplate.is_custom && <CustomBadge />
            )}
          </FlexRow>
          <View
            onClick={() => {
              onClose();
              setIsHoveringClose(false);
            }}
            style={{
              borderRadius: CornerRadius.xs,
              marginRight: Spacing.md,
              cursor: 'pointer',
              backgroundColor: isHoveringClose ? colors.neutral100 : 'transparent',
            }}
            onMouseEnter={() => setIsHoveringClose(true)}
            onMouseLeave={() => setIsHoveringClose(false)}
          >
            <CustomizableIcon type="cross" tint={colors.neutral900}></CustomizableIcon>
          </View>
        </FlexRow>
        <DividerLine />
        {vm.tabs.length > 0 && (
          <Tabs
            selectedTabIdentifier={vm.selectedTab}
            tabs={vm.tabs}
            onTabClicked={vm.onTabSelected}
            tabButtonContainerStyle={{ marginLeft: Spacing.md }}
            containerStyle={{ paddingBottom: 0, paddingRight: Spacing.md }}
          />
        )}

        <SelectedContentContainer>
          {vm.selectedTab === 'exercise-details' && (
            <View style={{ gap: Spacing.md }}>
              <MobileExerciseTemplateInfo {...vm.header} />
              <ExerciseInstructionsView
                exerciseInstructions={exerciseInstructions}
                customExerciseInstructions={customExerciseInstructions}
                videoAttachment={videoAttachment}
                exerciseTemplateTitle={exerciseTemplate.title}
              />
            </View>
          )}
          {vm.selectedTab === 'client-statistics' && (
            <ClientStatisticsView chartData={vm.chartData} isLoading={vm.isFetchingExerciseSets} />
          )}
          {vm.selectedTab === 'client-history' && (
            <PagedExerciseHistoryWorkoutsView
              workouts={vm.exerciseWorkoutHistory}
              onScrollToBottom={vm.fetchClientExerciseHistory}
              isFetchingMoreWorkouts={vm.isLoadingWorkoutHistory}
            />
          )}
        </SelectedContentContainer>

        <DividerLine />
        <LowerContainer>
          <PrimaryButton title="Close" onClick={onClose}></PrimaryButton>
          {vm.exerciseTemplateType !== 'client-custom' && (
            <SecondaryButton title="Edit Exercise" onClick={vm.onPressedEdit}></SecondaryButton>
          )}
        </LowerContainer>
      </View>
    );
  },
);

export const DesktopLayout = observer(
  ({ vm, onClose }: { onClose: () => void; vm: ExerciseDetailViewModel }) => {
    const {
      exerciseTemplate,
      customExerciseInstructions,
      exerciseInstructions,
      videoAttachment,
    } = vm;
    return (
      <View style={{ flex: 1 }}>
        <UpperContainer style={{ height: `calc(${DESKTOP_VIEW_HEIGHT} - 73px)` }}>
          <View style={{ flex: 0.5 }}>
            <ExerciseTemplateInfo {...vm.header} />
          </View>
          <VerticalDividerLine />
          <View style={{ flex: 1 }}>
            <ModalNavigationArea
              onClose={onClose}
              tabs={vm.tabs}
              onTabSelected={vm.onTabSelected}
              selectedTabIdentifier={vm.selectedTab}
            />
            {/* Layout hack to get a full-width divider that lines up with the tab component */}
            <DividerLine style={{ marginTop: vm.tabs.length === 0 ? undefined : -1 }} />
            <SelectedContentContainer>
              {vm.selectedTab === 'exercise-details' && (
                <ExerciseInstructionsView
                  exerciseInstructions={exerciseInstructions}
                  customExerciseInstructions={customExerciseInstructions}
                  videoAttachment={videoAttachment}
                  exerciseTemplateTitle={exerciseTemplate.title}
                />
              )}
              {vm.selectedTab === 'client-statistics' && (
                <ClientStatisticsView
                  chartData={vm.chartData}
                  isLoading={vm.isFetchingExerciseSets}
                />
              )}
              {vm.selectedTab === 'client-history' && (
                <PagedExerciseHistoryWorkoutsView
                  workouts={vm.exerciseWorkoutHistory}
                  onScrollToBottom={vm.fetchClientExerciseHistory}
                  isFetchingMoreWorkouts={vm.isLoadingWorkoutHistory}
                />
              )}
            </SelectedContentContainer>
          </View>
        </UpperContainer>
        <DividerLine />
        <LowerContainer>
          <PrimaryButton title="Close" onClick={onClose}></PrimaryButton>
          {vm.exerciseTemplateType !== 'client-custom' && (
            <SecondaryButton title="Edit Exercise" onClick={vm.onPressedEdit}></SecondaryButton>
          )}
        </LowerContainer>
      </View>
    );
  },
);

const DESKTOP_VIEW_HEIGHT = '80vh';
const PHONE_VIEW_HEIGHT = '90vh';
const DESKTOP_MODAL_WIDTH = '60vw';
const TABLET_MODAL_WIDTH = '80vw';
const PHONE_MODAL_WIDTH = '90vw';
export const ExerciseDetailModal = observer(({ vm, isOpen, onClose }: ExerciseDetailModalProps) => {
  const deviceSize = useDeviceSize();
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
      contentStyle={{
        height: deviceSize === 'phone' ? PHONE_VIEW_HEIGHT : DESKTOP_VIEW_HEIGHT,
        width:
          deviceSize === 'desktop'
            ? DESKTOP_MODAL_WIDTH
            : deviceSize === 'tablet'
            ? TABLET_MODAL_WIDTH
            : PHONE_MODAL_WIDTH,
      }}
    >
      {deviceSize !== 'phone' ? (
        <DesktopLayout vm={vm} onClose={onClose} />
      ) : (
        <MobileLayout vm={vm} onClose={onClose} />
      )}
    </Modal>
  );
});
