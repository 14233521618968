import { debounce } from 'lodash';
import { RefObject, useEffect, useMemo, useState } from 'react';

export default function useIsOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);
  const debouncedSetIntersecting = debounce((isIntersecting: boolean) => {
    setIntersecting(isIntersecting);
  }, 100);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => debouncedSetIntersecting(entry.isIntersecting)),
    [ref],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}
