import { captureException } from '@sentry/nextjs';
import { CoachClientInvite } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

const INVITES_LOCAL_STORAGE_KEY = 'INVITES_LOCAL_STORAGE_KEY';

interface ClientInvite {
  clientId?: string;
  coachId: string;
  createdAt: Date;
  inviteeEmail: string;
  inviteeUsername?: string;
  inviteeProfilePic?: string;
  inviteeFullName?: string;
  shortId: string;
  declinedAt?: Date;
}

export class ClientInvites {
  invites: ClientInvite[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  invitesForCoach = (coachId: string) => {
    return this.invites.filter(invite => invite.coachId === coachId);
  };

  hydrate = () => {
    const invitesJSON = window.localStorage.getItem(INVITES_LOCAL_STORAGE_KEY);
    if (invitesJSON) {
      this.invites = JSON.parse(invitesJSON);

      window.localStorage.setItem(INVITES_LOCAL_STORAGE_KEY, JSON.stringify(this.invites));
    }
  };

  updateModel = (invites: CoachClientInvite[]) => {
    this.invites = invites.map(invite => ({
      clientId: invite.client_id ?? undefined,
      coachId: invite.coach_id,
      createdAt: invite.created_at,
      inviteeEmail: invite.invitee_email,
      inviteeUsername: invite.invitee_username ?? undefined,
      inviteeProfilePic: invite.invitee_profile_pic ?? undefined,
      inviteeFullName: invite.invitee_full_name ?? undefined,
      shortId: invite.short_id,
      declinedAt: invite.declined_at ?? undefined,
    }));
    window.localStorage.setItem(INVITES_LOCAL_STORAGE_KEY, JSON.stringify(this.invites));
  };

  clearData = () => {
    this.invites = [];
    window.localStorage.removeItem(INVITES_LOCAL_STORAGE_KEY);
  };

  hasFetched = false;
  isFetchingInvites = false;
  fetch = async () => {
    if (this.isFetchingInvites) {
      return;
    }
    this.isFetchingInvites = true;
    try {
      const response = await API.getInvites();
      this.updateModel(response.data?.invites);
    } catch (e) {
      captureException(e);
      this.clearData();
      throw e;
    } finally {
      this.hasFetched = true;
      this.isFetchingInvites = false;
    }
  };
}
