const FacebookPixel: any = typeof window !== 'undefined' ? (window as any).fbq : undefined;

export const logFacebookPixelEvent = (event: string, params?: any) => {
  FacebookPixel?.('trackCustom', event, params);
};

export const logFacebookPurchaseEvent = (planId: string, planTitle: string, valueUSD?: number) => {
  FacebookPixel?.('track', 'Purchase', {
    value: valueUSD,
    currency: 'USD',
    content_ids: [planId],
    content_name: planTitle,
  });
};
