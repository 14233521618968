import styled, { CSSProperties } from 'styled-components';
import { TextXSMedium } from './Texts';
import React from 'react';
import { colors } from './colors';
import { CircleRow } from './CircleView';

const Circle = styled(CircleRow).attrs({ diameter: 20 })`
  justify-content: center;
`;

export const CircleNumber = ({
  backgroundColor,
  textColor,
  number,
  style,
}: {
  backgroundColor?: string;
  textColor?: string;
  number: number;
  style?: CSSProperties;
}) => {
  return (
    <Circle style={{ backgroundColor: backgroundColor ?? colors.primary500, ...style }}>
      <TextXSMedium
        style={{ color: textColor ?? colors.white, textAlign: 'center', paddingTop: 1 }}
      >
        {number > 99 ? ' ' : number}
      </TextXSMedium>
    </Circle>
  );
};
