import { captureException } from '@sentry/core';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { getUrlFromFile } from 'utils/imageUtils';
import { uploadImage } from 'utils/uploadMedia';
import { v4 as uuid } from 'uuid';

export class EnableTeamViewModel {
  title = '';
  imageUrl = '';
  titleError: string | undefined;
  isUploadingImage = false;
  imageUrlToCrop?: string;
  onClose: () => void;

  constructor(onClose: () => void) {
    makeAutoObservable(this);
    this.onClose = onClose;
  }

  get coach() {
    return localStorageStores.account;
  }

  get isCreateEnabled() {
    return !!this.title && !this.isUploadingImage;
  }

  get titlePlaceholder() {
    return `Team Name`;
  }

  onTeamTitleChange = (title: string) => {
    this.title = title;
    this.titleError = undefined;
  };

  onTeamImageChange = (imageUrl: string) => {
    this.imageUrl = imageUrl;
  };

  onCreateTeamClick = async () => {
    if (!this.title) {
      this.titleError = 'Please enter a team name';
      return;
    }

    sendEvent('startTeamModal_createTeamClick');

    try {
      await API.startTeam(this.title, this.imageUrl);
      await localStorageStores.team.fetch();
      this.onClose();
      router.push('/team');
      sendEvent('startTeamModal_teamCreated');
    } catch (e) {
      captureException(e);
      toast.error('Unknown error creating your team. Please try again later.');
    }
  };

  onDismissImageCrop = () => {
    this.isUploadingImage = false;
    this.imageUrlToCrop = undefined;
  };

  onFileSystemImageSelected = async (file: File) => {
    this.isUploadingImage = true;
    const { url } = await getUrlFromFile(file);

    this.imageUrlToCrop = url;
  };

  onCancelImageCrop = () => {
    this.imageUrlToCrop = undefined;
    this.isUploadingImage = false;
  };

  onUploadImage = async (imageUrl: string) => {
    if (!localStorageStores.team.id) {
      return;
    }

    this.isUploadingImage = true;
    this.imageUrlToCrop = undefined;
    try {
      const fileName = `${localStorageStores.team.id}-${uuid()}`;
      this.imageUrl = (await uploadImage(fileName, imageUrl)).url;
    } catch (e) {
      toast.error('Failed to upload photo');
    } finally {
      this.isUploadingImage = false;
    }
  };
}
