import {
  DistanceUnit,
  DistanceUnitShort,
  exactKgtoLbs,
  exactMetersToMiles,
  exactMetersToYards,
  WeightUnit,
  YARDS_IN_A_MILE,
  divide,
  BodyMeasurementUnit,
  roundToTwoDecimal,
  exactCmtoInch,
} from 'hevy-shared';

export const getLocaleBasedDefaultUnits = (): {
  weight: WeightUnit;
  distance: DistanceUnit;
  measurements: BodyMeasurementUnit;
} => {
  const isImperial = ['en-us', 'en-ca'].includes(navigator.language?.toLowerCase());
  if (isImperial) {
    return { weight: 'lbs', distance: 'miles', measurements: 'in' };
  } else return { weight: 'kg', distance: 'kilometers', measurements: 'cm' };
};

export const userWeight = (weightKg: number, unitPreference: WeightUnit) => {
  if (unitPreference === 'lbs') {
    return exactKgtoLbs(weightKg);
  }

  return weightKg;
};

export const userShortDistance = (distanceMeters: number) => {
  return distanceMeters;
};

export const userBodyMeasurement = (value: number, unit: BodyMeasurementUnit) => {
  if (unit === 'in') {
    return roundToTwoDecimal(exactCmtoInch(value));
  }

  return roundToTwoDecimal(value);
};

/**
 * Returns a pace like 6000seconds/km or 6000seconds/mile
 * depending on the distance unit.
 */
export const userPace = (
  distanceMeters: number,
  durationSeconds: number,
  distanceUnit: DistanceUnit,
) => {
  if (distanceUnit === 'miles') {
    return divide(durationSeconds, exactMetersToMiles(distanceMeters));
  }

  return divide(durationSeconds, distanceMeters / 1000);
};

/**
 *
 * @param distanceMeters
 * @param unitPreference
 * @returns Distance in miles or kilometers based on the passed preference
 */
export const userDistance = (
  distanceMeters: number,
  unitPreference: DistanceUnit | DistanceUnitShort,
) => {
  if (unitPreference === 'miles' || unitPreference === 'mi') {
    return exactMetersToYards(distanceMeters) / YARDS_IN_A_MILE;
  }

  return distanceMeters / 1000;
};
