import { HevyCoachLogo } from 'components/Logo/HevyCoachLogo';
import React from 'react';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';

const IncludedItem = ({ itemName }: { itemName: string }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: Spacing.md,
      }}
    >
      <View style={{ paddingRight: Spacing.md }}>
        <CustomizableIcon type="check" tint={colors.neutral900} />
      </View>
      {itemName}
    </View>
  );
};

export const WhatsIncludedView = () => {
  return (
    <View
      style={{
        flex: 1,
        display: 'flex',
      }}
    >
      <View style={{ width: 'fit-content' }}>
        <HevyCoachLogo
          style={{ marginLeft: -Spacing.sm, paddingBottom: Spacing.md }}
          fill={colors.black}
        />
        <TextSMRegular style={{ color: colors.neutral500, paddingBottom: Spacing.md }}>
          What's included
        </TextSMRegular>
        <IncludedItem itemName="Create unlimited workout programs" />
        <IncludedItem itemName="Extensive built-in exercise library" />
        <IncludedItem itemName="Create unlimited custom exercises" />
        <IncludedItem itemName="Track clients’ workouts & measurements" />
        <IncludedItem itemName="Clients get coached through Hevy app" />
        <IncludedItem itemName="Free Hevy Pro for all your clients" />
      </View>
    </View>
  );
};
