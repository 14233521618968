import { modal } from 'components/Modals/ModalManager';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';

export class PlanWarningsViewModel {
  constructor() {
    makeAutoObservable(this);
  }

  onUpgradePlanClicked = () => {
    modal.openUpgradePlanModal({
      source: 'planWarning',
      onClose: didUpgrade => {
        if (didUpgrade) {
          router.reload();
        }
      },
    });
  };

  onReactivatePlanClicked = () => {
    router.push('/settings/plan');
  };
}
