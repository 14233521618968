import { ChartData } from 'chart.js';
import { GraphOption } from 'components/MuscleDistributionGraphSelector';
import { HevyMuscleSplitRadarGraphOptions } from 'components/MuscleSplitRadarGraphOptions';
import {
  BaseRoutine,
  MuscleGroup,
  calculateMuscleSplit,
  getMuscleHeatmapDataForWorkoutsOrRoutines,
} from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { ViewRoutineExercise } from 'screens/Routines/types';
import { colors } from 'styleguide/colors';

import { calculateSetCountPerMuscleGroup } from 'utils/workoutUtils';
import { ExerciseTemplateAggregator } from 'state/aggregators/exerciseTemplateAggregatorStore';
import { Client } from 'types/client';

export class RoutineSummaryViewModel {
  exercises: ViewRoutineExercise[] = [];
  client?: Client;
  selectedMuscleGroupGraph: GraphOption = 'body';

  constructor(exercises: ViewRoutineExercise[], client?: Client) {
    makeAutoObservable(this);
    this.exercises = exercises;
    this.client = client;
  }

  get totalSets() {
    return this.exercises.reduce((acc, curr) => acc + curr.sets.length, 0);
  }

  get radarGraphOptions() {
    const options = HevyMuscleSplitRadarGraphOptions;
    options.animation = false;
    return options;
  }

  get heatMapData() {
    return getMuscleHeatmapDataForWorkoutsOrRoutines({
      workoutsOrRoutines: [{ exercises: this.exercisesWithMuscleGroups }],
    });
  }

  get muscleGroupData() {
    const setsPerMuscleGroup: { muscleGroupName: MuscleGroup; numberOfSets: number }[] = [];
    const rawData = calculateSetCountPerMuscleGroup([
      { exercises: this.exercisesWithMuscleGroups },
    ]);
    Object.keys(rawData).forEach(muscleGroup => {
      setsPerMuscleGroup.push({
        muscleGroupName: muscleGroup as MuscleGroup,
        numberOfSets: rawData[muscleGroup as MuscleGroup],
      });
    });
    return setsPerMuscleGroup;
  }

  get exercisesWithMuscleGroups(): {
    muscle_group: MuscleGroup;
    other_muscles: MuscleGroup[];
    sets: any[];
  }[] {
    return this.exercises
      .filter(e => {
        return !!ExerciseTemplateAggregator.getExerciseTemplate(
          e.templateId,
          e.parentTemplateId,
          this.client,
        );
      })
      .map(e => {
        const template = ExerciseTemplateAggregator.getExerciseTemplate(
          e.templateId,
          e.parentTemplateId,
          this.client,
        );

        if (!template) {
          throw new Error('Unable to find template!');
        }
        return {
          ...e,
          muscle_group: template.muscle_group,
          other_muscles: template.other_muscles,
        };
      });
  }

  get radarGraphData(): ChartData<'radar'> {
    const muscleSplitData = calculateMuscleSplit(([
      { exercises: this.exercisesWithMuscleGroups },
    ] as any) as BaseRoutine[]);
    const labels = ['Core', 'Shoulders', 'Arms', 'Legs', 'Back', 'Chest'];
    const data = [
      muscleSplitData.core,
      muscleSplitData.shoulders,
      muscleSplitData.arms,
      muscleSplitData.legs,
      muscleSplitData.back,
      muscleSplitData.chest,
    ];
    return {
      labels: labels,
      datasets: [
        {
          data: data,
          borderColor: colors.primary500,
          borderWidth: 1,
        },
      ],
    };
  }

  onSelectedMuscleGroupGraphChanged = (selected: GraphOption) => {
    this.selectedMuscleGroupGraph = selected;
  };
}
