import { CoachAccount } from 'hevy-shared';
import router from 'next/router';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { ProfileImageButton } from 'styleguide/Buttons';
import { FlexRow } from 'styleguide/Row';
import { ProfileImage } from './ProfileImage';
import { TextSMRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';

export const CoachLinkButton = ({
  coach,
  onClick,
  style,
}: {
  coach: CoachAccount;
  onClick?: () => void;
  style?: CSSProperties;
}) => {
  return (
    <ProfileImageButton
      style={style}
      imageUrl={coach.profile_pic}
      title={coach.full_name || coach.username}
      onClick={() => {
        onClick?.();
        router.push(`/team/members/${coach.username}`);
      }}
    />
  );
};

export const CoachLinkText = ({
  coach,
  style,
  profilePicDiameter,
  disableLink,
  onClick,
}: {
  coach: CoachAccount;
  style?: CSSProperties;
  profilePicDiameter?: number;
  disableLink?: boolean;
  onClick?: () => void;
}) => {
  return (
    <FlexRow
      style={{ cursor: disableLink ? undefined : 'pointer', gap: Spacing.sm, ...style }}
      onClick={
        disableLink
          ? undefined
          : () => {
              router.push(`/team/members/${coach.username}`);
              onClick?.();
            }
      }
    >
      <ProfileImage source={coach.profile_pic} diameter={profilePicDiameter || 20} />
      <TextSMRegular>{`by ${coach.full_name || coach.username}`}</TextSMRegular>
    </FlexRow>
  );
};
