import { ProfileImage } from 'components/ProfileImage';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Container = styled.div``;

interface TinyOverlappingProfileImagesProps {
  images: string[];
  style?: CSSProperties;
}

export const TinyOverlappingProfileImages = ({
  images,
  style,
}: TinyOverlappingProfileImagesProps) => {
  return (
    <Container style={style}>
      {images.map(i => (
        <ProfileImage key={i} style={{ marginRight: -5 }} source={i} diameter={20} />
      ))}
    </Container>
  );
};
