import { captureException } from '@sentry/nextjs';
import { makeAutoObservable } from 'mobx';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { validateYoutubeUrl as isYouTubeURL } from 'utils/youtube';

export class VideoUploadCellViewModel {
  youtubeVideoURL: string = '';
  hasYouTubeError: boolean = false;
  customVideoErrorMessage?: string;
  onAddVideoAttachment?: (video: VideoAttachment) => void;
  onSelectedFile?: (file: File) => void;

  constructor() {
    makeAutoObservable(this);
  }

  onYouTubeLinkChange = (value: string) => {
    if (this.hasYouTubeError) this.hasYouTubeError = false;
    this.youtubeVideoURL = value;
    if (!this.youtubeVideoURL) {
      return;
    }
    if (!isYouTubeURL(this.youtubeVideoURL)) {
      this.hasYouTubeError = true;
      return;
    }

    this.onAddVideoAttachment?.({ url: this.youtubeVideoURL, type: 'youtube' });
    this.youtubeVideoURL = '';
  };

  onFileDropped = (file: File) => {
    if (!['video/mp4', 'video/quicktime'].includes(file.type)) {
      captureException(
        new Error(`Coach attempted to upload exercise isntructions file of type: ${file.type}`),
      );
      this.customVideoErrorMessage = 'File must be an MP4 video.';
      return;
    } else {
      this.customVideoErrorMessage = undefined;
    }
    this.onSelectedFile?.(file);
  };
}
