import React from 'react';
import { colors } from 'styleguide/colors';
import DownloadAppstorePopup from 'components/assets/download-appstore.svg';
import DownloadGooglePlayPopup from 'components/assets/download-googleplay.svg';
import DownloadQRCode from 'components/assets/mobile-app-qr-code.svg';
import { TextMD } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { HevyMenu } from './HevyMenu/HevyMenu';
import styled from 'styled-components';
import { MenuAlign } from '@szhsin/react-menu';

type MobileAppPopupProps = {
  title?: string;
  align: MenuAlign;
  renderButton: () => JSX.Element;
};

export const MobileAppPopup = ({ title, align, renderButton }: MobileAppPopupProps) => {
  return (
    <HevyMenu
      arrow={false}
      align={align}
      menuButton={renderButton}
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        e.syntheticEvent.preventDefault();
      }}
      menuStyle={{
        border: `1px solid ${colors.neutral150}`,
        backgroundColor: colors.white,
        padding: Spacing.md,
        borderRadius: 10,
        boxShadow:
          '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PopUpContainer>
        {title && <TextMD style={{ lineHeight: '20px' }}>{title}</TextMD>}
        <Downloads>
          <QRCodeContainer>
            <DownloadQRCode style={{ width: 96, height: 96 }} />
          </QRCodeContainer>
          <Buttons>
            <a href="https://hevyapp.app.link/MVverXnGxyb" target="_blank" rel="noreferrer">
              <DownloadAppstorePopup />
            </a>
            <a href="https://hevyapp.app.link/Cn9UBiqGxyb" target="_blank" rel="noreferrer">
              <DownloadGooglePlayPopup />
            </a>
          </Buttons>
        </Downloads>
      </PopUpContainer>
    </HevyMenu>
  );
};

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 276px;
  gap: 8px;
`;

const Downloads = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const QRCodeContainer = styled.div`
  padding: 14px;
  border-radius: 7.147px;
  outline: 1px solid #e5e7eb;
  background-color: ${colors.white};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
