import React, { useState } from 'react';
import styled from 'styled-components';
import { DividerLine, VerticalDividerLine } from 'styleguide/DividerLine';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

const Container = styled(View)`
  height: 55px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    height: 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

interface ActionsProps {
  isLiked: boolean;
  onLike: () => void;
  onShare: () => void;
  onComment?: () => void;
}

export const WorkoutActions = ({ isLiked, onLike, onShare, onComment }: ActionsProps) => {
  const [isHoveringLike, setIsHoveringLike] = useState(false);
  const [isHoveringShare, setIsHoveringShare] = useState(false);
  const [isHoveringComment, setIsHoveringComment] = useState(false);

  return (
    <Container>
      <DividerLine />
      <ButtonsContainer>
        <ButtonContainer
          onClick={onLike}
          onMouseEnter={() => setIsHoveringLike(true)}
          onMouseLeave={() => setIsHoveringLike(false)}
        >
          <ButtonWrapper
            style={{ backgroundColor: isHoveringLike ? colors.neutral100 : undefined }}
          >
            <CustomizableIcon
              type={isLiked ? 'like-filled' : 'like'}
              tint={colors.neutral900}
            ></CustomizableIcon>
          </ButtonWrapper>
        </ButtonContainer>
        {!!onComment && (
          <>
            <VerticalDividerLine />
            <ButtonContainer
              onClick={onComment}
              onMouseEnter={() => setIsHoveringComment(true)}
              onMouseLeave={() => setIsHoveringComment(false)}
            >
              <ButtonWrapper
                style={{ backgroundColor: isHoveringComment ? colors.neutral100 : undefined }}
              >
                <CustomizableIcon type="comment" tint={colors.neutral900}></CustomizableIcon>
              </ButtonWrapper>
            </ButtonContainer>
          </>
        )}
        <VerticalDividerLine />
        <ButtonContainer
          onClick={onShare}
          onMouseEnter={() => setIsHoveringShare(true)}
          onMouseLeave={() => setIsHoveringShare(false)}
        >
          <ButtonWrapper
            style={{ backgroundColor: isHoveringShare ? colors.neutral100 : undefined }}
          >
            <CustomizableIcon type={'share'} tint={colors.neutral900}></CustomizableIcon>
          </ButtonWrapper>
        </ButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};
