import localFont from '@next/font/local';

export const InterFont = localFont({
  src: [
    {
      path: './assets/fonts/Inter-Regular-400.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './assets/fonts/Inter-Medium-500.ttf',
      weight: '500',
      style: 'normal',
    },
  ],
  display: 'swap',
});

const fontFamily = `${InterFont.style.fontFamily}`;

const fonts = {
  regular: {
    family: fontFamily,
    weight: '400',
  },
  medium: {
    family: fontFamily,
    weight: '500',
  },
};

export default fonts;
