import { View } from 'styleguide/View';
import React from 'react';
import { Spacing } from 'styleguide/spacing';
import VisaLogo from '../../../../assets/visa-logo.svg';
import MastercardLogo from '../../../../assets/mastercard-logo.svg';
import PayPalLogo from '../../../../assets/paypal-logo.svg';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import styled from 'styled-components';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';

const FooterText = styled(TextSMRegular)`
  color: ${colors.neutral400};
  text-align: center;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    font-size: 12px;
    line-height: 1;
  }
`;

export const PlanFooterContent = () => {
  return (
    <View style={{ padding: Spacing.lg }}>
      <FooterText>
        You can cancel your monthly subscription at any time before the end of the billing period.
        Your subscription will automatically renew each month.
      </FooterText>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          gap: Spacing.sm,
          paddingTop: Spacing.md,
        }}
      >
        <VisaLogo /> <MastercardLogo />
        <PayPalLogo />
      </View>
    </View>
  );
};
