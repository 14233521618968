import { modal } from 'components/Modals/ModalManager';
import { ResponsiveGraphContainer } from 'components/ResponsiveGraphContainer';
import { secondsToClockFormat, secondsToWordFormat } from 'hevy-shared';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { TertiaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Spacing } from 'styleguide/spacing';
import { DisplaySM, TextLG, TextMDRegular, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { memoryStores } from 'state/memoryStores';
import { EmptyLineGraph } from 'components/Graphs/EmptyGraph';
import { CustomizedAxisLabel, defaultXAxisProps, defaultYAxisProps } from 'components/Graphs/Axis';
import { CustomTooltipContainer, defaultTooltipProps } from 'components/Graphs/Tooltip';
import { calculateTooltipPosition } from 'components/Graphs/utils';
import { CustomLine, defaultActiveDot, defaultDot } from 'components/Graphs/Line';
import { CustomCartesianGrid } from 'components/Graphs/Grid';

const LINE_CHART_HEIGHT = 180;
const CHART_TOOLTIP_WIDTH = 165;
const X_AXIS_HEIGHT = 30;

const Container = styled(View)`
  flex: 1;
`;

const TooltipContent = ({
  payload,
  label,
  unit,
}: {
  payload?: any;
  label?: string;
  unit?: string;
}) => {
  return (
    <View>
      <View style={{ padding: Spacing.md, paddingBottom: Spacing.xs }}>
        <TextXSRegular>{label}</TextXSRegular>
        <TextMDRegular>
          {unit === 'seconds'
            ? `${secondsToWordFormat(payload.value)}`
            : `${payload.value} ${unit}`}
        </TextMDRegular>
      </View>
      <DividerLine color={colors.neutral200} />
      <View>
        <TertiaryButton
          onClick={async () => {
            try {
              toast.loading('Loading');
              const workout = await memoryStores.workouts.getClientWorkout(payload.workoutId);
              toast.dismiss();
              modal.openWorkoutDetailModal(workout.id);
            } catch {
              toast.dismiss();
              toast.error('Unable to open workout');
            }
          }}
          style={{ minWidth: CHART_TOOLTIP_WIDTH }}
          title="See workout"
        />
      </View>
    </View>
  );
};

export interface LineGraphProps {
  graphTitle: string;
  bestValueLabel: string;
  bestValue: string;
  data: Array<{
    date: string;
    value: number;
  }>;
  valueLabel: string;
  unit?: string;
  formatAxisAsTime?: boolean;
}

export const LineGraph = ({
  graphTitle,
  bestValueLabel,
  bestValue,
  valueLabel,
  data,
  unit,
  formatAxisAsTime,
}: LineGraphProps) => {
  const [valuePositionData, setposData] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // We use these min/max state values so we can calculate the tooltip position
  const maxDomainValue = Math.max(...data.map(week => week.value));
  const minDomainValue = Math.min(...data.map(week => week.value));

  // We keep track of whether the mouse is in the container so we can
  // show the tooltip till the mouse is completely outside the chart area
  const [isMouseInsideChartContainer, setIsMouseInsideChartContainer] = useState(false);
  return (
    <Container
      onMouseLeave={() => {
        setIsMouseInsideChartContainer(false);
      }}
      onMouseEnter={() => {
        setIsMouseInsideChartContainer(true);
      }}
    >
      <TextLG style={{ paddingBottom: Spacing.md }}>{graphTitle}</TextLG>

      {data.length === 0 ? (
        <EmptyLineGraph xAxisHeight={35} />
      ) : (
        <>
          <TextSMRegular style={{ color: colors.neutral500 }}>{bestValueLabel}</TextSMRegular>
          <DisplaySM style={{ margin: `0px 0 ${Spacing.md}px 0` }}>{bestValue}</DisplaySM>
          <ResponsiveGraphContainer>
            <LineChart
              data={data}
              style={{ height: LINE_CHART_HEIGHT }}
              // Helps ensure selected dots don't clip
              margin={{ top: Spacing.md, right: Spacing.md }}
              onMouseMove={data => {
                calculateTooltipPosition({
                  data,
                  dataMinValue: minDomainValue,
                  dataMaxValue: maxDomainValue,
                  chartHeight: LINE_CHART_HEIGHT,
                  gridHeight: 156,
                  offset: 140,
                  tooltipWidth: CHART_TOOLTIP_WIDTH,
                  setposData,
                });
              }}
            >
              <CustomCartesianGrid />
              <XAxis {...defaultXAxisProps} height={X_AXIS_HEIGHT} />
              <YAxis
                {...defaultYAxisProps}
                domain={[minDomainValue, maxDomainValue]}
                tick={
                  <CustomizedAxisLabel
                    formatter={value => (formatAxisAsTime ? secondsToClockFormat(value) : value)}
                  />
                }
              />
              <Tooltip
                {...defaultTooltipProps}
                cursor={{ stroke: colors.neutral500, strokeWidth: 1.5, strokeDasharray: '3 3' }}
                position={{ x: valuePositionData?.x ?? 0, y: valuePositionData?.y ?? 0 }}
                wrapperStyle={{ visibility: 'visible' }}
                content={
                  // Actual props (except isMouseInsideContainer) will be passed by Tooltip under the hood
                  <CustomTooltipContainer
                    isMouseInsideContainer={isMouseInsideChartContainer}
                    width={CHART_TOOLTIP_WIDTH}
                    downTriangle={true}
                  >
                    <TooltipContent unit={unit || valueLabel} />
                  </CustomTooltipContainer>
                }
              />
              <CustomLine
                type="linear"
                dataKey="value"
                stroke={colors.primary500}
                strokeWidth={2}
                isAnimationActive={false}
                dot={defaultDot}
                activeDot={defaultActiveDot}
              />
            </LineChart>
          </ResponsiveGraphContainer>
        </>
      )}
    </Container>
  );
};
