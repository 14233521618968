import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';
import toast from 'react-hot-toast';
import { ChatAggregator } from 'state/aggregators/chatAggregatorStore';
import { memoryStores } from 'state/memoryStores';
import { Client } from 'types/client';
import { sendEvent } from 'utils/analyticsEvents';
import { isValidLastWorkoutDate } from 'utils/dateAndTimeUtils';
import { clientMatchesSearch, compareClientsAlphabetically } from 'utils/pureUtils';
import { modal } from '../ModalManager';
import { localStorageStores } from 'state/localStorageStores';

export class ClientListViewModel {
  clients: Client[] = [];
  searchText = '';
  isOpeningChat = false;

  constructor(clients: Client[]) {
    makeAutoObservable(this);
    this.clients = clients;
  }

  clientSubtitleFromClientId = (clientId: string) => {
    const client = this.clients.find(client => client.id === clientId);
    if (!client) {
      return '';
    }

    const workouts = memoryStores.clients.clientWorkoutHistory[client.id];
    const workoutsOnThisWeek =
      workouts?.filter(
        workout =>
          workout.workout_end_unix > dayjs().subtract(1, 'week').startOf('day').unix() &&
          workout.workout_end_unix < dayjs().unix(),
      ).length || 0;

    const subtitle =
      workoutsOnThisWeek > 0
        ? `${
            workoutsOnThisWeek === 1 ? '1 workout' : `${workoutsOnThisWeek} workouts`
          } over the past 7 days`
        : isValidLastWorkoutDate(client.lastWorkoutDate)
        ? `Last workout: ${dayjs(client.lastWorkoutDate).fromNow()}`
        : `No workouts yet`;

    return subtitle;
  };

  get filteredClients() {
    return this.clients
      .filter(client => clientMatchesSearch(client, this.searchText))
      .sort(compareClientsAlphabetically);
  }

  onSearchTextChange = (value: string) => {
    this.searchText = value;
  };

  onMessageClientClick = async (client: Client, windowWidth: number, onClose: () => void) => {
    sendEvent('clientList_messageClick');

    if (client.id === localStorageStores.account.id) {
      sendEvent('clientList_messageClick_coachingSelf_alert');
      modal.openAlertModal({
        title: 'Unsupported',
        body: 'You cannot start a conversation with yourself',
        confirmButtonTitle: 'OK',
      });
      return;
    }

    this.isOpeningChat = true;
    try {
      if (windowWidth > 1024) {
        await ChatAggregator.openChatWidgetForClient(client, true, 'clientListModal');
      } else {
        router.push(`/chat/${client.username}`);
      }
    } catch (error) {
      toast.error('Unable to open conversation.');
    }
    this.isOpeningChat = false;
    onClose();
  };
}
