import { BaseRoutine, CoachAccount, Program, secondsToWordFormat } from 'hevy-shared';
import React from 'react';
import styled from 'styled-components';
import { Badge } from 'styleguide/Badge';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { zIndexes } from 'styleguide/zIndex';
import { ModalHeader } from './Components/ModalHeader';
import { CoachLinkText } from 'components/CoachLink';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  overflow-y: auto;
`;

const RoutinesContainer = styled(View)`
  gap: ${Spacing.lg}px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

export interface ProgramDetailModalProps {
  program: Program;
  coach?: CoachAccount;
  routines: BaseRoutine[];
  isOpen: boolean;
  onClose: () => void;
  onAddToMyLibraryClick: (programId: string) => Promise<void>;
  onEditProgramClick?: (programId: string) => void;
}

export const ProgramDetailModal = ({
  program,
  coach,
  routines,
  isOpen,
  onClose,
  onAddToMyLibraryClick,
  onEditProgramClick,
}: ProgramDetailModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={{ zIndex: zIndexes.modal }}
    >
      <Container>
        <ModalHeader text={program.title} onClose={onClose} style={{ paddingBottom: Spacing.sm }} />

        {!!coach && (
          <CoachLinkText
            coach={coach}
            style={{ marginLeft: Spacing.lg, marginBottom: Spacing.md }}
            onClick={onClose}
          />
        )}

        <DividerLine />
        <MiddleContainer>
          {!!program.notes && (
            <TextSMRegular style={{ marginBottom: Spacing.lg }}>{program.notes}</TextSMRegular>
          )}
          <FlexRow style={{ marginBottom: Spacing.md }}>
            <TextMD>Routines</TextMD>
            <Badge number={routines.length} />
          </FlexRow>
          <RoutinesContainer>
            {routines.map(routine => {
              return (
                <View style={{ gap: Spacing.sm }} key={routine.id}>
                  <TextMD>{routine.title}</TextMD>
                  <View>
                    {routine.exercises.map(exercise => {
                      return (
                        <TextSMRegular key={exercise.id}>
                          {`${exercise.sets.length} x ${exercise.title}${
                            exercise.rest_seconds
                              ? ', Rest: ' + secondsToWordFormat(exercise.rest_seconds)
                              : ''
                          }`}
                        </TextSMRegular>
                      );
                    })}
                  </View>
                </View>
              );
            })}
          </RoutinesContainer>
        </MiddleContainer>
        <DividerLine />
        <LowerContainer>
          {onEditProgramClick && (
            <SecondaryButton
              title={'Edit Program'}
              onClick={() => {
                onEditProgramClick(program.id);
                onClose();
              }}
            />
          )}
          <PrimaryButton
            title={'Add to My Library'}
            onClick={async () => {
              setIsLoading(true);
              await onAddToMyLibraryClick(program.id);
              setIsLoading(false);
              onClose();
            }}
            loading={isLoading}
            enabled={!isLoading}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
