import React from 'react';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { FlexRow } from 'styleguide/Row';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing, CornerRadius } from 'styleguide/spacing';

export type GraphOption = 'web' | 'body';

export const MuscleDistributionSelector = ({
  selectedOption,
  onSelectedOption,
  optionsOrder,
}: {
  selectedOption: GraphOption;
  onSelectedOption: (selectedOption: GraphOption) => void;
  optionsOrder: [GraphOption, GraphOption];
}) => {
  const selectedBorderColor = colors.primary200;
  return (
    <FlexRow style={{ cursor: 'pointer' }}>
      <View
        style={{
          backgroundColor: selectedOption === optionsOrder[0] ? colors.primary50 : undefined,
          padding: Spacing.sm,
          borderTopLeftRadius: CornerRadius.sm,
          borderBottomLeftRadius: CornerRadius.sm,
          border: 1,
          borderColor: selectedOption === optionsOrder[0] ? selectedBorderColor : colors.neutral200,
          borderStyle: 'solid',
        }}
        onClick={() => {
          onSelectedOption(optionsOrder[0]);
        }}
      >
        <CustomizableIcon
          type={optionsOrder[0] === 'web' ? 'spider-web' : 'muscle-group-body'}
          tint={selectedOption === optionsOrder[0] ? colors.primary500 : colors.neutral400}
        />
      </View>
      <View
        style={{
          backgroundColor: selectedOption === optionsOrder[1] ? colors.primary50 : undefined,
          padding: Spacing.sm,
          borderTopRightRadius: CornerRadius.sm,
          borderBottomRightRadius: CornerRadius.sm,
          border: 1,
          borderColor: selectedOption === optionsOrder[1] ? selectedBorderColor : colors.neutral200,
          borderStyle: 'solid',
        }}
        onClick={() => {
          onSelectedOption(optionsOrder[1]);
        }}
      >
        <CustomizableIcon
          type={optionsOrder[1] === 'web' ? 'spider-web' : 'muscle-group-body'}
          tint={selectedOption === optionsOrder[1] ? colors.primary500 : colors.neutral400}
        />
      </View>
    </FlexRow>
  );
};
