import styled from 'styled-components';
import { colors } from './colors';
import { CornerRadius, Spacing } from './spacing';
import { TABLET_WIDTH_BREAKPOINT } from './Breakpoints';

export const Card = styled.div`
  background-color: ${colors.white};
  border-radius: ${CornerRadius.md}px;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.neutral150};
  padding: ${Spacing.lg}px;
  margin-bottom: ${Spacing.md}px;
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    margin-bottom: ${Spacing.sm}px;
  }
`;

export const CardNoPadding = styled(Card)`
  padding: 0px;
`;
