import React from 'react';
import styled from 'styled-components';
import { CornerRadius } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { Client } from 'types/client';
import { NewChatView } from './components/NewChatView';
import { zIndexes } from 'styleguide/zIndex';

const Container = styled(View)`
  position: relative;
  z-index: ${zIndexes.chatWidgets};
  bottom: 0px;
  z-index: 2;
  border-top-left-radius: ${CornerRadius.md}px;
  border-top-right-radius: ${CornerRadius.md}px;
  border: 1px solid ${colors.neutral150};
  background-color: ${colors.white};
  width: 330px;
  height: 400px;
  align-items: center;
  box-shadow: 0px 4px 16px -2px rgba(17, 25, 39, 0.1); // colors.neutral900
`;

interface NewChatWidgetProps {
  isLoading?: boolean;
  onClose: () => void;
  onSelectClient: (client: Client) => void;
}

export const NewChatWidget = ({ onSelectClient, onClose, isLoading }: NewChatWidgetProps) => {
  return (
    <Container>
      <NewChatView onClose={onClose} onSelectClient={onSelectClient} isLoading={isLoading} />
    </Container>
  );
};
