'use client';
import { makeAutoObservable } from 'mobx';

export class TextInputViewModel {
  inputText = '';
  errorText: string | undefined = undefined;
  isHandlingSubmit = false;

  constructor(defaultText?: string) {
    this.inputText = defaultText ?? '';
    makeAutoObservable(this);
  }

  onInputTextChange = (text: string) => {
    this.inputText = text;
    this.errorText = undefined;
  };
}
