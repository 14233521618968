import { BaseRoutine } from 'hevy-shared';

export const doesRoutinesHaveClientCustomExercises = (
  routines: BaseRoutine[],
  clientUsername: string,
): boolean => {
  return !!routines.find(r =>
    r.exercises.find(e => e.username === clientUsername && e.parent_exercise_template_id === null),
  );
};
