import { captureException } from '@sentry/nextjs';
import { CoachsLead } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

const COACHS_LEADS_LOCAL_STORAGE_KEY = 'COACHS_LEADS_LOCAL_STORAGE_KEY';

export class CoachsLeads {
  leads: CoachsLead[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const leadsJSON = window.localStorage.getItem(COACHS_LEADS_LOCAL_STORAGE_KEY);
    if (leadsJSON) {
      this.leads = JSON.parse(leadsJSON);

      window.localStorage.setItem(COACHS_LEADS_LOCAL_STORAGE_KEY, JSON.stringify(this.leads));
    }
  };

  clearData = () => {
    this.leads = [];
    window.localStorage.removeItem(COACHS_LEADS_LOCAL_STORAGE_KEY);
  };

  inviteLead = async (lead: CoachsLead) => {
    const leadToInvite = this.leads.find(l => l.lead_email === lead.lead_email);
    if (!leadToInvite) {
      throw new Error('InvalidLead');
    }
    try {
      this.leads = this.leads.filter(l => l.lead_email !== lead.lead_email);
      await API.acceptCoachLead(leadToInvite.lead_email);
    } catch (error) {
      this.leads.push(leadToInvite);
      throw error;
    }
  };

  declineLead = async (lead: CoachsLead) => {
    const leadToDecline = this.leads.find(l => l.lead_email === lead.lead_email);
    if (!leadToDecline) {
      throw new Error('InvalidLead');
    }
    try {
      this.leads = this.leads.filter(l => l.lead_email !== lead.lead_email);
      await API.deleteCoachLead(leadToDecline.lead_email);
    } catch (error) {
      this.leads.push(leadToDecline);
      throw error;
    }
  };

  hasFetched = false;
  isFetchingLeads = false;
  fetch = async () => {
    if (this.isFetchingLeads) {
      return;
    }
    this.isFetchingLeads = true;
    try {
      this.leads = (await API.getCoachLeads()).data;
      this.hasFetched = true;
    } catch (e) {
      captureException(e);
      this.clearData();
      throw e;
    } finally {
      this.isFetchingLeads = false;
    }
  };
}
