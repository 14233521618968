import styled from 'styled-components';
import React from 'react';
import { colors } from './colors';
import { Spacing } from './spacing';

const ProgressContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${props => `${props.backgroundColor}`};
`;

const CompletedProgress = styled.div`
  background-color: ${colors.primary500};
  width: 100%;
`;

export const ProgressBar = ({
  progressFraction,
  progressColor,
  backgroundColor,
  height = Spacing.sm,
  borderRadius = Spacing.sm,
}: {
  progressFraction: number;
  progressColor?: string;
  backgroundColor?: string;
  height?: number;
  borderRadius?: number;
}) => {
  return (
    <ProgressContainer backgroundColor={backgroundColor} style={{ height, borderRadius }}>
      <CompletedProgress
        style={{
          borderRadius: borderRadius,
          height: height,
          width: `${Math.min(progressFraction * 100, 100)}%`,
          backgroundColor: progressColor,
        }}
      />
    </ProgressContainer>
  );
};
