import styled from 'styled-components';

export const CircleDiv = styled.div<{ diameter: number }>`
  width: ${({ diameter }) => diameter}px;
  height: ${({ diameter }) => diameter}px;
  border-radius: ${({ diameter }) => diameter / 2}px;
  flex-shrink: 0;
`;

export const CircleView = styled(CircleDiv)`
  display: flex;
  flex-direction: column;
`;

export const CircleRow = styled(CircleDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
