import { observer } from 'mobx-react-lite';

import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleguide/colors';
import { CustomizableIcon } from '../styleguide/CustomizableIcon';
import { Spacing } from '../styleguide/spacing';
import { View } from '../styleguide/View';
import { TextSMRegular } from 'styleguide/Texts';

interface WarningBannerProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  backgroundColor?: string;
}

const WarningBannerContainer = styled.div`
  background-color: ${colors.warningYellow};
  flex-direction: row;
  align-content: center;
  display: flex;
  gap: ${Spacing.xs}px;
  padding-left: ${Spacing.lg}px;
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  height: fit-content;
`;

export const ConnectionLostWarningBanner = () => {
  return (
    <WarningBanner
      icon={
        <View style={{ paddingTop: 1, paddingRight: Spacing.sm }}>
          <CustomizableIcon type="wifi-off" tint={colors.neutral900} />
        </View>
      }
      backgroundColor={colors.neutral100}
    >
      <TextSMRegular style={{ color: colors.neutral900 }}>
        Connection lost. Your changes will be automatically saved once your internet connection is
        restored.
      </TextSMRegular>
    </WarningBanner>
  );
};

export const WarningBanner = observer(({ children, backgroundColor, icon }: WarningBannerProps) => {
  return (
    <WarningBannerContainer style={{ backgroundColor: backgroundColor }}>
      {icon ? (
        <>{icon}</>
      ) : (
        <View style={{ width: 20, height: 17 }}>
          <CustomizableIcon tint={colors.neutral900} type={'warning-triangle'} />
        </View>
      )}

      {children}
    </WarningBannerContainer>
  );
});
