import React from 'react';
import { observer } from 'mobx-react-lite';
import { localStorageStores } from 'state/localStorageStores';
import { FullPageCenteredLoadingIndicator } from './FullPageCenteredLoadingIndicator';
import { memoryStores } from 'state/memoryStores';

interface BootstrapLoadingWrapperProps {
  children: React.ReactNode;
  waitForOptionalDataFetch?: boolean;
}

export const BootstrapLoadingWrapper = observer(
  ({ children, waitForOptionalDataFetch }: BootstrapLoadingWrapperProps) => {
    const { isBootstrapped } = localStorageStores.lifecycle;

    const isOptionalDataLoaded =
      !waitForOptionalDataFetch ||
      (localStorageStores.programs.hasFetched &&
        localStorageStores.exerciseTemplates.hasFetched &&
        localStorageStores.invites.hasFetched &&
        localStorageStores.plan.hasFetched &&
        memoryStores.libraryRoutines.hasFetched);
    if (isBootstrapped && isOptionalDataLoaded) {
      return <>{children}</>;
    }
    return <FullPageCenteredLoadingIndicator />;
  },
);
