import { Message } from 'hevy-shared';

export const oldestMessageFirst = (a: Message, b: Message) => {
  return Number(a.id) - Number(b.id);
};

export const newestMessageFirst = (a: Message, b: Message) => {
  return Number(b.id) - Number(a.id);
};

export const oldestMessageInConversation = (
  conversationId: string,
  messages: Message[],
): Message | undefined => {
  const oldest = messages
    .slice()
    .filter(m => {
      return m.conversation_id === conversationId;
    })
    .sort(oldestMessageFirst);
  return oldest[0];
};

export const latestMessageInConversation = (
  conversationId: string,
  messages: Message[],
): Message | undefined => {
  const latestMessageInConversation = messages
    .slice()
    .filter(m => {
      return m.conversation_id === conversationId;
    })
    .sort(newestMessageFirst);
  return latestMessageInConversation[0];
};

export const isUniqueMessage = (value: Message, index: number, messages: Message[]) => {
  return (
    messages.findIndex(message => {
      return message.id === value.id;
    }) === index
  );
};
