import axios from 'axios';
import { localStorageStores } from 'state/localStorageStores';
import { fireAndForget } from './async';
import Cookies from 'js-cookie';

const isTikTokInitialized = () => {
  return !!Cookies.get('_ttp');
};

/**
 * Okay, so Tiktok's CORS config doesn't allow sending events directly from the front-end. Instead, we call a little proxy API on the Vercel "backend" (/api/send_tiktok_event)
 * that sends the event to Tiktok.
 */

export const sendTitkTokEventToServerProxy = (event: string, params?: any) => {
  if (!isTikTokInitialized()) {
    return;
  }
  const userId = localStorageStores.account.id;
  const userEmail = localStorageStores.account.email;
  fireAndForget([
    axios.post('/api/send_tiktok_event', {
      userId,
      userEmail,
      event,
      params,
    }),
  ]);
};
export const sendTitkTokPurchaseEventToServerProxy = (
  planId: string,
  planTitle: string,
  valueUSD?: number,
) => {
  if (!isTikTokInitialized()) {
    return;
  }
  const userId = localStorageStores.account.id;
  const userEmail = localStorageStores.account.email;
  fireAndForget([
    axios.post('/api/send_tiktok_event', {
      userId,
      userEmail,
      planId,
      planTitle,
      valueUSD,
    }),
  ]);
};
