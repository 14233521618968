import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextMDRegular, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CloseButton } from 'components/CloseButton';
import fonts from 'styleguide/fonts';
import { Link } from 'components/Link';
import { FlexRow } from 'styleguide/Row';
import { DividerLine } from 'styleguide/DividerLine';
import { localStorageStores } from 'state/localStorageStores';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { CoachSelector } from '../components/CoachSelector';
import { InviteeView } from '../components/InviteeView';
import { SearchUserInput } from '../components/SearchUserInput';
import { ClientInviteViewModel } from './ClientInviteViewModel';
import { FormInput } from 'styleguide/Inputs';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';

const Container = styled(View)`
  border-radius: 10px;
  width: 684px;
`;

const UpperContainer = styled.div`
  padding: ${Spacing.lg}px ${Spacing.lg}px ${Spacing.md}px ${Spacing.lg}px;
`;

const LowerContainer = styled.div`
  display: flex;
  gap: ${Spacing.md}px;
  justify-content: flex-end;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  flex-wrap: wrap;
`;

const InviteesContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  flex: 1;
  overflow-y: auto;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

const CoachSelectorContainer = styled(View)`
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    min-width: 150px;
  }
`;

export interface ClientInviteModalProps {
  vm: ClientInviteViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const ClientInviteModal = observer(({ vm, isOpen, onClose }: ClientInviteModalProps) => {
  return (
    <Modal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      contentStyle={{ overflow: 'auto' }}
    >
      <Container>
        <UpperContainer>
          <FlexRow style={{ alignContent: 'center', justifyContent: 'space-between' }}>
            <FlexRow>
              <TextLG>{vm.title}</TextLG>
            </FlexRow>
            <CloseButton onClick={onClose} />
          </FlexRow>

          <TextMDRegular
            style={{
              paddingBottom: Spacing.md,
              color: colors.neutral500,
              paddingTop: Spacing.md,
            }}
          >
            {vm.subtext}{' '}
            <Link
              href={vm.learnMoreLink}
              target="blank"
              style={{
                paddingBottom: Spacing.md,
                fontWeight: fonts.medium.weight,
                display: 'inline',
              }}
            >
              Learn More
            </Link>
          </TextMDRegular>
          <FlexRow
            style={{
              border: `1px solid ${colors.neutral200}`,
              borderRadius: CornerRadius.sm,
              gap: Spacing.md,
              flexWrap: 'wrap',
              marginBottom: Spacing.md,
            }}
          >
            <FormInput
              style={{ flex: 1, minWidth: 100, borderStyle: 'none', textOverflow: 'ellipsis' }}
              value={vm.coachDirectClientLink}
            />
            <FlexRow
              onClick={vm.copyLeadLinkToClipboard}
              style={{ gap: Spacing.sm, marginRight: Spacing.md, cursor: 'pointer' }}
            >
              <CustomizableIcon type="link" tint={colors.primary500} />
              <TextSMMedium style={{ color: colors.primary500 }}>Copy Link</TextSMMedium>
            </FlexRow>
          </FlexRow>
          <DividerLine />
          <View style={{ marginTop: Spacing.md }}>
            <SearchUserInput vm={vm} placeholder={'client@example.com'} />
          </View>
        </UpperContainer>
        {vm.inviteeCount > 0 && (
          <>
            <DividerLine />
            <InviteesContainer>
              {vm.invitees.map((invitee, index) => {
                return (
                  <FlexRow
                    key={index}
                    style={{
                      gap: Spacing.md,
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <FlexRow
                      key={index}
                      style={{
                        gap: Spacing.md,
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        flex: 1,
                      }}
                    >
                      <InviteeView invitee={invitee} />
                      {vm.showCoachSelector && (
                        <CoachSelectorContainer>
                          <CoachSelector
                            coachOptions={localStorageStores.team.members}
                            selectedCoach={vm.selectedCoachForInvitee(invitee)}
                            onSelectCoach={coach => vm.onSelectCoach(invitee, coach)}
                          ></CoachSelector>
                        </CoachSelectorContainer>
                      )}
                    </FlexRow>
                    <SecondaryButton
                      title=""
                      iconType="trash"
                      onClick={() => vm.onRemoveInvitee(invitee)}
                    />
                  </FlexRow>
                );
              })}
            </InviteesContainer>
          </>
        )}

        <DividerLine />
        <LowerContainer>
          <PrimaryButton
            enabled={vm.isSubmitAvailable}
            style={{ display: 'flex', paddingLeft: Spacing.lg, paddingRight: Spacing.lg }}
            title={vm.inviteeCount > 1 ? `Send ${vm.inviteeCount} Invitations` : `Send Invitation`}
            loading={vm.isHandlingSubmit}
            onClick={vm.sendInvites}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});
