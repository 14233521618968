import { CartesianGrid } from 'recharts';
import { colors } from 'styleguide/colors';

export class CustomCartesianGrid extends CartesianGrid {
  static defaultProps = {
    ...CartesianGrid.defaultProps,
    //custom props
    strokeDasharray: '3 3',
    stroke: colors.neutral200,
    vertical: false,
  };
}
