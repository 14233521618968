'use client';
import axios from 'axios';
import { CoachInviteSearchUser, isCoachRole, isValidEmail } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { debounce } from 'lodash';
import { modal } from 'components/Modals/ModalManager';
import { InviteError, CoachInvitee } from '../types';
import {
  MAXIUMUM_COACHES_TO_INVITE,
  promptUserMaxInvitesReached,
  openAlertModalForFailedInvites,
} from '../utils';

export class CoachInviteViewModel {
  title = 'Invite new coaches';
  subtext =
    'Invite one or more coaches. They will receive a link to accept your invitation and be guided through the account setup process.';
  learnMoreLink = undefined;
  showInviteeRoleSelector = true;
  private _inputText = '';
  isHandlingSubmit = false;
  invitees: CoachInvitee[] = [];
  isEmailValidationErrorDetected = false;
  inviteErrors: InviteError[] = [];
  searchResults: CoachInviteSearchUser[] = [];
  showSearchResults = false;
  showCoachSelector = false;
  clientsCoaches: { [clientId: string]: string }[] = [];

  constructor(defaultText?: string) {
    this.inputText = defaultText ?? '';
    makeAutoObservable(this);
  }

  get inputText() {
    return this._inputText;
  }

  set inputText(value: string) {
    this.isEmailValidationErrorDetected = false;
    this._inputText = value;
  }

  onInviteeRoleChanged = (role: unknown, invitee: CoachInvitee) => {
    if (isCoachRole(role)) {
      invitee.role = role;
    }
  };

  onInputTextChanged = (value: string) => {
    this.inputText = value;
    // Do not show search results if the input text is less than 3 characters
    if (this.inputText.length <= 3) {
      this.showSearchResults = false;
      this.isSearching = false;
    } else {
      this.startSearch();
    }
  };

  get isInputEnabled() {
    return !this.isHandlingSubmit && this.inviteeCount <= MAXIUMUM_COACHES_TO_INVITE;
  }

  get isSubmitAvailable() {
    return !this.isHandlingSubmit && !this.isEmailValidationErrorDetected && this.inviteeCount > 0;
  }

  get shouldShowEmailSearchResults() {
    return (
      this.searchResults.length === 0 &&
      this.isEnteredTextValidEmail &&
      !this.doesEmailExistsInInvitees(this.inputText)
    );
  }

  get inviteeCount() {
    return this.invitees.length;
  }

  onPasteText = (text: string) => {
    const potentialEmails = text.trim().split(/[\s,]+/);
    const validatedEmails = [];
    for (const email of potentialEmails) {
      if (isValidEmail(email)) {
        validatedEmails.push(email);
      }
    }

    // If there are no valid emails, do nothing, just paste the text
    if (validatedEmails.length === 0) {
      this.onInputTextChanged(text);
      return;
    }

    // If there are some valid emails, show an error to the user
    if (validatedEmails.length !== potentialEmails.length) {
      this.inputText = text;
      this.isEmailValidationErrorDetected = true;
      return;
    }

    this.checkInviteLimitAndAddEmails(validatedEmails);
  };

  onUserSearchResultClick = (user: CoachInviteSearchUser) => {
    this.checkInviteLimitAndAddUser(user);
  };

  onEmailSearchResultClick = () => {
    if (!isValidEmail(this.inputText)) {
      this.isEmailValidationErrorDetected = true;
      return;
    }

    this.checkInviteLimitAndAddEmails([this.inputText]);
  };

  checkInviteLimitAndAddUser = (user: CoachInviteSearchUser) => {
    if (this.inviteeCount + 1 > MAXIUMUM_COACHES_TO_INVITE) {
      promptUserMaxInvitesReached();
      return;
    }

    this.invitees.push({ type: 'user', userData: user, role: 'member' });
    this.searchResults = this.searchResults.filter(s => s.username !== user.username);
    this.showSearchResults = false;
    this.inputText = '';
  };

  checkInviteLimitAndAddEmails = (emails: string[]) => {
    const totalEmails = this.inviteeCount + emails.length;
    if (totalEmails > MAXIUMUM_COACHES_TO_INVITE) {
      promptUserMaxInvitesReached();
      return;
    }

    this.invitees.push(
      ...emails.map(e => {
        return { type: 'email', email: e, role: 'member' } as CoachInvitee;
      }),
    );
    this.inputText = '';
    this.searchResults = [];
    this.showSearchResults = false;
  };

  onRemoveInvitee = (invitee: CoachInvitee) => {
    this.invitees = this.invitees.filter(e => e !== invitee);
  };

  onInputKeyPressed = (key: string) => {
    if (key === 'Enter' || key === ' ') {
      this.processEnteredText();
    }
  };

  get isEnteredTextValidEmail() {
    return isValidEmail(this._inputText);
  }

  processEnteredText = () => {
    this._inputText = this._inputText.trim();
    if (this._inputText === '') {
      return;
    }

    const maybeUser = this.searchResults.find(
      s => s.email === this.inputText || s.username === this.inputText,
    );

    if (maybeUser) {
      this.checkInviteLimitAndAddUser(maybeUser);
      return;
    }

    if (isValidEmail(this._inputText)) {
      this.checkInviteLimitAndAddEmails([this.inputText]);
    } else {
      this.isEmailValidationErrorDetected = true;
    }
  };

  isSearching = false;
  startSearch = () => {
    this.searchResults = [];
    this.isSearching = true;
    this.debouncedSearch();
    this.showSearchResults = true;
  };

  search = async () => {
    if (this._inputText.length < 3) {
      this.isSearching = false;
      return;
    }
    try {
      const result = isValidEmail(this._inputText)
        ? await API.searchUserByEmail(this._inputText.toLocaleLowerCase())
        : await API.searchUserByName(this._inputText.toLocaleLowerCase());

      this.searchResults = result.data.filter(s => {
        const isAlreadyAnTeamMember = !!localStorageStores.team.teamMemberWithUsername({
          username: s.username,
        });
        return (
          !isAlreadyAnTeamMember &&
          !this.doesEmailExistsInInvitees(s.email) &&
          !this.doesUsernameExistsInInvitees(s.username)
        );
      });
    } catch {
    } finally {
      this.isSearching = false;
    }
  };

  debouncedSearch = debounce(this.search, 300);

  sendInvites = async () => {
    if (this.inviteeCount === 0) {
      return;
    }

    const invitees = this.invitees.filter((value, index, self) => self.indexOf(value) === index);
    this.inviteErrors = [];
    const inviteErrors: { invitee: CoachInvitee; error: string }[] = [];
    this.isHandlingSubmit = true;
    const successfulInvites: CoachInvitee[] = [];

    for (const invitee of invitees) {
      if (!isCoachRole(invitee.role)) {
        continue;
      }
      try {
        switch (invitee.type) {
          case 'email':
            await API.createOrgInvite({ inviteeRole: invitee.role, inviteeEmail: invitee.email });
            break;
          case 'user':
            await API.createOrgInvite({
              inviteeRole: invitee.role,
              inviteeUsername: invitee.userData.username,
            });
            break;
        }
        await localStorageStores.teamInvites.fetch();
        sendEvent('inviteCoachToTeam_complete', { clientEmail: invitee });
        successfulInvites.push(invitee);
      } catch (requestError) {
        let error = 'Unknown error creating invite.';
        if (axios.isAxiosError(requestError)) {
          switch (requestError.response?.data.error) {
            case 'UserAlreadyHasInvite':
              error = 'This user already has a invite to Hevy Coach.';
              break;
            case 'UserIsAlreadyACoach':
              error = `The user is already a Hevy Coach.`;
              break;
            case 'UnknownUser':
              error = `No Hevy user with that email address.`;
              break;
            default:
          }
        }
        inviteErrors.push({ invitee: invitee, error: error });
      }
    }
    this.inviteErrors = inviteErrors;
    if (this.inviteErrors.length === 0) {
      toast.success('✉️ Invitation emails sent!');
    } else {
      if (successfulInvites.length > 0) {
        toast.success(
          `✉️ ${successfulInvites.length} invitation ${
            successfulInvites.length === 1 ? 'email' : 'emails'
          } sent!`,
        );
      }
      openAlertModalForFailedInvites(this.inviteErrors);
    }

    if (successfulInvites.length > 0) {
      modal.isCoachInviteModalOpen = false;
    }
    this.invitees = this.invitees.filter(e => !successfulInvites.includes(e));
    this.isHandlingSubmit = false;
  };

  doesEmailExistsInInvitees = (email?: string): boolean => {
    if (!email) return false;

    return !!this.invitees.find(
      i =>
        (i.type === 'user' && i.userData.email === email) ||
        (i.type === 'email' && i.email === email),
    );
  };

  doesUsernameExistsInInvitees = (username: string): boolean => {
    return !!this.invitees.find(i => i.type === 'user' && i.userData.username === username);
  };
}
