import { CoachRole } from 'hevy-shared';
import React from 'react';
import { StyledRadioButton } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { ProfileImage } from 'components/ProfileImage';
import { capitalize } from 'lodash';
import styled from 'styled-components';

const Container = styled(FlexRow)`
  gap: ${Spacing.md}px;
  cursor: pointer;
`;

export const CoachSelectionRow = ({
  isSelected,
  onSelected,
  coachName,
  profilePic,
  numberOfClients,
  role,
}: {
  isSelected: boolean;
  onSelected: () => void;
  coachName: string;
  profilePic?: string;
  numberOfClients: number;
  role: CoachRole;
}) => {
  return (
    <Container onClick={onSelected}>
      <StyledRadioButton checked={isSelected} />
      <FlexRow style={{ gap: Spacing.sm }}>
        <ProfileImage source={profilePic} diameter={40} />
        <View>
          <TextMD style={{ textAlign: 'left' }}>{coachName}</TextMD>
          <TextSMRegular style={{ textAlign: 'left' }}>
            {capitalize(role)}, {numberOfClients === 1 ? '1 client' : `${numberOfClients} clients`}
          </TextSMRegular>
        </View>
      </FlexRow>
    </Container>
  );
};
