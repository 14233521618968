import { localStorageStores } from 'state/localStorageStores';
import { setAmplitudeUserId } from './amplitude';
import { memoryStores } from 'state/memoryStores';
import { fireAndForget } from './async';
import { log } from './log';

const REQUIRED_DATA_FAILED_FETCH_RETRY_INTERVAL = 5000;
let isFetchingUserContent = false;

// Retries and blocks until we successfully fetch required content
export const refreshUserContent = async () => {
  if (isFetchingUserContent) {
    return;
  }
  isFetchingUserContent = true;
  // Continue to refresh data until we have everything we need
  while (true) {
    try {
      await fetchDataRequiredForFrontEnd();
      setAmplitudeUserId(localStorageStores.account.id);
      break;
    } catch (error) {
      // Wait...
      await new Promise(resolve => setTimeout(resolve, REQUIRED_DATA_FAILED_FETCH_RETRY_INTERVAL));
      if (!localStorageStores.auth.isAuthenticated) {
        return;
      }
    }
  }

  log('🥾 fetching programs, exercise templates, invites, and routines');

  // Start refreshing things that are nice to have
  fireAndForget([
    localStorageStores.programFolders.fetch(),
    localStorageStores.programs.fetch(),
    localStorageStores.exerciseTemplates.fetch(),
    localStorageStores.invites.fetch(),
    localStorageStores.teamInvites.fetch(),
    memoryStores.libraryRoutines.fetch(),
    localStorageStores.chats.fetchConversations(),
    localStorageStores.clientLeads.fetch(),
  ]);
  isFetchingUserContent = false;
};

const fetchDataRequiredForFrontEnd = async () => {
  log('🥾 fetching required data: account, plan, user preferences, and clients');
  await Promise.all([
    localStorageStores.account.fetch(),
    localStorageStores.team.fetch(),
    localStorageStores.plan.fetch(),
    localStorageStores.userPreferences.fetch(),
    localStorageStores.exerciseTemplateCustomizations.fetch(),
    localStorageStores.coachConfig.fetch(),

    // So much of the app depends on clients that we fetch them into memory during bootstrap
    memoryStores.clients.fetch(),
  ]);
};
