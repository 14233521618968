import React from 'react';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import Phone from 'components/assets/phone.svg';
import styled from 'styled-components';
import { TextSMRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';

export const ConnectWithClients = () => {
  return (
    <Container>
      <IconContainer>
        <Phone />
      </IconContainer>
      <TextContainer>
        <CustomText>Connect with clients anytime</CustomText>
        <CustomText style={{ color: colors.primary500 }}>Get the Hevy Coach app</CustomText>
      </TextContainer>
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row;
  outline: 1px solid;
  outline-color: ${colors.neutral150};
  align-items: center;
  cursor: pointer;
  height: 69px;
`;

const IconContainer = styled(View)`
  justify-content: center;
  padding-left: ${Spacing.md}px;
`;

const TextContainer = styled(View)`
  flex-direction: column;
  padding: 0px ${Spacing.sm}px;
`;

const CustomText = styled(TextSMRegular)`
  color: ${colors.neutral900};
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
