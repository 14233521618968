import { modal } from 'components/Modals/ModalManager';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { ProgressBar } from 'styleguide/ProgressBar';
import { TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { sendEvent } from 'utils/analyticsEvents';

const Container = styled(View)`
  justify-content: center;
  padding: ${Spacing.md}px;
  border-top: 1px solid ${colors.sideMenuLinesAndBorders};
  margin-bottom: ${Spacing.md}px;
`;

interface FreeTrialWidgetProps {
  daysLeft: number;
}

export const FreeTrialWidget = ({ daysLeft }: FreeTrialWidgetProps) => {
  return (
    <Container>
      {daysLeft >= 0 ? (
        <>
          <TextSMMedium style={{ color: colors.white, marginBottom: Spacing.sm }}>
            {daysLeft} days left on your trial, upgrade to keep full access.
          </TextSMMedium>
          <ProgressBar
            progressFraction={(30 - daysLeft) / 30}
            backgroundColor={colors.neutral600}
          ></ProgressBar>
        </>
      ) : (
        <TextSMMedium style={{ color: colors.white }}>Upgrade to get more clients ⚡</TextSMMedium>
      )}

      <PrimaryButton
        style={{ marginTop: Spacing.md }}
        title={'Upgrade Now'}
        onClick={() => {
          sendEvent('freeTrialCard_UpgradeClick');
          modal.isMobileNavBarModalOpen = false;
          modal.openUpgradePlanModal({ title: 'Upgrade Your Plan', source: 'freeTrialCard' });
        }}
      ></PrimaryButton>
    </Container>
  );
};
