import React from 'react';
import { ExerciseTemplate, Routine, RoutineExercise } from 'hevy-shared';
import { localStorageStores } from 'state/localStorageStores';
import { log } from 'utils/log';
import toast from 'react-hot-toast';
import { TextSMMedium } from 'styleguide/Texts';
import { Link } from 'components/Link';

export const MY_ROUTINES_FOLDER_ID = -1;

export const ROUTINES_WITHOUT_FOLDERS_FOLDER_ID = null;

export const MyRoutinesFolder = {
  id: MY_ROUTINES_FOLDER_ID,
  title: 'My Routines',
  created_at: '',
  updated_at: '',
  index: Number.MAX_SAFE_INTEGER, // This is a high number so the folder will always be at the bottom of the list
};

export const showProgramImportedToast = (
  programId: string,
  programTitle: string,
  onClose: () => void,
) => {
  toast.success(
    () => {
      return (
        <TextSMMedium>
          Successfully imported folder{' '}
          <Link
            onClick={() => {
              onClose();
              toast.dismiss();
            }}
            href={`/programs/${programId}`}
          >
            {programTitle}
          </Link>{' '}
          as program to your library
        </TextSMMedium>
      );
    },
    { duration: 5000 },
  );
};

const findMatchingHevyCoachCustomExerciseTemplate = (
  customHevyCoachExercises: ExerciseTemplate[],
  routineExercise: RoutineExercise,
): ExerciseTemplate | undefined => {
  return customHevyCoachExercises.find(
    e =>
      e.title === routineExercise.title &&
      e.exercise_type === routineExercise.exercise_type &&
      routineExercise.username !== 'hevy',
  );
};

export const createNewHevyCoachCustomExercisesForRoutines = async (
  routines: Routine[],
  customHevyCoachExercises: ExerciseTemplate[],
  coachId: string,
) => {
  for (const routine of routines) {
    for (const exercise of routine.exercises.filter(e => e.username !== 'hevy')) {
      const matchingCustomExerciseTemplate = findMatchingHevyCoachCustomExerciseTemplate(
        customHevyCoachExercises,
        exercise,
      );

      // If there's a matching custom exercise on HevyCoach, we don't need to create a new one
      if (!matchingCustomExerciseTemplate) {
        log('🏋🏻‍♂️ Creating new custom exercise since there is NO match in library', exercise.title);
        await localStorageStores.exerciseTemplates.createCustomExercise({
          title: exercise.title,
          exercise_type: exercise.exercise_type,
          muscle_group: exercise.muscle_group,
          other_muscles: exercise.other_muscles,
          equipment_category: exercise.equipment_category,
          thumbnail_url: exercise.thumbnail_url ?? undefined,
          custom_exercise_image_url: exercise.custom_exercise_image_url ?? undefined,
          coach_id: coachId,
        });
      }
    }
  }
};

export const replaceCustomExercisesWithHevyCoachCustomExercises = (
  routines: Routine[],
  customHevyCoachExercises: ExerciseTemplate[],
): Routine[] => {
  return routines.map(routine => {
    return {
      ...routine,
      exercises: routine.exercises.map(exercise => {
        const matchingCustomExerciseTemplate = findMatchingHevyCoachCustomExerciseTemplate(
          customHevyCoachExercises,
          exercise,
        );

        if (!!matchingCustomExerciseTemplate) {
          log(
            '♻️ Replacing custom exercise with matching custom exercise from HevyCoach',
            exercise.title,
            matchingCustomExerciseTemplate.id,
            exercise.exercise_template_id,
          );
          return {
            ...exercise,
            exercise_template_id: matchingCustomExerciseTemplate.id,
            title: matchingCustomExerciseTemplate.title,
            es_title: matchingCustomExerciseTemplate.es_title,
            de_title: matchingCustomExerciseTemplate.de_title,
            fr_title: matchingCustomExerciseTemplate.fr_title,
            it_title: matchingCustomExerciseTemplate.it_title,
            pt_title: matchingCustomExerciseTemplate.pt_title,
            ko_title: matchingCustomExerciseTemplate.ko_title,
            ja_title: matchingCustomExerciseTemplate.ja_title,
            tr_title: matchingCustomExerciseTemplate.tr_title,
            ru_title: matchingCustomExerciseTemplate.ru_title,
            zh_cn_title: matchingCustomExerciseTemplate.zh_cn_title,
            zh_tw_title: matchingCustomExerciseTemplate.zh_tw_title,
            exercise_type: matchingCustomExerciseTemplate.exercise_type,
            muscle_group: matchingCustomExerciseTemplate.muscle_group,
            other_muscles: matchingCustomExerciseTemplate.other_muscles,
            equipment_category: matchingCustomExerciseTemplate.equipment_category,
            thumbnail_url: matchingCustomExerciseTemplate.thumbnail_url,
            custom_exercise_image_url: matchingCustomExerciseTemplate.custom_exercise_image_url,
            url: matchingCustomExerciseTemplate.url,
            media_type: matchingCustomExerciseTemplate.media_type,
          };
        }

        return exercise;
      }),
    };
  });
};
