import React from 'react';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium } from 'styleguide/Texts';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { PictureViewOption } from '../ProgressPictureModalViewModel';
import { colors } from 'styleguide/colors';
import styled from 'styled-components';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';

const OptionContainer = styled(FlexRow)<{ selected: boolean }>`
  gap: ${Spacing.sm}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  border-radius: ${CornerRadius.sm}px;
  background-color: ${props => (props.selected ? colors.primary50 : 'transparent')};
  border-color: ${props => (props.selected ? colors.primary200 : colors.neutral200)};
  border-style: solid;
  border-width: 1px;
`;

const LeftOptionContainer = styled(OptionContainer)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: ${props => (props.selected ? 1 : 0)}px;
`;

const RightOptionContainer = styled(OptionContainer)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: ${props => (props.selected ? 1 : 0)}px;
`;

export const PictureViewModeSelector = ({
  selectedOption,
  onSelectOption,
}: {
  selectedOption: PictureViewOption;
  onSelectOption: (selectedOption: PictureViewOption) => void;
}) => {
  return (
    <FlexRow style={{ cursor: 'pointer' }}>
      <LeftOptionContainer
        onClick={() => onSelectOption('single')}
        selected={selectedOption === 'single'}
      >
        <CustomizableIcon
          type="picture-frame"
          tint={selectedOption === 'single' ? colors.primary500 : colors.neutral500}
        />
        <TextSMMedium
          style={{
            color: selectedOption === 'single' ? colors.primary500 : colors.neutral500,
            maxLines: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          Single Picture
        </TextSMMedium>
      </LeftOptionContainer>
      <RightOptionContainer
        onClick={() => onSelectOption('comparison')}
        selected={selectedOption === 'comparison'}
      >
        <CustomizableIcon
          type="compare"
          tint={selectedOption === 'comparison' ? colors.primary500 : colors.neutral500}
        />
        <TextSMMedium
          style={{ color: selectedOption === 'comparison' ? colors.primary500 : colors.neutral500 }}
        >
          Comparison
        </TextSMMedium>
      </RightOptionContainer>
    </FlexRow>
  );
};
