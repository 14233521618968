import {
  calculateCommentCount,
  calculateLikeCount,
  calculateWorkoutStats,
} from 'components/WorkoutCell/utils';
import dayjs from 'dayjs';
import { indexSetObjectsForUI, setObjectToUIFacingSetIndicator } from 'hevy-shared';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import { ExerciseProps } from './components/types';
import { calculateSecondColumnHeader, calculateSetValue } from './utils';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import toast from 'react-hot-toast';
import { sendEvent } from 'utils/analyticsEvents';
import { copyToClipboard } from 'utils/clipboard';
import { makeAutoObservable } from 'mobx';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';
import { captureException } from '@sentry/nextjs';

export class WorkoutDetailViewModel {
  workoutId: string;

  constructor(workoutId: string) {
    makeAutoObservable(this);
    this.workoutId = workoutId;
  }

  get workout() {
    return memoryStores.workouts.workouts[this.workoutId];
  }

  get metaData() {
    const w = this.workout;

    if (!w) {
      captureException(new Error(`Workout with id ${this.workoutId} not found on the store`));
      return null;
    }

    return {
      username: w.username,
      user_id: w.user_id,
      date: getRelativeDateWithTime(dayjs.unix(w.end_time)),
      profileImage: w.profile_image,
      title: w.name,
      description: w.description,
      isLiked: w.is_liked_by_user,
      likeCount: calculateLikeCount(w),
      commentCount: calculateCommentCount(w),
      likeImages: w.like_images || [],
      stats: calculateWorkoutStats(w, CoachUnitsAggregator.preferences),
    };
  }

  get exercises(): ExerciseProps[] {
    return this.workout.exercises.map(e => {
      const indexedSets = indexSetObjectsForUI(e.sets);

      return {
        key: e.id,
        image: e.thumbnail_url ?? undefined,
        title: e.title,
        supersetId: e.superset_id ?? undefined,
        notes: e.notes,
        secondColumnHeader: calculateSecondColumnHeader(e),
        sets: e.sets.map((s, setIndex) => ({
          key: `${s.id}`,
          indicator: setObjectToUIFacingSetIndicator(indexedSets[setIndex]),
          value: calculateSetValue(e, s, CoachUnitsAggregator.preferences),
          rpe: s.rpe,
          prs: s.prs,
        })),
        exerciseDetailURL: `${location.origin}/clients/${this.workout.user_id}/exercise-statistics?exerciseId=${e.exercise_template_id}`,
      } as ExerciseProps;
    });
  }

  onLikeWorkout = async () => {
    const newLikeState = !this.workout.is_liked_by_user;
    this.workout.is_liked_by_user = newLikeState;

    try {
      if (newLikeState === true) {
        await API.likeWorkout(this.workout.id);
        this.workout.like_count += 1;
      } else {
        await API.unlikeWorkout(this.workout.id);
        this.workout.like_count -= 1;
      }
      this.fetchWorkout();
    } catch {
      this.workout.is_liked_by_user = !newLikeState;
      toast.error('Something went wrong liking this workout. Please try again.');
    }
  };

  onShareWorkout = async () => {
    sendEvent('workoutCell_sharePress');
    copyToClipboard(`https://hevy.com/workout/${this.workout.id}`);
    toast.success('Copied link to clipboard');
  };

  fetchWorkout = async () => {
    await memoryStores.workouts.fetchWorkout(this.workout.id);
  };
}
