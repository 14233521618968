import React, { useState } from 'react';
import { colors } from 'styleguide/colors';
import { CornerRadius } from 'styleguide/spacing';
import { ChatMessageContainer } from './ChatMessageContainer';
import { View } from 'styleguide/View';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { modal } from 'components/Modals/ModalManager';
import Image from 'next/image';

const ViewFiller = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ImageChatMessage = ({
  imageUrl,
  isSender,
  source,
  timestamp,
  clientProfilePic,
  isLoading,
  isLastMessageOfMessageBlock,
  onDeleteMessageClick,
}: {
  imageUrl: string;
  isSender: boolean;
  source: 'widget' | 'screen';
  timestamp: string;
  clientProfilePic?: string;
  isLoading: boolean;
  isLastMessageOfMessageBlock: boolean;
  onDeleteMessageClick?: () => void;
}) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const renderImageModalContent = () => {
    return (
      <Image
        onLoadingComplete={() => setIsLoadingImage(false)}
        onLoadStart={() => setIsLoadingImage(true)}
        src={imageUrl}
        alt={''}
        width={0}
        height={0}
        sizes="100vw"
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'contain',
        }}
      />
    );
  };
  return (
    <ChatMessageContainer
      isSender={isSender}
      messageTimestamp={timestamp}
      clientProfilePic={clientProfilePic}
      onDeleteMessageClick={onDeleteMessageClick}
      source={source}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
    >
      <View style={{ position: 'relative' }}>
        {(isLoading || isLoadingImage) && (
          <>
            <ViewFiller style={{ backgroundColor: colors.white, opacity: 0.5 }} />
            <ViewFiller>
              <ClipLoader size={20} color={colors.neutral900} loading={true} />
            </ViewFiller>
          </>
        )}

        <Image
          onClick={() => modal.openGenericModal(renderImageModalContent)}
          onLoadStart={() => setIsLoadingImage(true)}
          onLoadingComplete={() => setIsLoadingImage(false)}
          key={`${imageUrl}-${timestamp}`}
          src={imageUrl}
          alt={''}
          width={0}
          height={0}
          sizes="100vw"
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: 400,
            minHeight: 50,
            minWidth: 50,
            borderRadius: CornerRadius.xl,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.neutral150,
            objectFit: 'fill',
          }}
        />
      </View>
    </ChatMessageContainer>
  );
};
