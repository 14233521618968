import { CoachAccount } from 'hevy-shared';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { Client } from 'types/client';
import { Toggle } from 'components/Toggle';
import { EmptyState } from 'components/EmptyState';
import { TransferClientsToCoachViewModel } from './TransferClientsToCoachViewModel';
import { observer } from 'mobx-react-lite';
import { ModalSearchHeader } from '../Components/ModalSearchHeader';
import { HideOnMobileDiv, ShowOnDesktopDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { CardNoPadding } from 'styleguide/Card';
import { ClientSelectionRow } from './components/ClientSelectionRow';

const Container = styled(View)`
  width: 600px;
  max-height: 80vh;
  border-radius: ${CornerRadius.md}px;
`;

const MiddleContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  overflow-y: auto;
  height: 40vh;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

const EmptyStateContainer = styled(View)`
  flex: 1;
  justify-content: center;
`;

export interface TransferClientsToCoachModalProps {
  clients: Client[];
  closeModal: () => void;
  isOpen: boolean;
  onCoachSelected: (coachId: string, keepProgram: boolean) => void;
  coachOptions: CoachAccount[];
}

export const TransferClientsToCoachModal = observer(() => {
  const vm = TransferClientsToCoachViewModel;
  const { targetCoach, clientOptions } = vm;

  if (!targetCoach || !clientOptions) return <></>;

  return (
    <Modal
      isOpen={vm.isOpen}
      onClose={vm.onClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Container>
        <ModalSearchHeader
          text={`Select the clients you want to transfer to ${
            targetCoach.full_name ?? targetCoach.username
          }`}
          onClose={vm.onClose}
          searchPlaceholder={'Search clients'}
          searchValue={vm.searchValue}
          onSearchTextUpdated={vm.onSearchTextUpdated}
        />
        <DividerLine />
        {vm.anySelectedClientHasAssignedProgram && (
          <CardNoPadding style={{ margin: `${Spacing.md}px ${Spacing.lg}px` }}>
            <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
              <View>
                <TextSMMedium>Keep current workout program?</TextSMMedium>
                <TextSMRegular>
                  Continue with the client's current program, routines, and schedule.
                </TextSMRegular>
              </View>
              <Toggle isOn={vm.keepAssignedProgram} onClick={vm.onChangeKeepAssignedProgram} />
            </FlexRow>
          </CardNoPadding>
        )}

        {vm.filteredClientOptions.length !== 0 && (
          <ShowOnDesktopDiv>
            <FlexRow style={{ margin: `${Spacing.sm}px ${Spacing.lg}px` }}>
              <FlexRow style={{ flex: 1.5 }}>
                <TextSMMedium>Client</TextSMMedium>
              </FlexRow>

              <FlexRow style={{ flex: 1 }}>
                <TextSMMedium>Current Coach</TextSMMedium>
              </FlexRow>
            </FlexRow>
          </ShowOnDesktopDiv>
        )}

        <HideOnMobileDiv>
          <DividerLine />
        </HideOnMobileDiv>

        <MiddleContainer>
          {vm.filteredClientOptions.length === 0 ? (
            <EmptyStateContainer>
              <EmptyState title={'No clients found'} iconType={'search'} />
            </EmptyStateContainer>
          ) : (
            <>
              {vm.filteredClientOptions.map(clientOption => {
                return (
                  <ClientSelectionRow
                    key={clientOption.id}
                    isSelected={vm.isClientSelected(clientOption)}
                    onSelected={() => vm.onSelectedClient(clientOption)}
                    coachName={clientOption.coach.full_name ?? clientOption.coach.username}
                    coachImageUrl={clientOption.coach.profile_pic}
                    clientName={clientOption.fullName ?? clientOption.username}
                    clientEmail={clientOption.email}
                    clientImageUrl={clientOption.profilePic}
                  />
                );
              })}
            </>
          )}
        </MiddleContainer>

        <DividerLine />
        <LowerContainer>
          <SecondaryButton title="Cancel" onClick={vm.onClose} />
          <PrimaryButton
            title={'Continue'}
            enabled={!!vm.hasSelectedAClient}
            onClick={vm.onContinue}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});
