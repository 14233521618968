import React, { CSSProperties } from 'react';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { observer } from 'mobx-react-lite';
import RSelect, { components, GroupBase, MenuProps, Props } from 'react-select';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import fonts from 'styleguide/fonts';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { zIndexes } from 'styleguide/zIndex';
import { StyledRadioButton } from 'styleguide/Inputs';
import { DividerLine } from 'styleguide/DividerLine';

const Container = styled(View)`
  width: min(460px, 80vw);
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral150};
  border-radius: ${CornerRadius.md}px;
  box-shadow: 0px 4px 16px -2px rgba(16, 24, 40, 0.1);
  @media (max-width: 1320px) {
    left: 0;
  }
  @media (max-width: 1024px) {
    left: unset;
    right: 0;
  }
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    right: -60px;
  }
`;

const OptionContainer = styled(FlexRow)`
  gap: ${Spacing.md}px;
  align-items: center;
  padding: ${Spacing.md}px;
  cursor: pointer;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    gap: ${Spacing.sm}px;
    padding: ${Spacing.sm + Spacing.xs}px;
  }
`;

interface RoleSelectorViewProps {
  role: 'admin' | 'member';
}

export const RoleSelectorView = (props: MenuProps<RoleOption, false> & RoleSelectorViewProps) => {
  const selectedRole = props.role;

  return (
    <components.Menu {...props}>
      <Container>
        <OptionContainer
          onClick={() =>
            props.setValue(
              selectedRole === 'member'
                ? null
                : {
                    label: 'Member',
                    value: 'member',
                  },
              'select-option',
            )
          }
        >
          <StyledRadioButton checked={selectedRole === 'member'}></StyledRadioButton>
          <View>
            <TextSMMedium>Member</TextSMMedium>
            <TextSMRegular>
              Members can invite and coach their clients but they cannot see other clients inside
              the Team.
            </TextSMRegular>
          </View>
        </OptionContainer>
        <DividerLine />
        <OptionContainer
          onClick={() =>
            props.setValue(
              selectedRole === 'admin'
                ? null
                : {
                    label: 'Admin',
                    value: 'admin',
                  },
              'select-option',
            )
          }
        >
          <StyledRadioButton checked={selectedRole === 'admin'}></StyledRadioButton>
          <View>
            <TextSMMedium>Admin</TextSMMedium>
            <TextSMRegular>
              Admins can invite and coach their clients, view all clients within the Team, reassign
              clients, and edit all programs.
            </TextSMRegular>
          </View>
        </OptionContainer>
      </Container>
    </components.Menu>
  );
};

interface RoleOption {
  label: 'Admin' | 'Member';
  value: 'admin' | 'member';
}

type RoleSelectorProps = Props<RoleOption, false, GroupBase<RoleOption>>;

interface RoleSelectororProps extends RoleSelectorProps {
  role: 'admin' | 'member';
}

export const RoleSelector = observer((props: RoleSelectororProps) => {
  const { role } = props;
  return (
    <RSelect
      options={roleOptions}
      value={roleOptions.find(r => r.value === role)}
      isSearchable={false}
      menuPortalTarget={document.body}
      closeMenuOnSelect={false}
      styles={{
        control: styles => ({
          ...styles,
          ...controlStyle,
        }),

        singleValue: styles => ({
          ...styles,
          ...{ color: colors.neutral900 },
        }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: styles => ({
          ...styles,
          ...{ position: 'absolute', right: 0 },
        }),
        menuPortal: base => ({
          ...base,
          zIndex: zIndexes.modalOverlay,
        }),
      }}
      components={{
        Menu: props => <RoleSelectorView {...props} role={role} />,

        DropdownIndicator: props => {
          return (
            <components.DropdownIndicator {...props}>
              <CustomizableIcon type="chevron-down" tint={colors.neutral900}></CustomizableIcon>
            </components.DropdownIndicator>
          );
        },
      }}
      {...props}
    />
  );
});

const controlStyle: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  height: '40px',
  minHeight: 'auto',
  borderColor: colors.neutral150,
  backgroundColor: colors.white,
  fontFamily: fonts.regular.family,
  fontWeight: fonts.regular.weight,
  fontSize: '14px',
  cursor: 'pointer',
  position: 'relative',
};

const roleOptions: RoleOption[] = [
  { label: 'Admin', value: 'admin' },
  { label: 'Member', value: 'member' },
];
