import React, { useEffect, useState } from 'react';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextMD, TextSMMedium, TextXSMedium, TextXSRegular } from 'styleguide/Texts';
import { FlexRow } from 'styleguide/Row';
import {
  Workout,
  WorkoutExercise,
  WorkoutExerciseSet,
  indexSetObjectsForUI,
  setObjectToUIFacingSetIndicator,
} from 'hevy-shared';
import { View } from 'styleguide/View';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
  calculateSecondColumnHeader,
  calculateSetValue,
} from 'components/Modals/WorkoutDetail/utils';
import { StaticSetIndicator } from 'components/StaticSetIndicator';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { prTitleForPRType } from 'utils/pureUtils';
import { colors } from 'styleguide/colors';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import useIsOnScreen from 'utils/useIsOnScreen';
import { ClipLoader } from 'react-spinners';
import { FlexColumn } from 'styleguide/Column';
import { EmptyState } from 'components/EmptyState';
import { ExerciseSet } from 'types/exerciseSet';
import { memoryStores } from 'state/memoryStores';
import { modal } from 'components/Modals/ModalManager';
import { SmallHevyActivityIndicator } from 'components/HevyActivityIndicator';

export interface PagedExerciseHistoryWorkoutsViewProps {
  workouts: Workout[];
  onScrollToBottom: () => void;
  isFetchingMoreWorkouts: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  margin-top: ${Spacing.sm}px;
  margin-bottom: ${Spacing.sm}px;
`;

const Column1 = styled.div`
  display: flex;
  flex: 0.2;
  justify-content: left;
`;

const Column2 = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SetContainer = styled.div`
  display: flex;
  padding: ${Spacing.xs}px 0;
  padding-left: ${Spacing.xs}px;
`;

const getSimpleSetBackgroundColor = (index: number): string => {
  const oddSetColor = colors.neutral100;

  return index % 2 === 0 ? oddSetColor : colors.transparent;
};

const SetRow = ({ set, index }: { set: ExerciseSet; index: number }) => {
  return (
    <SetContainer
      key={set.key}
      style={{ backgroundColor: getSimpleSetBackgroundColor(index), borderRadius: CornerRadius.sm }}
    >
      <Column1>
        <StaticSetIndicator indicator={set.indicator} />
      </Column1>
      <Column2>
        <FlexRow>
          <TextSMMedium>{set.value}</TextSMMedium>
          {set.rpe && <TextXSMedium>&nbsp;@ {set.rpe} rpe</TextXSMedium>}
        </FlexRow>
        <FlexRow style={{ gap: Spacing.md, paddingRight: Spacing.md, flexWrap: 'wrap' }}>
          {set.prs.map((pr, index) => {
            return (
              <FlexRow key={index}>
                <CustomizableIcon
                  type="pr"
                  tint={''}
                  style={{ marginRight: Spacing.xs }}
                ></CustomizableIcon>
                <TextXSRegular style={{ color: colors.neutral500 }}>
                  {prTitleForPRType(pr.type)}
                </TextXSRegular>
              </FlexRow>
            );
          })}
        </FlexRow>
      </Column2>
    </SetContainer>
  );
};

const exerciseSetFromWorkoutExerciseSet = (
  s: WorkoutExerciseSet,
  setIndex: number,
  e: WorkoutExercise,
): ExerciseSet => {
  const indexedSets = indexSetObjectsForUI(e.sets);
  return {
    key: `${s.id}`,
    indicator: setObjectToUIFacingSetIndicator(indexedSets[setIndex]),
    value: calculateSetValue(e, s, CoachUnitsAggregator.preferences),
    rpe: s.rpe,
    prs: s.prs,
  };
};

interface ExerciseHistoryWorkoutsViewProps {
  workouts: Workout[];
}

const WorkoutView = ({ workout }: { workout: Workout }) => {
  const [isLoadingFullWorkout, setIsLoadingFullWorkout] = useState(false);
  return (
    <View>
      <FlexRow style={{ gap: Spacing.md }}>
        <TextMD
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            setIsLoadingFullWorkout(true);
            try {
              await memoryStores.workouts.getClientWorkout(workout.id);
              modal.openWorkoutDetailModal(workout.id);
            } catch {
            } finally {
              setIsLoadingFullWorkout(false);
            }
          }}
        >
          {workout.name}
        </TextMD>
        {isLoadingFullWorkout && <ClipLoader color={colors.neutral400} size={14} />}
      </FlexRow>
      <TextXSRegular>{dayjs(workout.created_at).fromNow()}</TextXSRegular>
      {workout.exercises.map((exercise, index) => {
        return (
          <View key={index}>
            <HeaderContainer>
              <Column1>
                <TextXSMedium>SET</TextXSMedium>
              </Column1>
              <Column2>
                <TextXSMedium>{calculateSecondColumnHeader(exercise)}</TextXSMedium>
              </Column2>
            </HeaderContainer>
            {exercise.sets
              .map((s, index) => exerciseSetFromWorkoutExerciseSet(s, index, exercise))
              .map((s, index) => (
                <SetRow index={index} key={index} set={s} />
              ))}
          </View>
        );
      })}
    </View>
  );
};
export const ExerciseHistoryWorkoutsView = ({ workouts }: ExerciseHistoryWorkoutsViewProps) => {
  return (
    <FlexColumn style={{ gap: Spacing.lg }}>
      {workouts.map(workout => {
        return <WorkoutView key={workout.id} workout={workout} />;
      })}
    </FlexColumn>
  );
};

export const PagedExerciseHistoryWorkoutsView = ({
  workouts,
  onScrollToBottom,
  isFetchingMoreWorkouts,
}: PagedExerciseHistoryWorkoutsViewProps) => {
  const bottomOfWorkoutsRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useIsOnScreen(bottomOfWorkoutsRef);
  useEffect(() => {
    if (isOnScreen) {
      onScrollToBottom();
    }
  }, [isOnScreen]);
  if (workouts.length === 0 && !isFetchingMoreWorkouts) {
    return (
      <FlexRow style={{ flex: 1, justifyContent: 'center' }}>
        <EmptyState
          title="No workouts yet"
          subtitle="Your client hasn't logged this exercise yet"
          iconType="barbell"
        />
      </FlexRow>
    );
  }
  return (
    <>
      <ExerciseHistoryWorkoutsView workouts={workouts} />
      <div ref={bottomOfWorkoutsRef} />
      {isFetchingMoreWorkouts && (
        <FlexRow style={{ justifyContent: 'center', padding: Spacing.md }}>
          <SmallHevyActivityIndicator />
        </FlexRow>
      )}
    </>
  );
};
