('use client');
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextMD, TextSMRegular } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { SearchInput } from 'styleguide/Inputs';
import { CloseButton } from 'components/CloseButton';
import { EmptyState } from 'components/EmptyState';
import { FlexRow } from 'styleguide/Row';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { ImportFromHevyViewModel } from './ImportFromHevyViewModel';
import { Card } from 'styleguide/Card';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { sortAscending } from 'utils/pureUtils';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
  margin: ${Spacing.lg}px;
  margin-bottom: 0px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
    margin-bottom: 0px;
  }
`;

const MiddleContainer = styled(View)`
  max-height: 70vh;
  min-height: 300px;
  overflow-y: auto;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px ${Spacing.md}px;
  }
`;

const NoFoldersContainer = styled(View)`
  align-items: center;
  justify-content: center;
  padding: ${Spacing.lg}px;
`;

const SearchInputContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px ${Spacing.md}px;
  }
`;

const FolderTitleRow = styled(FlexRow)`
  gap: ${Spacing.sm}px;
  justify-content: space-between;
  @media (max-width: ${600}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const RoutinesContainer = styled(View)`
  gap: ${Spacing.sm}px;
`;

export interface ImportFromHevyModalProps {
  vm: ImportFromHevyViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const ImportFromHevyModal = observer(({ isOpen, onClose, vm }: ImportFromHevyModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <TextLG>{'Import From Hevy'}</TextLG>
          <CloseButton onClick={onClose} />
        </UpperContainer>
        <SearchInputContainer>
          <SearchInput
            style={{ backgroundColor: colors.neutral100 }}
            value={vm.searchText}
            unfocusedBorderColor="transparent"
            onChange={vm.onSearchTextChange}
            placeholder={'Search your Hevy folders'}
          />
        </SearchInputContainer>
        <DividerLine />
        <MiddleContainer>
          {vm.isFetching ? (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <TextMD style={{ paddingBottom: Spacing.md }}>
                Fetching your folders and routines...
              </TextMD>
              <MediumHevyActivityIndicator />
            </View>
          ) : vm.filteredFolders?.length === 0 ? (
            <NoFoldersContainer>
              <EmptyState
                title={'No Folders Found'}
                subtitle={
                  vm.isSearching
                    ? 'No folder matched your search'
                    : `You don't have any routines yet`
                }
                iconType="folder"
              ></EmptyState>
            </NoFoldersContainer>
          ) : (
            <View style={{ marginBottom: Spacing.sm, gap: Spacing.md }}>
              {vm.filteredFolders.map(folder => (
                <View style={{ gap: Spacing.md }} key={folder.id}>
                  <FolderTitleRow>
                    <FlexRow style={{ gap: Spacing.sm }}>
                      <CustomizableIcon
                        type="folder-solid"
                        tint={colors.neutral400}
                        style={{ flexShrink: 0 }}
                      />
                      <TextMD>{folder.title}</TextMD>
                    </FlexRow>
                    <PrimaryButton
                      iconType={vm.hasFolderBeenImported(folder) ? 'check' : 'import'}
                      title={`${
                        vm.hasFolderBeenImported(folder) ? 'Imported' : 'Import'
                      } Folder as Program`}
                      onClick={() => vm.onImportFolderClick(folder)}
                      enabled={
                        !vm.hasFolderBeenImported(folder) && vm.importingFolderId !== folder.id
                      }
                      loading={vm.importingFolderId === folder.id}
                      textStyle={{ whiteSpace: 'nowrap' }}
                    />
                  </FolderTitleRow>
                  <RoutinesContainer>
                    {vm
                      .routinesForFolder(folder.id)
                      .sort((a, b) => sortAscending(a.index ?? 0, b.index ?? 0))
                      .map(routine => (
                        <Card style={{ gap: Spacing.sm, marginBottom: 0 }} key={folder.id}>
                          <TextMD>{routine.title}</TextMD>
                          <TextSMRegular>
                            {routine.exercises
                              .map(exercise => {
                                return `${exercise.sets.length}x ${exercise.title}`;
                              })
                              .join(', ')}
                          </TextSMRegular>
                        </Card>
                      ))}
                  </RoutinesContainer>
                </View>
              ))}
            </View>
          )}
        </MiddleContainer>
      </Container>
    </Modal>
  );
});
