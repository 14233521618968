import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius } from 'styleguide/spacing';

const Container = styled(View)<{ isHovering: boolean }>`
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: ${CornerRadius.sm}px;
  background-color: ${props => (props.isHovering ? colors.neutral100 : undefined)};
  cursor: pointer;
`;

export const NewChatButton = ({ onClick }: { onClick: () => void }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Container
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      isHovering={isHovering}
    >
      <CustomizableIcon type="new-chat" tint={colors.neutral900}></CustomizableIcon>
    </Container>
  );
};
