import React, { CSSProperties, useState } from 'react';
import { MenuItem } from '@szhsin/react-menu';
import { CustomizableIcon, CustomizableIconType } from 'styleguide/CustomizableIcon';
import { colors } from 'styleguide/colors';
import { TextSMRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { Icon, IconType } from 'styleguide/Icon';

interface HevyMenuItemProps {
  title: string;
  customizableIconType?: CustomizableIconType;
  iconType?: IconType;
  onClick: () => void;
  tint?: string;
  iconStyle?: CSSProperties;
  textStyle?: CSSProperties;
}

export const HevyMenuItem = (props: HevyMenuItemProps) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <MenuItem
      onClick={props.onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: isHovering ? colors.neutral100 : undefined,
        height: '40px',
      }}
    >
      {props.customizableIconType && (
        <CustomizableIcon
          style={{ marginLeft: -Spacing.sm, ...props.iconStyle }}
          type={props.customizableIconType}
          tint={props.tint || colors.neutral900}
        />
      )}
      {props.iconType && (
        <Icon style={{ marginLeft: -Spacing.sm, ...props.iconStyle }} type={props.iconType}></Icon>
      )}
      <TextSMRegular style={{ marginLeft: Spacing.sm, color: colors.black, ...props.textStyle }}>
        {props.title}
      </TextSMRegular>
    </MenuItem>
  );
};
