import React from 'react';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { observer } from 'mobx-react-lite';
import { ProfileImage } from 'components/ProfileImage';
import { CoachAccount } from 'hevy-shared';
import { ControlledMenu, MenuProps, useMenuState } from '@szhsin/react-menu';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { colors } from 'styleguide/colors';
import { CardNoPadding } from 'styleguide/Card';
import { zIndexes } from 'styleguide/zIndex';
import { SearchInput } from 'styleguide/Inputs';
import { coachMatchesSearch } from 'utils/pureUtils';

const SelectedCoachContainer = styled(CardNoPadding)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.md}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  margin-bottom: 0;
  cursor: pointer;
`;

const SearchInputContainer = styled(FlexRow)`
  padding: ${Spacing.sm}px ${Spacing.md}px;
`;

const OptionContainer = styled(FlexRow)`
  gap: ${Spacing.md}px;
  align-items: center;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${colors.neutral100};
  }
`;

type CoachSelectorProps = Omit<MenuProps, 'menuButton'> & {
  coachOptions: CoachAccount[];
  selectedCoach: CoachAccount;
  onSelectCoach: (coach: CoachAccount) => void;
};

export const CoachSelector = observer(
  ({ selectedCoach, onSelectCoach, ...props }: CoachSelectorProps) => {
    const selectorRef = React.useRef(null);
    const [menuState, toggleMenu] = useMenuState({ transition: true });
    const [searchText, setSearchText] = React.useState('');

    const filteredCoaches = props.coachOptions.filter(coach =>
      coachMatchesSearch(coach, searchText),
    );
    const menuRef = React.useRef(null);
    return (
      <View>
        <SelectedCoachContainer
          ref={selectorRef}
          onClick={() => {
            toggleMenu(menuState.state === 'open' ? false : true);
          }}
        >
          <FlexRow style={{ gap: Spacing.sm, overflow: 'hidden' }}>
            <ProfileImage source={selectedCoach.profile_pic} diameter={20} />

            <TextSMMedium
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {selectedCoach.full_name || selectedCoach.username}
            </TextSMMedium>
          </FlexRow>

          <CustomizableIcon
            type="chevron-down"
            tint={colors.neutral900}
            style={{ flexShrink: 0 }}
          />
        </SelectedCoachContainer>
        <View
          ref={(ref: any) => {
            menuRef.current = ref;
          }}
        />

        <ControlledMenu
          {...props}
          anchorRef={menuRef}
          arrow={false}
          align="end"
          onItemClick={e => {
            e.syntheticEvent.stopPropagation();
            e.syntheticEvent.preventDefault();
          }}
          portal={true}
          menuStyle={{
            width: 300,
            border: `1px solid ${colors.neutral150}`,
            backgroundColor: colors.white,
            borderRadius: CornerRadius.sm,
            boxShadow: '0px 4px 16px -2px rgba(16, 24, 40, 0.1)',
            zIndex: zIndexes.modalOverlay,
          }}
          {...menuState}
        >
          <SearchInputContainer>
            <SearchInput
              placeholder="Search for a coach"
              onChange={setSearchText}
              value={searchText}
              style={{ backgroundColor: colors.neutral100, width: '100%' }}
            />
          </SearchInputContainer>
          <View>
            {filteredCoaches.map(coach => {
              const isSelected = selectedCoach.id === coach.id;
              return (
                <OptionContainer
                  key={coach.id}
                  onClick={() => {
                    onSelectCoach(coach);
                    toggleMenu(false);
                  }}
                >
                  <FlexRow style={{ gap: Spacing.sm }}>
                    <ProfileImage source={coach.profile_pic} diameter={20} />
                    <TextSMMedium style={{ fontWeight: isSelected ? '500' : '400' }}>
                      {coach.full_name || coach.username}
                    </TextSMMedium>
                  </FlexRow>
                  {isSelected && <CustomizableIcon type="check" tint={colors.primary500} />}
                </OptionContainer>
              );
            })}
          </View>
        </ControlledMenu>
      </View>
    );
  },
);
