import React, { CSSProperties, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { colors } from './colors';
import fonts from './fonts';
import { TextSMMedium } from './Texts';
import { CustomizableIcon, CustomizableIconType } from './CustomizableIcon';
import { ClipLoader } from 'react-spinners';
import { CornerRadius, Spacing } from './spacing';
import { View } from './View';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { FlexRow } from './Row';
import { ProfileImage } from 'components/ProfileImage';

//Buttons are for clicking and changing state
//Links are for navigating inside the app with routes

const TextButtonText = styled(TextSMMedium)`
  background: transparent;
  border: none;
  color: ${colors.primary500};
  text-decoration: none;
  outline: none;
  display: inline;
`;

export const ClickableText = ({
  text,
  onClick,
  style,
  enabled = true,
  tabIndex,
}: {
  text: string;
  onClick?: React.MouseEventHandler<HTMLParagraphElement>;
  style?: CSSProperties;
  enabled?: boolean;
  tabIndex?: number;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <TextButtonText
      tabIndex={tabIndex}
      style={{
        color: enabled ? (isHovering ? colors.primary600 : colors.primary500) : colors.neutral900,
        cursor: enabled ? 'pointer' : 'default',
        ...style,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={e => {
        if (enabled && !!onClick) onClick(e);
      }}
    >
      {text}
    </TextButtonText>
  );
};

export const AnchorText = ({
  text,
  URL,
  textStyle,
  target,
  style,
}: {
  text: string;
  URL: string;
  textStyle?: CSSProperties;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  style?: CSSProperties;
}) => {
  return (
    <a target={target} href={URL} style={{ textDecoration: 'none', display: 'inherit', ...style }}>
      <ClickableText style={textStyle} text={text} />
    </a>
  );
};

export const TextButton = ({
  title,
  onClick,
  style,
  iconType,
  tabIndex,
}: {
  title: string;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  iconType?: CustomizableIconType;
  tabIndex?: number;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <FlexRow
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
    >
      {iconType && (
        <View style={{ paddingRight: Spacing.xs }}>
          <CustomizableIcon
            tint={isHovering ? colors.primary600 : colors.primary500}
            type={iconType}
          />
        </View>
      )}
      <TextButtonText
        tabIndex={tabIndex}
        style={{
          color: isHovering ? colors.primary600 : colors.primary500,
          ...style,
        }}
      >
        {title}
      </TextButtonText>
    </FlexRow>
  );
};

const ButtonBase = styled.button`
  height: 38px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${CornerRadius.sm}px;
  padding-right: ${Spacing.md}px;
  padding-left: ${Spacing.md}px;
  min-height: 40px;
`;

const StyledTertiaryButton = styled(ButtonBase)`
  background: transparent;
  background-color: ${(props: { hovering: boolean; disableHovering: boolean }) => {
    return !props.disableHovering && props.hovering ? colors.neutral100 : undefined;
  }};
  border: none;
  color: ${colors.primary500};
  text-decoration: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  cursor: pointer;
  outline: none;
`;

export const TertiaryButton = ({
  iconType,
  title,
  onClick,
  style,
  disableHovering,
  tabIndex,
  imageUrl,
}: ButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <StyledTertiaryButton
      tabIndex={tabIndex}
      disableHovering={!!disableHovering}
      hovering={isHovering}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={style}
      onClick={e => {
        if (!!onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      <>
        {!!iconType && (
          <CustomizableIcon
            type={iconType}
            tint={colors.primary500}
            style={{ marginRight: Spacing.sm }}
          />
        )}
        {!!imageUrl && (
          <ProfileImage source={imageUrl} diameter={24} style={{ marginRight: Spacing.sm }} />
        )}
        <TextSMMedium style={{ color: colors.primary500 }}>{title}</TextSMMedium>
      </>
    </StyledTertiaryButton>
  );
};

interface ButtonProps {
  iconType?: CustomizableIconType;
  title: string;
  type?: 'submit';
  enabled?: boolean;
  loading?: boolean;
  disableHovering?: boolean;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  tabIndex?: number;
  imageUrl?: string;
}

const StyledPrimaryButton = styled(ButtonBase)`
  border: none;
  color: ${colors.white};
  pointer-events: auto;
  background-color: ${(props: {
    enabled: boolean;
    hovering: boolean;
    disableHovering: boolean;
  }) => {
    if (!props.enabled) {
      return colors.neutral200;
    }
    return props.hovering && !props.disableHovering ? colors.primary600 : colors.primary500;
  }};
`;

export const PrimaryButton = ({
  iconType,
  title,
  type,
  enabled = true,
  loading = false,
  onClick,
  style,
  textStyle,
  disableHovering,
  tabIndex,
  imageUrl,
}: ButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  return (
    <StyledPrimaryButton
      tabIndex={tabIndex}
      disableHovering={!!disableHovering}
      hovering={isHovering}
      type={type}
      enabled={enabled}
      style={{
        ...(enabled
          ? {
              cursor: 'pointer',
              pointerEvents: 'auto',
            }
          : {
              cursor: 'default',
              pointerEvents: 'none',
            }),
        ...(loading ? { pointerEvents: 'none', outline: 'none' } : {}),
        ...(isClicking && enabled && { backgroundColor: colors.primary700 }),
        ...style,
      }}
      onClick={e => {
        if (enabled && !!onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onMouseOut={() => setIsClicking(false)}
    >
      {loading ? (
        <View>
          <View
            style={{
              position: 'relative',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader size={20} color={colors.white} loading={true} />
            {/* Below invisible text is so that a loading button takes the same width as not loading */}
            <TextSMMedium style={{ opacity: 0.0, height: 0 }}>{title}</TextSMMedium>
          </View>
        </View>
      ) : (
        <>
          {!!iconType && (
            <CustomizableIcon
              type={iconType}
              tint={colors.white}
              style={{ marginRight: Spacing.sm, flexShrink: 0 }}
            />
          )}
          {!!imageUrl && (
            <ProfileImage source={imageUrl} diameter={24} style={{ marginRight: Spacing.sm }} />
          )}
          <TextSMMedium style={{ color: colors.white, ...textStyle }}>{title}</TextSMMedium>
        </>
      )}
    </StyledPrimaryButton>
  );
};

const StyledSecondaryButton = styled(ButtonBase)`
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.neutral200};
  background-color: ${(props: { hovering: boolean; enabled: boolean }) => {
    if (!props.enabled) {
      return colors.neutral200;
    }
    return props.hovering ? colors.neutral50 : colors.white;
  }};
  border-radius: ${CornerRadius.sm}px;

  outline: none;
`;

export const SecondaryButton = ({
  iconType,
  title,
  enabled = true,
  loading = false,
  onClick,
  style,
  textStyle,
  tabIndex,
  imageUrl,
}: ButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  return (
    <StyledSecondaryButton
      tabIndex={tabIndex}
      enabled={enabled}
      hovering={isHovering}
      style={{
        ...(enabled
          ? {
              cursor: 'pointer',
              pointerEvents: 'auto',
            }
          : {
              cursor: 'default',
              pointerEvents: 'none',
            }),
        ...(loading ? { pointerEvents: 'none' } : {}),
        ...(isClicking && enabled && { backgroundColor: colors.neutral150 }),
        ...style,
      }}
      onClick={e => {
        if (enabled && !!onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onMouseOut={() => setIsClicking(false)}
    >
      <>
        {loading ? (
          <View>
            <View
              style={{
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MediumHevyActivityIndicator />
              {/* Below invisible text is so that a loading button takes the same width as not loading */}
              <TextSMMedium style={{ opacity: 0.0, height: 0 }}>{title}</TextSMMedium>
            </View>
          </View>
        ) : (
          <FlexRow style={{ gap: Spacing.sm }}>
            {!!iconType && (
              <CustomizableIcon type={iconType} tint={enabled ? colors.neutral900 : colors.white} />
            )}
            {!!imageUrl && <ProfileImage source={imageUrl} diameter={24} />}
            {!!title && (
              <TextSMMedium
                style={{ color: enabled ? colors.neutral900 : colors.white, ...textStyle }}
              >
                {title}
              </TextSMMedium>
            )}
          </FlexRow>
        )}
      </>
    </StyledSecondaryButton>
  );
};
export const ProfileImageButton = ({
  title,
  enabled = true,
  loading = false,
  onClick,
  style,
  textStyle,
  tabIndex,
  imageUrl,
}: Omit<ButtonProps, 'iconType'>) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  return (
    <StyledSecondaryButton
      tabIndex={tabIndex}
      enabled={enabled}
      hovering={isHovering}
      style={{
        ...(enabled
          ? {
              cursor: 'pointer',
              pointerEvents: 'auto',
            }
          : {
              cursor: 'default',
              pointerEvents: 'none',
            }),
        ...(loading ? { pointerEvents: 'none' } : {}),
        ...(isClicking && enabled && { backgroundColor: colors.neutral150 }),
        ...style,
      }}
      onClick={e => {
        if (enabled && !!onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onMouseOut={() => setIsClicking(false)}
    >
      <>
        {loading ? (
          <View>
            <View
              style={{
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MediumHevyActivityIndicator />
              {/* Below invisible text is so that a loading button takes the same width as not loading */}
              <TextSMMedium style={{ opacity: 0.0, height: 0 }}>{title}</TextSMMedium>
            </View>
          </View>
        ) : (
          <>
            <ProfileImage source={imageUrl} diameter={24} style={{ marginRight: Spacing.sm }} />
            <TextSMMedium style={{ ...textStyle }}>{title}</TextSMMedium>
          </>
        )}
      </>
    </StyledSecondaryButton>
  );
};
