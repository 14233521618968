import { CoachAccount } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { Client } from 'types/client';
import { clientMatchesSearch, compareClientsAlphabetically } from 'utils/pureUtils';

export interface TransferClientsToCoachModalProps {
  clientOptions: Client[];
  targetCoach: CoachAccount;
  onComplete: (selectedClientIds: string[], keepProgram: boolean) => void;
  searchValue: string;
  isOpen: boolean;
  selectedClientIds: string[];
  keepAssignedPrograms: boolean;
}

class TransferClientsToCoachViewModelClass {
  private _state: TransferClientsToCoachModalProps | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  open = (
    state: Omit<
      TransferClientsToCoachModalProps,
      'searchValue' | 'isOpen' | 'selectedClientIds' | 'keepAssignedPrograms'
    >,
  ) => {
    this._state = {
      ...state,
      searchValue: '',
      isOpen: true,
      selectedClientIds: [],
      keepAssignedPrograms: true,
    };
  };

  get filteredClientOptions(): (Client & { coach: CoachAccount })[] {
    if (!this._state) return [];
    const { clientOptions, searchValue } = this._state;

    const options = clientOptions
      .filter(client => {
        if (!localStorageStores.team.teamMemberWithId(client.coachId)) {
          return false;
        }

        return clientMatchesSearch(client, searchValue);
      })
      .sort(compareClientsAlphabetically)
      .map(client => ({
        ...client,
        coach: localStorageStores.team.teamMemberWithId(
          client.coachId,
          // Cast since we filtered above
        ) as CoachAccount,
      }));

    return options;
  }

  onSelectedClient = (client: Client) => {
    if (this._state?.selectedClientIds.includes(client.id)) {
      this._state.selectedClientIds = this._state.selectedClientIds.filter(id => id !== client.id);
    } else {
      this._state?.selectedClientIds.push(client.id);
    }
  };

  isClientSelected = (client: Client) => {
    return this._state?.selectedClientIds.includes(client.id) ?? false;
  };

  onContinue = () => {
    if (!this._state) return;
    this._state.onComplete(this._state.selectedClientIds, this._state.keepAssignedPrograms);
    this._state = undefined;
  };

  onClose = () => {
    this._state = undefined;
  };

  get keepAssignedProgram() {
    return this._state?.keepAssignedPrograms ?? false;
  }

  get hasSelectedAClient() {
    return this._state?.selectedClientIds.length ?? 0 > 0;
  }

  onChangeKeepAssignedProgram = () => {
    if (!this._state) return;
    this._state.keepAssignedPrograms = !this._state.keepAssignedPrograms;
  };

  get anySelectedClientHasAssignedProgram() {
    return !!this._state?.selectedClientIds.find(client =>
      localStorageStores.programs.programAssignedToClient(client),
    );
  }

  get searchValue() {
    return this._state?.searchValue ?? '';
  }

  onSearchTextUpdated = (value: string) => {
    if (this._state) {
      this._state.searchValue = value;
    }
  };

  get isOpen() {
    return this._state?.isOpen ?? false;
  }

  get targetCoach() {
    return this._state?.targetCoach;
  }

  get clientOptions() {
    return this._state?.clientOptions;
  }
}

export const TransferClientsToCoachViewModel = new TransferClientsToCoachViewModelClass();
