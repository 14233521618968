import React from 'react';
import styled from 'styled-components';
import { colors } from './colors';
import { CornerRadius, Spacing } from './spacing';
import { TextXSMedium } from './Texts';
interface BadgeProps {
  number: number;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const BadgeContainer = styled.div`
  background-color: ${colors.neutral100};
  border-radius: ${CornerRadius.xl}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 22px;
  padding: 2px ${Spacing.md}px 2px ${Spacing.md}px;
  gap: ${Spacing.xs}px;
  margin-left: ${Spacing.sm}px;
`;

export const Badge = ({ number, style, textStyle }: BadgeProps) => {
  return (
    <BadgeContainer style={style}>
      <TextXSMedium
        style={{ color: colors.neutral900, lineHeight: 1, fontWeight: 500, ...textStyle }}
      >
        {number}
      </TextXSMedium>
    </BadgeContainer>
  );
};
