import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';
import { TextLG } from 'styleguide/Texts';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

const Container = styled(View)`
  padding: ${Spacing.lg}px;
  gap: ${Spacing.md}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

export const ModalHeader = ({
  text,
  onClose,
  style,
}: {
  text: String;
  onClose: () => void;
  style?: CSSProperties;
}) => {
  return (
    <Container style={style}>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <TextLG>{text}</TextLG>
        <CloseButton onClick={onClose} />
      </FlexRow>
    </Container>
  );
};
