import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { DisplaySM, DisplayXS, TextMDRegular, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { sendEvent } from 'utils/analyticsEvents';
import { UpgradePlanView } from './Plan/components/UpgradePlanView';
import { UpgradePlanViewModel } from './Plan/UpgradePlanViewModel';
import { PlanFooterContent } from './Plan/components/PlanFooterContent';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';

const Container = styled(View)`
  flex: 1;
`;

const MiddleContainer = styled(View)`
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.lg}px;
  padding-top: ${Spacing.md}px;
  padding-bottom: ${Spacing.md}px;
  min-height: 320px;
`;

const HourglassContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Spacing.md}px;
  min-height: 88px;
  width: 88px;

  border-radius: 44px;
  background-color: ${colors.primary50};
`;

const HourglassText = styled(DisplaySM)`
  font-size: 34px;
  font-weight: 500;
  line-height: 60px;
`;

const BottomContainer = styled(View)`
  padding: ${Spacing.lg}px;
  align-items: center;
`;

export interface FreeTrialEndModalProps {
  isOpen: boolean;
  onClose: () => void;
  upgradeVM: UpgradePlanViewModel;
}

export const FreeTrialEndModal = ({
  isOpen,
  onClose,
  upgradeVM,
}: FreeTrialEndModalProps & { upgradeVM: UpgradePlanViewModel }) => {
  const [isClickedSeePlans, setIsClickedSeePlans] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      contentStyle={{ width: isClickedSeePlans ? 1038 : 684 }}
    >
      <Container>
        <FlexRow
          style={{ justifyContent: 'flex-end', paddingTop: Spacing.md, paddingRight: Spacing.md }}
        >
          <CloseButton onClick={onClose} />
        </FlexRow>
        {isClickedSeePlans ? (
          <>
            <View style={{ overflowY: 'auto' }}>
              <View style={{ margin: Spacing.md }}>
                <UpgradePlanView vm={upgradeVM} />
              </View>
              <DividerLine />
              <PlanFooterContent />
            </View>
          </>
        ) : (
          <>
            <MiddleContainer>
              <View style={{ alignItems: 'center' }}>
                <HourglassContainer>
                  <HourglassText>⌛️</HourglassText>
                </HourglassContainer>
                <DisplayXS style={{ textAlign: 'center', marginBottom: Spacing.md }}>
                  Free Trial Ended
                </DisplayXS>
                <TextMDRegular style={{ textAlign: 'center' }}>
                  Thank you so much for using the trial version of Hevy Coach! To ensure you
                  continue enjoying access to your clients and their data, please upgrade to a paid
                  plan.
                </TextMDRegular>
              </View>
              <PrimaryButton
                title="See plans & Pricing"
                onClick={() => {
                  sendEvent('freeTrialEndModal_seePlansClicked');
                  setIsClickedSeePlans(true);
                }}
                style={{ marginTop: Spacing.md, maxHeight: 40 }}
              ></PrimaryButton>
            </MiddleContainer>
            <DividerLine />
            <BottomContainer>
              <TextSMMedium style={{ color: colors.neutral500, textAlign: 'center' }}>
                If you decide not to upgrade, you'll loose access to your clients. You can easily
                regain access anytime by upgrading your plan.
              </TextSMMedium>
            </BottomContainer>
          </>
        )}
      </Container>
    </Modal>
  );
};
