import React from 'react';
import { DividerLine } from 'styleguide/DividerLine';
import { View } from 'styleguide/View';
import { ChatInput } from './ChatInput/ChatInput';
import styled from 'styled-components';
import { ClientChatMessagesView } from './ClientChatMessagesView';
import { Message } from 'hevy-shared';
import { ChatViewModel } from '../ChatViewModel';

const Container = styled(View)`
  flex: 1;
`;

interface WidgetClientChatViewProps {
  client: any;
  messages: Message[];
  chatVm: ChatViewModel;
  isFetchingOlderMessages: boolean;
  onScrollToTop: () => void;
  setMessagesContainerRef: (ref: HTMLDivElement) => void;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  dividerline?: boolean;
  textBubbleMaxWidth?: number;
  onSend: (message: string) => Promise<void>;
  onSendFile: (file: File) => Promise<void>;
  onDeleteMessageClick: (messageId: string) => void;
  imageBeingUploaded?: string;
  videoBeingUploaded?: string;
  updateUnsentMessage: (message: string) => void;
}

export const WidgetClientChatView = ({
  client,
  messages,
  chatVm,
  isFetchingOlderMessages,
  onScrollToTop,
  setMessagesContainerRef,
  style,
  bodyStyle,
  dividerline = true,
  textBubbleMaxWidth,
  onDeleteMessageClick,
  imageBeingUploaded,
  videoBeingUploaded,
}: WidgetClientChatViewProps) => {
  return (
    <Container style={style}>
      <ClientChatMessagesView
        client={client}
        source="widget"
        messages={messages}
        isFetchingOlderMessages={isFetchingOlderMessages}
        onScrollToTop={onScrollToTop}
        setMessagesContainerRef={setMessagesContainerRef}
        style={bodyStyle}
        textBubbleMaxWidth={textBubbleMaxWidth}
        onDeleteMessageClick={onDeleteMessageClick}
        videoBeingUploaded={videoBeingUploaded}
        imageBeingUploaded={imageBeingUploaded}
      />
      {dividerline && <DividerLine />}
      <ChatInput chatVm={chatVm} />
    </Container>
  );
};
