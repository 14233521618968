import React from 'react';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { CustomizableIconType } from 'styleguide/CustomizableIcon';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { Link } from './Link';

interface Props {
  title: string;
  subtitle?: string;
  iconType: CustomizableIconType;
  style?: React.CSSProperties;
  learnMoreLink?: string;
}

export const EmptyState = ({ title, subtitle, iconType, style, learnMoreLink }: Props) => {
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', ...style }}>
      <DoubleCircledIcon containerStyle={{ marginBottom: Spacing.md }} type={iconType} />
      <TextMD style={{ textAlign: 'center' }}>{title}</TextMD>
      {!!subtitle && (
        <TextSMRegular style={{ textAlign: 'center', marginTop: Spacing.xs }}>
          {subtitle}
          {learnMoreLink && (
            <Link
              href={learnMoreLink}
              target="blank"
              style={{
                display: 'inline',
              }}
            >
              {' '}
              Learn More
            </Link>
          )}
        </TextSMRegular>
      )}
    </View>
  );
};
