import dayjs from 'dayjs';
import { localStorageStores } from 'state/localStorageStores';
import { AnchorText, ClickableText } from 'styleguide/Buttons';
import { TextSMRegular } from 'styleguide/Texts';
import React from 'react';
import { WarningBanner } from 'components/WarningBanner';
import { colors } from 'styleguide/colors';
import { observer } from 'mobx-react-lite';
import { PlanWarningsViewModel } from './PlanWarningsViewModel';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';

export const PlanWarningsView = observer(({ vm }: { vm: PlanWarningsViewModel }) => {
  if (!localStorageStores.lifecycle.isBootstrapped) {
    return <></>;
  }
  return (
    <>
      {localStorageStores.plan.hasActivePlan && PlanAggregator.isTeamPastPlanLimits && (
        <WarningBanner>
          <div>
            <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
              Your plan has reached its maximum number of clients. Please remove the excess clients
              or{' '}
            </TextSMRegular>
            <ClickableText
              style={{ display: 'inline' }}
              text={'Upgrade your Plan.'}
              onClick={vm.onUpgradePlanClicked}
            ></ClickableText>
          </div>
        </WarningBanner>
      )}
      {PlanAggregator.isExpiringPlanExceedsFreeClientLimit && (
        <WarningBanner>
          <div>
            <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
              Your current plan expires{' '}
              {localStorageStores.plan.currentPlan?.renewal_date
                ? `on ${dayjs(localStorageStores.plan.currentPlan?.renewal_date).format(
                    'MMM D, YYYY',
                  )}`
                : 'soon'}
              .{' '}
            </TextSMRegular>
            <ClickableText
              style={{ display: 'inline' }}
              text={'Reactivate your plan'}
              onClick={vm.onReactivatePlanClicked}
            ></ClickableText>
            <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
              {' '}
              to avoid losing access to your current clients.
            </TextSMRegular>
          </div>
        </WarningBanner>
      )}
      {PlanAggregator.isExpiredPlanExceedsFreeClientLimit && (
        <WarningBanner>
          <div>
            <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
              Your current plan expired{' '}
              {localStorageStores.plan.currentPlan?.renewal_date
                ? `on ${dayjs(localStorageStores.plan.currentPlan?.renewal_date).format(
                    'MMM D, YYYY',
                  )}`
                : ''}
              .{' '}
            </TextSMRegular>
            <ClickableText
              style={{ display: 'inline' }}
              text={'Reactivate your plan'}
              onClick={vm.onReactivatePlanClicked}
            ></ClickableText>
            <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
              {' '}
              to regain access to your clients.
            </TextSMRegular>
          </div>
        </WarningBanner>
      )}
      {localStorageStores.plan.isHavingTroubleWithPayment &&
        localStorageStores.plan.currentPlan?.paddle_update_payment_url && (
          <WarningBanner>
            <div>
              <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
                Your most recent payment failed. Please{' '}
                <AnchorText
                  text={'update your billing information'}
                  URL={localStorageStores.plan.currentPlan.paddle_update_payment_url}
                  target="_blank"
                  style={{ display: 'inline' }}
                ></AnchorText>{' '}
                in the next 7 days to keep access to your account, or{' '}
                <AnchorText
                  style={{ display: 'inline' }}
                  URL="mailto:hello@hevycoach.com"
                  text="contact us"
                ></AnchorText>{' '}
                for help.
              </TextSMRegular>
            </div>
          </WarningBanner>
        )}
    </>
  );
});
