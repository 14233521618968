import React from 'react';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { CloseButton } from 'components/CloseButton';
import { SearchInput } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { TextLG } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';

const UpperContainer = styled(View)`
  padding: ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

export const ModalSearchHeader = ({
  text,
  onClose,
  searchPlaceholder,
  searchValue,
  onSearchTextUpdated,
}: {
  text: String;
  onClose: () => void;
  searchPlaceholder: string;
  searchValue: string;
  onSearchTextUpdated: (value: string) => void;
}) => {
  return (
    <UpperContainer>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <TextLG style={{ marginBottom: Spacing.xs }}>{text}</TextLG>
        <CloseButton onClick={onClose} />
      </FlexRow>
      <SearchInput
        placeholder={searchPlaceholder}
        onChange={onSearchTextUpdated}
        value={searchValue}
        style={{ backgroundColor: colors.neutral100, width: '100%' }}
      />
    </UpperContainer>
  );
};
