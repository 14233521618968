import styled from 'styled-components';
import { colors } from './colors';
import fonts from './fonts';
import { Spacing } from './spacing';

interface RegularTextProps {
  type?: 'secondary' | 'primary';
}

export const DisplaySM = styled.h1`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 30px;
  line-height: 38px;
  color: ${colors.neutral900};
  margin: 0px;
`;

export const DisplayXS = styled.h1`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 24px;
  line-height: 32px;
  color: ${colors.neutral900};
  margin: 0px;
`;

export const HeaderText = styled(DisplayXS)`
  margin-bottom: ${Spacing.sm}px;
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: ${Spacing.xs}px;
  }
`;

export const TextXLMedium = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 20px;
  color: ${colors.neutral900};
  line-height: 28px;
  margin: 0px;
`;

export const TextLG = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 18px;
  color: ${colors.neutral900};
  line-height: 28px;
  margin: 0px;
`;

export const TextLGRegular = styled.p`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 18px;
  color: ${(props: RegularTextProps) =>
    props.type === 'secondary' ? colors.neutral900 : colors.neutral500};
  line-height: 28px;
  margin: 0px;
`;

export const TextMD = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutral900};
  margin: 0px;
`;

//TODO: Make this a neutral500 by default just like the other regular texts
export const TextMDRegular = styled.p`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.neutral900};
  margin: 0px;
`;

export const TextSMMedium = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.neutral900};
  margin: 0px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const TextSMRegular = styled.p`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  line-height: 20px;
  color: ${(props: RegularTextProps) =>
    props.type === 'secondary' ? colors.neutral900 : colors.neutral500};
  margin: 0px;
`;

export const TextXSRegular = styled.p`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 12px;
  line-height: 18px;
  color: ${(props: RegularTextProps) =>
    props.type === 'secondary' ? colors.neutral900 : colors.neutral500};
  margin: 0px;
`;

export const TextXSMedium = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 12px;
  line-height: 18px;
  color: ${colors.neutral500};
  margin: 0px;
`;

export const Table = styled.p`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.neutral500};
  margin: 0px;
`;
