import React, { CSSProperties, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import API from 'utils/API';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { TextAreaAutosize } from 'styleguide/Inputs';
import { TextLG, TextSMRegular } from 'styleguide/Texts';
import { observer } from 'mobx-react-lite';
import { Spacing } from 'styleguide/spacing';
import { localStorageStores } from 'state/localStorageStores';
import { View } from 'styleguide/View';
import { ProfileImage } from 'components/ProfileImage';
import { CloseButton } from 'components/CloseButton';
import { Modal } from 'styleguide/Modal';
import { useDeviceSize } from 'utils/useSize';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: ${Spacing.lg}px;
  position: 'absolute';
  right: 0;
  bottom: 0;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding-bottom: ${Spacing.lg}px;
`;

export interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FeedbackModal = observer(({ isOpen, onClose }: FeedbackModalProps) => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await API.postFeedback('hevy-coach-feedback', input);
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    onClose();

    setInput('');
  };

  const device = useDeviceSize();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onAfterOpen={() => {
        inputRef.current?.focus();
      }}
      onAfterClose={() => {
        setInput('');
      }}
      contentStyle={{ width: device === 'phone' ? '80vw' : 400 }}
    >
      <Container>
        <UpperContainer>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              paddingBottom: Spacing.xs,
            }}
          >
            <View style={{ flex: 1 }}></View>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <ProfileImage
                source="https://www.hevyapp.com/wp-content/uploads/2019/07/guillem.png"
                diameter={84}
              />
            </View>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <CloseButton onClick={onClose} />
            </View>
          </View>
          <TextLG>Hello, {localStorageStores.account.personalCoachName}! 👋</TextLG>
          <TextSMRegular
            style={{ color: colors.neutral900, textAlign: 'center', paddingBottom: Spacing.md }}
          >
            I am Guillem, the Co-Founder & CEO of HevyCoach. Thanks for trying out our product. Let
            us know if it meets your expectations and if there are any features you'd like to see.
          </TextSMRegular>
          <TextAreaAutosize
            ref={(ref: any) => (inputRef.current = ref)}
            placeholder="Your feedback"
            minRows={4}
            value={input}
            style={{ backgroundColor: colors.neutral50 }}
            onChange={e => {
              const { value } = e.currentTarget;

              if (value.length > 800) return;

              setInput(value);
            }}
          />
        </UpperContainer>

        <LowerContainer>
          <PrimaryButton
            style={styles.button}
            title={'Send Feedback'}
            loading={isLoading}
            onClick={handleSubmit}
            enabled={!!input}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
  },
};
