import React from 'react';
import { Card } from 'styleguide/Card';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { TextMDRegular, TextSMRegular, TextLG, DisplaySM } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { ProfileImage } from './ProfileImage';
import { MobileFriendlyRowWrapper } from 'styleguide/Breakpoints';
import { colors } from 'styleguide/colors';

const TestimonialCard = ({
  name,
  title,
  testimonial,
  stars,
  profileImageUrl,
}: {
  name: string;
  title: string;
  testimonial: string;
  stars: number;
  profileImageUrl?: string;
}) => {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <View style={{ alignItems: 'center', textAlign: 'center' }}>
        <ProfileImage diameter={96} style={{ marginBottom: Spacing.md }} source={profileImageUrl} />
        <TextMDRegular>{name}</TextMDRegular>
        <TextSMRegular style={{ paddingBottom: Spacing.md }}>{title}</TextSMRegular>
        <TextLG style={{ paddingBottom: Spacing.lg }}>"{testimonial}"</TextLG>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        {Array.from({ length: stars }).map((_, i) => {
          return <CustomizableIcon key={i} type={'star'} tint={colors.neutral900} />;
        })}
      </View>
    </Card>
  );
};

export const CoachReviews = () => {
  return (
    <View>
      <View style={{ paddingTop: Spacing.lg * 2, paddingBottom: Spacing.lg * 2 }}>
        <DisplaySM style={{ textAlign: 'center', marginBottom: Spacing.sm }}>
          What coaches think about us
        </DisplaySM>
      </View>
      <MobileFriendlyRowWrapper gap={Spacing.lg}>
        <TestimonialCard
          profileImageUrl={
            'https://hevycoach.com/wp-content/uploads/2023/04/Screenshot-2023-04-18-at-16.54.40.png'
          }
          name="Scott Slaman"
          title="Coach in HevyCoach"
          testimonial="Absolute game changer. I'm not great with technology, but this is so easy to use to manage clients, and assign workout programs. I'm recommending it to all my coach friends."
          stars={5}
        />
        <TestimonialCard
          profileImageUrl="https://hevycoach.com/wp-content/uploads/2023/04/8_adqZ4h_400x400.jpeg"
          name="Fit With Rashid"
          title="Coach in HevyCoach"
          testimonial="Loving the Hevy Coach platform and the clients too! My clients love to be able to see progressive overload on their app and see how they're improving over time. Legendary!"
          stars={5}
        />
        <TestimonialCard
          profileImageUrl="https://hevycoach.com/wp-content/uploads/2023/04/giigo.png"
          name="Gilian Reichert"
          title="Coach in HevyCoach"
          testimonial="This platform is mega incredible! I'm coaching all my clients through Hevy Coach and it's so easy to keep track of everyone!"
          stars={5}
        />
      </MobileFriendlyRowWrapper>
    </View>
  );
};
