import { amplitudeEvent } from './amplitude';
import { log } from './log';
import { isProduction } from './misc';
import { hotjarEvent } from './hotjar';
import { logFacebookPixelEvent } from './facebook';
import { sendTitkTokEventToServerProxy } from './tiktok';

export const HEVY_COACH_EVENT_PREFIX = '[WEB-COACH]-';

export const sendEvent = (event: string, params?: {}) => {
  log(`👣 amplitudeEvent: ${event} params: `, params);
  if (!isProduction()) {
    return;
  }
  try {
    sendTitkTokEventToServerProxy(`${event.replaceAll('-', '_')}`, params);
  } catch {
    // Ignore
  }
  amplitudeEvent(`${HEVY_COACH_EVENT_PREFIX}${event}`, params);
  logFacebookPixelEvent(`${HEVY_COACH_EVENT_PREFIX}${event}`, params);
  hotjarEvent(`${HEVY_COACH_EVENT_PREFIX}${event}`);

  // No prefix for Google Analytics because they truncate them to like,
  // 20 characters, so we loose data
  sendGoogleEvent(`${event}`, params);
};

const sendGoogleEvent = (event: string, params?: {}) => {
  if (typeof window === 'undefined') {
    return;
  }
  (window as any)?.gtag?.('event', event, params);
};
