import { env } from 'env';
import { log } from './log';
import { isProduction } from './misc';
import { loadScript } from './loadScriptPromise';
const PADDLE_SANDBOX_VENDOR_ID = 11643;
const PADDLE_PRODUCTION_VENDOR_ID = 150329;

const loadPaddleScript = async () => {
  await loadScript('https://cdn.paddle.com/paddle/paddle.js');
};

// This is sort of a work-around to ensure Paddle is defined. For details
// on paddle.js, see https://developer.paddle.com/guides/b4f911a991bd7-overlay-checkout,
// where they explicitly say not to self-host it.
export let Paddle: any;

export const initPaddle = async () => {
  try {
    await loadPaddleScript();
    Paddle = typeof window !== 'undefined' ? (window as any).Paddle : undefined;
  } catch (error) {
    return;
  }
  const vendorId = isProduction() ? PADDLE_PRODUCTION_VENDOR_ID : PADDLE_SANDBOX_VENDOR_ID;
  log(`💰Initializing Paddle with ${vendorId}`);
  if (env.env !== 'production') {
    Paddle.Environment.set('sandbox');
  }
  Paddle.Setup({
    vendor: vendorId,
  });
};
