import { modal } from 'components/Modals/ModalManager';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { ChatAggregator } from 'state/aggregators/chatAggregatorStore';
import { memoryStores } from 'state/memoryStores';
import { Client } from 'types/client';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import { ChatWidgetEventEmitter, isChatWidgetEvent } from './ChatsWidgetEvents';
import { ChatViewModel } from './ChatViewModel';
import { localStorageStores } from 'state/localStorageStores';

export class ChatsWidgetViewModel {
  chatVm: ChatViewModel;
  isNewChatWidgetOpen = false;
  isCollapsed = true;

  isCreatingConversation = false;

  toggleCollapse = () => {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      sendEvent('chatWidget_opened');
    }
    this.chatVm.searchText = '';
  };

  constructor() {
    makeAutoObservable(this);
    this.chatVm = new ChatViewModel('chatWidget');
  }

  startListeningForEvents = () => {
    ChatWidgetEventEmitter.on('open_conversation', this._handleChatWidgetEvent);
  };

  stopListeningForEvents = () => {
    ChatWidgetEventEmitter.removeListener('open_conversation', this._handleChatWidgetEvent);
    this.chatVm.stopListeningForDragAndDrop();
  };

  private _handleChatWidgetEvent = (event: any) => {
    if (!isChatWidgetEvent(event)) {
      return;
    }

    switch (event.event_type) {
      case 'open_conversation':
        this.openConversation(event.conversation_id);
        break;
    }
  };

  onCreateConversation = async (client: Client) => {
    try {
      const newConversationId = await this.chatVm.createConversation(client);
      this.isNewChatWidgetOpen = false;

      this.openConversation(newConversationId);
    } catch (error) {
      toast.error('Unable to create conversation.');
    }
  };

  onSelectClient = (client: Client) => {
    if (client.configuration.is_chat_enabled) {
      this.onCreateConversation(client);
    } else if (client.id === localStorageStores.account.id) {
      modal.openAlertModal({
        title: 'Unsupported',
        body: 'You cannot start a conversation with yourself',
        confirmButtonTitle: 'OK',
      });
    } else {
      modal.openAlertModal({
        title: 'Chat Disabled',
        body: `Chat is disabled for ${
          client.fullName || client.username
        }. Would you like to enable chat for this client?`,
        confirmButtonTitle: 'Enable Chat',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => {
          sendEvent('chatWidget_enableChatForClickConfirm');
          try {
            await API.updateClientConfiguration([
              {
                client_id: client.id,
                config: {
                  is_chat_enabled: true,
                  are_notifications_enabled: client.configuration?.are_notifications_enabled,
                },
              },
            ]);
            await fireAndForget([memoryStores.clients.fetch()]);
          } catch (error) {
            toast.error(`Error enabling chat with "${client.username}". Please try again`);
            return;
          }
          this.onCreateConversation(client);
        },
      });
    }
  };

  onConversationPress = (conversationId: string) => {
    if (this.chatVm.selectedConversationId === conversationId) {
      this.clearSelectedConversation();
      return;
    }
    this.openConversation(conversationId);
  };

  openConversation = async (conversationId: string | undefined) => {
    this.isNewChatWidgetOpen = false;
    this.chatVm.selectedConversationId = conversationId;

    if (!conversationId) {
      return;
    }

    sendEvent('chatWidget_selectConversation');
    await ChatAggregator.loadLatestMessagesForConversation(conversationId);
    this.chatVm.messagesContainerRef?.scrollTo(0, 0);
  };

  clearSelectedConversation = () => {
    this.chatVm.selectedConversationId = undefined;
  };

  onNewChatClick = () => {
    this.chatVm.selectedConversationId = undefined;
    this.isNewChatWidgetOpen = true;
    sendEvent('coachChat_newChatWidgetOpened');
  };
}
