import { makeAutoObservable } from 'mobx';
import {
  CoachAccountConfiguration,
  EmailNotificationConfigurationType,
  WebNotificationConfigurationType,
} from 'hevy-shared';
import API from 'utils/API';

const DEFAULT_WEB_NOTIFICATIONS = {
  client_completes_workout: false,
  client_sends_message: false,
  client_adds_measurement: false,
};

const DEFAULT_EMAIL_NOTIFICATIONS = {
  client_completes_workout: false,
  client_sends_message: false,
  client_adds_measurement: false,
  program_finishes_next_week: false,
};

const COACH_CONFIG_LOCAL_STORAGE_KEY = 'COACH_CONFIG_LOCAL_STORAGE_KEY';
const DEFAULT_CONFIG: CoachAccountConfiguration = {
  enable_chat_for_new_clients: false,
  enable_notifications_for_new_clients: false,
  weight_unit: 'kg',
  distance_unit: 'kilometers',
  body_measurement_unit: 'cm',
  default_rest_timer_seconds: 0,
  is_rpe_enabled_for_new_routines: false,
  first_weekday: 'sunday',
  default_rep_input_type: 'reps',
  web_notifications: DEFAULT_WEB_NOTIFICATIONS,
  email_notifications: DEFAULT_EMAIL_NOTIFICATIONS,
};

const SHOW_PROGRAM_SUMMARY_SIDE_BAR_KEY = 'SHOW_PROGRAM_SUMMARY_SIDE_BAR_KEY';

export class CoachConfigStore {
  private _config: CoachAccountConfiguration = DEFAULT_CONFIG;
  private _showProgramSummarySideBar = true;

  get config() {
    return this._config;
  }

  get defaultRestTimerSeconds() {
    return this.config.default_rest_timer_seconds || 0;
  }

  get isRpeEnabledForNewRoutines() {
    return this.config.is_rpe_enabled_for_new_routines || false;
  }

  get firstWeekday() {
    return this.config.first_weekday || 'sunday';
  }

  get defaultRepInputType() {
    return this.config.default_rep_input_type || 'reps';
  }

  get showProgramSummarySideBar() {
    return this._showProgramSummarySideBar;
  }

  set showProgramSummarySideBar(value: boolean) {
    this._showProgramSummarySideBar = value;
    window.localStorage.setItem(
      SHOW_PROGRAM_SUMMARY_SIDE_BAR_KEY,
      JSON.stringify(this._showProgramSummarySideBar),
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  private persist = () => {
    if (!!this._config) {
      window.localStorage.setItem(COACH_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(this._config));
    }
  };

  updateWebNotifications = async ({
    type,
    enabled,
  }: {
    type: WebNotificationConfigurationType;
    enabled: boolean;
  }) => {
    const newConfig = {
      ...DEFAULT_WEB_NOTIFICATIONS,
      ...this._config.web_notifications,
      [type]: enabled,
    };

    await this.update({ web_notifications: newConfig });
  };

  updateEmailNotifications = async ({
    type,
    enabled,
  }: {
    type: EmailNotificationConfigurationType;
    enabled: boolean;
  }) => {
    const newConfig = {
      ...DEFAULT_EMAIL_NOTIFICATIONS,
      ...this._config.email_notifications,
      [type]: enabled,
    };

    await this.update({ email_notifications: newConfig });
  };

  update = async (newConfig: Partial<CoachAccountConfiguration>) => {
    const rollbackConfig = this._config;
    this._config = { ...this._config, ...newConfig };
    try {
      await API.setConfig(this._config);
      this.persist();
    } catch (error) {
      this._config = rollbackConfig;
      throw error;
    }
  };

  fetch = async () => {
    const result = await API.getConfig();
    this._config = result.data;
    this.persist();
  };

  hydrate = () => {
    const config = window.localStorage.getItem(COACH_CONFIG_LOCAL_STORAGE_KEY);
    if (!!config) {
      this._config = JSON.parse(config);
    }

    this.hydrateShowProgramSummarySideBarData();
  };

  clearData = () => {
    window.localStorage.removeItem(COACH_CONFIG_LOCAL_STORAGE_KEY);
    this._config = DEFAULT_CONFIG;

    this.clearShowProgramSummarySideBarData();
  };

  hydrateShowProgramSummarySideBarData = () => {
    const showProgramSummarySideBar = window.localStorage.getItem(
      SHOW_PROGRAM_SUMMARY_SIDE_BAR_KEY,
    );
    if (showProgramSummarySideBar) {
      this.showProgramSummarySideBar = JSON.parse(showProgramSummarySideBar);
    }
  };

  clearShowProgramSummarySideBarData = () => {
    window.localStorage.removeItem(SHOW_PROGRAM_SUMMARY_SIDE_BAR_KEY);
    this._showProgramSummarySideBar = true;
  };
}
