import { WarningBanner } from 'components/WarningBanner';
import { observer } from 'mobx-react-lite';
import router from 'next/router';
import React from 'react';
import { BackendReachability } from 'state/backendReachability';
import { ClickableText } from 'styleguide/Buttons';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import fonts from 'styleguide/fonts';

export const ReachabilityWarningBanner = observer(() => {
  if (BackendReachability.backendReachabilityState !== 'unreachable') {
    return null;
  }
  return (
    <WarningBanner>
      <div>
        <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
          We're having some trouble connecting to our server. If something isn't working, try{' '}
          <ClickableText
            text="refreshing the page"
            onClick={() => {
              router.reload();
            }}
          />
          , or you can{' '}
          <a
            // Mailto, since our server may not be reachable for feedback submissions
            href="mailto:hello@hevycoach.com"
            style={{ textDecoration: 'none', fontWeight: fonts.medium.weight }}
          >
            let us know
          </a>{' '}
          so we can look into it.
        </TextSMRegular>
      </div>
    </WarningBanner>
  );
});
