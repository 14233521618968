import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import fonts from 'styleguide/fonts';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { FlexColumn } from 'styleguide/Column';
import { TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { zIndexes } from 'styleguide/zIndex';
import { SearchResultRow } from './SearchResultRow';
import { CoachInviteSearchUser } from 'hevy-shared';
import { autorun } from 'mobx';

const StyleFreeInput = styled.input`
  flex: 1;
  font-size: 16px;
  outline: none;
  color: ${colors.neutral900};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: none;
  resize: none;
  margin: ${Spacing.xs}px 0px;
`;

const InputContainer = styled(FlexRow)`
  justify-content: flex-start;
  width: 100%;
  min-height: 40px;
  padding: 0px ${Spacing.sm}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  border-radius: ${CornerRadius.sm}px;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  color: ${colors.neutral900};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: 1px solid ${colors.neutral200};
  cursor: text;
`;

const SearchResultsContainer = styled.div`
  overflow-y: auto;
  min-width: min(302px, 90vw);
  max-width: 90vw;
  max-height: min(300px, 90vh);
`;

export interface SearchUserInputProps {
  vm: SearchUserViewModel;
  placeholder?: string;
}

export interface SearchUserViewModel {
  inputText: string;
  isInputEnabled: boolean;
  isSearching: boolean;
  isEmailValidationErrorDetected: boolean;
  isEnteredTextValidEmail: boolean;
  shouldShowEmailSearchResults: boolean;
  searchResults: CoachInviteSearchUser[];
  showSearchResults: boolean;
  onEmailSearchResultClick: () => void;
  onUserSearchResultClick: (user: CoachInviteSearchUser) => void;
  onInputTextChanged: (text: string) => void;
  onInputKeyPressed: (key: string) => void;
  onPasteText: (text: string) => void;
}

export const SearchUserInput = observer(({ vm, placeholder }: SearchUserInputProps) => {
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const [isInputFocused, setIsInputFocused] = useState(true);
  const textInputRef = React.useRef<HTMLInputElement>(null);
  const searchResultsRef = React.useRef(null);

  autorun(() => {
    vm.showSearchResults && isInputFocused ? toggleMenu(true) : toggleMenu(false);
  });

  return (
    <View>
      <InputContainer
        ref={searchResultsRef}
        onSubmit={e => e.preventDefault()}
        style={{
          border:
            isInputFocused && vm.isInputEnabled ? `1px solid ${colors.primary500}` : undefined,
          outline:
            isInputFocused && vm.isInputEnabled ? `1px solid ${colors.primary500}` : undefined,
          color: vm.isInputEnabled ? undefined : colors.neutral400,
        }}
      >
        <StyleFreeInput
          spellCheck={false}
          autoComplete="off"
          ref={textInputRef}
          onBlur={() => setIsInputFocused(false)}
          onFocus={() => setIsInputFocused(true)}
          autoFocus={true}
          value={vm.inputText}
          style={{
            color: vm.isEmailValidationErrorDetected ? colors.error500 : undefined,
          }}
          onChange={e => vm.onInputTextChanged(e.target.value)}
          onKeyDown={e => vm.onInputKeyPressed(e.key)}
          onPaste={e => {
            vm.onPasteText(e.clipboardData.getData('text'));
            e.stopPropagation();
            e.preventDefault();
          }}
          placeholder={placeholder}
        ></StyleFreeInput>
      </InputContainer>
      <ControlledMenu
        menuStyle={{
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          borderRadius: CornerRadius.md,
          maxHeight: 300,
          zIndex: zIndexes.modalOverlay,
        }}
        portal={true}
        anchorRef={searchResultsRef}
        captureFocus={false}
        direction="bottom"
        {...menuState}
      >
        <SearchResultsContainer>
          <FlexColumn>
            {vm.searchResults.length === 0 && !vm.isEnteredTextValidEmail && !vm.isSearching && (
              <TextSMRegular style={{ padding: Spacing.md, width: 300 }}>
                It seems that person isn't a member of Hevy yet. Add their email address to invite
                them.
              </TextSMRegular>
            )}
            {vm.shouldShowEmailSearchResults && (
              <SearchResultRow
                result={vm.inputText}
                onPress={() => {
                  setIsInputFocused(true);
                  vm.onEmailSearchResultClick();
                }}
              />
            )}
            {vm.searchResults?.map(s => {
              return (
                <SearchResultRow
                  key={s.username}
                  result={s}
                  onPress={() => {
                    setIsInputFocused(true);
                    vm.onUserSearchResultClick(s);
                  }}
                />
              );
            })}
            {vm.isSearching && (
              <View style={{ padding: Spacing.md, alignSelf: 'center' }}>
                <MediumHevyActivityIndicator />
              </View>
            )}
          </FlexColumn>
        </SearchResultsContainer>
      </ControlledMenu>
    </View>
  );
});
