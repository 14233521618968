import { Plan } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import API from 'utils/API';
import { modal } from '../ModalManager';
import toast from 'react-hot-toast';
import { sendEvent } from 'utils/analyticsEvents';
import { memoryStores } from 'state/memoryStores';
import router from 'next/router';
import { captureException } from '@sentry/nextjs';
import dayjs from 'dayjs';
import { LAUNCH_DISCOUNT_CUTOFF_DATE } from 'constants/plans';

interface UpgradePlanViewModelProps {
  onPlanSelected: (didChangePlan: boolean) => void;
  onStartedCheckout?: () => void;
  allowFreePlan?: boolean;
  postPurchasePath?: string;
  disableRecommendation?: boolean;
  source: string;
}

export class UpgradePlanViewModel {
  isUpgradingPlan = false;
  isLoading = true;
  allPlans: Plan[] = [];
  allowFreePlan: boolean = false;
  onPlanSelected: (didChangePlan: boolean) => void;
  onStartedCheckout?: () => void;
  postPurchasePath?: string;
  disableRecommendation?: boolean;
  source: string;

  constructor({
    onPlanSelected: onClose,
    allowFreePlan = false,
    postPurchasePath,
    onStartedCheckout,
    disableRecommendation,
    source,
  }: UpgradePlanViewModelProps) {
    makeAutoObservable(this);
    this.onPlanSelected = onClose;
    this.allowFreePlan = allowFreePlan;
    this.postPurchasePath = postPurchasePath;
    this.onStartedCheckout = onStartedCheckout;
    this.disableRecommendation = disableRecommendation;
    this.source = source;
  }

  fetchInitial = () => {
    this.isUpgradingPlan = false;
    this.isLoading = true;
    this.allPlans = [];
    this.fetchPlans();
  };

  fetchPlans = async () => {
    this.isLoading = true;
    try {
      await localStorageStores.plan.fetchCurrentPlan();
      await localStorageStores.plan.fetchAvailablePlans();

      if (memoryStores.clients.allClients.length === 0) {
        await memoryStores.clients.fetch();
      }
      this.allPlans = localStorageStores.plan.availablePlans;
    } catch (error) {
      captureException('Error fetching plans', { extra: { error: error } });
      toast.error('Error loading plans. Please refresh, or try again later.');
    }
    this.isLoading = false;
  };

  get discountPercent() {
    if (
      dayjs(localStorageStores.account.became_coach_at).isBefore(dayjs(LAUNCH_DISCOUNT_CUTOFF_DATE))
    ) {
      return 50;
    }
    return undefined;
  }

  get enableFreeTrial() {
    return localStorageStores.plan.isEligibleToStartFreeTrial;
  }

  get clientCount() {
    return memoryStores.clients.nonSampleClientsCount;
  }

  get freePlanClientLimit() {
    return localStorageStores.plan.freePlanClientLimit;
  }

  get recommendedPlan() {
    if (this.disableRecommendation) {
      return;
    }

    return this.allPlans.find(plan => plan.maximum_clients > this.clientCount);
  }

  get plan() {
    return localStorageStores.plan.currentPlan;
  }

  get isOnFreePlan() {
    return localStorageStores.plan.isOnFreePlan;
  }

  get defaultPlan() {
    return this.allPlans.find(plan => {
      return (
        plan.id !== localStorageStores.plan.currentPlan?.id &&
        plan.maximum_clients > this.clientCount
      );
    });
  }

  get hasExeededClientLimit() {
    return (
      this.clientCount >
      (this.plan?.type === 'trial'
        ? this.freePlanClientLimit
        : this.plan?.maximum_clients ?? this.freePlanClientLimit)
    );
  }

  get availablePlans(): Plan[] {
    return this.allPlans.filter((plan: Plan) => {
      return plan.id !== this.plan?.id;
    });
  }

  onFreeTrialClicked = async () => {
    sendEvent('upgradePlanModal_startFreeTrial_Pressed', { source: this.source });
    this.isUpgradingPlan = true;

    try {
      await API.startFreeTrial();
      await localStorageStores.plan.fetch();
      sendEvent('upgradePlanModal_startFreeTrial_Success', { source: this.source });
      this.onPlanSelected(true);
    } catch (error) {
      sendEvent('upgradePlanModal_startFreeTrial_Error', {
        error: error,
        source: this.source,
      });
      toast.error('Error starting free trial. Please try again.');
    }
    this.isUpgradingPlan = false;
  };

  onSelectPlanClicked = (plan: Plan) => {
    if (plan.maximum_clients < this.clientCount) {
      modal.openAlertModal({
        title: 'Warning',
        body: `The plan you’ve selected supports fewer clients than you currently have (${
          this.clientCount
        }). You’ll need to remove ${
          this.clientCount - plan.maximum_clients
        } clients before you’ll be able to access any of your clients data.`,
        confirmButtonTitle: 'OK, I understand',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => this.confirmSelectedPlan(plan),
      });
    } else {
      this.confirmSelectedPlan(plan);
    }
  };

  onSelectPlan = async (plan: Plan) => {
    sendEvent('upgradePlanModal_planSelected', {
      currentPlan: this.plan,
      selectedPlan: plan.title,
      source: this.source,
    });

    // If the user is on a paid plan, and the plan is active, we can change the plan immediately
    if (this.plan?.type === 'paid_paddle' && this.plan?.status === 'active') {
      this.isUpgradingPlan = true;
      try {
        await API.postChangePlan('change', plan.id);
        await localStorageStores.plan.fetch();
        sendEvent('upgradePlanModal_planSelected_upgradeSuccessful', {
          currentPlan: this.plan,
          selectedPlan: plan.title,
          source: this.source,
        });
      } catch (error) {
        modal.openAlertModal({
          title: 'Unable to update plan',
          body: (error as any)?.message,
          confirmButtonTitle: 'Okay',
        });
        sendEvent('upgradePlanModal_planSelected_upgradeFailed', {
          currentPlan: this.plan,
          selectedPlan: plan.title,
          source: this.source,
        });
      }
      this.isUpgradingPlan = false;
      this.onPlanSelected(true);
    } else {
      // If the user does not have a paid plan, or the plan is not active, we need to start the checkout flow
      if (localStorageStores.account.role !== 'owner') {
        modal.openAlertModal({
          title: 'Contact your owner',
          body: `Only team owners can manage the team’s plan. Please contact ${localStorageStores.team
            .owners()
            .map(a => a.full_name ?? a.username)
            .join(', ')}.`,
          confirmButtonTitle: 'Okay',
        });
        return;
      }
      this.onStartedCheckout?.();
      modal.isUpgradePlanModalOpen = false;
      modal.isFreeTrialEndModalOpen = false;
      router.push(
        `/checkout/?planId=${plan.id}&postPurchasePath=${this.postPurchasePath ?? router.asPath}`,
      );
    }
  };

  private confirmSelectedPlan = (plan: Plan) => {
    const currentPlan = this.plan;
    const selectedPlan = plan;

    if (currentPlan && currentPlan.type === 'paid_paddle') {
      modal.openAlertModal({
        title: 'Warning',
        body: `Are you sure you want to change your plan from ${currentPlan.title} to ${selectedPlan.title}? This will take effect immediately.`,
        confirmButtonTitle: 'Change Plan',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => this.onSelectPlan(plan),
      });
    }

    this.onSelectPlan(plan);
  };
}
