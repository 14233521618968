import { makeAutoObservable } from 'mobx';
import {
  BodyMeasurementUnit,
  DistanceUnit,
  DistanceUnitShort,
  isBodyMeasurementUnit,
  isDistanceUnit,
  isWeightUnit,
  Language,
  UserPreferences as TUserPreferences,
  Weekday,
  WeightUnit,
} from 'hevy-shared';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';

const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'USER_PREFERENCES_LOCAL_STORAGE_KEY';

type WebUserPreferences = Omit<
  TUserPreferences,
  'username' | 'workout_keep_awake' | 'disabled_review_request'
>;

export class CoachUserPreferencesStore {
  private _language?: Language;
  private weight_unit?: WeightUnit;
  private distance_unit?: DistanceUnit;
  private body_measurement_unit?: BodyMeasurementUnit;
  private first_weekday?: Weekday;

  constructor(serverPreferences?: TUserPreferences) {
    if (serverPreferences) {
      this.updateModel(serverPreferences);
    }
    makeAutoObservable(this);
  }

  get language(): Language {
    return this._language || 'en';
  }

  /**
   * @deprecated use coachConfig instead for Hevy Coach
   */
  get weightUnit(): WeightUnit {
    return this.weight_unit || 'kg';
  }

  /**
   * @deprecated use coachConfig instead for Hevy Coach
   */
  get distanceUnit(): DistanceUnit {
    return this.distance_unit || 'kilometers';
  }

  /**
   * @deprecated use coachConfig instead for Hevy Coach
   */
  get distanceUnitShort(): DistanceUnitShort {
    return this.distanceUnit === 'kilometers' ? 'km' : 'mi';
  }

  /**
   * @deprecated use coachConfig instead for Hevy Coach
   */
  get bodyMeasurementUnit(): BodyMeasurementUnit {
    return this.body_measurement_unit || 'cm';
  }

  get firstWeekday(): Weekday {
    return this.first_weekday || 'sunday';
  }

  update = async (p: Omit<TUserPreferences, 'username'>) => {
    if (p.weight_unit && isWeightUnit(p.weight_unit)) {
      this.weight_unit = p.weight_unit;
    }

    if (p.distance_unit && isDistanceUnit(p.distance_unit)) {
      this.distance_unit = p.distance_unit;
    }

    if (p.body_measurement_unit && isBodyMeasurementUnit(p.body_measurement_unit)) {
      this.body_measurement_unit = p.body_measurement_unit;
    }

    await API.updateUserPreferences(p);
  };

  fetch = async () => {
    try {
      const result = await API.getUserPreferences();
      this.updateModel(result.data);
      window.localStorage.setItem(USER_PREFERENCES_LOCAL_STORAGE_KEY, JSON.stringify(result.data));
    } catch (e) {
      captureException(e);
      throw e;
    }
  };

  hydrate = () => {
    const accountJSON = window.localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY);
    if (accountJSON) {
      this.updateModel(JSON.parse(accountJSON));
    }
  };

  clearData = () => {
    window.localStorage.removeItem(USER_PREFERENCES_LOCAL_STORAGE_KEY);
    const emptyUserPreferences: WebUserPreferences = {};
    this.updateModel(emptyUserPreferences);
  };

  private updateModel = (userPreferences: WebUserPreferences) => {
    this._language = userPreferences.language;
    this.weight_unit = userPreferences.weight_unit;
    this.distance_unit = userPreferences.distance_unit;
    this.body_measurement_unit = userPreferences.body_measurement_unit;
    this.first_weekday = userPreferences.first_weekday;
  };
}
