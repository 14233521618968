import { BaseRoutine } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import { ClientProgram } from 'utils/programUtils';
import { modal } from '../ModalManager';
import { humanReadableStringList } from 'utils/pureUtils';
import { doesRoutinesHaveClientCustomExercises } from 'utils/routineUtils';

const MY_PROGRAMS_FOLDER_ID = Number.MAX_SAFE_INTEGER;

interface SelectRoutineViewModelProps {
  onClose: () => void;
  routine: BaseRoutine;
}

export class CopyRoutineViewModel {
  selectedProgramIds: string[] = [];
  searchText: string = '';
  routine: BaseRoutine;
  isCopyingPrograms: boolean = false;
  onClose: () => void;

  constructor({ onClose, routine }: SelectRoutineViewModelProps) {
    makeAutoObservable(this);
    this.onClose = onClose;
    this.routine = routine;
  }

  get programs() {
    return localStorageStores.programs.myPrograms;
  }

  get filteredPrograms() {
    return this.programs.filter(program => {
      return program.title.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  get filteredClientPrograms(): ClientProgram[] {
    return this.filteredPrograms.filter(program => program.client_id !== null) as ClientProgram[];
  }

  get filteredMyPrograms() {
    return this.filteredPrograms.filter(program => !program.client_id);
  }

  get programFolders() {
    return [
      {
        title: localStorageStores.programFolders.DEFAULT_FOLDER_NAME,
        id: MY_PROGRAMS_FOLDER_ID,
        index: 0,
      },
      ...localStorageStores.programFolders.myProgramFolders.map(folder => {
        return {
          title: folder.title,
          id: folder.id ?? MY_PROGRAMS_FOLDER_ID,
          index: folder.index + 1,
        };
      }),
    ]
      .slice()
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      })

      .filter(folder => {
        return this.programsForFolder(folder.id).length > 0;
      });
  }

  clientForProgram = (program: ClientProgram) => {
    return memoryStores.clients.clientForUserId(program.client_id);
  };

  programsForFolder = (folderId: number) => {
    return this.filteredMyPrograms
      .filter(program => {
        return folderId === MY_PROGRAMS_FOLDER_ID
          ? program.folder_id === null
          : program.folder_id === folderId;
      })
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  onSearchTextChanged = (text: string) => {
    this.searchText = text;
  };

  isProgramSelected = (programId: string): boolean => {
    return (
      this.selectedProgramIds.find(otherId => {
        return otherId === programId;
      }) !== undefined
    );
  };

  onSelectProgram = (programId: string) => {
    if (this.isProgramSelected(programId)) {
      this.selectedProgramIds = this.selectedProgramIds.filter(otherId => {
        return otherId !== programId;
      });
    } else {
      this.selectedProgramIds.push(programId);
    }
  };

  get isCopyRoutineToProgramButtonEnabled() {
    return (
      this.selectedProgramIds.length > 0 &&
      !this.isCopyingPrograms &&
      this.filteredPrograms.length > 0
    );
  }

  onCopyRoutinesToProgramClick = async () => {
    if (!this.isCopyRoutineToProgramButtonEnabled) return;

    const doesRoutineHaveClientCustomExercises = this.routine.username
      ? doesRoutinesHaveClientCustomExercises([this.routine], this.routine.username)
      : false;

    const clientNames = this.filteredClientPrograms
      .filter(p => this.selectedProgramIds.find(id => p.id === id))
      .map(p => {
        const client = this.clientForProgram(p);
        return client ? client.fullName || client.username : '';
      })
      .filter(c => c !== '');

    const humanReadableClientNames = humanReadableStringList(clientNames);

    if (clientNames.length > 0) {
      modal.openAlertModal({
        title: `Add this routine to client's existing program?`,
        body: `Are you certain about adding this routine to ${humanReadableClientNames}'s existing program? By proceeding, this routine will be incorporated into their current program.${
          doesRoutineHaveClientCustomExercises
            ? ` This routine also contains your client ${this.routine.username}'s custom exercises, which will NOT be included in the copied routines.`
            : ''
        }`,
        confirmButtonStyle: 'destructive',
        confirmButtonTitle: 'Confirm',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => {
          await this.copyRoutineToPrograms();
          this.onClose();
        },
      });
    } else if (doesRoutineHaveClientCustomExercises) {
      modal.openAlertModal({
        title: `This routine contains exercises created by ${this.routine.username}`,
        body: `If you copy this routine to a template program, these exercises will not be included. Are you sure you want to continue?`,
        confirmButtonTitle: 'OK',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => {
          await this.copyRoutineToPrograms();
          this.onClose();
        },
      });
    } else {
      await this.copyRoutineToPrograms();
      this.onClose();
    }
  };

  copyRoutineToPrograms = async () => {
    const programTitles = this.programs
      .filter(p => this.selectedProgramIds.find(id => p.id === id))
      .map(p => p.title);

    sendEvent('copyRoutineModal_copyRoutineToPrograms_click', {
      programs: programTitles,
      routine: this.routine.title,
    });
    try {
      this.isCopyingPrograms = true;

      for (const programId of this.selectedProgramIds) {
        await API.postAddRoutineToProgram({
          routineId: this.routine.id,
          programId: programId,
        });
      }

      sendEvent('copyRoutineModal_copyRoutineToPrograms_complete', {
        programs: programTitles,
        routine: this.routine.title,
      });
      toast.success('Routine copied to program(s).');
    } catch (error) {
      sendEvent('copyRoutineModal_copyRoutineToPrograms_error', {
        programs: programTitles,
        routine: this.routine.title,
      });
      toast.error("Couldn't copy routine to program(s).");
    } finally {
      this.isCopyingPrograms = false;
      await fireAndForget([memoryStores.libraryRoutines.fetch()]);
    }
  };
}
