import EventEmitter from 'events';
import { makeAutoObservable } from 'mobx';
import { DragEvent } from 'react';

class GlobalDragAndDropManagerClass {
  isDragging = false;
  dropEventEmitter?: EventEmitter;

  constructor() {
    makeAutoObservable(this);

    if (typeof window !== 'undefined') {
      this.dropEventEmitter = new EventEmitter();
    }
  }

  get isDragAndDropEnabled() {
    return this.dropEventEmitter?.listenerCount('drop') ?? 0 > 0;
  }

  handleDragOver = (e: DragEvent) => {
    if (!this.isDragAndDropEnabled) return;
    e.preventDefault();
    this.isDragging = true;
  };

  handleDragLeave = () => {
    if (!this.isDragAndDropEnabled) return;
    this.isDragging = false;
  };

  handleDrop = (e: DragEvent) => {
    if (!this.isDragAndDropEnabled) return;
    e.preventDefault();
    this.isDragging = false;

    // Access the dropped files from the event data transfer
    const files = Array.from(e.dataTransfer.files);

    // You can now work with the dropped files, for example, by displaying them or uploading them.
    this.dropEventEmitter?.emit('drop', files);
  };
}

export const GlobalDragAndDropManager = new GlobalDragAndDropManagerClass();
