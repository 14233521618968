import { CoachAccount } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { Client } from 'types/client';
import { pluralizedClient } from 'utils/pluralize';
import {
  clientMatchesSearch,
  coachMatchesSearch,
  compareClientsAlphabetically,
} from 'utils/pureUtils';

export interface TransferClientsFromCoachModalProps {
  currentCoach: CoachAccount;
  selectedCoach: CoachAccount | undefined;
  onComplete: (
    selectedClientIds: string[],
    keepProgram: boolean,
    targetCoach: CoachAccount,
  ) => void;
  searchValue: string;
  isOpen: boolean;
  selectedClientIds: string[];
  keepAssignedPrograms: boolean;
  currentStep: 'select-clients' | 'select-coach';
}

class TransferClientsFromCoachViewModelClass {
  private _state: TransferClientsFromCoachModalProps | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  open = (
    state: Omit<
      TransferClientsFromCoachModalProps,
      | 'searchValue'
      | 'isOpen'
      | 'selectedClientIds'
      | 'keepAssignedPrograms'
      | 'currentStep'
      | 'selectedCoach'
    >,
  ) => {
    this._state = {
      ...state,
      searchValue: '',
      isOpen: true,
      selectedClientIds: [],
      keepAssignedPrograms: true,
      currentStep: 'select-clients',
      selectedCoach: undefined,
    };
  };

  get currentStep() {
    return this._state?.currentStep || 'select-clients';
  }

  get isCompletedSelectingClients() {
    return this._state?.currentStep === 'select-coach' && this._state?.selectedClientIds.length > 0;
  }

  get filteredClientOptions(): Client[] {
    if (!this._state) return [];
    const clientOptions = memoryStores.clients.clientsForCoachId(this._state.currentCoach.id);
    const { searchValue } = this._state;
    const options = clientOptions
      .filter(client => {
        if (!localStorageStores.team.teamMemberWithId(client.coachId)) return false;
        return clientMatchesSearch(client, searchValue);
      })
      .sort(compareClientsAlphabetically);

    return options;
  }

  get filteredCoachOptions(): CoachAccount[] {
    if (!this._state) return [];

    const coachOptions = localStorageStores.team.members
      .filter(member => member.id !== this._state?.currentCoach.id)
      .filter(member => coachMatchesSearch(member, this._state?.searchValue));
    return coachOptions;
  }

  onSelectedClient = (client: Client) => {
    if (this._state?.selectedClientIds.includes(client.id)) {
      this._state.selectedClientIds = this._state.selectedClientIds.filter(id => id !== client.id);
    } else {
      this._state?.selectedClientIds.push(client.id);
    }
  };

  isClientSelected = (client: Client) => {
    return this._state?.selectedClientIds.includes(client.id) ?? false;
  };

  isCoachSelected = (coach: CoachAccount) => {
    return this._state?.selectedCoach?.id === coach.id;
  };

  onSelectedCoach = (coach: CoachAccount) => {
    if (!this._state) return;
    this._state.selectedCoach = coach;
  };

  clientsForCoach = (coachId: string) => {
    return memoryStores.clients.clientsForCoachId(coachId);
  };

  onContinue = () => {
    if (this._state?.currentStep === 'select-clients') {
      this.onContinueWithSelectedClients();
    } else {
      this.onContinueWithSelectedCoachPressed();
    }
  };

  get title() {
    if (this.currentStep === 'select-clients') {
      return `Select the clients to transfer from ${
        this.currentCoach?.full_name || this.currentCoach?.username
      }`;
    } else {
      return `Select the coach to transfer ${pluralizedClient(
        this._state?.selectedClientIds.length ?? 0,
      )} to`;
    }
  }

  get isSearching() {
    return !!this.searchValue;
  }

  areAllClientsChecked = () => {
    if (!this._state) return false;
    return (
      this._state.selectedClientIds.length === this.filteredClientOptions.length &&
      this.filteredClientOptions.length > 0
    );
  };

  onSelectAllClientsChecked = () => {
    if (!this._state) return;
    if (this._state.selectedClientIds.length === this.filteredClientOptions.length) {
      this._state.selectedClientIds = [];
    } else {
      this._state.selectedClientIds = this.filteredClientOptions.map(client => client.id);
    }
  };

  isContinueEnabled = () => {
    if (this._state?.currentStep === 'select-clients') {
      return !!this.hasSelectedAClient;
    } else {
      return !!this._state?.selectedCoach;
    }
  };

  onContinueWithSelectedCoachPressed = () => {
    if (!this._state || !this._state.selectedCoach) return;
    this._state.onComplete(
      this._state.selectedClientIds,
      this._state.keepAssignedPrograms,
      this._state.selectedCoach,
    );
    this._state = undefined;
  };

  onContinueWithSelectedClients = () => {
    if (!this._state) return;
    this._state.currentStep = 'select-coach';
    this._state.searchValue = '';
  };

  onClose = () => {
    this._state = undefined;
  };

  get keepAssignedProgram() {
    return this._state?.keepAssignedPrograms ?? false;
  }

  get hasSelectedAClient() {
    return this._state?.selectedClientIds.length ?? 0 > 0;
  }

  onChangeKeepAssignedProgram = () => {
    if (!this._state) return;
    this._state.keepAssignedPrograms = !this._state.keepAssignedPrograms;
  };

  get anySelectedClientHasAssignedProgram() {
    return !!this._state?.selectedClientIds.find(client =>
      localStorageStores.programs.programAssignedToClient(client),
    );
  }

  get searchPlaceholder() {
    return this._state?.currentStep === 'select-coach' ? 'Search coaches' : 'Search clients';
  }

  get searchValue() {
    return this._state?.searchValue ?? '';
  }

  onSearchTextUpdated = (value: string) => {
    if (this._state) {
      this._state.searchValue = value;
    }
  };

  get isOpen() {
    return this._state?.isOpen ?? false;
  }

  get currentCoach() {
    return this._state?.currentCoach;
  }
}

export const TransferClientsFromCoachViewModel = new TransferClientsFromCoachViewModelClass();
