import { colors } from 'styleguide/colors';
import { TextSMRegular } from 'styleguide/Texts';
import React, { useRef, useState } from 'react';
import { Spacing } from 'styleguide/spacing';
import { DividerLine } from 'styleguide/DividerLine';
import styled from 'styled-components';
import useIsOnScreen from 'utils/useIsOnScreen';
import fonts from 'styleguide/fonts';
import { FlexRow } from 'styleguide/Row';
import { TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { View } from 'styleguide/View';
import { ProfileImage } from './ProfileImage';

export interface TabDescriptor {
  id: string;
  title: string;
  profilePic?: string;
}

const TabButtonTextRow = styled(FlexRow)`
  padding: ${Spacing.md - Spacing.xs}px;
  padding-top: 0px;
  cursor: pointer;
  gap: ${Spacing.sm}px;
`;

const TabButtonText = styled(TextSMRegular)<{ isActive: boolean }>`
  color: ${props => (props.isActive ? colors.primary500 : colors.neutral900)};
  font-family: ${props => (props.isActive ? fonts.medium.family : fonts.regular.family)};
  font-weight: ${props => (props.isActive ? fonts.medium.weight : fonts.regular.weight)};
  white-space: nowrap;
`;

const TabButtonBottomBorder = styled.div<{ isActive: boolean; isHovering: boolean }>`
  box-sizing: border-box;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${props =>
    props.isActive ? colors.primary500 : props.isHovering ? colors.primary200 : 'transparent'};
`;

const TabButton = ({
  title,
  active,
  profilePic,
  onSelected,
}: {
  title: string;
  active: boolean;
  profilePic?: string;
  onSelected: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <View
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onSelected}
    >
      <TabButtonTextRow>
        {profilePic && <ProfileImage source={profilePic} diameter={20} />}
        <TabButtonText isActive={active}>{title}</TabButtonText>
      </TabButtonTextRow>

      <TabButtonBottomBorder isActive={active} isHovering={isHovering} />
    </View>
  );
};

const TabButtonsContainer = styled.div`
  flex-direction: row;
  display: flex;
  overflow-x: scroll;

  /* Hide the scroll indicator */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome */
  }
`;

const Container = styled.div`
  padding-top: ${Spacing.lg}px;
  padding-bottom: ${Spacing.lg}px;
  position: relative;
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    padding-top: ${Spacing.md}px;
    padding-bottom: ${Spacing.md}px;
  }
`;

const RightMoreTabsIndicator = styled.div`
  position: absolute;
  width: 50px;
  top: 0px;
  right: 0px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, ${colors.neutral50} 100%);
  height: 100%;
  display: grid;
`;
const LeftMoreTabsIndicator = styled.div`
  position: absolute;
  width: 50px;
  top: 0px;
  left: 0px;
  background: linear-gradient(90deg, ${colors.neutral50} 0%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  display: grid;
`;

export const Tabs = ({
  tabs,
  selectedTabIdentifier: selectedTabId,
  onTabClicked,
  containerStyle,
  tabButtonContainerStyle,
}: {
  tabs: TabDescriptor[];
  selectedTabIdentifier: string;
  onTabClicked: (tabId: string) => void;
  containerStyle?: React.CSSProperties;
  tabButtonContainerStyle?: React.CSSProperties;
}) => {
  const rightEdgeOfTabs = useRef<HTMLDivElement>(null);
  const isRightMostTabVisible = useIsOnScreen(rightEdgeOfTabs);
  const leftEdgeOfTabs = useRef<HTMLDivElement>(null);
  const isLeftMostTabVisible = useIsOnScreen(leftEdgeOfTabs);

  return (
    <Container style={containerStyle}>
      {!isLeftMostTabVisible && <LeftMoreTabsIndicator />}
      {!isRightMostTabVisible && <RightMoreTabsIndicator />}
      <TabButtonsContainer style={tabButtonContainerStyle}>
        <div ref={leftEdgeOfTabs} />
        <FlexRow>
          {tabs.map((tab, index) => {
            return (
              <TabButton
                key={index}
                active={tab.id === selectedTabId}
                title={tab.title}
                onSelected={() => onTabClicked(tab.id)}
                profilePic={tab.profilePic}
              />
            );
          })}
        </FlexRow>
        <div ref={rightEdgeOfTabs} />
      </TabButtonsContainer>
      <DividerLine />
    </Container>
  );
};
