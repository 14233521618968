import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextSMMedium } from 'styleguide/Texts';

type ViewRoutineSetIndicator = 'dropset' | 'warmup' | 'failure' | number;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-color: ${colors.neutral200};
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
`;

interface StaticSetIndicatorProps {
  indicator: ViewRoutineSetIndicator;
  style?: CSSProperties;
}

export const StaticSetIndicator = ({ indicator }: StaticSetIndicatorProps) => {
  const { value, textColor } = getIndicatorProperties(indicator);

  return (
    <Container>
      <TextSMMedium
        style={{
          color: textColor,
          userSelect: 'none',
        }}
      >
        {value}
      </TextSMMedium>
    </Container>
  );
};

const getIndicatorProperties = (
  indicator: ViewRoutineSetIndicator,
): { value: string; textColor?: string } => {
  switch (indicator) {
    case 'warmup':
      return {
        value: 'W',
        textColor: '#EEAA00',
      };
    case 'failure':
      return {
        value: 'F',
        textColor: colors.red,
      };
    case 'dropset':
      return {
        value: 'D',
        textColor: '#35BAFF',
      };
    default:
      return {
        value: `${indicator}`,
      };
  }
};
