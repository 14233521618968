import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextAreaAutosize } from 'styleguide/Inputs';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { FlexRow } from 'styleguide/Row';
import { observer } from 'mobx-react-lite';
import { HevyTooltip } from 'styleguide/HevyTooltip';
import { ClipLoader } from 'react-spinners';
import { ChatViewModel } from 'components/Chat/ChatViewModel';

const Container = styled(FlexRow)`
  position: relative;
  margin: ${Spacing.sm}px ${Spacing.md}px;
  gap: ${Spacing.sm}px;
`;

const TextAreaContainer = styled(FlexRow)`
  flex: 1;
  gap: ${Spacing.sm}px;
`;

const StyledTextArea = styled(TextAreaAutosize)`
  display: flex;
  flex: 1;
  background-color: ${colors.neutral100};
  border-radius: ${CornerRadius.xl * 2}px;
  padding: 13px ${Spacing.md}px;
  padding-top: 13px;
  font-size: 14px;
  min-height: 44px;
  resize: none;
  max-height: 150px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid ${colors.neutral200};
`;

interface ChatInputProps {
  style?: CSSProperties;
  /**
   * Need the entire vm here to tuck the state of unsentMessage to
   * ensure we only re-render the input when the unsentMessage changes.
   */
  chatVm: ChatViewModel;
}

const Label = styled.label`
  color: ${colors.primary500};
  cursor: pointer;
`;

export const ChatInput = observer(({ style, chatVm }: ChatInputProps) => {
  const [isHoveringSendButton, setIsHoveringSendButton] = useState(false);
  const [isHoveringAttachButton, setIsHoveringAttachButton] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Container style={style}>
      <TextAreaContainer>
        <Label htmlFor="file-upload">
          <HevyTooltip
            enabled={!chatVm.isFileBeingProcessed && !chatVm.isSendButtonEnabled}
            direction="top"
            text={'Attach image/video'}
          >
            <ActionButtonContainer
              onMouseEnter={() => setIsHoveringAttachButton(true)}
              onMouseLeave={() => setIsHoveringAttachButton(false)}
              style={{
                backgroundColor:
                  isHoveringAttachButton && !chatVm.isSendButtonEnabled
                    ? colors.neutral50
                    : colors.white,
                cursor:
                  chatVm.isFileBeingProcessed || chatVm.isSendButtonEnabled ? 'default' : 'pointer',
              }}
            >
              <input
                disabled={chatVm.isFileBeingProcessed || chatVm.isSendButtonEnabled}
                ref={fileInputRef}
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg, video/mp4, image/jpg"
                multiple={false}
                onChange={e => {
                  const file = e?.target.files?.[0];
                  if (file) {
                    chatVm.onSendFile(file);
                  }
                  // Clear the selected value so onChange fires if the user selects the same filea again
                  if (!!fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }}
              />
              {chatVm.isFileBeingProcessed ? (
                <>
                  <ClipLoader size={15} />
                </>
              ) : (
                <CustomizableIcon
                  type="image-plus"
                  tint={chatVm.isSendButtonEnabled ? colors.neutral300 : colors.neutral400}
                />
              )}
            </ActionButtonContainer>
          </HevyTooltip>
        </Label>

        <StyledTextArea
          onKeyPress={event => {
            if (event.key === 'Enter' && !event.shiftKey) {
              // Prevent dropping a line on enter press, since we'll send the message.
              event.preventDefault();
              chatVm.onSend();
            }
          }}
          placeholder="Write a message..."
          value={chatVm.unsentMessage}
          onChange={e => {
            chatVm.updateUnsentMessage(e.currentTarget.value);
          }}
        />
        <ActionButtonContainer
          onMouseEnter={() => setIsHoveringSendButton(true)}
          onMouseLeave={() => setIsHoveringSendButton(false)}
          style={{
            backgroundColor: chatVm.isSendButtonEnabled
              ? isHoveringSendButton
                ? colors.primary600
                : colors.primary500
              : colors.white,
            cursor: chatVm.isSendButtonEnabled ? 'pointer' : 'default',
          }}
        >
          <View onClick={chatVm.onSend}>
            <CustomizableIcon
              type="send"
              tint={chatVm.isSendButtonEnabled ? colors.white : colors.neutral400}
            />
          </View>
        </ActionButtonContainer>
      </TextAreaContainer>
    </Container>
  );
});
