import React from 'react';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { modal } from '../ModalManager';
import { View } from 'styleguide/View';
import { InviteError } from './types';
import { TextSMMedium } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';

export const MAXIMUM_CLIENTS_TO_INVITE = 20;
export const MAXIUMUM_COACHES_TO_INVITE = 20;

export const promptUserMaxInvitesReached = () => {
  modal.openAlertModal({
    title: 'Invite limit reached',
    body: `You can send a maximum of ${MAXIMUM_CLIENTS_TO_INVITE} invitations at once.`,
    confirmButtonTitle: 'Okay',
  });
};

export const promptUserPlanLimitReached = () => {
  modal.openAlertModal({
    title: 'Invite limit reached',
    body: `Your plan only allows for ${PlanAggregator.numberOfInvitesLeft} more ${
      PlanAggregator.numberOfInvitesLeft === 1 ? 'client' : 'clients'
    } to be added to your account. Upgrade your plan or remove clients to send additional invites.`,
    confirmButtonTitle: 'Upgrade Plan',
    cancelButtonTitle: 'Cancel',
    handleAlertConfirm: async () => {
      modal.openUpgradePlanModal({ source: 'inviteClientModal' });
    },
  });
};

export const openAlertModalForFailedInvites = (inviteErrors: InviteError[]) => {
  if (inviteErrors.length === 0) return;

  modal.openAlertModal({
    title: 'Some invitations failed to send',
    renderComponent: () => {
      return (
        <View>
          <>
            {inviteErrors.map((errorContext, index) => {
              const inviteeDescription =
                errorContext.invitee.type === 'email'
                  ? errorContext.invitee.email
                  : errorContext.invitee.type === 'user'
                  ? errorContext.invitee.userData.username
                  : 'unknown ';
              return (
                <View key={index}>
                  <TextSMMedium
                    style={{
                      color: colors.error500,
                      paddingTop: Spacing.sm,
                      display: 'inline',
                      fontWeight: '600',
                    }}
                  >
                    {inviteeDescription}:{' '}
                    <TextSMMedium
                      style={{
                        color: colors.error500,
                        paddingTop: Spacing.sm,
                        display: 'inline',
                      }}
                    >
                      {errorContext.error}
                    </TextSMMedium>
                  </TextSMMedium>
                </View>
              );
            })}
          </>
        </View>
      );
    },
    confirmButtonTitle: 'Okay',
  });
};
