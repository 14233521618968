export const pluralize = (count: number, singular: string, plural: string) => {
  return `${count} ${count === 1 ? singular : plural}`;
};

export const pluralizedClient = (count: number) => {
  return `${count} ${count === 1 ? 'client' : 'clients'}`;
};

export const pluralizedCoach = (count: number) => {
  return `${count} ${count === 1 ? 'coach' : 'coaches'}`;
};
