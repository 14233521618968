import { ProfileImage } from 'components/ProfileImage';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextAreaAutosize } from 'styleguide/Inputs';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextSMRegular } from 'styleguide/Texts';
import { MAX_COMMENT_LENGTH } from 'hevy-shared';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const Container = styled.div`
  position: relative;
  display: flex;
  margin: ${Spacing.md}px 0;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  margin-right: ${Spacing.md}px;
  align-items: center;
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const StyledTextArea = styled(TextAreaAutosize)`
  display: flex;
  flex: 1;
  background-color: ${colors.neutral100};
  border-radius: ${CornerRadius.sm}px;
  padding: ${Spacing.md}px;
  /* min-height fixes firefox bug where bottom padding is ignored */
  min-height: ${Spacing.md}px;
`;

const PostButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${Spacing.md}px;
  width: 30px;
`;

interface CommentInputProps {
  profilePic?: string;
  onSend: (comment: string) => Promise<void>;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  style?: CSSProperties;
}

export const CommentInput = ({ profilePic, onSend, inputRef, style }: CommentInputProps) => {
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendComment = async () => {
    setIsLoading(true);
    try {
      await onSend(comment);
      setComment('');
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container style={style}>
      <ProfileImageContainer>
        <ProfileImage source={profilePic} diameter={40} />
      </ProfileImageContainer>
      <TextAreaContainer>
        <StyledTextArea
          style={{ resize: 'none' }}
          ref={inputRef}
          onKeyPress={event => {
            if (event.key === 'Enter' && !event.shiftKey) {
              // Prevent dropping a line on enter press, since we'll send the comment.
              event.preventDefault();

              if (comment) {
                sendComment();
              }
            }
          }}
          placeholder="Write a comment"
          disabled={isLoading}
          value={comment}
          onChange={e => {
            const comment = e.currentTarget.value;
            if (comment.length > MAX_COMMENT_LENGTH) return;

            setComment(comment);
          }}
        />
        <PostButtonContainer>
          {isLoading ? (
            <MediumHevyActivityIndicator />
          ) : (
            <TextSMRegular
              style={{
                cursor: comment !== '' ? 'pointer' : 'default',
                color: comment !== '' ? colors.primary500 : colors.neutral400,
              }}
              onClick={() => {
                if (comment) {
                  sendComment();
                }
              }}
            >
              Post
            </TextSMRegular>
          )}
        </PostButtonContainer>
      </TextAreaContainer>
    </Container>
  );
};
