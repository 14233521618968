export const zIndexes = {
  aboveSurface: 1, //for things like tooltips, empty states, etc
  chatWidgets: 2,
  menu: 3,
  modal: 1000,
  modalOverlay: 1001, //for modals that can be opened on top of other modals
  videoModal: 1002,
  alertModal: 1003,
  mobileNavBar: 1004,
  dragAndDropOverlay: 1005,
};
