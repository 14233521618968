import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';

class ClientAggregatorClass {
  constructor() {
    makeAutoObservable(this);
  }

  get clientsWithoutProgram() {
    return memoryStores.clients.allClients.filter(client => {
      return !localStorageStores.programs.programAssignedToClient(client.id);
    });
  }

  get clientsAssignedAProgram() {
    return memoryStores.clients.allClients.filter(client => {
      return !!localStorageStores.programs.programAssignedToClient(client.id);
    });
  }
}

export const ClientAggregator = new ClientAggregatorClass();
