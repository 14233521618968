import { log } from './log';
import { isProduction } from './misc';
import Hotjar from '@hotjar/browser';

const siteId = 3513480;
const hotjarVersion = 6;

export const initHotjar = () => {
  Hotjar.init(siteId, hotjarVersion);
};

export const setHotjarIdentity = (identityProperties: any, userId: string) => {
  if (!isProduction()) {
    log(` 👣 Hotjar - Setting Identity for ${userId}: ${JSON.stringify(identityProperties)}`);
    return;
  }
  Hotjar.identify(userId, identityProperties);
};

export const hotjarEvent = (event: string) => {
  Hotjar.event(event);
};

export const startHotjarRecording = () => {
  // Hotjar doesn't give a lot of options for filtering besides IP and a particular event, so we use this event
  // to start Hotjar session recording
  hotjarEvent('non_employee_app_launch_complete');
};
