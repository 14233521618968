import { ExerciseTemplate } from 'hevy-shared';
import { localStorageStores } from 'state/localStorageStores';
import { Client } from 'types/client';

class ExerciseTemplateAggregatorClass {
  getExerciseTemplate(
    exerciseTemplateId: string,
    parentTemplateId?: string,
    client?: Client,
  ): ExerciseTemplate | undefined {
    return parentTemplateId
      ? localStorageStores.exerciseTemplates.getExercise(parentTemplateId)
      : localStorageStores.exerciseTemplates.getExercise(exerciseTemplateId) ??
          (client !== undefined
            ? client.customExercises.find(e => e.id === exerciseTemplateId)
            : undefined);
  }
}

export const ExerciseTemplateAggregator = new ExerciseTemplateAggregatorClass();
