import { CoachRole } from 'hevy-shared';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { SelectableCard } from 'components/SelectableCard';
import { ModalHeader } from './Components/ModalHeader';
import { TextSMRegular } from 'styleguide/Texts';

const Container = styled(View)`
  width: 600px;
  border-radius: ${CornerRadius.md}px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.lg}px;
`;

export interface CoachRoleModalProps {
  initialRole: CoachRole;
  closeModal: () => void;
  isOpen: boolean;
  onSavePressed: (selectedRole: CoachRole) => void;
  coachName: string;
}

export const CoachRoleModal = ({
  initialRole,
  closeModal,
  isOpen,
  onSavePressed,
  coachName,
}: CoachRoleModalProps) => {
  const [selectedRole, setSelectedRole] = useState(initialRole);
  useEffect(() => {
    setSelectedRole(initialRole);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Container>
        <ModalHeader text={`Change role for ${coachName}`} onClose={closeModal} />
        <DividerLine />
        <View style={{ padding: Spacing.lg, paddingTop: Spacing.md, gap: Spacing.md }}>
          <TextSMRegular>
            Adjust this member's level of access and actions on the platform.
          </TextSMRegular>

          <SelectableCard
            isSelected={selectedRole === 'member'}
            onSelected={() => setSelectedRole('member')}
            title="Member"
            description="Members can invite and coach their clients but they cannot see other clients inside the Team."
          />
          <SelectableCard
            isSelected={selectedRole === 'admin'}
            onSelected={() => setSelectedRole('admin')}
            title="Admin"
            description="Admins can invite and coach their clients, view all clients within the team, reassign clients, and edit all programs."
          />
        </View>
        <DividerLine />
        <LowerContainer>
          <SecondaryButton title="Cancel" onClick={closeModal} />
          <PrimaryButton
            title="Change Role"
            onClick={() => {
              onSavePressed(selectedRole);
              closeModal();
            }}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
