import { sendChatWidgetEvent } from 'components/Chat/ChatsWidgetEvents';
import { Conversation } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { ActiveConversation } from 'types/chat';
import { Client } from 'types/client';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import { latestMessageInConversation } from 'utils/chatUtils';

class ChatAggregatorClass {
  constructor() {
    makeAutoObservable(this);
  }

  get activeConversations(): ActiveConversation[] {
    // Filter out conversations with users that aren't clients (for coaches that are also
    // clients with conversations with other coaches)
    let clientIds = memoryStores.clients.allClients.map((client: Client) => {
      return client.id;
    });

    // Filter out conversations with clients that have chat disabled
    clientIds = clientIds.filter(id => {
      return memoryStores.clients.clientForUserId(id)?.configuration?.is_chat_enabled === true;
    });

    const safeConversations = localStorageStores.chats.chatData.conversations.slice().filter(c => {
      return !!c.user_ids.find(userId => {
        return clientIds.includes(userId);
      });
    });

    const sortedConversations = safeConversations.sort((a, b) => {
      const latestMessageA = latestMessageInConversation(
        a.conversation_id,
        localStorageStores.chats.messages,
      );

      const latestMessageB = latestMessageInConversation(
        b.conversation_id,
        localStorageStores.chats.messages,
      );

      if (!latestMessageA && !latestMessageB) {
        return 0;
      }

      return (
        new Date(latestMessageB?.created_at ?? 0).getTime() -
        new Date(latestMessageA?.created_at ?? 0).getTime()
      );
    });

    const conversationWithUnreadMessageCounts = sortedConversations.map(c => {
      return {
        ...c,
        unreadMessages: this.unreadMessageCountForConversation(c.conversation_id),
      };
    });

    return conversationWithUnreadMessageCounts;
  }

  get totalUnreadMessages(): number {
    let total = 0;
    for (const conversation of localStorageStores.chats.chatData.conversations) {
      total += this.unreadMessageCountForConversation(conversation.conversation_id);
    }
    return total;
  }

  unreadMessageCountForConversation = (conversationId: string): number => {
    const conversation = localStorageStores.chats.chatData.conversations.find(
      c => c.conversation_id === conversationId,
    );
    if (!conversation) {
      return 0;
    }

    return localStorageStores.chats.messages.filter(
      message =>
        message.conversation_id === conversationId &&
        message.sender_user_id !== localStorageStores.account.id &&
        (!conversation.lastReadMessageId ||
          Number(message.id) > Number(conversation.lastReadMessageId)),
    ).length;
  };

  clientForConversation = (conversation: Conversation): Client | undefined => {
    for (const userId of conversation.user_ids) {
      if (userId !== localStorageStores.account.id) {
        return memoryStores.clients.clientForUserId(userId);
      }
    }
  };

  loadOlderMessagesForConversation = async (conversationId: string) => {
    localStorageStores.chats.loadOlderMessagesForConversation(
      conversationId,
      this.activeConversations,
    );
  };

  loadLatestMessagesForConversation = async (conversationId: string) => {
    localStorageStores.chats.loadLatestMessagesForConversation(
      conversationId,
      this.activeConversations,
    );
  };
  syncDeletedMessagesForConversation = async (conversationId: string) => {
    localStorageStores.chats.syncDeletedMessagesForConversation(conversationId);
  };

  onSendMessage = async (message: string, conversationId: string) => {
    localStorageStores.chats.onSend(message, conversationId, localStorageStores.account.id);
  };

  createNewConversation = async (client: Client): Promise<string> => {
    return await localStorageStores.chats.createNewConversation(
      client,
      localStorageStores.account.id,
    );
  };

  openChatWidgetForClient = async (
    client: Client,
    createConversationIfNeeded: boolean = false,
    source: string,
  ) => {
    let conversationId = localStorageStores.chats.conversationForClientId(client.id)
      ?.conversation_id;

    sendEvent('chatAggregator_openChatWidgetForClient', { source: source });

    if (!conversationId && createConversationIfNeeded) {
      // If chat is disabled for the client, enable it
      if (client.configuration?.is_chat_enabled === false) {
        sendEvent('chatAggregator_enableChatForClient', { source: source });
        await API.updateClientConfiguration([
          {
            client_id: client.id,
            config: {
              is_chat_enabled: true,
              are_notifications_enabled: client.configuration?.are_notifications_enabled,
            },
          },
        ]);
        await fireAndForget([memoryStores.clients.fetch(), localStorageStores.chats.reset()]);
      }

      await ChatAggregator.createNewConversation(client);
      sendEvent('chatAggregator_createNewConversation', { source: source });
      conversationId = localStorageStores.chats.conversationForClientId(client.id)?.conversation_id;
    }

    if (!conversationId) {
      throw new Error('InvalidConversationId');
    }

    sendChatWidgetEvent({
      event_type: 'open_conversation',
      conversation_id: conversationId,
    });
  };
}

export const ChatAggregator = new ChatAggregatorClass();
