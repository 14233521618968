import dayjs from 'dayjs';
import React, { useRef, useEffect } from 'react';
import { localStorageStores } from 'state/localStorageStores';
import { TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Client } from 'types/client';
import useIsOnScreen from 'utils/useIsOnScreen';
import { messageDate, messageTimestamp } from '../utils';
import { ImageChatMessage } from './ImageMessage';
import { TextChatBubble } from './TextMessages';
import { VideoChatMessage } from './VideoMessage';
import { ProfileImage } from 'components/ProfileImage';
import styled from 'styled-components';
import { Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { TextPill } from 'styleguide/TextPill';
import { FlexRow } from 'styleguide/Row';
import { Message } from 'hevy-shared';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const Body = styled(View)`
  overflow-y: auto;
  padding: ${Spacing.md}px;
  gap: ${Spacing.xs}px;
  flex-direction: column-reverse;
  height: 100%;
`;

const EmptyStateContainer = styled(View)`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const NoMessagesView = ({ client, style }: { client: Client; style?: React.CSSProperties }) => {
  return (
    <EmptyStateContainer style={style}>
      <ProfileImage diameter={56} source={client.profilePic} style={{ marginBottom: Spacing.md }} />
      <TextSMMedium style={{ textAlign: 'center', marginBottom: Spacing.sm }}>
        Start a conversation with {client.fullName || client.username}
      </TextSMMedium>
    </EmptyStateContainer>
  );
};

interface DateCell {
  type: 'date';
  date: string;
}

type MessageViewCell = DateCell | Message;

export const ClientChatMessagesView = ({
  client,
  messages,
  source,
  isFetchingOlderMessages,
  onScrollToTop,
  setMessagesContainerRef,
  style,
  textBubbleMaxWidth,
  imageBeingUploaded,
  videoBeingUploaded,
  onDeleteMessageClick,
}: {
  client: Client;
  messages: Message[];
  source: 'widget' | 'screen';
  isFetchingOlderMessages: boolean;
  onScrollToTop: () => void;
  setMessagesContainerRef: (ref: HTMLDivElement) => void;
  style?: React.CSSProperties;
  textBubbleMaxWidth?: number;
  imageBeingUploaded?: string;
  videoBeingUploaded?: string;
  onDeleteMessageClick: (messageId: string) => void;
}) => {
  const topOfChatRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = React.useRef<HTMLDivElement | null>(null);
  const isTopOfChatVisible = useIsOnScreen(topOfChatRef);

  const ImageUploadingMessage = () => {
    if (!imageBeingUploaded) {
      return <></>;
    }
    return (
      <ImageChatMessage
        imageUrl={imageBeingUploaded}
        isSender={true}
        timestamp={messageTimestamp(dayjs(new Date()))}
        isLoading={true}
        source={source}
        isLastMessageOfMessageBlock={false}
      />
    );
  };

  const VideoUploadingMessage = () => {
    if (!videoBeingUploaded) {
      return <></>;
    }
    return (
      <VideoChatMessage
        videoUrl={videoBeingUploaded}
        isSender={true}
        timestamp={messageTimestamp(dayjs(new Date()))}
        isLoading={true}
        source={source}
        isLastMessageOfMessageBlock={false}
      />
    );
  };

  useEffect(() => {
    if (isTopOfChatVisible) {
      onScrollToTop();
    }
  }, [isTopOfChatVisible]);

  const datesGroups = messages.reduce((acc, message) => {
    const date = dayjs(message.created_at).format('MMM D, YYYY');
    if (acc[date]) {
      acc[date].push(message);
    } else {
      acc[date] = [message];
    }
    return acc;
  }, {} as { [key: string]: Message[] });

  const messageViewCells = Object.keys(datesGroups).reduce((acc, date) => {
    acc.push({ type: 'date', date });
    acc.push(...datesGroups[date]);
    return acc;
  }, [] as MessageViewCell[]);

  return (
    <Body
      ref={(r: any) => {
        messagesContainerRef.current = r;
        setMessagesContainerRef(r);
      }}
      style={style}
    >
      <ImageUploadingMessage />
      <VideoUploadingMessage />
      {messageViewCells.length !== 0 ? (
        <>
          {messageViewCells
            //.slice() because we don't want to change the order of the messages array
            .slice()
            //.reverse() because we're laying out backwards
            .reverse()
            .map((message, index, array) => {
              const previousCell = array[index + 1];
              const nextCell = array[index - 1];
              const isLastMessageOfMessageBlock =
                nextCell?.type === 'date' ||
                (message.type !== 'date' && message.sender_user_id !== nextCell?.sender_user_id);

              switch (message.type) {
                case 'text':
                  return (
                    <TextChatBubble
                      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
                      isFirstMessageOfMessageBlock={
                        previousCell?.type === 'date' ||
                        message.sender_user_id !== previousCell?.sender_user_id
                      }
                      key={message.id ?? index}
                      message={message.text}
                      isSender={message.sender_user_id === localStorageStores.account.id}
                      profilePic={client.profilePic}
                      timestamp={messageTimestamp(dayjs(message.created_at))}
                      maxWidth={textBubbleMaxWidth}
                      onDeleteMessageClick={() => onDeleteMessageClick(message.id)}
                      source={source}
                    />
                  );
                case 'image':
                  return (
                    <ImageChatMessage
                      key={message.id}
                      imageUrl={message.image_url}
                      isSender={message.sender_user_id === localStorageStores.account.id}
                      timestamp={messageTimestamp(dayjs(message.created_at))}
                      clientProfilePic={client?.profilePic}
                      isLoading={false}
                      onDeleteMessageClick={() => onDeleteMessageClick(message.id)}
                      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
                      source={source}
                    />
                  );
                case 'video':
                  return (
                    <VideoChatMessage
                      key={message.id}
                      videoUrl={message.video_url}
                      isSender={message.sender_user_id === localStorageStores.account.id}
                      timestamp={messageTimestamp(dayjs(message.created_at))}
                      clientProfilePic={client?.profilePic}
                      isLoading={false}
                      onDeleteMessageClick={() => onDeleteMessageClick(message.id)}
                      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
                      source={source}
                    />
                  );
                case 'date':
                  const dateCell = message as DateCell;
                  return (
                    <FlexRow
                      style={{ justifyContent: 'center', paddingBottom: Spacing.sm }}
                      key={message.date}
                    >
                      <TextPill
                        text={messageDate(dateCell.date)}
                        backgroundColor={colors.neutral100}
                        textColor={colors.neutral900}
                      ></TextPill>
                    </FlexRow>
                  );
              }
            })}
          <div id={'topOfChat'} ref={topOfChatRef} />
          {isFetchingOlderMessages && (
            <View style={{ alignItems: 'center', opacity: isFetchingOlderMessages ? 1.0 : 0.0 }}>
              <MediumHevyActivityIndicator />
            </View>
          )}
        </>
      ) : (
        <NoMessagesView client={client} />
      )}
    </Body>
  );
};
