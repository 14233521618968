import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';

const BootstrapLoadingContainer = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
  padding-top: ${Spacing.lg}px;
`;

export const FullPageCenteredLoadingIndicator = () => {
  return (
    <BootstrapLoadingContainer>
      <View style={{ alignSelf: 'center', flex: 1, justifyContent: 'center' }}>
        <ClipLoader />
      </View>
    </BootstrapLoadingContainer>
  );
};
