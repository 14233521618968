import { Modal } from 'styleguide/Modal';
import React from 'react';
import styled from 'styled-components';
import { zIndexes } from 'styleguide/zIndex';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { colors } from 'styleguide/colors';
import { ModalHeader } from './Components/ModalHeader';
import { CornerRadius } from 'styleguide/spacing';

const Container = styled(View)`
  align-items: center;
  overflow-y: auto;
  align-items: center;
`;

export interface GenericModalProps {
  isOpen: boolean;
  onClose: () => void;
  renderContent: () => React.ReactNode;
}

export const GenericTitleModalView = ({
  onClose,
  title,
  children,
}: {
  onClose: () => void;
  title: String;
  children: React.ReactNode;
}) => {
  return (
    <View style={{ backgroundColor: 'white', borderRadius: CornerRadius.md }}>
      <ModalHeader text={title} onClose={onClose} />
      <DividerLine />
      {children}
    </View>
  );
};

export const GenericLoadingModalView = () => {
  return <MediumHevyActivityIndicator overrideColor={colors.white} />;
};

export const GenericModal = ({ isOpen, onClose, renderContent: content }: GenericModalProps) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={{ zIndex: zIndexes.videoModal }}
      contentStyle={{
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
      }}
    >
      <Container>{content()}</Container>
    </Modal>
  );
};
