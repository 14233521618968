import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

interface Props {
  data: CategoricalChartState;
  dataMinValue: number;
  dataMaxValue: number;
  chartHeight: number;
  gridHeight: number;
  tooltipWidth: number;
  offset: number;
  setposData: ({ x, y }: { x: number; y: number }) => void;
}

export const calculateTooltipPosition = ({
  data,
  dataMinValue,
  dataMaxValue,
  chartHeight,
  gridHeight,
  tooltipWidth,
  offset,
  setposData,
}: Props) => {
  if (data.activeCoordinate && data.activePayload?.[0]?.payload.value !== null) {
    const activePayloadValue = data.activePayload?.[0]?.payload.value;

    // Okay, for some reason recharts doesn't give us the "y" value of the
    // currently active POINT (it gives us the mouse location), so
    // let's do some math to find where the point is:
    const percentOfChartHeight =
      dataMaxValue === dataMinValue
        ? 0.5
        : (activePayloadValue - dataMinValue) / (dataMaxValue - dataMinValue);

    // The "y = 0" corresponds to the top of the chart, so we need to
    // flip the percentage to get the correct position (e.g. 0% should be at the bottom of the chart, not the top)
    // We also need an offset to account for the height of the tooltip and some margin
    // Lastly we need to account for the fact that the chart is centered in the grid
    // The grid size is random so we need to inspect the page to find its height
    // And then pass it to this function to account for the padding between the chart and the grid

    const y = gridHeight * (1 - percentOfChartHeight) - offset + (chartHeight - gridHeight) / 2;

    setposData({ x: data.activeCoordinate.x - tooltipWidth / 2, y: y });
  }
};

export const calculateFocusedBar = ({
  data,
  setFocusedBar,
}: {
  data: CategoricalChartState;
  setFocusedBar: (value: number | undefined) => void;
}) => {
  if (data.isTooltipActive) {
    setFocusedBar(data.activeTooltipIndex);
  } else {
    setFocusedBar(undefined);
  }
};
