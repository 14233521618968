import React from 'react';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import RSelect, { DropdownIndicatorProps, Props, SingleValueProps, components } from 'react-select';
import { colors } from 'styleguide/colors';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { HevyTooltip } from 'styleguide/HevyTooltip';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import fonts from 'styleguide/fonts';
import { TextSMMedium } from 'styleguide/Texts';
import { FlexRow } from 'styleguide/Row';
import { zIndexes } from 'styleguide/zIndex';
import { motion } from 'framer-motion';

export const isUpdate = (x: unknown): x is { label: string; value: string } => {
  return !!(x as any)?.label && !!(x as any)?.value;
};

interface CustomProps extends Props {
  overrideBorderColor?: string;
  tooltipText?: string;
  backgroundColor?: string;
}

interface CustomSelectProps extends CustomProps {
  icon?: React.ReactNode;
  showRecommendedBadge?: boolean;
}

interface CustomMultiselectProps extends CustomProps {
  placeholder?: string;
}

export const Multiselect = observer((props: CustomMultiselectProps) => {
  const { tooltipText } = props;
  return (
    <RSelect
      isMulti={true}
      isSearchable={false}
      placeholder={props.placeholder}
      styles={{
        placeholder: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral400 : undefined },
        }),
        control: (styles, { isDisabled }) => ({
          ...styles,
          ...controlStyle(
            'multiselect',
            props.overrideBorderColor,
            isDisabled,
            props.backgroundColor,
          ),
        }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        singleValue: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral400 : colors.neutral900 },
        }),
        multiValue: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral400 : colors.neutral900 },
        }),
        dropdownIndicator: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral150 : colors.neutral900 },
        }),
        menu: styles => ({
          ...styles,
          ...menuStyle,
        }),
      }}
      components={
        props.isDisabled
          ? {
              DropdownIndicator: props => {
                return LockDropdownIndicator({ ...props, tooltipText });
              },
            }
          : {
              DropdownIndicator: props => {
                return NormalDropdownIndicator({ ...props });
              },
            }
      }
      {...props}
    />
  );
});

export const Select = observer((props: CustomSelectProps) => {
  const { tooltipText, icon } = props;
  return (
    <RSelect
      isMulti={false}
      isSearchable={false}
      styles={{
        placeholder: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral400 : undefined },
        }),
        control: (styles, { isDisabled }) => ({
          ...styles,
          ...controlStyle('select', props.overrideBorderColor, isDisabled, props.backgroundColor),
        }),
        singleValue: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral400 : colors.neutral900 },
        }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: (styles, { isDisabled }) => ({
          ...styles,
          ...{ color: isDisabled ? colors.neutral150 : colors.neutral900 },
        }),
        option: (styles, state) => ({
          ...styles,
          ...{ color: state.isSelected ? colors.white : colors.neutral900 },
        }),
        menu: styles => ({
          ...styles,
          ...menuStyle,
        }),
      }}
      components={
        props.isDisabled
          ? {
              DropdownIndicator: props => {
                return LockDropdownIndicator({ ...props, tooltipText });
              },
            }
          : props.showRecommendedBadge
          ? {
              DropdownIndicator: props => {
                return NormalDropdownIndicator({ ...props });
              },
              IndicatorsContainer: () => {
                return (
                  <>
                    <RecommendedBadge />
                    <CustomizableIcon
                      type="chevron-down"
                      tint={colors.neutral900}
                      style={{ paddingRight: Spacing.md }}
                    ></CustomizableIcon>
                  </>
                );
              },
            }
          : {
              DropdownIndicator: props => {
                return NormalDropdownIndicator({ ...props });
              },

              SingleValue: props => {
                return <CustomSingleValue {...props} icon={icon} />;
              },
            }
      }
      {...props}
    />
  );
});

const LockDropdownIndicator = (props: DropdownIndicatorProps & { tooltipText?: string }) => {
  return (
    <components.DropdownIndicator {...props}>
      <HevyTooltip enabled={!!props.tooltipText} direction="left" text={props.tooltipText}>
        <View style={{ pointerEvents: 'auto', padding: Spacing.xs }}>
          <CustomizableIcon type="lock" tint={colors.neutral300}></CustomizableIcon>
        </View>
      </HevyTooltip>
    </components.DropdownIndicator>
  );
};

const NormalDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <View style={{ paddingRight: Spacing.sm }}>
        <motion.div
          initial={{ scale: 1 }}
          animate={{
            rotate: props.selectProps.menuIsOpen ? '180deg' : '0deg',
            offsetRotate: 'auto',
            scale: 1,
          }}
        >
          <CustomizableIcon type="chevron-down" tint={colors.neutral900}></CustomizableIcon>
        </motion.div>
      </View>
    </components.DropdownIndicator>
  );
};

const RecommendedBadge = () => {
  return (
    <View
      style={{
        backgroundColor: colors.primary50,
        borderRadius: CornerRadius.xs,
        padding: Spacing.xs / 2,
        paddingLeft: Spacing.xs,
        paddingRight: Spacing.xs,
        marginLeft: Spacing.sm,
        marginRight: Spacing.sm,
      }}
    >
      <TextSMMedium style={{ color: colors.primary500 }}>Recommended</TextSMMedium>
    </View>
  );
};

const CustomSingleValue = (props: SingleValueProps & { icon?: React.ReactNode }) => {
  return (
    <components.SingleValue {...props}>
      <FlexRow style={{ pointerEvents: 'auto', padding: Spacing.xs, gap: Spacing.sm }}>
        {!!props.icon && props.icon}
        {props.children}
      </FlexRow>
    </components.SingleValue>
  );
};

const controlStyle = (
  type: 'multiselect' | 'select',
  overrideBorderColor?: string,
  isDisabled?: boolean,
  backgroundColor?: string,
): CSSProperties => {
  return {
    height: type === 'select' ? '40px' : 'auto',
    minHeight: type === 'multiselect' ? '40px' : 'auto',
    borderColor: overrideBorderColor ?? colors.neutral150,
    backgroundColor: isDisabled ? 'transparent' : backgroundColor,
    color: isDisabled ? colors.neutral400 : undefined,
    fontFamily: fonts.regular.family,
    fontWeight: fonts.regular.weight,
    fontSize: '14px',
    cursor: 'pointer',
  };
};

const menuStyle = {
  zIndex: zIndexes.menu,
  backgroundColor: colors.neutral50,
  fontSize: 14,
  fontWeight: 400,
  fontFamily: fonts.regular.family,
};
