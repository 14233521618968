import React, { CSSProperties, useState } from 'react';
import { ExerciseTemplate } from 'hevy-shared';
import { Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { muscleGroupToHuman } from 'utils/exerciseUtils';
import { FlexRow } from 'styleguide/Row';
import { CircleView } from 'styleguide/CircleView';
import { CustomBadge } from 'components/CustomBadge';

const ExerciseTemplateContainer = styled(FlexRow)`
  padding: ${Spacing.xs}px;
  padding-left: ${Spacing.md}px;
  gap: ${Spacing.sm}px;
  cursor: pointer;
`;

const ExerciseImageContainer = styled(View)`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  border-style: solid;
  border-color: ${colors.neutral150};
  border-width: 1px;
  overflow: hidden;
`;

const ThumbnailPlaceholder = styled.img`
  content: url(/images/exercise_thumbnail_placeholder.png);
  width: 48px;
  height: 48px;
`;

export const ExerciseCell = (props: {
  template: ExerciseTemplate;
  hidePlusIcons?: boolean;
  selected?: boolean;
  style?: CSSProperties;
  onAddExercise: (template: ExerciseTemplate) => void;
}) => {
  const [didJustSelectThis, setDidJustSelectThis] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <ExerciseTemplateContainer
      style={{
        backgroundColor:
          didJustSelectThis || !!props.selected || isHovering ? colors.neutral100 : colors.white,
        ...props.style,
      }}
      onClick={() => {
        props.onAddExercise(props.template);

        // Animate a little bit so the user knows something happened
        setDidJustSelectThis(true);
        setTimeout(() => {
          setDidJustSelectThis(false);
        }, 250);
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <FlexRow style={{ gap: Spacing.sm }}>
        {!props.hidePlusIcons && (
          <CircleView
            diameter={24}
            style={{
              backgroundColor: colors.primary500,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomizableIcon
              tint={colors.white}
              type={didJustSelectThis ? 'check' : 'plus-small'}
            />
          </CircleView>
        )}
        <ExerciseImageContainer
          style={{ backgroundColor: props.template.thumbnail_url ? undefined : colors.neutral700 }}
        >
          {props.template.thumbnail_url ? (
            <img width={48} height={48} src={props.template.thumbnail_url} />
          ) : (
            <ThumbnailPlaceholder />
          )}
        </ExerciseImageContainer>
      </FlexRow>
      <View>
        <TextSMMedium>{props.template.title}</TextSMMedium>
        <View style={{ flexDirection: 'row', gap: Spacing.sm }}>
          <TextSMRegular style={{ color: colors.neutral500 }}>
            {muscleGroupToHuman(props.template.muscle_group)}
          </TextSMRegular>
          {props.template.is_custom && <CustomBadge />}
        </View>
      </View>
    </ExerciseTemplateContainer>
  );
};
