import { HevyCoachLogo } from 'components/Logo/HevyCoachLogo';
import React from 'react';
import styled from 'styled-components';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import NavChevron from '../assets/chevron.svg';
import { Spacing } from 'styleguide/spacing';
import { ProgressBar } from 'styleguide/ProgressBar';
import { View } from 'styleguide/View';
import { useDeviceSize } from 'utils/useSize';
import { FlexRow } from 'styleguide/Row';

interface OnboardingNavProps {
  screenName: string;
  includeUsernameStep: boolean;
}

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 61px;
  background-color: ${colors.white};
`;

const OnboardingNavContainer = styled(FlexRow)`
  flex: 1;
  justify-content: space-between;
`;

const Spacer = styled.div`
  flex: 1;
`;

export const OnboardingNav = ({ screenName, includeUsernameStep }: OnboardingNavProps) => {
  const size = useDeviceSize();

  const requiredSteps = ['Name'];
  const steps: string[] =
    includeUsernameStep || screenName === 'username'
      ? ['Username', ...requiredSteps]
      : requiredSteps;

  if (size === 'phone') {
    steps.push('Mobile');
  }

  let currentIndex =
    screenName === 'username'
      ? 0
      : screenName === 'name'
      ? 0
      : screenName === 'mobile-onboarding'
      ? 1
      : -1;

  if (screenName !== 'username' && includeUsernameStep) {
    currentIndex += 1;
  }

  const stepCount = steps.length;
  const currentStep = currentIndex + 1;

  return (
    <Container>
      <OnboardingNavContainer>
        <View style={{ flex: 0.2 }}>
          <HevyCoachLogo fill={colors.black} style={{ paddingLeft: Spacing.sm }} />
        </View>
        {size !== 'desktop' && (
          <TextSMMedium style={{ paddingRight: Spacing.md }}>
            Step {currentStep}{' '}
            <TextSMRegular type="secondary" style={{ display: 'inline' }}>
              of {stepCount}
            </TextSMRegular>
          </TextSMMedium>
        )}
        {size === 'desktop' && steps.length > 1 && (
          <>
            <FlexRow style={{ flex: 1, justifyContent: 'center' }}>
              {steps.map((step, index) => {
                if (index === currentIndex) {
                  return (
                    <TextSMMedium key={index}>
                      {step}{' '}
                      {index !== steps.length - 1 && (
                        <NavChevron
                          style={{
                            paddingLeft: Spacing.sm,
                            paddingRight: Spacing.sm,
                            marginBottom: -3,
                          }}
                        />
                      )}
                    </TextSMMedium>
                  );
                }
                return (
                  <TextSMRegular key={index}>
                    {step}{' '}
                    {index !== steps.length - 1 && (
                      <NavChevron
                        style={{
                          paddingLeft: Spacing.sm,
                          paddingRight: Spacing.sm,
                          marginBottom: -3,
                        }}
                      />
                    )}
                  </TextSMRegular>
                );
              })}
            </FlexRow>
            <Spacer style={{ flex: 0.2 }} />
          </>
        )}
      </OnboardingNavContainer>
      <ProgressBar
        progressFraction={currentStep / stepCount}
        height={4}
        borderRadius={0}
        backgroundColor={colors.neutral200}
      />
    </Container>
  );
};
