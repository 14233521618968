import React from 'react';
import { ClipLoader } from 'react-spinners';
import { colors } from 'styleguide/colors';

export const SmallHevyActivityIndicator = () => {
  return <ClipLoader color={colors.neutral400} size={14} />;
};

export const MediumHevyActivityIndicator = ({ overrideColor }: { overrideColor?: string }) => {
  return <ClipLoader size={20} color={overrideColor ?? colors.primary500} loading={true} />;
};
