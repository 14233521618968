import { Select } from 'components/Select';
import React from 'react';
import styled from 'styled-components';
import { CardNoPadding } from 'styleguide/Card';
import { TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { weeksToDays } from 'utils/dateAndTimeUtils';

const options: { label: string; value: number | null }[] = [
  { label: 'Unlimited', value: null },
  ...Array.from({ length: 53 }, (v, i: number) => i)
    .filter(e => e !== 0)
    .map(e => ({
      label: `${e} weeks`,
      value: weeksToDays(e),
    })),
];

export const ProgramDurationSelector = ({
  style,
  selectedDuration,
  onSelectDuration,
}: {
  style?: React.CSSProperties;
  selectedDuration: number | null;
  onSelectDuration: (value: number | null) => void;
}) => {
  return (
    <View style={style}>
      <Select
        backgroundColor={colors.white}
        options={options}
        value={options.find(e => e.value === selectedDuration)}
        onChange={newValue =>
          onSelectDuration((newValue as { label: string; value: number | null }).value)
        }
      ></Select>
    </View>
  );
};

const SelectionCard = styled(CardNoPadding)<{ isSelected: boolean; isHovering: boolean }>`
  background-color: ${props =>
    props.isSelected || props.isHovering ? colors.primary50 : colors.white};
  border-color: ${props => (props.isSelected ? colors.primary500 : colors.neutral200)};
  cursor: pointer;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  height: 40px;
`;

export const ProgramDurationSelectionList = ({
  style,
  selectedDuration,
  onSelectDuration,
}: {
  style?: React.CSSProperties;
  selectedDuration: number | null;
  onSelectDuration: (value: number | null) => void;
}) => {
  const [hoveringDuration, setHoveringDuration] = React.useState<number | null>(56);

  return (
    <View style={{ gap: Spacing.sm, ...style }}>
      {options.map(option => (
        <SelectionCard
          key={option.label}
          isSelected={option.value === selectedDuration}
          isHovering={option.value === hoveringDuration}
          onClick={() => onSelectDuration(option.value)}
          onMouseEnter={() => setHoveringDuration(option.value)}
          onMouseLeave={() => setHoveringDuration(56)}
        >
          <TextSMRegular
            style={{
              color: option.value === selectedDuration ? colors.primary500 : colors.neutral500,
              fontWeight: option.value === selectedDuration ? '500' : '400',
            }}
          >
            {option.label}
          </TextSMRegular>
        </SelectionCard>
      ))}
    </View>
  );
};
