import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { FormInput } from 'styleguide/Inputs';
import { Modal } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { TextLG, TextMDRegular, TextSMMedium } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { TextInputViewModel } from './TextInputViewModel';
import { HevyTooltip } from 'styleguide/HevyTooltip';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: ${Spacing.lg}px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 ${Spacing.lg}px ${Spacing.md}px ${Spacing.lg}px;
  margin: 0 -${Spacing.lg}px 0 -${Spacing.lg}px;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LowerContainer = styled.div`
  display: flex;
  gap: ${Spacing.md}px;
  margin-top: 20px;
  justify-content: flex-end;
  padding: ${Spacing.md}px ${Spacing.lg}px 0 ${Spacing.lg}px;
  margin: 0px -${Spacing.lg}px 0 -${Spacing.lg}px;
  flex-wrap: wrap;
`;

export interface TextInputModalProps {
  title: string;
  body?: string;
  defaultText?: string;
  placeholder: string;
  inputLabel?: string;
  moreInfoText?: string;
  submitButtonTitle: string;
  cancelButtonTitle?: string;
  handleSubmit?: (text: string) => Promise<string | undefined>;
  vm: TextInputViewModel;
  isOpen: boolean;
  onClose: () => void;
  buttonStyle?: CSSProperties;
}

export const TextInputModal = observer(
  ({
    title,
    body: subtitle,
    placeholder,
    inputLabel,
    submitButtonTitle,
    cancelButtonTitle,
    moreInfoText,
    handleSubmit,
    vm,
    isOpen,
    onClose,
    buttonStyle,
  }: TextInputModalProps) => {
    const onSubmit = async () => {
      if (!handleSubmit) {
        onClose();
        return;
      }

      vm.isHandlingSubmit = true;
      vm.errorText = await handleSubmit(vm.inputText);
      vm.isHandlingSubmit = false;
    };

    return (
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        contentStyle={{ overflow: 'visible' }}
      >
        <Container style={{ width: subtitle ? 684 : 544 }}>
          <UpperContainer>
            <FlexRow style={{ alignContent: 'center', justifyContent: 'space-between' }}>
              <FlexRow>
                <TextLG>{title}</TextLG>
                {moreInfoText && (
                  <HevyTooltip direction="top" text={moreInfoText}>
                    <View style={{ padding: Spacing.xs, paddingLeft: Spacing.sm }}>
                      <CustomizableIcon type="question-circle" tint={colors.neutral500} />
                    </View>
                  </HevyTooltip>
                )}
              </FlexRow>
              <CloseButton onClick={onClose} />
            </FlexRow>
          </UpperContainer>
          <MiddleContainer>
            {subtitle && (
              <TextMDRegular style={{ paddingBottom: Spacing.md, color: colors.neutral500 }}>
                {subtitle}
              </TextMDRegular>
            )}
            <TextSMMedium style={{ marginBottom: 6 }}>{inputLabel}</TextSMMedium>
            <FormInput
              spellCheck={false}
              autoComplete="off"
              value={vm.inputText}
              onChange={event => vm.onInputTextChange(event.currentTarget.value)}
              placeholder={placeholder}
            />
            {vm.errorText && (
              <TextSMMedium style={{ color: colors.error500, paddingTop: Spacing.sm }}>
                {vm.errorText}
              </TextSMMedium>
            )}
          </MiddleContainer>
          <LowerContainer>
            {cancelButtonTitle && (
              <SecondaryButton
                style={{ ...styles.button, minWidth: 100 }}
                title={cancelButtonTitle}
                onClick={onClose}
              />
            )}
            <PrimaryButton
              style={{ ...styles.button, ...buttonStyle }}
              title={submitButtonTitle}
              loading={vm.isHandlingSubmit}
              onClick={onSubmit}
            />
          </LowerContainer>
        </Container>
      </Modal>
    );
  },
);

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    paddingLeft: Spacing.lg,
    paddingRight: Spacing.lg,
  },
};
