import { colors } from 'styleguide/colors';

import { ChartOptions } from 'chart.js/dist/types/index';
import { InterFont } from 'styleguide/fonts';
export const HevyMuscleSplitRadarGraphOptions: ChartOptions<'radar'> = {
  scales: {
    r: {
      min: 0,
      angleLines: {
        display: true,
      },
      ticks: {
        display: false,
        count: 5,
      },
      pointLabels: {
        font: {
          size: 12,
          family: InterFont.style.fontFamily,
        },
        color: colors.neutral500,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },

  elements: {
    line: {
      fill: {
        target: 'origin',
        //  0x26 = 15% opacity
        below: `${colors.primary500}26`,
      },

      backgroundColor: colors.primary500,
    },
    point: {
      pointStyle: false,
    },
  },
};
