import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { zIndexes } from 'styleguide/zIndex';
import { ModalHeader } from './Components/ModalHeader';
import { Link } from 'components/Link';
import fonts from 'styleguide/fonts';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.lg}px;
  padding-top: 0px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

export interface AlertModalProps {
  title: string;
  body?: string;
  renderComponent?: () => ReactElement;
  confirmButtonStyle?: 'primary' | 'destructive';
  confirmButtonTitle: string;
  cancelButtonTitle?: string;
  linkButtonTitle?: string;
  linkButtonHref?: string;
  isOpen: boolean;
  closeModal: () => void;
  handleAlertConfirm?: () => Promise<{ error: string } | void>;
  onCancelClick?: () => void;
}

export const AlertModal = ({
  title,
  body: bodyText,
  renderComponent,
  confirmButtonStyle,
  confirmButtonTitle,
  cancelButtonTitle,
  linkButtonTitle,
  linkButtonHref,
  isOpen,
  closeModal,
  handleAlertConfirm,
  onCancelClick,
}: AlertModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const bodyComponent = renderComponent ? renderComponent() : null;

  useEffect(() => {
    setError('');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayStyle={{ zIndex: zIndexes.alertModal }}
    >
      <Container>
        <ModalHeader text={title} onClose={closeModal} style={{ paddingBottom: Spacing.md }} />
        <MiddleContainer>
          <div>
            <TextSMRegular style={{ display: 'inline' }}>{bodyText}</TextSMRegular>
            {linkButtonTitle && linkButtonHref && (
              <Link
                href={linkButtonHref}
                target="blank"
                style={{
                  paddingBottom: Spacing.md,
                  fontWeight: fonts.medium.weight,
                  fontSize: 14,
                  display: 'inline',
                }}
              >
                {' '}
                Learn More
              </Link>
            )}
          </div>

          {bodyComponent}
          {!!error && (
            <TextSMRegular style={{ color: colors.error500, marginTop: Spacing.sm }}>
              {error}
            </TextSMRegular>
          )}
        </MiddleContainer>
        <DividerLine />
        <LowerContainer>
          {cancelButtonTitle && (
            <SecondaryButton
              title={cancelButtonTitle}
              onClick={() => {
                onCancelClick?.();
                closeModal();
              }}
              enabled={!isLoading}
            />
          )}
          <PrimaryButton
            style={{
              backgroundColor:
                confirmButtonStyle === 'destructive' ? colors.error500 : colors.primary500,
            }}
            title={confirmButtonTitle}
            onClick={async () => {
              if (!handleAlertConfirm) {
                closeModal();
                return;
              }
              setIsLoading(true);
              try {
                const result = await handleAlertConfirm?.();
                if (result?.error) {
                  setError(result.error);
                } else {
                  closeModal();
                }
              } catch (error) {
                setError('Unknown error');
              }

              setIsLoading(false);
            }}
            loading={isLoading}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
