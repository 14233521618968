import styled from 'styled-components';

export const TABLET_WIDTH_BREAKPOINT = 1159;
export const PHONE_WIDTH_BREAKPOINT = 820;

export const HideOnPhoneWrapper = styled.div`
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none !important;
  }
`;
export const ShowOnPhoneWrapper = styled.div`
  @media (min-width: ${PHONE_WIDTH_BREAKPOINT + 1}px) {
    display: none !important;
  }
`;

export const MobileFriendlyRowWrapper = styled.div<{ gap: number }>`
  flex-direction: row;
  display: flex;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-direction: column;
  }
  gap: ${props => props.gap}px;
`;

export const ShowOnPhoneOnlyWrapper = styled.div<{ visibleDisplayMode?: string }>`
  display: ${props => props.visibleDisplayMode ?? 'block'};
  @media (min-width: ${PHONE_WIDTH_BREAKPOINT + 1}px) {
    display: none;
  }
`;
