import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

const WHATS_NEW_STORAGE_KEY = 'WHATS_NEW_STORAGE_KEY';

interface Update {
  date: Date;
  features: string[];
}

export class WhatsNew {
  lastWhatsNewOpenDate?: Date;

  private _updates: Update[] = [
    {
      date: new Date('2024-05-06'),
      features: [
        `📷 Progress Pictures: You can now view and compare progress pictures uploaded by your clients.`,
      ],
    },
    {
      date: new Date('2024-05-01'),
      features: [
        `🔔 Notifications: you can now opt into browser and email notifications for things like chat messages, workouts, body measurements, and more. Get started by going to Settings > Notifications.`,
      ],
    },
    {
      date: new Date('2024-04-26'),
      features: [
        `🚀 Grow your business! You can now set up a personalized link and application form for potential clients to apply directly from your website or social media platforms. Check it out on the "Grow" tab.`,
      ],
    },
    {
      date: new Date('2024-04-10'),
      features: [
        `Introducing team management made easy! Add your fellow coaches to your account to facilitate seamless program sharing and coordinated client management across all team members.`,
      ],
    },
    {
      date: new Date('2024-03-06'),
      features: [
        `You can now assign rep ranges to an exercise instead of reps when editing a routine.`,
      ],
    },
    {
      date: new Date('2024-02-07'),
      features: [
        `Programs can now be configured with a duration, and assigned programs have a start-date, allowing you to keep track of what week of a program your client is on.`,
      ],
    },
    {
      date: new Date('2024-01-16'),
      features: [
        'You can now import routines from your Hevy account! Just click the little "..." button on the Program Library screen.',
      ],
    },
    {
      date: new Date('2024-01-05'),
      features: [
        'You can now view set count and muscle group data when editing a program or routine.',
      ],
    },
    {
      date: new Date('2023-12-19'),
      features: ['The routine editor now allows you to assign a target RPE for each set.'],
    },
    {
      date: new Date('2023-12-08'),
      features: [
        "View the workout history of a specific exercise on a client's Exercise Statistics page, or in the Exercise Details when editing an assigned routine.",
      ],
    },
    {
      date: new Date('2023-12-07'),
      features: ['Notes can now be added at the program level.'],
    },
    {
      date: new Date('2023-10-27'),
      features: [
        'You can now change your preferred weight, distance, and measurement units for Hevy Coach on the Settings page.',
      ],
    },
    {
      date: new Date('2023-10-23'),
      features: [
        'Accelerate getting started by importing programs from the new HevyCoach program library!',
      ],
    },
    {
      date: new Date('2023-10-18'),
      features: [
        'Clients and coaches can now use Hevy Coach chat to send images and videos.',
        'Clients and coaches can now delete messages from a conversation.',
      ],
    },
    {
      date: new Date('2023-10-12'),
      features: [
        `📈 Advanced Statistics!`,
        `Gain valuable insights into your clients' fitness journey by tracking their workout volume, duration, and total set count over time.`,
        `Dive even deeper into their progress by monitoring set counts for each specific muscle group.`,
      ],
    },
    {
      date: new Date('2023-09-19'),
      features: [
        `Added a new screen "Coach Dashboard" and it's now the default screen when you log in.`,
        `Dashboard is a new way to view your all clients' latest activities in one place.`,
        `You can quickly see both active and inactive clients and send messages to them.`,
      ],
    },
    {
      date: new Date('2023-08-25'),
      features: [
        `💬 Chat! After many weeks of work we're happy to be adding chat to Hevy Coach. You can now send and receive private messages directly from Hevy/Hevy Coach. To get started, enable chat for your clients from the Settings screen.`,
      ],
    },
    {
      date: new Date('2023-06-30'),
      features: [
        `Programs can now be searched, organized into folders, and reordered as desired inside the Program Library.`,
        `Program & client search now quickly accessible in the top bar on mobile devices.`,
        `Much nicer user interface for saving programs and routines as you change them.`,
      ],
    },
    {
      date: new Date('2023-06-15'),
      features: [
        `You can now add custom instructions and videos to exercises. These are visible to your clients on the "How To" tab of the exercise details screen in the Hevy App.`,
      ],
    },
    {
      date: new Date('2023-06-06'),
      features: [
        `Added this "what's new" button! 🎉`,
        'Programs and routines now automatically save as you make changes',
        'You can now search for clients by email address, as well as name or username',
      ],
    },
    {
      date: new Date('2023-05-31'),
      features: [
        'You can now search for users or programs right in the navigation bar',
        'Added some tooltips to clarify various client fields',
      ],
    },
    {
      date: new Date('2023-05-30'),
      features: [
        `You can now add notes to your client's routines`,
        `You can now search for clients when assigning a program`,
      ],
    },
    {
      date: new Date('2023-05-26'),
      features: [
        `Improvements to the program assignment popup`,
        `You can now quickly override the client's current program easily from the client's Workout Program page`,
      ],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  get orderedUpdates() {
    return this._updates.slice().sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });
  }

  get hasUserSeenLatestWhatsNew() {
    if (!this.lastWhatsNewOpenDate) {
      return false;
    }

    const latestUpdate = dayjs(this.orderedUpdates[0].date);
    const lastOpened = dayjs(this.lastWhatsNewOpenDate);
    return lastOpened.isAfter(latestUpdate);
  }

  handledUserOpenedWhatsNew = () => {
    this.lastWhatsNewOpenDate = new Date();

    window.localStorage.setItem(WHATS_NEW_STORAGE_KEY, this.lastWhatsNewOpenDate.toString());
  };

  hydrate = () => {
    const lastWhatsNewOpenDate = window.localStorage.getItem(WHATS_NEW_STORAGE_KEY);
    if (lastWhatsNewOpenDate) {
      this.lastWhatsNewOpenDate = new Date(lastWhatsNewOpenDate);

      window.localStorage.setItem(WHATS_NEW_STORAGE_KEY, this.lastWhatsNewOpenDate.toString());
    }
  };

  clearData = () => {
    this.lastWhatsNewOpenDate = undefined;
    window.localStorage.removeItem(WHATS_NEW_STORAGE_KEY);
  };
}
