'use client';
import axios from 'axios';
import { CoachAccount, CoachInviteSearchUser, isValidEmail } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { modal } from '../../ModalManager';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { debounce } from 'lodash';
import { memoryStores } from 'state/memoryStores';
import { EmailInvitee, InviteError, Invitee } from '../types';
import {
  MAXIMUM_CLIENTS_TO_INVITE,
  openAlertModalForFailedInvites,
  promptUserMaxInvitesReached,
  promptUserPlanLimitReached,
} from '../utils';
import { env } from 'env';

export class ClientInviteViewModel {
  title = 'Invite new clients';
  subtext =
    'Invite one or more clients. They will receive a link to accept your invitation and be guided to download the Hevy App to be coached.';

  learnMoreLink = 'https://help.hevycoach.com/en/articles/8460760-invite-clients';

  private _inputText = '';
  isHandlingSubmit = false;
  invitees: Invitee[] = [];
  isEmailValidationErrorDetected = false;
  inviteErrors: InviteError[] = [];
  searchResults: CoachInviteSearchUser[] = [];
  showSearchResults = false;

  clientsCoaches: { [clientUsernameOrEmail: string]: CoachAccount } = {};

  constructor(defaultText?: string) {
    this.inputText = defaultText ?? '';
    makeAutoObservable(this);
  }

  get inputText() {
    return this._inputText;
  }

  set inputText(value: string) {
    this.isEmailValidationErrorDetected = false;
    this._inputText = value;
  }

  onInputTextChanged = (value: string) => {
    this.inputText = value;
    // Do not show search results if the input text is less than 3 characters
    if (this.inputText.length <= 3) {
      this.showSearchResults = false;
      this.isSearching = false;
    } else {
      this.startSearch();
    }
  };

  get isInputEnabled() {
    return !this.isHandlingSubmit && this.inviteeCount <= MAXIMUM_CLIENTS_TO_INVITE;
  }

  get isSubmitAvailable() {
    return !this.isHandlingSubmit && !this.isEmailValidationErrorDetected && this.inviteeCount > 0;
  }

  get shouldShowEmailSearchResults() {
    return (
      this.searchResults.length === 0 &&
      this.isEnteredTextValidEmail &&
      !this.doesEmailExistsInInvitees(this.inputText)
    );
  }

  get inviteeCount() {
    return this.invitees.length;
  }

  onPasteText = (text: string) => {
    const potentialEmails = text.trim().split(/[\s,]+/);
    const validatedEmails = [];
    for (const email of potentialEmails) {
      if (isValidEmail(email)) {
        validatedEmails.push(email);
      }
    }

    // If there are no valid emails, do nothing, just paste the text
    if (validatedEmails.length === 0) {
      this.onInputTextChanged(text);
      return;
    }

    // If there are some valid emails, show an error to the user
    if (validatedEmails.length !== potentialEmails.length) {
      this.inputText = text;
      this.isEmailValidationErrorDetected = true;
      return;
    }

    this.checkInviteLimitAndAddEmails(validatedEmails);
  };

  onUserSearchResultClick = (user: CoachInviteSearchUser) => {
    this.checkInviteLimitAndAddUser(user);
  };

  onEmailSearchResultClick = () => {
    if (!isValidEmail(this.inputText)) {
      this.isEmailValidationErrorDetected = true;
      return;
    }

    this.checkInviteLimitAndAddEmails([this.inputText]);
  };

  checkInviteLimitAndAddUser = (user: CoachInviteSearchUser) => {
    if (this.inviteeCount + 1 > MAXIMUM_CLIENTS_TO_INVITE) {
      promptUserMaxInvitesReached();
      return;
    }

    if (this.inviteeCount + 1 > PlanAggregator.numberOfInvitesLeft) {
      promptUserPlanLimitReached();
      return;
    }

    this.invitees.push({ type: 'user', userData: user });
    this.searchResults = this.searchResults.filter(s => s.username !== user.username);
    this.showSearchResults = false;
    this.inputText = '';
  };

  checkInviteLimitAndAddEmails = (emails: string[]) => {
    const totalEmails = this.inviteeCount + emails.length;
    if (totalEmails > MAXIMUM_CLIENTS_TO_INVITE) {
      promptUserMaxInvitesReached();
      return;
    }
    if (totalEmails > PlanAggregator.numberOfInvitesLeft) {
      promptUserPlanLimitReached();
      return;
    }
    this.invitees.push(
      ...emails.map(e => {
        return { type: 'email', email: e } as EmailInvitee;
      }),
    );
    this.inputText = '';
    this.searchResults = [];
    this.showSearchResults = false;
  };

  onRemoveInvitee = (invitee: Invitee) => {
    this.invitees = this.invitees.filter(e => e !== invitee);
    delete this.clientsCoaches[
      invitee.type === 'email' ? invitee.email : invitee.userData.username
    ];
  };

  onInputKeyPressed = (key: string) => {
    if (key === 'Enter' || key === ' ') {
      this.processEnteredText();
    }
  };

  get isEnteredTextValidEmail() {
    return isValidEmail(this._inputText);
  }

  processEnteredText = () => {
    this._inputText = this._inputText.trim();
    if (this._inputText === '') {
      return;
    }

    const maybeUser = this.searchResults.find(
      s => s.email === this.inputText || s.username === this.inputText,
    );

    if (maybeUser) {
      this.checkInviteLimitAndAddUser(maybeUser);
      return;
    }

    if (isValidEmail(this._inputText)) {
      this.checkInviteLimitAndAddEmails([this.inputText]);
    } else {
      this.isEmailValidationErrorDetected = true;
    }
  };

  isSearching = false;
  startSearch = () => {
    this.searchResults = [];
    this.isSearching = true;
    this.debouncedSearch();
    this.showSearchResults = true;
  };

  search = async () => {
    if (this._inputText.length < 3) {
      this.isSearching = false;
      return;
    }
    try {
      const result = isValidEmail(this._inputText)
        ? await API.searchUserByEmail(this._inputText.toLocaleLowerCase())
        : await API.searchUserByName(this._inputText.toLocaleLowerCase());

      this.searchResults = result.data.filter(s => {
        const isAlreadyClient = memoryStores.clients.clientForUsername(s.username);
        return (
          !isAlreadyClient &&
          !this.doesEmailExistsInInvitees(s.email) &&
          !this.doesUsernameExistsInInvitees(s.username)
        );
      });
    } catch {
    } finally {
      this.isSearching = false;
    }
  };

  debouncedSearch = debounce(this.search, 300);

  get coachDirectClientLink() {
    return `${env.hevyCoachBaseUrl}/${localStorageStores.account.username}/accept`;
  }

  copyLeadLinkToClipboard = () => {
    navigator.clipboard.writeText(this.coachDirectClientLink);
    toast.success('Link copied!');
    sendEvent('inviteClient_linkCopied');
  };

  sendInvites = async () => {
    if (this.inviteeCount === 0) {
      return;
    }

    const invitees = this.invitees.filter((value, index, self) => self.indexOf(value) === index);
    this.inviteErrors = [];
    const inviteErrors: { invitee: Invitee; error: string }[] = [];
    this.isHandlingSubmit = true;
    const successfulInvites: Invitee[] = [];

    for (const invitee of invitees) {
      try {
        switch (invitee.type) {
          case 'email':
            await API.createClientInvite(
              invitee.email.toLowerCase(),
              this.clientsCoaches[invitee.email]?.id,
            );
            break;
          case 'user':
            await API.createClientInvite(
              invitee.userData.username.toLowerCase(),
              this.clientsCoaches[invitee.userData.username]?.id,
            );
            break;
        }
        await localStorageStores.invites.fetch();
        sendEvent('inviteClient_complete', { clientEmail: invitee });
        successfulInvites.push(invitee);
      } catch (requestError) {
        let error = 'Unknown error creating client invite.';
        if (axios.isAxiosError(requestError)) {
          switch (requestError.response?.data.error) {
            case 'UserAlreadyHasACoachInvite':
              error = 'This user already has a invite to Hevy Coach.';
              break;
            case 'UserIsAlreadyCoached':
              error = `The user is already being coached.`;
              break;
            case 'UnknownUser':
              error = `No Hevy user with that email address.`;
              break;
            case 'CoachPlanClientLimitReached':
              error = 'Upgrade your plan to invite more clients.';
              break;
            default:
          }
        }
        inviteErrors.push({ invitee: invitee, error: error });
      }
    }
    this.inviteErrors = inviteErrors;
    if (this.inviteErrors.length === 0) {
      toast.success('✉️ Invitation emails sent!');
    } else {
      if (successfulInvites.length > 0) {
        toast.success(
          `✉️ ${successfulInvites.length} invitation ${
            successfulInvites.length === 1 ? 'email' : 'emails'
          } sent!`,
        );
      }
      openAlertModalForFailedInvites(this.inviteErrors);
    }

    if (successfulInvites.length > 0) {
      modal.isClientInviteModalOpen = false;
    }
    this.invitees = this.invitees.filter(e => !successfulInvites.includes(e));
    this.isHandlingSubmit = false;
  };

  doesEmailExistsInInvitees = (email?: string): boolean => {
    if (!email) return false;

    return !!this.invitees.find(
      i =>
        (i.type === 'user' && i.userData.email === email) ||
        (i.type === 'email' && i.email === email),
    );
  };

  doesUsernameExistsInInvitees = (username: string): boolean => {
    return !!this.invitees.find(i => i.type === 'user' && i.userData.username === username);
  };

  get showCoachSelector() {
    return (
      localStorageStores.account.isAdminOrOwner && localStorageStores.team.hasMoreThanOneMember
    );
  }

  selectedCoachForInvitee = (invitee: Invitee) => {
    return (
      this.clientsCoaches[invitee.type === 'email' ? invitee.email : invitee.userData.username] ||
      localStorageStores.team.teamMemberWithId(localStorageStores.account.id)
    );
  };

  onSelectCoach = (invitee: Invitee, coach: CoachAccount) => {
    this.clientsCoaches[
      invitee.type === 'email' ? invitee.email : invitee.userData.username
    ] = coach;
  };
}
