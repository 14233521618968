import { GlobalDragAndDropManager } from 'utils/globals/GlobalDragAndDropManager';
import { Conversation } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { ChatAggregator } from 'state/aggregators/chatAggregatorStore';
import { localStorageStores } from 'state/localStorageStores';
import { ActiveConversation } from 'types/chat';
import { Client } from 'types/client';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import { clientMatchesSearch } from 'utils/pureUtils';
import { getUrlFromFile } from 'utils/imageUtils';
import { uploadChatFile } from 'utils/uploadMedia';
import { HevyWebsocketClient } from 'utils/globals/HevyWebsocketClient';

export class ChatViewModel {
  private _selectedConversationId: string | undefined = undefined;

  get selectedConversationId() {
    return this._selectedConversationId;
  }

  set selectedConversationId(conversationId: string | undefined) {
    if (!!conversationId) {
      GlobalDragAndDropManager.dropEventEmitter?.addListener('drop', this.handleFileDrop);
    } else {
      GlobalDragAndDropManager.dropEventEmitter?.removeListener('drop', this.handleFileDrop);
    }
    if (this.viewContext === 'chatScreen') {
      localStorageStores.chats.screenActiveConversationID = conversationId;
    } else {
      localStorageStores.chats.widgetActiveConversationID = conversationId;
    }
    this._selectedConversationId = conversationId;
  }

  messagesContainerRef: HTMLDivElement | null = null;
  isCreatingConversation = false;
  imageBeingProcessed?: string;
  videoBeingProcessed?: string;
  isFileBeingProcessed: boolean = false;

  searchText = '';

  viewContext: 'chatScreen' | 'chatWidget';

  constructor(viewContext: 'chatScreen' | 'chatWidget') {
    makeAutoObservable(this);
    this.viewContext = viewContext;
  }

  stopListeningForDragAndDrop = () => {
    GlobalDragAndDropManager.dropEventEmitter?.removeListener('drop', this.handleFileDrop);
  };

  handleFileDrop = (files: File[]) => {
    if (files.length > 1) {
      toast.error('You can only send one file at a time');
      return;
    }

    const file = files[0];
    if (!file || !this.isValidChatFile(file)) {
      toast.error('You can only send MP4 videos, or JPG or PNG images');
      return;
    }

    this.onSendFile(file);
  };

  isValidChatFile = (file: File): boolean => {
    const contentType = file.type;
    if (contentType.includes('video') || contentType.includes('image')) {
      return true;
    } else {
      return false;
    }
  };

  setMessagesContainerRef = (ref: HTMLDivElement) => {
    this.messagesContainerRef = ref;
  };

  onSend = async () => {
    if (!this._selectedConversationId || !this.isSendButtonEnabled) {
      return;
    }
    const message = this.unsentMessage;
    this.updateUnsentMessage(undefined);

    sendEvent(`${this.viewContext}_sendMessage`);
    try {
      await ChatAggregator.onSendMessage(message.trim(), this._selectedConversationId);
    } catch {
      this.updateUnsentMessage(message);
      toast.error('Unable to send message, please try again.');
    }
  };

  onDeleteMessageClick = async (messageId: string) => {
    try {
      await localStorageStores.chats.deleteMessage(messageId);
    } catch (error) {
      toast.error('Unable to delete message, please try again.');
    }
  };

  onBackClick = () => {
    this.selectedConversationId = undefined;
  };

  onSendFile = async (file: File) => {
    if (!this.selectedConversationId) {
      return;
    }
    this.isFileBeingProcessed = true;
    try {
      const { url } = await getUrlFromFile(file);
      const contentType = file.type;
      let type: 'video' | 'image' | undefined = undefined;
      if (contentType.includes('video')) {
        type = 'video';
      } else if (contentType.includes('image')) {
        type = 'image';
      } else {
        throw new Error('InvalidFileType');
      }
      if (type === 'image') {
        this.imageBeingProcessed = url;
      } else {
        this.videoBeingProcessed = url;
      }
      const extension = file.name.split('.')[file.name.split('.').length - 1];
      const fileUrl = (await uploadChatFile(file.name, url, file.type, extension)).url;
      try {
        await localStorageStores.chats.onSendFileMessage(
          fileUrl,
          this.selectedConversationId,
          type,
        );
        sendEvent(`chat${this.viewContext}_sendFileMessage`, { fileType: type });
      } catch (error) {
        toast.error('Unable send message, please try again');
      }
    } catch (error) {
      toast.error('Unable to upload file, please try again');
    } finally {
      this.imageBeingProcessed = undefined;
      this.videoBeingProcessed = undefined;
      this.isFileBeingProcessed = false;
    }
  };

  loadOlderMessagesForCurrentConversation = () => {
    if (!this._selectedConversationId) {
      return;
    }
    sendEvent(`${this.viewContext}_loadOlderMessages`);
    ChatAggregator.loadOlderMessagesForConversation(this._selectedConversationId);
  };

  get isFetchingOlderMessages() {
    return localStorageStores.chats.isFetchingOlderMessages;
  }

  onSearchTextChange = (text: string) => {
    this.searchText = text;
  };

  reset = () => {
    fireAndForget([localStorageStores.chats.reset()]);
  };

  latestMessageForConversation = (conversationId: string) => {
    return localStorageStores.chats.latestMessageForConversation(conversationId);
  };

  get selectedConversation() {
    return this.conversations.find(c => {
      return c.conversation_id === this.selectedConversationId;
    });
  }

  get supportedMessagesInSelectedConversation() {
    const messages = this.messages.filter(message => {
      return (
        message.conversation_id === this.selectedConversationId &&
        ['text', 'image', 'video'].includes(message.type)
      );
    });
    return messages;
  }

  get isChatOnline() {
    return HevyWebsocketClient.isConnected;
  }

  createConversation = async (client: Client): Promise<string> => {
    this.isCreatingConversation = true;
    try {
      return await ChatAggregator.createNewConversation(client);
    } catch (error) {
      throw error;
    } finally {
      this.isCreatingConversation = false;
    }
  };

  get conversations(): ActiveConversation[] {
    return ChatAggregator.activeConversations;
  }

  get filteredConversations() {
    if (!this.searchText) {
      return this.conversations;
    }
    return this.conversations.filter(c => {
      const client = this.clientForConversation(c);
      if (!client) {
        return false;
      }
      return clientMatchesSearch(client, this.searchText);
    });
  }

  clientForConversation = (conversation: Conversation) => {
    return ChatAggregator.clientForConversation(conversation);
  };

  get isLoadingConversations() {
    return localStorageStores.chats.isLoadingConversations;
  }

  get clientForSelectedConversation() {
    if (!this.selectedConversation) {
      return undefined;
    }
    return this.clientForConversation(this.selectedConversation);
  }

  get totalUnreadMessages() {
    return ChatAggregator.totalUnreadMessages;
  }

  get hasFailedToInitialize() {
    return localStorageStores.chats.hasFailedToIntialize;
  }

  get messages() {
    return localStorageStores.chats.messages;
  }

  updateUnsentMessage = (message: string | undefined) => {
    if (!this.selectedConversationId) {
      return;
    }

    localStorageStores.chats.updateUnsentMessage(this.selectedConversationId, message);
  };

  get unsentMessage(): string {
    if (!this._selectedConversationId) return '';

    return localStorageStores.chats.unsentMessages[this._selectedConversationId] || '';
  }

  get isSendButtonEnabled(): boolean {
    return this.unsentMessage.trim().length > 0;
  }
}
