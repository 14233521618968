import { ExerciseCell } from 'components/ExerciseLibrary/ExerciseCell';
import { ExerciseLibraryViewModel } from 'components/ExerciseLibrary/ExerciseLibraryViewModel';
import { Equipment, MuscleGroup, isExerciseTemplate } from 'hevy-shared';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { PrimaryButton, TextButton } from 'styleguide/Buttons';
import { DividerLine } from 'styleguide/DividerLine';
import { SearchInput } from 'styleguide/Inputs';
import { Modal } from 'styleguide/Modal';
import { TextLG, TextSMRegular, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { modal } from './ModalManager';
import { colors } from 'styleguide/colors';
import { Select } from 'components/Select';
import styled from 'styled-components';
import { CloseButton } from 'components/CloseButton';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { FlexRow } from 'styleguide/Row';

const ExercisesListContainer = styled(View)`
  flex: 1;
  overflow-y: auto;
  padding: ${Spacing.sm}px 0px;
`;

const UpperContainer = styled(View)`
  padding: ${Spacing.md}px;
`;

const TitleRow = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${Spacing.md}px;
`;

const ErrorText = styled(TextSMMedium)`
  align-self: center;
  padding: ${Spacing.sm}px;
  text-align: center;
  padding-top: ${Spacing.md}px;
`;

export interface ExerciseLibraryModalProps {
  vm: ExerciseLibraryViewModel;
  exerciseIdToBeReplaced?: string;
  hidePlusIcons?: boolean;
  enableCreateExerciseButton?: boolean;
  enableCreateExerciseOnNoResults?: boolean;
  onSelectExercise?: (templateId: string) => void;
  selectedExerciseId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ExerciseLibraryModal = observer(
  ({
    vm,
    hidePlusIcons,
    exerciseIdToBeReplaced,
    onSelectExercise,
    selectedExerciseId,
    enableCreateExerciseButton,
    enableCreateExerciseOnNoResults,
    isOpen,
    onClose,
  }: ExerciseLibraryModalProps) => {
    const exercisesContainerRef = React.useRef<HTMLDivElement | null>(null);

    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        isOpen={isOpen}
        onClose={onClose}
        contentStyle={{ height: '100%' }}
      >
        <View style={{ width: 500 }}>
          <UpperContainer>
            <TitleRow>
              <FlexRow
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                  justifyContent: 'space-between',
                  gap: Spacing.sm,
                }}
              >
                <TextLG>{exerciseIdToBeReplaced ? 'Replace Exercise' : 'Select Exercise'}</TextLG>
                {enableCreateExerciseButton && (
                  <TextButton
                    title="Custom Exercise"
                    iconType="plus"
                    onClick={() => {
                      modal.openEditExerciseModal({
                        onExerciseSaved: exerciseTemplateId => {
                          if (onSelectExercise !== undefined) {
                            onSelectExercise(exerciseTemplateId);
                            onClose();
                          }
                        },
                      });
                    }}
                    style={{ marginRight: Spacing.sm }}
                  />
                )}
              </FlexRow>
              <CloseButton onClick={onClose} />
            </TitleRow>
            <View style={{ gap: Spacing.sm }}>
              <FlexRow style={{ gap: Spacing.sm, flexWrap: 'wrap' }}>
                <View style={{ flex: 1, minWidth: 160 }}>
                  <Select
                    options={vm.equipmentFilterOptions}
                    onChange={newValue => {
                      vm.onSelectedEquipmentFiltersUpdate(
                        newValue as { label: string; value: Equipment },
                      );
                    }}
                    value={vm.equipmentFilter}
                  />
                </View>
                <View style={{ flex: 1, minWidth: 150 }}>
                  <Select
                    options={vm.muscleGroupFilterOptions}
                    onChange={newValue => {
                      vm.onSelectedMuscleGroupFiltersUpdate(
                        newValue as { label: string; value: MuscleGroup },
                      );
                    }}
                    value={vm.muscleGroupFilter}
                  />
                </View>
              </FlexRow>
              <SearchInput
                value={vm.exerciseSearchText}
                style={{ backgroundColor: colors.neutral100 }}
                onChange={vm.onExerciseSearchChanged}
                placeholder="Search exercises"
              ></SearchInput>
            </View>
          </UpperContainer>
          <View>
            <DividerLine />
          </View>
          <ExercisesListContainer
            ref={(r: any) => {
              exercisesContainerRef.current = r;
              vm.setExercisesContainerRef(r);
            }}
          >
            {vm.data.map((item, index) => {
              if (isExerciseTemplate(item)) {
                return (
                  <ExerciseCell
                    key={item.key}
                    template={item}
                    hidePlusIcons={hidePlusIcons}
                    selected={selectedExerciseId === item.id}
                    onAddExercise={template => {
                      if (onSelectExercise !== undefined) {
                        onSelectExercise(template.id);
                        onClose();
                      }
                    }}
                    style={
                      hidePlusIcons
                        ? {
                            borderRadius: CornerRadius.md,
                            marginRight: Spacing.sm,
                            marginLeft: Spacing.sm,
                          }
                        : {}
                    }
                  />
                );
              }

              switch (item.type) {
                case 'section':
                  return (
                    <TextSMRegular
                      key={`${item.title}-section`}
                      style={{
                        color: colors.neutral500,
                        paddingLeft: Spacing.lg,
                        paddingBottom: Spacing.md,
                      }}
                    >
                      {item.title}
                    </TextSMRegular>
                  );
                case 'spacer':
                  return (
                    <DividerLine
                      key={`${index}-spacer`}
                      style={{ marginTop: Spacing.sm, marginBottom: Spacing.md }}
                    />
                  );
                case 'loading':
                  return (
                    <View
                      key={`${index}-spacer`}
                      style={{ justifyContent: 'center', alignItems: 'center', height: 100 }}
                    >
                      <MediumHevyActivityIndicator />
                    </View>
                  );
                case 'no-exercises-match-search':
                  return (
                    <>
                      <ErrorText>{`Couldn't find "${vm.exerciseSearchText}"`}</ErrorText>
                      <TextSMRegular
                        style={{ alignSelf: 'center', padding: Spacing.sm, textAlign: 'center' }}
                      >
                        {`We don't have that exercise in our database yet.`}
                      </TextSMRegular>
                      {enableCreateExerciseOnNoResults && (
                        <PrimaryButton
                          title={'Create Custom Exercise'}
                          style={{ margin: Spacing.md, maxHeight: 40 }}
                          onClick={() => {
                            modal.openEditExerciseModal({
                              onExerciseSaved: exerciseId => {
                                if (onSelectExercise !== undefined) {
                                  onSelectExercise(exerciseId);
                                  onClose();
                                }
                              },
                              exerciseTitle: vm.exerciseSearchText,
                            });
                          }}
                        />
                      )}
                    </>
                  );
                case 'no-exercises-found':
                  return <ErrorText>{`No exercises found`}</ErrorText>;
              }
            })}
          </ExercisesListContainer>
        </View>
      </Modal>
    );
  },
);
