import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import HorizontalDotsAccent from './assets/icon-horizontal-dots-accent.svg';
import VerticalDots from './assets/icon-vertical-dots.svg';
import CheckAccent from './assets/icon-check-accent.svg';
import Reorder from './assets/reorder.svg';
import Search from './assets/search.svg';
import XSmall from './assets/x-small.svg';
import Plus from './assets/plus.svg';
import Edit from './assets/edit.svg';
import Copy from './assets/copy.svg';
import Menu from './assets/menu.svg';
import XClose from './assets/x-close.svg';
import Replace from './assets/replace.svg';
import Folder from './assets/folder.svg';
import Delete from './assets/delete.svg';

const iconTypeImageMap = {
  verticalDots: VerticalDots,
  horizontalDotsAccent: HorizontalDotsAccent,
  checkAccent: CheckAccent,
  xSmall: XSmall,
  copy: Copy,
  search: Search,
  plus: Plus,
  edit: Edit,
  menu: Menu,
  xClose: XClose,
  reorder: Reorder,
  replace: Replace,
  folder: Folder,
  delete: Delete,
};

export type IconType = keyof typeof iconTypeImageMap;

const Container = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface IconProps {
  type: IconType;
  style?: CSSProperties;
}

/**
 * Icon component is for icons that can not be tinted but can be styled.
 * Default size is 30x30px.
 * @param type - Icon type
 * @param style - CSS style
 *  */
export const Icon = ({ type, style }: IconProps) => (
  <Container style={style}>{iconTypeImageMap[type]()}</Container>
);
