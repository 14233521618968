import React from 'react';
import { Modal } from 'styleguide/Modal';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { TextLG, TextMD, TextSMRegular } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { Spacing } from 'styleguide/spacing';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { DividerLine } from 'styleguide/DividerLine';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { MoveProgramToFolderViewModel } from './MoveProgramToFolderViewModel';
import { SearchInput, StyledRadioButton } from 'styleguide/Inputs';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';

const MiddleContainer = styled(View)`
  flex: 1;
  padding: ${Spacing.lg}px;
  overflow-y: auto;
`;

const LowerContainer = styled(View)`
  flex-direction: row-reverse;
  align-items: center;
  padding: ${Spacing.md}px;
  gap: ${Spacing.md}px;
  flex-wrap: wrap;
`;

const TitleRow = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  padding: ${Spacing.lg}px;
  padding-bottom: 0px;
`;

export interface MoveProgramToFolderProps {
  isOpen: boolean;
  onClose: () => void;
  vm: MoveProgramToFolderViewModel;
  onAddFolderPress: () => void;
}

export const MoveProgramToFolderModal = observer(
  ({ isOpen, onClose, vm, onAddFolderPress }: MoveProgramToFolderProps) => {
    return (
      <Modal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        isOpen={isOpen}
        onClose={onClose}
        contentStyle={{ overflow: 'hidden' }}
      >
        <View style={{ width: 684 }}>
          <TitleRow>
            <FlexRow
              style={{
                flexWrap: 'wrap',
                flex: 1,
                justifyContent: 'space-between',
                gap: Spacing.sm,
              }}
            >
              <TextLG>Move {`"${vm.programToMove.title}"`}</TextLG>
            </FlexRow>
            <CloseButton onClick={onClose} />
          </TitleRow>
          <View
            style={{
              paddingLeft: Spacing.lg,
              paddingRight: Spacing.lg,
              paddingTop: Spacing.md,
              paddingBottom: Spacing.md,
            }}
          >
            <SearchInput
              style={{ backgroundColor: colors.neutral100 }}
              value={vm.searchText}
              unfocusedBorderColor="transparent"
              onChange={vm.onSearchChanged}
              placeholder={'Search folders'}
            />
          </View>
          <DividerLine></DividerLine>
          <MiddleContainer>
            {vm.programFolders.length === 0 ? (
              <View style={{ alignItems: 'center' }}>
                <DoubleCircledIcon containerStyle={{ marginBottom: Spacing.lg }} type="plus" />
                <TextMD>{vm.isSearching ? 'No folders found' : 'No folder'}</TextMD>
                <TextSMRegular style={{ color: colors.neutral500 }}>
                  {vm.isSearching
                    ? 'No folders matched your search'
                    : 'Create a folder to organize your programs'}
                </TextSMRegular>
                <PrimaryButton
                  title="Add Folder"
                  iconType="plus"
                  onClick={() => {
                    onClose();
                    onAddFolderPress();
                  }}
                  style={{ marginTop: Spacing.md }}
                />
              </View>
            ) : (
              <View style={{ gap: Spacing.md }}>
                {vm.programFolders.map(folder => {
                  return (
                    <FlexRow
                      key={folder.id}
                      style={{
                        cursor: 'pointer',
                        paddingTop: Spacing.xs,
                        paddingBottom: Spacing.xs,
                      }}
                      onClick={() => vm.onFolderSelected(folder.id)}
                    >
                      <StyledRadioButton
                        style={{ marginRight: Spacing.md }}
                        checked={vm.selectedFolderId === folder.id}
                      />
                      <CustomizableIcon type="folder-solid" tint={colors.neutral400} />
                      <TextMD
                        style={{ paddingLeft: Spacing.md, flex: 1 }}
                        color={colors.neutral900}
                      >
                        {folder.title}
                      </TextMD>
                    </FlexRow>
                  );
                })}
              </View>
            )}
          </MiddleContainer>
          <DividerLine></DividerLine>
          <LowerContainer>
            <PrimaryButton
              enabled={vm.isMoveEnabled}
              title="Move to Folder"
              onClick={async () => {
                await vm.onMovePressed();
                onClose();
              }}
              loading={vm.isMovingProgram}
            ></PrimaryButton>
            <SecondaryButton title="Cancel" onClick={onClose}></SecondaryButton>
          </LowerContainer>
        </View>
      </Modal>
    );
  },
);
