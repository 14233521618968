import { makeAutoObservable } from 'mobx';
import { urlBase64ToUint8Array } from 'utils/pureUtils';

type PushNotificationPermissions = 'granted' | 'denied' | 'default';

class PushNotificationsClass {
  pushNotificationPermission: PushNotificationPermissions = 'default';
  browserSupportsWorkerBasedNotifications =
    typeof navigator !== 'undefined' &&
    typeof window !== 'undefined' &&
    'PushManager' in window &&
    'serviceWorker' in navigator;
  isServiceWorkerRegistered = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _registerServiceWorker = async () => {
    if (this.browserSupportsWorkerBasedNotifications) {
      await navigator.serviceWorker.register('/pushNotificationServiceWorker.js');
    } else {
      throw new Error('Browser does not support service workers or push messages.');
    }
  };

  initialize = () => {
    this._checkNotificationPermission();
    if (this.browserSupportsWorkerBasedNotifications) {
      this._registerServiceWorker();
    }
  };

  private _checkNotificationPermission = () => {
    if (typeof Notification !== 'undefined') {
      this.pushNotificationPermission = Notification.permission;
    }
  };

  get arePushNotificationsEnabled() {
    return this.pushNotificationPermission === 'granted';
  }

  requestNotificationPermission = async () => {
    if (typeof Notification !== 'undefined') {
      this.pushNotificationPermission = await Notification.requestPermission();
    }
  };

  getPushSubscription = async (): Promise<PushSubscription> => {
    if (this.pushNotificationPermission !== 'granted') {
      throw new Error('Push notification permissions not granted');
    }
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) throw new Error('Service worker not registered');

    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      return existingSubscription;
    }

    const publicKey = process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY;
    if (!publicKey) {
      throw new Error('Public key not set in NEXT_PUBLIC_VAPID_PUBLIC_KEY');
    }

    const basy64PublicKey = urlBase64ToUint8Array(publicKey);

    return await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: basy64PublicKey,
    });
  };
}

export const PushNotifications = new PushNotificationsClass();
