import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import { ProfileImage } from 'components/ProfileImage';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { SearchInput } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { Client } from 'types/client';
import { ClientChatSearchViewModel } from './ClientChatSearchViewModel';

const SearchResultsContainer = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const NoResultContainer = styled.div`
  margin: 0px ${Spacing.sm}px;
  padding: ${Spacing.sm}px 0px;
`;

const SearchResultsDropdown = observer(
  ({ vm, style }: { vm: ClientChatSearchViewModel; style?: React.CSSProperties }) => {
    return (
      <SearchResultsContainer style={style}>
        {vm.results.length === 0 && (
          <NoResultContainer>
            <TextMD style={{ flexWrap: 'wrap', flex: 1 }}>Can't find "{vm.searchString}"</TextMD>
            <TextSMRegular style={{ flex: 1 }}>
              Search for a username, full name, or email of a client
            </TextSMRegular>
          </NoResultContainer>
        )}
        {vm.results.map(client => {
          return (
            <ClientSearchResult
              onSelect={vm.onSelectClient}
              key={client.username}
              client={client}
            />
          );
        })}
      </SearchResultsContainer>
    );
  },
);

const ClientResultContainer = styled(FlexRow)<{ isHovering: boolean }>`
  padding: ${Spacing.sm}px ${Spacing.md}px;
  background-color: ${({ isHovering }) => (isHovering ? colors.neutral100 : undefined)};
  cursor: pointer;
`;

const ClientSearchResult = ({
  client,
  onSelect,
}: {
  client: Client;
  onSelect: (client: Client) => void;
}) => {
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <ClientResultContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => onSelect(client)}
      isHovering={isHovering}
    >
      <ProfileImage diameter={28} source={client.profilePic} />
      <FlexRow style={{ marginLeft: Spacing.sm }}>
        <div>
          <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
            {client.username}
          </TextSMRegular>
          {client.fullName && (
            <TextSMRegular style={{ display: 'inline' }}>{` (${client.fullName})`}</TextSMRegular>
          )}
        </div>
      </FlexRow>
    </ClientResultContainer>
  );
};

const Container = styled.div`
  padding: 0px ${Spacing.md}px;
`;

export const ClientChatSearch = observer(
  ({
    vm,
    dropdownStyle,
  }: {
    vm: ClientChatSearchViewModel;
    dropdownStyle?: React.CSSProperties;
  }) => {
    const menuRef = React.useRef(null);
    const [menuState, toggleMenu] = useMenuState({ transition: true });

    autorun(() => {
      vm.showMenu ? toggleMenu(true) : toggleMenu(false);
    });

    return (
      <Container>
        <SearchInput
          autoFocus
          placeholder="Search for a client"
          style={{ backgroundColor: colors.neutral100 }}
          value={vm.searchString}
          onChange={vm.onSearchStringChanged}
        />
        <View
          ref={(ref: any) => {
            menuRef.current = ref;
          }}
        />
        <ControlledMenu
          menuStyle={{ padding: 0, margin: 0, overflow: 'hidden', borderRadius: CornerRadius.md }}
          anchorRef={menuRef}
          captureFocus={false}
          direction="bottom"
          {...menuState}
        >
          <SearchResultsDropdown vm={vm} style={dropdownStyle} />
        </ControlledMenu>
      </Container>
    );
  },
);
