import React from 'react';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { TextMD, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { ExerciseInstructionsStep } from 'screens/ExerciseLibrary/utils';
import { DividerLine } from 'styleguide/DividerLine';
import { ExerciseVideoThumbnailCell } from 'components/ExerciseVideoThumbnailCell';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { FlexRow } from 'styleguide/Row';
import { CircleView } from 'styleguide/CircleView';

export const ExerciseInstructionsView = ({
  exerciseTemplateTitle,
  customExerciseInstructions,
  exerciseInstructions,
  videoAttachment,
}: {
  exerciseTemplateTitle: string;
  customExerciseInstructions?: string;
  exerciseInstructions: ExerciseInstructionsStep[];
  videoAttachment?: VideoAttachment;
}) => {
  return (
    <FlexRow style={{ alignItems: 'flex-start' }}>
      <View>
        <View style={{ marginBottom: Spacing.lg }}>
          <TextMD>Exercise Instructions</TextMD>
          {customExerciseInstructions ? (
            <View style={{ paddingTop: Spacing.sm + Spacing.xs }}>
              <TextSMRegular
                style={{
                  flex: 1,
                  color: colors.neutral900,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {customExerciseInstructions}
              </TextSMRegular>
            </View>
          ) : exerciseInstructions?.length > 0 ? (
            exerciseInstructions.map((instruction, index, array) => (
              <View key={index}>
                <FlexRow
                  style={{
                    height: 'fit-content',
                    paddingTop: Spacing.md,
                    paddingBottom: Spacing.md,
                  }}
                >
                  <CircleView
                    diameter={34}
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: colors.neutral150,
                    }}
                  >
                    <TextSMMedium style={{ color: colors.neutral400 }}>
                      {instruction.index}
                    </TextSMMedium>
                  </CircleView>

                  <TextSMRegular
                    style={{ marginLeft: Spacing.md, flex: 1, color: colors.neutral900 }}
                  >
                    {instruction.description}
                  </TextSMRegular>
                </FlexRow>
                {index !== array.length - 1 && <DividerLine></DividerLine>}
              </View>
            ))
          ) : (
            <FlexRow style={{ paddingTop: Spacing.sm + Spacing.xs }}>
              <SingleCircledIcon type="menu" diameter={34}></SingleCircledIcon>
              <TextSMRegular style={{ flex: 1, marginLeft: Spacing.sm }}>
                There is no instructions available.
              </TextSMRegular>
            </FlexRow>
          )}
        </View>
        <View>
          <TextMD style={{ marginBottom: Spacing.sm }}>Attachment</TextMD>
          {!!videoAttachment ? (
            <ExerciseVideoThumbnailCell
              videoAttachment={videoAttachment}
              exerciseTitle={exerciseTemplateTitle}
            ></ExerciseVideoThumbnailCell>
          ) : (
            <FlexRow style={{ paddingTop: Spacing.sm + Spacing.xs }}>
              <SingleCircledIcon type="video" diameter={34}></SingleCircledIcon>
              <TextSMRegular style={{ flex: 1, marginLeft: Spacing.sm }}>
                There is no attachment
              </TextSMRegular>
            </FlexRow>
          )}
        </View>
      </View>
    </FlexRow>
  );
};
