import React from 'react';
import { ClickableText, PrimaryButton } from 'styleguide/Buttons';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { DividerLine } from 'styleguide/DividerLine';
import { TextMD, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { ClientChatCell } from './ClientChatCell';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Conversation, Message } from 'hevy-shared';
import { Client } from 'types/client';
import { SearchInput } from 'styleguide/Inputs';
import router from 'next/router';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { MobileAppPopup } from 'components/MobileAppPopup';
import { ConnectWithClients } from 'screens/Chat/components/ConnectWithClients';
import { localStorageStores } from 'state/localStorageStores';
import { HideOnMobileDiv } from 'components/mobileVsDesktopShowHideHelpers';

const SearchContainer = styled(View)`
  padding: ${Spacing.md}px;
  padding-top: 0px;
`;

const Body = styled(View)`
  overflow-x: hidden;
  flex: 1;
`;

const EmptyStateContainer = styled(View)`
  flex: 1;
  text-align: center;
  padding-top: ${Spacing.md}px;
`;

export const NoChatHistory = ({
  onNewChatClick,
  style,
  footerTextStyles,
}: {
  onNewChatClick: () => void;
  style?: React.CSSProperties;
  footerTextStyles?: React.CSSProperties;
}) => {
  return (
    <EmptyStateContainer style={style}>
      <View style={{ gap: Spacing.md, alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <DoubleCircledIcon type={'chats'}></DoubleCircledIcon>
        <View>
          <TextSMMedium style={{ marginBottom: Spacing.sm, textAlign: 'center' }}>
            No Chat History!
          </TextSMMedium>
          <TextSMRegular style={{ textAlign: 'center' }}>
            Start a new chat with one of your clients
          </TextSMRegular>
        </View>

        <PrimaryButton
          style={{ marginTop: Spacing.sm, maxHeight: 40 }}
          onClick={onNewChatClick}
          iconType="new-chat"
          title="Start New Chat"
        ></PrimaryButton>
      </View>

      <View style={{ width: '100%', alignSelf: 'flex-end' }}>
        <DividerLine />
        <div style={{ textAlign: 'center', padding: `${Spacing.md}px ${Spacing.sm}px` }}>
          <TextSMRegular style={{ display: 'inline', ...footerTextStyles }}>
            {'You can also decide not to use chat at all or enable/disable for any  client. '}
          </TextSMRegular>
          <ClickableText
            text={'Open Settings'}
            onClick={() => {
              router.push('/settings/chat');
            }}
            style={{ display: 'inline', ...footerTextStyles }}
          ></ClickableText>
        </div>
      </View>
    </EmptyStateContainer>
  );
};

interface ChatsOverviewMainContentProps {
  hasFailedToInitialize: boolean;
  isLoadingConversations: boolean;
  conversations: (Conversation & { unreadMessages: number })[];
  latestMessageForConversation: (conversationId: string) => Message | undefined;
  selectedConversationId: string | undefined;
  clientForConversation: (conversation: Conversation) => Client | undefined;
  onConversationPress: (conversationId: string) => void;
  onNewChatClick?: () => void;
  reset: () => void;
  style?: React.CSSProperties;
  searchText: string;
  onSearchTextChange: (text: string) => void;
  hideEmptyState?: boolean;
}

export const ChatsOverviewMainContent = ({
  hasFailedToInitialize,
  isLoadingConversations,
  conversations,
  latestMessageForConversation,
  selectedConversationId,
  clientForConversation,
  onConversationPress,
  onNewChatClick,
  reset,
  style,
  searchText,
  onSearchTextChange,
  hideEmptyState,
}: ChatsOverviewMainContentProps) => {
  return (
    <View style={style}>
      <SearchContainer>
        <SearchInput
          placeholder="Search conversations"
          style={{ backgroundColor: colors.neutral100 }}
          value={searchText}
          onChange={onSearchTextChange}
        />
      </SearchContainer>
      <DividerLine />
      <Body>
        {hasFailedToInitialize ? (
          <View style={{ alignSelf: 'center', padding: Spacing.lg, textAlign: 'center' }}>
            <TextMD>Error loading chat</TextMD>
            <ClickableText onClick={reset} text={'Retry'}></ClickableText>
          </View>
        ) : (
          <>
            {isLoadingConversations ? (
              <View style={{ alignSelf: 'center', padding: Spacing.lg }}>
                <MediumHevyActivityIndicator />
              </View>
            ) : (
              <>
                {conversations.length === 0 ? (
                  searchText.length > 0 ? (
                    <EmptyStateContainer>
                      <TextSMRegular>No results found</TextSMRegular>
                    </EmptyStateContainer>
                  ) : (
                    !hideEmptyState &&
                    !!onNewChatClick && (
                      <NoChatHistory
                        onNewChatClick={onNewChatClick}
                        footerTextStyles={{ fontSize: 12, lineHeight: '18px' }}
                      />
                    )
                  )
                ) : (
                  conversations.map(conversation => {
                    const client = clientForConversation(conversation);
                    if (!client) {
                      return <View key={conversation.conversation_id}>Error</View>;
                    }
                    return (
                      <View key={conversation.conversation_id}>
                        <ClientChatCell
                          clientId={client.id}
                          clientName={client.fullName || client.username}
                          clientProfilePic={client.profilePic}
                          lastMessage={latestMessageForConversation(conversation.conversation_id)}
                          onClick={() => {
                            onConversationPress(conversation.conversation_id);
                          }}
                          isSelected={selectedConversationId === conversation.conversation_id}
                          unreadMessages={conversation.unreadMessages}
                        />
                        <DividerLine />
                      </View>
                    );
                  })
                )}
              </>
            )}
          </>
        )}
      </Body>
      <HideOnMobileDiv>
        {localStorageStores.betaFeatures.areBetaFeaturesEnabled && (
          <MobileAppPopup
            title={'Get the Hevy Coach app!'}
            align={'center'}
            renderButton={ConnectWithClients}
          />
        )}
      </HideOnMobileDiv>
    </View>
  );
};
