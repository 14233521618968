import { CoachAccount } from 'hevy-shared';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { Client } from 'types/client';
import { memoryStores } from 'state/memoryStores';
import { Toggle } from 'components/Toggle';
import { EmptyState } from 'components/EmptyState';
import { localStorageStores } from 'state/localStorageStores';
import { ModalSearchHeader } from '../Components/ModalSearchHeader';
import { CoachSelectionRow } from './components/CoachSelectionRow';
import { compareCoachesAlphabetically } from 'utils/pureUtils';
import { CardNoPadding } from 'styleguide/Card';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const MiddleContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.lg}px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

export interface ReassignClientModalProps {
  clients: Client[];
  closeModal: () => void;
  isOpen: boolean;
  onCoachSelected: (coachId: string, keepProgram: boolean) => void;
  coachOptions: CoachAccount[];
  modalTitle: string;
}

export const ReassignClientModal = ({
  clients,
  closeModal,
  isOpen,
  onCoachSelected,
  coachOptions,
  modalTitle,
}: ReassignClientModalProps) => {
  const anyClientHasAssignedProgram = clients.some(
    client => !!localStorageStores.programs.programAssignedToClient(client.id),
  );
  const [selectedCoach, setSelectedCoach] = useState<CoachAccount | null>(null);
  const [keepProgram, setKeepProgram] = useState(true);
  const [searchText, setSearchText] = useState('');

  const filteredCoachOptions = coachOptions.filter(coach => {
    return (
      coach.full_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      coach.username?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Container>
        <ModalSearchHeader
          text={modalTitle}
          onClose={closeModal}
          searchPlaceholder={'Search coach'}
          searchValue={searchText}
          onSearchTextUpdated={setSearchText}
        />
        {anyClientHasAssignedProgram && (
          <>
            <DividerLine />
            <CardNoPadding style={{ margin: `${Spacing.md}px ${Spacing.lg}px` }}>
              <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
                <View>
                  <TextSMMedium>Keep current workout program?</TextSMMedium>
                  <TextSMRegular>
                    Continue with the client's current program, routines, and schedule.
                  </TextSMRegular>
                </View>
                <Toggle isOn={keepProgram} onClick={() => setKeepProgram(!keepProgram)} />
              </FlexRow>
            </CardNoPadding>
          </>
        )}

        <DividerLine />

        <MiddleContainer>
          {filteredCoachOptions.length === 0 && (
            <EmptyState title={'No coaches found'} iconType={'search'} />
          )}
          {filteredCoachOptions.sort(compareCoachesAlphabetically).map(coach => {
            return (
              <CoachSelectionRow
                key={coach.id}
                isSelected={selectedCoach?.id === coach.id}
                onSelected={() => setSelectedCoach(coach)}
                coachName={coach.full_name ?? coach.username}
                profilePic={coach.profile_pic}
                numberOfClients={memoryStores.clients.clientsForCoachId(coach.id).length}
                role={coach.role}
              />
            );
          })}
        </MiddleContainer>

        <DividerLine />

        <LowerContainer>
          <SecondaryButton title="Cancel" onClick={closeModal} />
          <PrimaryButton
            title={'Reassign'}
            enabled={!!selectedCoach}
            onClick={async () => {
              if (selectedCoach) {
                onCoachSelected(selectedCoach.id, keepProgram);
              }
              closeModal();
            }}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
