import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { View } from 'styleguide/View';
import { TextMD, TextSMMedium, TextSMRegular, TextXLMedium } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';
import { PrimaryButton } from 'styleguide/Buttons';
import { WhatsIncludedView } from '../Plan/components/WhatsIncludedView';
import { FlexColumn } from 'styleguide/Column';
import { ProgressBar } from 'styleguide/ProgressBar';
import { colors } from 'styleguide/colors';
import { useDeviceSize } from 'utils/useSize';
import { localStorageStores } from 'state/localStorageStores';
import { sendEvent } from 'utils/analyticsEvents';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border-radius: ${CornerRadius.md}px;
`;

export interface TrialStartedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FreePlanNotAvailableDescriptionView = ({ trialDaysLeft }: { trialDaysLeft: number }) => {
  return (
    <FlexColumn style={{ minHeight: 200, justifyContent: 'space-between', flex: 1 }}>
      <View>
        <TextXLMedium>Your free plan for 0 to 3 clients is no longer available</TextXLMedium>
      </View>
      <TextSMRegular>
        In our commitment to delivering the best possible product, we have decided to discontinue
        support for our free plan. As part of this transition, you have been enrolled in a 30-day
        trial, granting you complete access to all our premium features.
      </TextSMRegular>
      <View>
        <TextSMMedium style={{ marginBottom: Spacing.sm }}>
          {trialDaysLeft} days left on your trial, upgrade anytime to continue coaching your
          clients.
        </TextSMMedium>
        <ProgressBar
          progressFraction={(30 - trialDaysLeft) / 30}
          backgroundColor={colors.neutral150}
        ></ProgressBar>
      </View>
    </FlexColumn>
  );
};

export const TrialStartedModal = observer(({ isOpen, onClose }: TrialStartedModalProps) => {
  const device = useDeviceSize();
  const viewMargin = device === 'phone' ? Spacing.md : Spacing.lg;
  const trialDaysLeft = localStorageStores.plan.trialDaysLeft ?? 0;
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContainer style={{ width: device === 'phone' ? 400 : 800 }}>
        <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
          <TextMD>New updates in your plan</TextMD>
          <CloseButton
            onClick={() => {
              onClose();
            }}
          />
        </FlexRow>
        <DividerLine />
        <div style={{ overflowY: 'auto' }}>
          {device === 'phone' && (
            <>
              <View style={{ margin: viewMargin }}>
                <FreePlanNotAvailableDescriptionView trialDaysLeft={trialDaysLeft} />
              </View>
              <DividerLine />
            </>
          )}
          <FlexRow style={{ alignItems: 'stretch' }}>
            {device !== 'phone' && (
              <>
                <View style={{ flex: 1, margin: viewMargin, alignSelf: 'stretch' }}>
                  <FreePlanNotAvailableDescriptionView trialDaysLeft={trialDaysLeft} />
                </View>
              </>
            )}
            <View
              style={{ width: 1, backgroundColor: colors.neutral150, alignSelf: 'stretch' }}
            ></View>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                margin: viewMargin,
              }}
            >
              <WhatsIncludedView />
            </View>
          </FlexRow>
        </div>
        <DividerLine />
        <FlexRow style={{ margin: viewMargin, justifyContent: 'flex-end' }}>
          <PrimaryButton
            title="Continue with Free Trial"
            onClick={() => {
              sendEvent('freeTrialEnded_continuePressed');
              onClose();
            }}
          />
        </FlexRow>
      </ModalContainer>
    </Modal>
  );
});
