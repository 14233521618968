import React from 'react';
import { Modal } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { observer } from 'mobx-react-lite';
import { ProgressPictureModalViewModel } from './ProgressPictureModalViewModel';
import { FlexRow } from 'styleguide/Row';
import { DividerLine } from 'styleguide/DividerLine';
import styled from 'styled-components';
import { ProgressPictureCell } from './components/ProgressPictureCell';
import { PictureViewModeSelector } from './components/PictureViewModeSelector';
import {
  LargeProgressPicture,
  MobileLargeProgressPicture,
} from './components/LargeProgressPicture';
import { ModalHeader } from 'components/Modals/Components/ModalHeader';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { HideOnPhoneDiv, ShowOnPhoneDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const MiddleContainer = styled(FlexRow)`
  align-items: flex-start;
  height: 100%;
  max-height: 684px;
  overflow-y: auto;
`;

const PictureSelectionContainer = styled(View)`
  overflow-y: scroll;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px;
  box-sizing: border-box; // to include padding in width and height
  height: 100%;
  width: 250px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const RightContainer = styled(View)`
  height: 100%;
  flex: 1;
  padding: ${Spacing.md}px;
  gap: ${Spacing.md}px;
  box-sizing: border-box;
  align-items: center;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: 0;
    padding-top: ${Spacing.md}px;
  }
  overflow-y: auto;
`;

const Container = styled(View)`
  width: 996px;
`;

const LoadingView = () => {
  return (
    <View
      style={{
        height: 500,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MediumHevyActivityIndicator />
    </View>
  );
};

export const ProgressPictureModal = observer(({ vm }: { vm: ProgressPictureModalViewModel }) => {
  return (
    <Modal
      isOpen={vm.isOpen}
      onClose={vm.onClose}
      contentStyle={{ width: 996, overflow: 'hidden' }}
    >
      <Container>
        <ModalHeader text={'Progress Pictures'} onClose={vm.onClose} />
        <DividerLine />
        {vm.isLoading ? (
          <LoadingView />
        ) : (
          <>
            <MiddleContainer>
              <PictureSelectionContainer>
                {vm.measurements.map(measurement => {
                  return (
                    <ProgressPictureCell
                      measurement={measurement}
                      key={measurement.id}
                      selected={vm.selectionModeForMeasurementId(measurement.id)}
                      onClick={() => vm.onMeasurementClicked(measurement.id)}
                    />
                  );
                })}
              </PictureSelectionContainer>
              <RightContainer>
                <PictureViewModeSelector
                  onSelectOption={vm.onSelectPictureViewMode}
                  selectedOption={vm.pictureViewMode}
                />

                <FlexRow style={{ justifyContent: 'center' }}>
                  {!!vm.singleSelectedMeasurement ? (
                    <View style={{ width: '50%', boxSizing: 'border-box' }}>
                      <ShowOnPhoneDiv>
                        <MobileLargeProgressPicture
                          measurement={vm.singleSelectedMeasurement}
                          onClickChangePicture={vm.onChangePictureClick}
                        />
                      </ShowOnPhoneDiv>
                      <HideOnPhoneDiv>
                        <LargeProgressPicture
                          isActive={false}
                          measurement={vm.singleSelectedMeasurement}
                        />
                      </HideOnPhoneDiv>
                    </View>
                  ) : (
                    <>
                      <ShowOnPhoneDiv style={{ height: '100%' }}>
                        <DividerLine />
                        <FlexRow style={{ height: '100%' }}>
                          <MobileLargeProgressPicture
                            measurement={vm.beforeSelectedMeasurement}
                            pictureType="before"
                            onClickChangePicture={vm.onChangePictureClick}
                          />
                          <MobileLargeProgressPicture
                            measurement={vm.afterSelectedMeasurement}
                            pictureType="after"
                            onClickChangePicture={vm.onChangePictureClick}
                          />
                        </FlexRow>
                      </ShowOnPhoneDiv>
                      <HideOnPhoneDiv style={{ height: '100%' }}>
                        <FlexRow style={{ gap: Spacing.sm, height: '100%' }}>
                          <LargeProgressPicture
                            isActive={vm.activePicture === 'before'}
                            measurement={vm.beforeSelectedMeasurement}
                            pictureType="before"
                            onClick={() => vm.onLargePictureClicked('before')}
                          />
                          <LargeProgressPicture
                            isActive={vm.activePicture === 'after'}
                            measurement={vm.afterSelectedMeasurement}
                            pictureType="after"
                            onClick={() => vm.onLargePictureClicked('after')}
                          />
                        </FlexRow>
                      </HideOnPhoneDiv>
                    </>
                  )}
                </FlexRow>
              </RightContainer>
            </MiddleContainer>
          </>
        )}
      </Container>
    </Modal>
  );
});
