import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { IconFormInput, TextAreaAutosize } from 'styleguide/Inputs';
import { Select, Multiselect, isUpdate } from 'components/Select';
import { ClickableText, PrimaryButton } from 'styleguide/Buttons';
import { ExerciseImage } from 'components/ExerciseImage';
import { TextLG, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { CropImage } from 'components/Modals/CropImage/CropImage';
import { EditExerciseViewModel } from './EditExerciseViewModel';
import { Spacing } from 'styleguide/spacing';
import { VideoUploadCell } from './components/VideoUploadCell/VideoUploadCell';
import { ExerciseVideoThumbnailCell } from 'components/ExerciseVideoThumbnailCell';
import { CloseButton } from 'components/CloseButton';
import { zIndexes } from 'styleguide/zIndex';
import { FlexRow } from 'styleguide/Row';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const Container = styled(View)`
  max-width: 684px;
  width: 100vw;
`;

const TopContainer = styled(FlexRow)`
  padding: ${Spacing.lg}px;
  justify-content: space-between;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

const MiddleContainer = styled(View)`
  overflow-y: auto;
  padding: ${Spacing.lg}px;
  gap: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
    gap: ${Spacing.md}px;
  }
`;

const BottomContainer = styled(View)`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

const CameraCircle = styled(View)`
  height: 128px;
  width: 128px;
  border-radius: 64px;
  margin-bottom: ${Spacing.md}px;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.neutral150};
`;

const Label = styled.label`
  color: ${colors.primary500};
  cursor: pointer;
`;

const CellContainer = styled(View)``;

const CellContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
`;

const CropContainer = styled(View)`
  width: 100%;
  height: 80vh;
`;

export interface EditExerciseModalProps {
  vm: EditExerciseViewModel;
  isOpen: boolean;
}

export const EditExerciseModal = observer(({ vm, isOpen }: EditExerciseModalProps) => {
  const exerciseNameRef = useRef<HTMLDivElement>(null);
  const exerciseTypeRef = useRef<HTMLDivElement>(null);
  const equipmentRef = useRef<HTMLDivElement>(null);
  const muscleGroupRef = useRef<HTMLDivElement>(null);

  const scrollToTopError = () => {
    if (vm.invalidFields.exerciseName) {
      exerciseNameRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (vm.invalidFields.exerciseType) {
      exerciseTypeRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (vm.invalidFields.equipment) {
      equipmentRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (vm.invalidFields.primaryMuscleGroup) {
      muscleGroupRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={vm.onCloseRequested}
      overlayStyle={{ zIndex: zIndexes.modalOverlay }}
    >
      <Container>
        {vm.imageUrlToCrop ? (
          <CropContainer>
            <CropImage
              imageUrl={vm.imageUrlToCrop}
              onImageCropped={vm.onUploadImage}
              onCancel={vm.onCancelImageCrop}
            />
          </CropContainer>
        ) : (
          <>
            <TopContainer>
              <TextLG>{vm.modalTitle}</TextLG>
              <CloseButton onClick={vm.onCloseRequested} />
            </TopContainer>
            <DividerLine />
            <MiddleContainer>
              <View style={{ alignItems: 'center', overflow: 'visible' }}>
                <Label htmlFor="file-upload">
                  <CameraCircle>
                    <ImageComponent
                      isLoadingImage={vm.isUploadingImage}
                      remoteThumbnailUrl={vm.remoteThumbnailUrl}
                      isCustomExercise={vm.isCustomExercise}
                    />
                  </CameraCircle>
                </Label>
                <Label htmlFor="file-upload">
                  <input
                    style={{ display: 'none' }}
                    id="file-upload"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={e => {
                      const file = e?.target.files?.[0];
                      if (file) {
                        vm.onFileSystemImageSelected(file);
                      }
                    }}
                    disabled={vm.mode === 'edit-bundled-exercise'}
                  />
                  {vm.mode !== 'edit-bundled-exercise' && (
                    <ClickableText
                      text={!!vm.remoteThumbnailUrl ? 'Change Image' : 'Add Image'}
                    ></ClickableText>
                  )}
                </Label>
              </View>
              <CellContainer>
                <CellContent>
                  <TextSMMedium ref={exerciseNameRef}>Exercise Name</TextSMMedium>
                  <View style={{ width: '100%' }}>
                    <IconFormInput
                      disabled={vm.mode === 'edit-bundled-exercise'}
                      placeholder=""
                      value={vm.exerciseTitle}
                      onChange={vm.onTitleUpdate}
                      style={{
                        borderColor:
                          vm.showErrors && vm.invalidFields.exerciseName
                            ? colors.error500
                            : colors.neutral150,
                      }}
                      showDisabledIcon={true}
                      disabledIconTooltipText="The metadata for this exercise is predefined and cannot be modified."
                    />
                  </View>
                  {vm.showErrors && vm.invalidFields.exerciseName && (
                    <TextSMMedium style={{ color: colors.error500 }}>Enter a title</TextSMMedium>
                  )}
                </CellContent>
              </CellContainer>

              <CellContainer>
                <CellContent>
                  <TextSMMedium ref={exerciseTypeRef}>Exercise Type</TextSMMedium>
                  <View style={{ width: '100%' }}>
                    <Select
                      isDisabled={vm.isEditing}
                      tooltipText={
                        vm.editingCustomExerciseTemplateId
                          ? 'You can not update exercise type of a custom exercise.'
                          : 'The metadata for this exercise is predefined and cannot be modified.'
                      }
                      value={vm.selectedExerciseType}
                      onChange={e => {
                        if (isUpdate(e) || e === undefined) {
                          vm.onExerciseTypeUpdate(e);
                        }
                      }}
                      options={vm.exerciseTypeOptions}
                      overrideBorderColor={
                        vm.showErrors && vm.invalidFields.exerciseType ? colors.error500 : undefined
                      }
                    />
                  </View>
                  {vm.showErrors && vm.invalidFields.exerciseType && (
                    <TextSMMedium style={{ color: colors.error500 }}>
                      Select an exercise type
                    </TextSMMedium>
                  )}
                </CellContent>
              </CellContainer>

              <CellContainer>
                <CellContent>
                  <TextSMMedium ref={equipmentRef}>Equipment</TextSMMedium>
                  <View style={{ width: '100%' }}>
                    <Select
                      isDisabled={vm.mode === 'edit-bundled-exercise'}
                      tooltipText="The metadata for this exercise is predefined and cannot be modified."
                      options={vm.equipmentOptions}
                      value={vm.selectedEquipment}
                      onChange={e => {
                        if (isUpdate(e) || e === undefined) {
                          vm.onEquipmentUpdate(e);
                        }
                      }}
                      overrideBorderColor={
                        vm.showErrors && vm.invalidFields.equipment ? colors.error500 : undefined
                      }
                    />
                  </View>
                  {vm.showErrors && vm.invalidFields.equipment && (
                    <TextSMMedium style={{ color: colors.error500 }}>Select equipment</TextSMMedium>
                  )}
                </CellContent>
              </CellContainer>
              <CellContainer>
                <CellContent>
                  <TextSMMedium ref={muscleGroupRef}>Primary Muscle Group</TextSMMedium>
                  <View style={{ width: '100%' }}>
                    <Select
                      isDisabled={vm.mode === 'edit-bundled-exercise'}
                      tooltipText="The metadata for this exercise is predefined and cannot be modified."
                      options={vm.muscleGroupOptions}
                      value={vm.selectedPrimaryMuscleGroup}
                      onChange={e => {
                        if (isUpdate(e) || e === undefined) {
                          vm.onMuscleGroupUpdate(e);
                        }
                      }}
                      overrideBorderColor={
                        vm.showErrors && vm.invalidFields.primaryMuscleGroup
                          ? colors.error500
                          : undefined
                      }
                    />
                  </View>
                  {vm.showErrors && vm.invalidFields.primaryMuscleGroup && (
                    <TextSMMedium style={{ color: colors.error500 }}>
                      Select a primary muscle group
                    </TextSMMedium>
                  )}
                </CellContent>
              </CellContainer>
              <CellContainer>
                <CellContent>
                  <View style={{ flexDirection: 'row', gap: Spacing.sm }}>
                    <TextSMMedium>Other Muscles</TextSMMedium>
                    <TextSMRegular style={{ color: colors.neutral400 }}>(optional)</TextSMRegular>
                  </View>
                  <View style={{ width: '100%' }}>
                    <Multiselect
                      isDisabled={vm.mode === 'edit-bundled-exercise'}
                      tooltipText="The metadata for this exercise is predefined and cannot be modified."
                      options={vm.secondaryMuscleGroupOptions}
                      value={vm.selectedSecondaryMuscleGroups}
                      onChange={(e: any) => vm.onSecondaryMuscleGroupUpdate(e)}
                    />
                  </View>
                </CellContent>
              </CellContainer>
              <CellContainer>
                <CellContent>
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: Spacing.sm,
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                  >
                    <TextSMMedium>Exercise Instructions</TextSMMedium>
                    {vm.defaultExerciseInstructions && !vm.isShowingDefaultInstructions && (
                      <TextSMMedium
                        style={{ color: colors.neutral400, cursor: 'pointer' }}
                        onClick={vm.onResetToDefaultInstructions}
                      >
                        Reset To Default
                      </TextSMMedium>
                    )}
                  </View>
                  <View style={{ width: '100%' }}>
                    <TextAreaAutosize
                      value={vm.instructions}
                      onChange={e => vm.onInstructionsUpdate(e.currentTarget.value)}
                      style={{ backgroundColor: colors.neutral50 }}
                      placeholder="Type some exercise instructions here... e.g. keep your back straight"
                    />
                  </View>
                </CellContent>
              </CellContainer>
              <CellContainer>
                <TextSMMedium style={{ marginBottom: Spacing.sm }}>Video attachment</TextSMMedium>
                {!!vm.videoAttachment ? (
                  <FlexRow style={{ gap: Spacing.sm }}>
                    <ExerciseVideoThumbnailCell
                      videoAttachment={vm.videoAttachment}
                      onRemoveVideo={vm.onRemoveVideoAttachment}
                      exerciseTitle={vm.exerciseTitle}
                    ></ExerciseVideoThumbnailCell>
                  </FlexRow>
                ) : (
                  <VideoUploadCell
                    isUploadingVideo={vm.isUploadingVideo}
                    onAddVideoAttachment={vm.onAddVideoAttachment}
                    vm={vm.videoCellVm}
                  ></VideoUploadCell>
                )}
              </CellContainer>
            </MiddleContainer>

            <DividerLine />
            <BottomContainer>
              <PrimaryButton
                title={vm.buttonTitle}
                onClick={() => {
                  vm.onSavePressed();
                  scrollToTopError();
                }}
                enabled={vm.isSaveEnabled}
                loading={vm.isSaving}
              />
            </BottomContainer>
          </>
        )}
      </Container>
    </Modal>
  );
});

interface ImageComponentProp {
  isLoadingImage: boolean;
  remoteThumbnailUrl?: string;
  isCustomExercise?: boolean;
}

const ImageComponent = ({
  isLoadingImage,
  remoteThumbnailUrl,
  isCustomExercise,
}: ImageComponentProp) => {
  if (isLoadingImage) {
    return <MediumHevyActivityIndicator />;
  }

  if (!!remoteThumbnailUrl || !isCustomExercise) {
    return <ExerciseImage source={remoteThumbnailUrl} diameter={128} />;
  }

  return <CustomizableIcon tint={colors.neutral900} type="camera" />;
};
