import React from 'react';
import styled from 'styled-components';
import { Link } from '../Link';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { HevyCoachLogo } from 'components/Logo/HevyCoachLogo';
import { modal } from 'components/Modals/ModalManager';
import { Icon } from 'styleguide/Icon';
import { zIndexes } from 'styleguide/zIndex';
import { NavBarUserSearchViewModel } from './components/NavBarSearchViewModel';
import { NavBarMainContent } from './components/NavBarMainContent';
import { observer } from 'mobx-react-lite';
import { NavBarSearch } from './components/NavBarSearch';
import { FlexRow } from 'styleguide/Row';
import { localStorageStores } from 'state/localStorageStores';
import { FlexColumn } from 'styleguide/Column';
import { TextSMMedium } from 'styleguide/Texts';
import { sendEvent } from 'utils/analyticsEvents';
import router from 'next/router';

const MobileNavBarContainer = styled(FlexRow)`
  height: 64px;
  background-color: ${colors.sideMenuBackground};
  position: sticky;
  top: 0;
  justify-content: space-between;
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
  z-index: ${zIndexes.aboveSurface};
`;

const DesktopNavBarContainer = styled(View)`
  min-width: 256px;
  max-width: 256px;
  height: 100vh;
  background-color: ${colors.sideMenuBackground};
  position: sticky;
  top: 0;
  @media (max-width: 1024px) {
    display: none;
  }
  color-scheme: dark; // This is to make the scrollbar dark for window heights that are too small
`;

const HevyCoachLogoContainer = styled.div`
  padding-top: ${Spacing.lg}px;
  padding-left: ${Spacing.sm}px;
`;

export const DesktopVerticalNavBar = observer(({ pathname }: { pathname: string }) => {
  const searchVm = new NavBarUserSearchViewModel();
  return (
    <DesktopNavBarContainer>
      <HevyCoachLogoContainer>
        <Link href="/">
          <HevyCoachLogo />
        </Link>
      </HevyCoachLogoContainer>

      <NavBarMainContent vm={searchVm} pathname={pathname} />
    </DesktopNavBarContainer>
  );
});

export const MobileHorizontalNavBar = () => {
  const searchVm = new NavBarUserSearchViewModel();
  return (
    <MobileNavBarContainer>
      <View
        onClick={() => {
          modal.openMobileNavBarModal();
        }}
        style={{ margin: Spacing.md }}
      >
        <Icon style={{ cursor: 'pointer' }} type="menu" />
      </View>
      <View style={{ flex: 1 }}>
        <NavBarSearch vm={searchVm} />
      </View>
      {localStorageStores.plan.isTrialing && (
        <FlexColumn
          style={{ paddingRight: Spacing.sm, cursor: 'pointer' }}
          onClick={() => {
            sendEvent('mobile_horizontal_navbar_startFreeTrial_clicked');
            modal.openUpgradePlanModal({
              source: 'mobile_horizontal_navbar',
              onClose: upgraded => {
                if (upgraded) {
                  router.reload();
                }
              },
            });
          }}
        >
          <TextSMMedium style={{ color: colors.white }}>
            Trial {localStorageStores.plan.trialDaysLeft}{' '}
            {localStorageStores.plan.trialDaysLeft === 1 ? 'day' : 'days'} left
          </TextSMMedium>
          <TextSMMedium style={{ color: colors.primary500 }}>Upgrade Now</TextSMMedium>
        </FlexColumn>
      )}
    </MobileNavBarContainer>
  );
};
