import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { TextLG, TextMDRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';
import { DividerLine } from 'styleguide/DividerLine';
import { InviteeView } from '../components/InviteeView';
import { SearchUserInput } from '../components/SearchUserInput';
import { CoachInviteViewModel } from './CoachInviteViewModel';
import { RoleSelector } from '../components/RoleSelector';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

const Container = styled(View)`
  border-radius: 10px;
  width: 684px;
`;

const UpperContainer = styled.div`
  padding: ${Spacing.lg}px ${Spacing.lg}px ${Spacing.md}px ${Spacing.lg}px;
`;

const LowerContainer = styled.div`
  display: flex;
  gap: ${Spacing.md}px;
  justify-content: flex-end;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  flex-wrap: wrap;
`;

const InviteesContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  flex: 1;
  overflow-y: auto;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

const SelectorContainer = styled(View)`
  width: 150px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    width: 100px;
  }
`;

export interface CoachInviteModalProps {
  vm: CoachInviteViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const CoachInviteModal = observer(({ vm, isOpen, onClose }: CoachInviteModalProps) => {
  return (
    <Modal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
      contentStyle={{ overflow: 'auto' }}
    >
      <Container>
        <UpperContainer>
          <FlexRow style={{ alignContent: 'center', justifyContent: 'space-between' }}>
            <FlexRow>
              <TextLG>{vm.title}</TextLG>
            </FlexRow>
            <CloseButton onClick={onClose} />
          </FlexRow>

          <TextMDRegular style={{ paddingBottom: Spacing.md, paddingTop: Spacing.md }}>
            {vm.subtext}
          </TextMDRegular>

          <SearchUserInput vm={vm} placeholder={'coach@example.com'} />
        </UpperContainer>
        {vm.inviteeCount > 0 && (
          <>
            <DividerLine />
            <InviteesContainer>
              {vm.invitees.map((invitee, index) => {
                return (
                  <FlexRow key={index} style={{ gap: Spacing.md, justifyContent: 'space-between' }}>
                    <InviteeView invitee={invitee} />
                    <FlexRow style={{ gap: Spacing.md }}>
                      {vm.showInviteeRoleSelector && (
                        <SelectorContainer>
                          <RoleSelector
                            onChange={newValue =>
                              vm.onInviteeRoleChanged?.(newValue?.value || 'member', invitee)
                            }
                            role={invitee.role === 'admin' ? 'admin' : 'member'}
                          />
                        </SelectorContainer>
                      )}
                      <SecondaryButton
                        title=""
                        iconType="trash"
                        onClick={() => vm.onRemoveInvitee(invitee)}
                      />
                    </FlexRow>
                  </FlexRow>
                );
              })}
            </InviteesContainer>
          </>
        )}

        <DividerLine />
        <LowerContainer>
          <PrimaryButton
            enabled={vm.isSubmitAvailable}
            style={{ display: 'flex', paddingLeft: Spacing.lg, paddingRight: Spacing.lg }}
            title={vm.inviteeCount > 1 ? `Send ${vm.inviteeCount} Invitations` : `Send Invitation`}
            loading={vm.isHandlingSubmit}
            onClick={vm.sendInvites}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});
