import React from 'react';
import { colors } from 'styleguide/colors';
import { CornerRadius } from 'styleguide/spacing';
import { ChatMessageContainer } from './ChatMessageContainer';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { ClipLoader } from 'react-spinners';

const ViewFiller = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const VideoChatMessage = ({
  videoUrl,
  source,
  isSender,
  timestamp,
  clientProfilePic,
  isLoading,
  isLastMessageOfMessageBlock,
  onDeleteMessageClick,
}: {
  videoUrl: string;
  source: 'widget' | 'screen';
  isSender: boolean;
  timestamp: string;
  clientProfilePic?: string;
  isLoading: boolean;
  isLastMessageOfMessageBlock: boolean;
  onDeleteMessageClick?: () => void;
}) => {
  return (
    <ChatMessageContainer
      isSender={isSender}
      messageTimestamp={timestamp}
      clientProfilePic={clientProfilePic}
      onDeleteMessageClick={onDeleteMessageClick}
      source={source}
      isLastMessageOfMessageBlock={isLastMessageOfMessageBlock}
    >
      <View style={{ position: 'relative' }}>
        {isLoading && (
          <>
            <ViewFiller style={{ backgroundColor: colors.white, opacity: 0.5 }} />
            <ViewFiller>
              <ClipLoader size={20} color={colors.neutral900} loading={true} />
            </ViewFiller>
          </>
        )}
        <video
          style={{
            height: 200,
            width: '100%',
            borderRadius: CornerRadius.xl,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.neutral150,
            objectFit: 'contain',
          }}
          key={videoUrl}
          loop
          muted
          playsInline
          width="100%"
          controls={!isLoading}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </View>
    </ChatMessageContainer>
  );
};
