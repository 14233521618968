import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { TextSMMedium } from 'styleguide/Texts';
import { getSupersetColor } from 'utils/misc';

const Container = styled.div`
  padding: ${Spacing.xs}px ${Spacing.md}px;
  border-radius: 7px;
`;

interface SupersetChickletProps {
  id: number;
  style?: CSSProperties;
}

export const SupersetChicklet = ({ id }: SupersetChickletProps) => (
  <Container style={{ backgroundColor: getSupersetColor(id) }}>
    <TextSMMedium style={{ color: colors.white }}>Superset</TextSMMedium>
  </Container>
);
