import { env } from 'env';
import { colors } from 'styleguide/colors';

export const isProduction = () => {
  return env.env === 'production';
};

export const getSupersetColor = (id: number): string => {
  const normalizedId = id % colors.supersets.length;
  return colors.supersets[normalizedId].bold;
};
