import React from 'react';
import { observer } from 'mobx-react-lite';
import { localStorageStores } from 'state/localStorageStores';
import { ClickableText } from 'styleguide/Buttons';
import { TextSMRegular } from 'styleguide/Texts';
import { WarningBanner } from 'components/WarningBanner';
import { colors } from 'styleguide/colors';

export const ImpersonationWarningBanner = observer(() => {
  const shouldShowImpersonationWarning =
    localStorageStores.auth.isImpersonating && localStorageStores.lifecycle.isBootstrapped;
  if (!shouldShowImpersonationWarning) {
    return <></>;
  }
  return (
    <>
      <WarningBanner backgroundColor={colors.error500}>
        <div>
          <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
            You are impersonating {localStorageStores.account.username}.
          </TextSMRegular>
          <TextSMRegular style={{ color: colors.neutral900, display: 'inline' }}>
            {' '}
            All done? Please{' '}
          </TextSMRegular>
          <ClickableText
            style={{ display: 'inline', color: colors.neutral900 }}
            text={'become yourself again.'}
            onClick={localStorageStores.auth.stopImpersonatingUser}
          ></ClickableText>
        </div>
      </WarningBanner>
    </>
  );
});
