import { MuscleGroup } from 'hevy-shared';
import React, { useState } from 'react';
import { FlexColumn } from 'styleguide/Column';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { DividerLine } from 'styleguide/DividerLine';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { muscleGroupToHuman } from 'utils/exerciseUtils';

export type SortDirection = 'asc' | 'desc';

export const MuscleGroupSetsTable = ({
  muscleGroups,
}: {
  muscleGroups: { muscleGroupName: MuscleGroup; numberOfSets: number }[];
}) => {
  const [sortedColumn, setSortedColumn] = useState<'muscle-group' | 'set-count'>('muscle-group');
  const [muscleGroupSortDirection, setMuscleGroupSortDirection] = useState<SortDirection>('asc');
  const [setCountSortDirection, setSetCountSortDirection] = useState<SortDirection>('asc');

  const sortedMuscleGroups = muscleGroups.sort((a, b) => {
    if (sortedColumn === 'muscle-group') {
      if (muscleGroupSortDirection === 'desc') {
        return b.muscleGroupName.localeCompare(a.muscleGroupName);
      } else {
        return a.muscleGroupName.localeCompare(b.muscleGroupName);
      }
    } else {
      if (setCountSortDirection === 'desc') {
        return b.numberOfSets - a.numberOfSets;
      } else {
        return a.numberOfSets - b.numberOfSets;
      }
    }
  });

  return (
    <View style={{ marginTop: Spacing.sm }}>
      <FlexRow
        style={{
          marginTop: Spacing.sm,
          marginLeft: -Spacing.sm,
          marginRight: -Spacing.sm,
          backgroundColor: colors.neutral100,
          borderRadius: CornerRadius.sm,
          padding: Spacing.sm,
          cursor: 'pointer',
        }}
      >
        <FlexRow
          style={{ flex: 0.75, gap: Spacing.sm }}
          onClick={() => {
            if (sortedColumn === 'muscle-group') {
              if (muscleGroupSortDirection === 'desc') {
                setMuscleGroupSortDirection('asc');
              } else {
                setMuscleGroupSortDirection('desc');
              }
            } else {
              setSortedColumn('muscle-group');
            }
          }}
        >
          <TextSMRegular style={{ color: colors.neutral900 }}>Muscle Group</TextSMRegular>
          <View style={{ rotate: muscleGroupSortDirection === 'desc' ? '180deg' : '0deg' }}>
            <CustomizableIcon
              type="sort-arrow"
              tint={sortedColumn === 'muscle-group' ? colors.neutral900 : colors.neutral300}
            />
          </View>
        </FlexRow>
        <FlexRow
          style={{ flex: 0.25, justifyContent: 'flex-end' }}
          onClick={() => {
            if (sortedColumn === 'set-count') {
              if (setCountSortDirection === 'desc') {
                setSetCountSortDirection('asc');
              } else {
                setSetCountSortDirection('desc');
              }
            } else {
              setSortedColumn('set-count');
            }
          }}
        >
          <FlexRow style={{ gap: Spacing.sm }}>
            <TextSMRegular style={{ color: colors.neutral900 }}>Sets</TextSMRegular>
            <View style={{ rotate: setCountSortDirection === 'desc' ? '180deg' : '0deg' }}>
              <CustomizableIcon
                type="sort-arrow"
                tint={sortedColumn === 'set-count' ? colors.neutral900 : colors.neutral300}
              />
            </View>
          </FlexRow>
        </FlexRow>
      </FlexRow>
      <FlexColumn style={{ gap: Spacing.sm, paddingTop: Spacing.sm }}>
        {sortedMuscleGroups.map((mg, index) => {
          return (
            <View key={mg.muscleGroupName} style={{ gap: Spacing.sm }}>
              <FlexRow style={{}}>
                <TextSMRegular style={{ flex: 0.75 }}>
                  {muscleGroupToHuman(mg.muscleGroupName)}
                </TextSMRegular>
                <TextSMRegular type="secondary" style={{ flex: 0.25, textAlign: 'end' }}>
                  {mg.numberOfSets}
                </TextSMRegular>
              </FlexRow>
              {index !== sortedMuscleGroups.length - 1 && <DividerLine />}
            </View>
          );
        })}
      </FlexColumn>
    </View>
  );
};
