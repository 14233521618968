import { Program } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { ClientProgram, ProgramIsClientProgram } from 'utils/programUtils';
import { localStorageStores } from 'state/localStorageStores';

const PROGRAMS_LOCAL_STORAGE_KEY = 'PROGRAMS_LOCAL_STORAGE_KEY';

export class Programs {
  private _programs: Program[] = [];

  get myPrograms(): Program[] {
    return this._programs.filter(p => {
      return p.coach_id === localStorageStores.account.id;
    });
  }

  removeProgramWithId = (programId: string) => {
    this._programs = this._programs.filter(program => {
      return program.id !== programId;
    });
  };

  get otherTeamMemberTemplatePrograms(): Program[] {
    return this._programs.filter(p => {
      return p.coach_id !== localStorageStores.account.id && p.client_id === null;
    });
  }

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const programsJSON = window.localStorage.getItem(PROGRAMS_LOCAL_STORAGE_KEY);
    if (programsJSON) {
      this._programs = JSON.parse(programsJSON);

      window.localStorage.setItem(PROGRAMS_LOCAL_STORAGE_KEY, JSON.stringify(this._programs));
    }
  };

  removeProgram = (programId: string) => {
    this._programs = this._programs.filter(program => {
      return program.id !== programId;
    });
    this.persist();
  };

  isFetching = false;
  hasFetched = false;
  fetch = async () => {
    if (this.isFetching) {
      return;
    }
    this.isFetching = true;
    try {
      const programs = await API.getPrograms();
      this._programs = programs.data;
      this.persist();
    } catch (error) {
      captureException(error);
      throw error;
    } finally {
      this.hasFetched = true;
      this.isFetching = false;
    }
  };

  myTemplateProgramsWithNoFolder = (): Program[] => {
    return this.myProgramTemplates
      .filter(program => program.folder_id === null)
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  otherTeamMemberProgramsWithNoFolder = (memberId: string): Program[] => {
    return this._programs
      .filter(
        program =>
          program.folder_id === null && program.coach_id === memberId && program.client_id === null,
      )
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  myTemplateProgramsForFolder = (folderId: number): Program[] => {
    return this._programs
      .filter(program => program.folder_id === folderId && program.client_id === null)
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  templateProgramsFor = ({
    folderId,
    coachId,
  }: {
    folderId: number;
    coachId: string;
  }): Program[] => {
    return this._programs
      .filter(
        program =>
          program.folder_id === folderId &&
          program.coach_id === coachId &&
          program.client_id === null,
      )
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  updateProgramLocation = async (
    movedProgram: Program,
    newIndex: number,
    targetCoachId: string,
    newFolderId?: number,
  ) => {
    let targetCoachProgramTemplates = this.programTemplatesForMember(targetCoachId).slice();
    const otherMemberProgramTemplates = this.allProgramTemplates
      .filter(p => p.coach_id !== targetCoachId)
      .slice();
    const programsBeforeUpdate = this._programs.slice();

    // All programs minus the moved one
    targetCoachProgramTemplates = targetCoachProgramTemplates.filter(p => p.id !== movedProgram.id);

    // Ordered programs in the folder besides the reordered one
    let programsInFolder = targetCoachProgramTemplates
      .filter(p => p.folder_id === (newFolderId || null))
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });

    // Insert the folder in it's new index
    programsInFolder.splice(newIndex, 0, movedProgram);

    // Update the index of all programs in the folder
    programsInFolder = programsInFolder.map((p, index) => {
      return { ...p, index, folder_id: newFolderId ?? null };
    });

    // Remove all programs in the folder from the list of all programs
    targetCoachProgramTemplates = targetCoachProgramTemplates.filter(p => {
      return p.folder_id !== (newFolderId ?? null);
    });

    // Add the programs in the folder back to the list of all programs, with their new index
    targetCoachProgramTemplates.push(...programsInFolder);

    // Generate the updates for the backend
    const updates = programsInFolder.map((p, index) => {
      return {
        index,
        program_id: p.id,
        folder_id: newFolderId ?? null,
        title: p.title,
      };
    });

    // Update the state with the latest folders/order
    this._programs = [
      ...otherMemberProgramTemplates,
      ...targetCoachProgramTemplates,
      ...this.clientPrograms,
    ];
    try {
      await API.updateProgramLocations(updates);
    } catch (error) {
      // Revert the state if the API call fails
      this._programs = programsBeforeUpdate;
      throw error;
    }

    this.persist();
  };

  persist = () => {
    this._programs.sort((p1, p2) => {
      return p1.title.localeCompare(p2.title);
    });
    window.localStorage.setItem(PROGRAMS_LOCAL_STORAGE_KEY, JSON.stringify(this._programs));
  };

  clearData = () => {
    this._programs = [];
    window.localStorage.removeItem(PROGRAMS_LOCAL_STORAGE_KEY);
  };

  programAssignedToClient = (clientId: string): Program | undefined => {
    return this._programs.find(program => {
      return program.client_id === clientId;
    });
  };

  programWithId = (programId: string): Program | undefined => {
    return this._programs.find(program => {
      return program.id === programId;
    });
  };

  get myProgramTemplates() {
    return this.myPrograms.filter(program => {
      return !program.client_id;
    });
  }

  get allProgramTemplates() {
    return this._programs.filter(program => {
      return !program.client_id;
    });
  }

  programTemplatesForMember = (memberId: string) => {
    return this._programs.filter(program => {
      return !program.client_id && program.coach_id === memberId;
    });
  };

  get clientPrograms(): ClientProgram[] {
    return this.myPrograms.filter(ProgramIsClientProgram);
  }
}
