export const validateYoutubeUrl = (url: string): boolean => {
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/v\/|youtube\.com\/user\/[^/]+#p\/|youtube\.com\/attribution_link\?a=|youtube\.com\/channel\/|youtube\.com\/c\/|youtube\.com\/playlist\?list=)[a-zA-Z0-9_-]{11}(?:&[^&\s]*)?/;

  return youtubeRegex.test(url);
};

export const getYoutubeEmbedURL = (videoURL: string): string | undefined => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = videoURL.match(regExp);
  const youTubeID = match && match[7].length === 11 ? match[7] : undefined;

  if (!youTubeID) {
    return undefined;
  }

  return `https://www.youtube.com/embed/${youTubeID}?&autoplay=1&mute=1`;
};

export const getYoutubeThumbnailURL = (url: string): string | undefined => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  const youTubeID = match && match[7].length === 11 ? match[7] : undefined;

  if (!youTubeID) {
    return;
  }

  return `https://img.youtube.com/vi/${youTubeID}/hqdefault.jpg`;
};
