import dayjs, { Dayjs } from 'dayjs';
import { Weekday } from 'hevy-shared';
import { Day, DayType } from './types';

export const CIRCLE_DIAMETER = 40.5;

interface MonthProps {
  month: Dayjs;
  firstWeekdayOffset: number;
}

export const buildMonth = ({ month, firstWeekdayOffset }: MonthProps): Day[][] => {
  let day = dayjs(month).startOf('month');
  const dates: Day[][] = [];

  // Build first week.
  const firstWeek: Day[] = [];
  for (let weekDay = 0; weekDay < 7; weekDay++) {
    const relativeWeekday = (weekDay + firstWeekdayOffset) % 7;
    if (day.day() !== relativeWeekday) {
      firstWeek.push({
        type: 'other-month',
        date: day.subtract((day.day() - relativeWeekday + 7) % 7, 'day').format('YYYY-MM-DD'),
      });
    } else {
      firstWeek.push({
        type: 'this-month',
        date: day.format('YYYY-MM-DD'),
      });
      day = day.add(1, 'day');
    }
  }

  dates.push(firstWeek);

  // Build weeks 2 - 4
  for (let week = 1; week < 4; week++) {
    dates.push([]);
    for (let weekDay = 0; weekDay < 7; weekDay++) {
      dates[week].push({
        type: 'this-month',
        date: day.format('YYYY-MM-DD'),
      });
      day = day.add(1, 'day');
    }
  }

  // Build week 5

  const weekFive: Day[] = [];
  for (let weekDay = 0; weekDay < 7; weekDay++) {
    if (day.format('MM') !== month.format('MM')) {
      weekFive.push({ type: 'other-month', date: day.format('YYYY-MM-DD') });
    } else {
      weekFive.push({
        type: 'this-month',
        date: day.format('YYYY-MM-DD'),
      });
    }
    day = day.add(1, 'day');
  }

  dates.push(weekFive);

  // Build week 6

  const weekSix: Day[] = [];
  for (let weekDay = 0; weekDay < 7; weekDay++) {
    if (day.format('MM') !== month.format('MM')) {
      weekSix.push({ type: 'other-month', date: day.format('YYYY-MM-DD') });
    } else {
      weekSix.push({
        type: 'this-month',
        date: day.format('YYYY-MM-DD'),
      });
    }
    day = day.add(1, 'day');
  }

  if (weekSix[0].type === 'this-month') {
    dates.push(weekSix);
  }

  return dates;
};

export const dayLabels = (
  firstWeekday: Weekday,
): [string, string, string, string, string, string, string] => {
  switch (firstWeekday) {
    case 'monday':
      return ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    case 'tuesday':
      return ['T', 'W', 'T', 'F', 'S', 'S', 'M'];
    case 'wednesday':
      return ['W', 'T', 'F', 'S', 'S', 'M', 'T'];
    case 'thursday':
      return ['T', 'F', 'S', 'S', 'M', 'T', 'W'];
    case 'friday':
      return ['F', 'S', 'S', 'M', 'T', 'W', 'T'];
    case 'saturday':
      return ['S', 'S', 'M', 'T', 'W', 'T', 'F'];
    case 'sunday':
      return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
};

export const getDayType = (
  date: string,
  durationDays: number | null,
  selectedDate?: string | null,
): DayType => {
  const dateDayjs = dayjs(date);
  const selectedDateDayjs = dayjs(selectedDate);

  if (durationDays) {
    const start = selectedDateDayjs;
    const end = selectedDateDayjs.add(durationDays - 1, 'day'); // -1 because we count the start day

    if (dateDayjs.isSame(start, 'day')) {
      return 'start';
    }

    if (dateDayjs.isSame(end, 'day')) {
      return 'end';
    }

    if (dateDayjs.isAfter(start, 'day') && dateDayjs.isBefore(end, 'day')) {
      return 'middle';
    }
  }

  if (dateDayjs.isSame(selectedDateDayjs, 'day')) {
    return 'single';
  }

  return 'unrelated';
};
