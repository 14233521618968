import { CloseButton } from 'components/CloseButton';
import { MuscleDistributionSelector } from 'components/MuscleDistributionGraphSelector';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Radar } from 'react-chartjs-2';
import { PrimaryButton } from 'styleguide/Buttons';
import { FlexColumn } from 'styleguide/Column';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { TextLG, TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { MuscleHeatmap } from 'components/MuscleHeatmap/MuscleHeatmap';
import { MuscleGroupSetsTable } from 'components/MuscleGroupSetsTable';
import { RoutineSummaryViewModel } from './RoutineSummaryModalViewModel';
import { colors } from 'styleguide/colors';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export interface RoutineSummaryModalProps {
  onClose: () => void;
  isOpen: boolean;
  vm: RoutineSummaryViewModel;
}

export const RoutineSummaryModal = observer(({ onClose, isOpen, vm }: RoutineSummaryModalProps) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FlexColumn style={{ width: 554 }}>
        <FlexRow style={{ justifyContent: 'space-between', flex: 1, padding: Spacing.lg }}>
          <TextLG>Summary</TextLG>
          <View>
            <CloseButton onClick={onClose} />
          </View>
        </FlexRow>
        <DividerLine />
        <View style={{ overflowY: 'auto' }}>
          <FlexColumn style={{ padding: Spacing.lg, gap: Spacing.sm }}>
            <FlexRow>
              <TextSMRegular style={{ flex: 0.5 }}>Total Exercises</TextSMRegular>
              <TextSMRegular style={{ flex: 0.5, textAlign: 'end', color: colors.neutral900 }}>
                {vm.exercises.length}
              </TextSMRegular>
            </FlexRow>
            <DividerLine />
            <FlexRow>
              <TextSMRegular style={{ flex: 0.5 }}>Total Sets</TextSMRegular>
              <TextSMRegular style={{ flex: 0.5, textAlign: 'end', color: colors.neutral900 }}>
                {vm.totalSets}
              </TextSMRegular>
            </FlexRow>
            <FlexRow style={{ justifyContent: 'space-between', paddingTop: Spacing.md }}>
              <TextMD>Muscle Distribution</TextMD>
              <MuscleDistributionSelector
                selectedOption={vm.selectedMuscleGroupGraph}
                onSelectedOption={vm.onSelectedMuscleGroupGraphChanged}
                optionsOrder={['body', 'web']}
              />
            </FlexRow>
            {vm.selectedMuscleGroupGraph === 'web' && (
              <FlexRow style={{ justifyContent: 'space-around' }}>
                <View
                  style={{
                    // Work around weird padding inside the chart
                    marginTop: -25,
                    marginBottom: -40,
                    overflow: 'hidden',
                    // Make overlapping elements clickable
                    zIndex: -1,
                    height: 270,
                  }}
                >
                  <Radar options={vm.radarGraphOptions} data={vm.radarGraphData} />
                </View>
              </FlexRow>
            )}
            {vm.selectedMuscleGroupGraph === 'body' && (
              <FlexRow style={{ justifyContent: 'space-around' }}>
                <FlexRow
                  style={{ paddingTop: Spacing.md, justifyContent: 'flex-start', gap: Spacing.lg }}
                >
                  <View style={{ height: 189, aspectRatio: 0.42105263 }}>
                    <MuscleHeatmap height={189} data={vm.heatMapData} side="front" />
                  </View>
                  <View style={{ height: 189, aspectRatio: 0.42105263 }}>
                    <MuscleHeatmap height={189} data={vm.heatMapData} side="back" />
                  </View>
                </FlexRow>
              </FlexRow>
            )}
            <FlexColumn style={{ paddingTop: Spacing.md }}>
              <TextMD>Set count per muscle group</TextMD>
              <MuscleGroupSetsTable muscleGroups={vm.muscleGroupData} />
            </FlexColumn>
          </FlexColumn>
        </View>
        <DividerLine />
        <FlexRow
          style={{
            justifyContent: 'flex-end',
            flex: 1,
            padding: Spacing.lg,
            paddingTop: Spacing.md,
            paddingBottom: Spacing.md,
          }}
        >
          <PrimaryButton title="Done" onClick={onClose} />
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
});
