import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { colors } from './colors';

const Button = styled.button`
  background-color: ${colors.white};
  cursor: pointer;
  border-style: solid;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${colors.neutral150};
`;

interface ClickableCardProps {
  onClick: () => void;
  children: React.ReactNode;
  style?: CSSProperties;
}

export const ClickableCard = (props: ClickableCardProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Button
      onClick={props.onClick}
      style={{
        ...props.style,
        ...(isHovering && {
          backgroundColor: colors.neutral50,
        }),
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {props.children}
    </Button>
  );
};
