import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';
import { View } from 'styleguide/View';
import { ClientChatWidget } from './ClientChatWidget';
import { observer } from 'mobx-react-lite';
import { NewChatWidget } from './NewChatWidget';
import { ChatsWidgetViewModel } from './ChatsWidgetViewModel';
import { ChatsOverviewMainContent } from './components/ChatsOverviewMainContent';
import { ChatsHeader } from './components/ChatsHeader';
import { zIndexes } from 'styleguide/zIndex';

const ChatWidgetVM = new ChatsWidgetViewModel();

const Container = styled(FlexRow)`
  position: fixed;
  align-items: flex-end;
  right: ${Spacing.lg}px;
  gap: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    display: none;
  }
  bottom: 0px;
  z-index: ${zIndexes.chatWidgets};
`;

const ChatsWidgetContainer = styled(View)`
  border-top-left-radius: ${CornerRadius.md}px;
  border-top-right-radius: ${CornerRadius.md}px;
  border: 1px solid ${colors.neutral150};
  background-color: ${colors.white};
  width: 304px;
  align-items: center;
  box-shadow: 0px 4px 16px -2px rgba(17, 25, 39, 0.1); // colors.neutral900
`;

export const ChatsWidget = () => {
  useEffect(() => {
    ChatWidgetVM.startListeningForEvents();
    return () => {
      ChatWidgetVM.stopListeningForEvents();
    };
  }, []);

  return <ChatsWidgetInner vm={ChatWidgetVM} />;
};

const ChatsWidgetInner = observer(({ vm }: { vm: ChatsWidgetViewModel }) => {
  return (
    <Container>
      {vm.chatVm.selectedConversationId && vm.chatVm.clientForSelectedConversation && (
        <ClientChatWidget
          isLoadingConversations={vm.chatVm.isLoadingConversations}
          onSend={vm.chatVm.onSend}
          conversationId={vm.chatVm.selectedConversationId}
          client={vm.chatVm.clientForSelectedConversation}
          messages={vm.chatVm.supportedMessagesInSelectedConversation}
          onClose={vm.clearSelectedConversation}
          isFetchingOlderMessages={vm.chatVm.isFetchingOlderMessages}
          onScrollToTop={vm.chatVm.loadOlderMessagesForCurrentConversation}
          setMessagesContainerRef={vm.chatVm.setMessagesContainerRef}
          onDeleteMessageClick={vm.chatVm.onDeleteMessageClick}
          onSendFile={vm.chatVm.onSendFile}
          videoBeingUploaded={vm.chatVm.videoBeingProcessed}
          imageBeingUploaded={vm.chatVm.imageBeingProcessed}
          isFileBeingProcessed={vm.chatVm.isFileBeingProcessed}
          updateUnsentMessage={vm.chatVm.updateUnsentMessage}
          chatVm={vm.chatVm}
        />
      )}
      {vm.isNewChatWidgetOpen && (
        <NewChatWidget
          onClose={() => {
            vm.isNewChatWidgetOpen = false;
          }}
          onSelectClient={vm.onSelectClient}
          isLoading={vm.isCreatingConversation}
        />
      )}
      <ChatsWidgetContainer>
        <View style={{ width: '100%' }}>
          <ChatsHeader
            onNewChatClick={vm.onNewChatClick}
            isCollapsed={vm.isCollapsed}
            isChatOnline={vm.chatVm.isChatOnline}
            toggleCollapse={vm.toggleCollapse}
            unreadMessagesCount={vm.chatVm.totalUnreadMessages}
          ></ChatsHeader>

          {!vm.isCollapsed && (
            <ChatsOverviewMainContent
              hasFailedToInitialize={vm.chatVm.hasFailedToInitialize}
              isLoadingConversations={vm.chatVm.isLoadingConversations}
              conversations={vm.chatVm.filteredConversations}
              latestMessageForConversation={vm.chatVm.latestMessageForConversation}
              selectedConversationId={vm.chatVm.selectedConversationId}
              clientForConversation={vm.chatVm.clientForConversation}
              onConversationPress={vm.onConversationPress}
              onNewChatClick={vm.onNewChatClick}
              reset={vm.chatVm.reset}
              style={{ maxHeight: '75vh', minHeight: 400 }}
              searchText={vm.chatVm.searchText}
              onSearchTextChange={vm.chatVm.onSearchTextChange}
            ></ChatsOverviewMainContent>
          )}
        </View>
      </ChatsWidgetContainer>
    </Container>
  );
});
