import { HevyMenu } from 'components/HevyMenu/HevyMenu';
import { HevyMenuItem } from 'components/HevyMenu/HevyMenuItem';
import React from 'react';
import { Icon } from 'styleguide/Icon';

interface CommentOptionsMenuProps {
  onDeleteClick: () => void;
}

export const CommentOptionsMenu = ({ onDeleteClick }: CommentOptionsMenuProps) => (
  <HevyMenu
    direction="left"
    menuButton={
      <div style={{ display: 'flex', cursor: 'pointer' }}>
        <Icon type="horizontalDotsAccent" />
      </div>
    }
  >
    <HevyMenuItem onClick={onDeleteClick} title="Delete Comment" iconType="delete"></HevyMenuItem>
  </HevyMenu>
);
