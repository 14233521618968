import EventEmitter from 'events';

interface ChatWidgetEventBase {
  event_type: string;
}

interface ChatWidgetOpenConversationEvent extends ChatWidgetEventBase {
  event_type: 'open_conversation';
  conversation_id: string;
}

export type ChatWidgetEvent = ChatWidgetOpenConversationEvent;

export const isChatWidgetEvent = (event?: any): event is ChatWidgetEvent => {
  return (
    !!event &&
    event.event_type === 'open_conversation' &&
    !!event.conversation_id &&
    typeof event.conversation_id === 'string'
  );
};

export const ChatWidgetEventEmitter = new EventEmitter();

export const sendChatWidgetEvent = (event: ChatWidgetEvent) => {
  ChatWidgetEventEmitter.emit(event.event_type, event);
};
