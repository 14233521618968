import { CloseButton } from 'components/CloseButton';
import React from 'react';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { ClientChatSearch } from './ClientChatSearch/ClientChatSearch';
import styled from 'styled-components';
import { Client } from 'types/client';
import { ClientChatSearchViewModel } from './ClientChatSearch/ClientChatSearchViewModel';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const Header = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.md}px;
`;

const Container = styled(View)`
  flex: 1;
  width: 100%;
`;

interface NewChatViewProps {
  onClose?: () => void;
  onSelectClient: (client: Client) => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
}

export const NewChatView = ({ onClose, onSelectClient, style, isLoading }: NewChatViewProps) => {
  const searchVm = new ClientChatSearchViewModel(onSelectClient);

  return (
    <Container style={style}>
      <View style={{ width: '100%' }}>
        <Header style={{ justifyContent: 'space-between' }}>
          <FlexRow style={{ gap: Spacing.xs }}>
            <TextSMMedium>New Chat</TextSMMedium>
          </FlexRow>
          <FlexRow style={{ gap: Spacing.md }}>
            {onClose && <CloseButton onClick={onClose} style={{ padding: 0 }} />}
          </FlexRow>
        </Header>

        <ClientChatSearch vm={searchVm} dropdownStyle={{ minWidth: 300 }} />
      </View>
      {!!isLoading && (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <MediumHevyActivityIndicator />
        </View>
      )}
    </Container>
  );
};
