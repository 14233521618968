import React from 'react';
import styled from 'styled-components';
import { ClickableCard } from 'styleguide/ClickableCard';
import { colors } from 'styleguide/colors';
import { StyledRadioButton } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';

const SelectionCard = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.lg}px ${Spacing.md}px;
`;

export const SelectableCard = ({
  isSelected,
  onSelected,
  title,
  description,
  isDestructive,
}: {
  isSelected: boolean;
  onSelected: () => void;
  title: string;
  description: string;
  isDestructive?: boolean;
}) => {
  return (
    <ClickableCard style={{ padding: 0 }} onClick={onSelected}>
      <SelectionCard>
        <FlexRow style={{ gap: Spacing.md }}>
          <StyledRadioButton checked={isSelected} />

          <View style={{ gap: Spacing.xs }}>
            <TextMD
              style={{ textAlign: 'left', color: isDestructive ? colors.error500 : undefined }}
            >
              {title}
            </TextMD>
            <TextSMRegular
              style={{ textAlign: 'left', color: isDestructive ? colors.error500 : undefined }}
            >
              {description}
            </TextSMRegular>
          </View>
        </FlexRow>
      </SelectionCard>
    </ClickableCard>
  );
};
