import {
  Equipment,
  EquipmentFilter,
  equipmentFilters,
  ExerciseTemplate,
  filterExercises,
  MuscleGroup,
  MuscleGroupFilter,
  muscleGroupFilters,
  popularExerciseTemplateIds,
} from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import { isPlanLimitErrorResponse } from 'utils/axiosErrors';
import { equipmentFilterToHuman, muscleGroupFilterToHuman } from 'utils/exerciseUtils';

interface LoadingCell {
  type: 'loading';
}

interface SectionHeaderCell {
  type: 'section';
  title: string;
}

interface SpacerCell {
  type: 'spacer';
}

interface NoExercisesMatchSearchCell {
  type: 'no-exercises-match-search';
  searchText: string;
}

interface NoExercisesFoundCell {
  type: 'no-exercises-found';
}

type KeyedExerciseTemplate = { key: string } & ExerciseTemplate;

export type ExerciseDataCell =
  | LoadingCell
  | SpacerCell
  | KeyedExerciseTemplate
  | SectionHeaderCell
  | NoExercisesMatchSearchCell
  | NoExercisesFoundCell;

export class ExerciseLibraryViewModel {
  exerciseSearchText: string = '';
  exercisesContainerRef: HTMLDivElement | null = null;

  private _exerciseSearchEquipment: EquipmentFilter = 'all_equipment';
  private _exerciseSearchMuscleGroup: MuscleGroupFilter = 'all_muscles';

  isLoading: boolean = false;
  clientUserId?: string;

  private _clientExerciseHistory?: {
    exercise_template_id: string;
    date_used: number;
  }[] = undefined;

  constructor(clientUserId?: string) {
    this.clientUserId = clientUserId;
    makeAutoObservable(this);
    if (this.clientUserId) {
      this.fetchClientExerciseUsageHistory();
    }
  }

  fetchClientExerciseUsageHistory = async () => {
    this.isLoading = true;

    if (this.clientUserId) {
      try {
        const exercisesResult = await API.getClientsRecentExercises(this.clientUserId);
        this._clientExerciseHistory = exercisesResult.data;
      } catch (error) {
        if (!isPlanLimitErrorResponse(error)) {
          toast.error('Unable to load recent exercises.');
        }
      }
    }

    this.isLoading = false;
  };

  get allExerciseTemplates() {
    const client = memoryStores.clients.clientForUserId(this.clientUserId);
    if (client && this._clientExerciseHistory) {
      if (!this._clientExerciseHistory) {
        return [];
      }
      const allExercises = localStorageStores.exerciseTemplates.bundledTemplates.concat(
        client.customExercises,
      );
      const filteredAndSorted = allExercises
        // Filter for used exercises
        .filter(e => {
          return (
            this._clientExerciseHistory?.find(exercise => {
              return exercise.exercise_template_id === e.id && !e.is_archived;
            }) !== undefined
          );
        })
        .sort((et1, et2) => {
          const exerciseUsage1 = this._clientExerciseHistory?.find(exercise => {
            return exercise.exercise_template_id === et1.id;
          });
          const exerciseUsage2 = this._clientExerciseHistory?.find(exercise => {
            return exercise.exercise_template_id === et2.id;
          });
          return (exerciseUsage2?.date_used ?? 0) - (exerciseUsage1?.date_used ?? 0);
        });
      return filteredAndSorted;
    }

    return localStorageStores.exerciseTemplates.exercises.filter(e => !e.is_archived);
  }

  get allExercisesFiltered(): KeyedExerciseTemplate[] {
    return filterExercises({
      exercises: this.allExerciseTemplates.map(template => {
        return { ...template, localized_muscle_group_name: template.muscle_group };
      }),
      searchText: this.exerciseSearchText ?? '',
      muscleGroupFilter: this._exerciseSearchMuscleGroup,
      equipmentType: this._exerciseSearchEquipment,
      language: 'en',
    }).map(e => ({ ...e, key: e.id }));
  }

  get popularExercisesAlphabeticalFiltered(): KeyedExerciseTemplate[] {
    const popularExerciseTemplates = popularExerciseTemplateIds
      .map(id => this.allExerciseTemplates.find(e => e.id === id))
      .filter((e): e is ExerciseTemplate => !!e);

    return filterExercises({
      exercises: popularExerciseTemplates.map(e => ({
        ...e,
        localized_muscle_group_name: e.muscle_group,
      })),
      muscleGroupFilter: this.muscleGroupFilter.value,
      equipmentType: this.equipmentFilter.value,
      searchText: this.exerciseSearchText,
      language: 'en',
    })
      .map(e => ({ ...e, key: `popular-${e.id}` }))
      .sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
  }

  get data(): ExerciseDataCell[] {
    if (!!this.clientUserId) {
      // Showing a client's recent exercises

      if (this.allExercisesFiltered.length === 0) {
        return [{ type: 'section', title: 'Recent Exercises' }, { type: 'no-exercises-found' }];
      } else {
        return [{ type: 'section', title: 'Recent Exercises' }, ...this.allExercisesFiltered];
      }
    }

    // Showing our own exercise library

    if (!!this.exerciseSearchText.length) {
      if (this.allExercisesFiltered.length === 0) {
        return [{ type: 'no-exercises-match-search', searchText: this.exerciseSearchText }];
      }

      const searchResultsSection: ExerciseDataCell[] = [
        { type: 'section', title: 'Search Results' },
        ...this.allExercisesFiltered,
      ];

      return searchResultsSection;
    }

    if (this.allExercisesFiltered.length === 0) {
      return [{ type: 'no-exercises-found' }];
    }

    const popularSection: ExerciseDataCell[] =
      localStorageStores.programs.myProgramTemplates.length === 0
        ? [
            { type: 'section', title: 'Popular Exercises' },
            ...this.popularExercisesAlphabeticalFiltered,
            { type: 'spacer' },
          ]
        : [];

    const allSection: ExerciseDataCell[] = [
      { type: 'section', title: 'All Exercises' },
      ...this.allExercisesFiltered,
    ];

    return [...popularSection, ...allSection];
  }

  get equipmentFilter() {
    return {
      label: equipmentFilterToHuman(this._exerciseSearchEquipment),
      value: this._exerciseSearchEquipment,
    };
  }

  get equipmentFilterOptions() {
    return equipmentFilters.map(e => ({ label: equipmentFilterToHuman(e), value: e }));
  }

  onSelectedEquipmentFiltersUpdate = (newValue: { label: string; value: Equipment }) => {
    this._exerciseSearchEquipment = newValue.value;
    this.scrollToTop();
  };

  get muscleGroupFilter() {
    return {
      label: muscleGroupFilterToHuman(this._exerciseSearchMuscleGroup),
      value: this._exerciseSearchMuscleGroup,
    };
  }

  get muscleGroupFilterOptions() {
    return muscleGroupFilters.map(mg => ({ label: muscleGroupFilterToHuman(mg), value: mg }));
  }

  onSelectedMuscleGroupFiltersUpdate = (newValue: { label: string; value: MuscleGroup }) => {
    this._exerciseSearchMuscleGroup = newValue.value;
    this.scrollToTop();
  };

  onExerciseSearchChanged = (value: string) => {
    this.exerciseSearchText = value;
    this.scrollToTop();
  };

  setExercisesContainerRef = (ref: HTMLDivElement) => {
    this.exercisesContainerRef = ref;
  };

  scrollToTop = () => {
    this.exercisesContainerRef?.scrollTo(0, 0);
  };
}
