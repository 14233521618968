import { colors } from './colors';
import React, { CSSProperties } from 'react';
import { CustomizableIcon, CustomizableIconType } from './CustomizableIcon';
import styled from 'styled-components';
import { CircleRow } from './CircleView';

interface DoubleCircledIconProps {
  type: CustomizableIconType;
  containerStyle?: CSSProperties;
}

interface SingleCircledIconProps {
  type: CustomizableIconType;
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  tint?: string;
  diameter?: number;
  onClick?: () => void;
}

const Circle = styled(CircleRow)`
  justify-content: center;
`;

const LargeCircle = styled(Circle).attrs({ diameter: 64 })`
  background-color: ${colors.primary25};
`;

const SmallCircle = styled(Circle).attrs({ diameter: 48 })`
  background-color: ${colors.primary50};
`;

const SingleCircle = styled(Circle)`
  background-color: ${colors.neutral100};
`;

export const DoubleCircledIcon = ({ type, containerStyle }: DoubleCircledIconProps) => {
  return (
    <LargeCircle style={containerStyle}>
      <SmallCircle>
        <CustomizableIcon type={type} tint={colors.primary500} />
      </SmallCircle>
    </LargeCircle>
  );
};

export const SingleCircledIcon = ({
  type,
  containerStyle,
  iconStyle,
  tint,
  diameter,
  onClick,
}: SingleCircledIconProps) => {
  return (
    <SingleCircle diameter={diameter || 48} style={containerStyle} onClick={onClick}>
      <CustomizableIcon
        type={type}
        tint={tint ?? colors.neutral400}
        style={{ flexShrink: 0, ...iconStyle }}
      ></CustomizableIcon>
    </SingleCircle>
  );
};
