import { PHONE_WIDTH_BREAKPOINT, TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

export const useDeviceSize = (): 'phone' | 'tablet' | 'desktop' => {
  if (typeof window === 'undefined') {
    return 'phone';
  }

  const windowWidth = useWindowSize().width;

  if (windowWidth > TABLET_WIDTH_BREAKPOINT) return 'desktop';
  if (windowWidth > PHONE_WIDTH_BREAKPOINT) return 'tablet';
  return 'phone';
};
