import { ExerciseImage } from 'components/ExerciseImage';
import { StaticSetIndicator } from 'components/StaticSetIndicator';
import { SupersetChicklet } from 'components/SupersetChicklet';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextMD, TextSMMedium, TextSMRegular, TextXSMedium, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { ExerciseProps } from './types';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { Spacing } from 'styleguide/spacing';
import { prTitleForPRType } from 'utils/pureUtils';
import { AnchorText } from 'styleguide/Buttons';
import { FlexRow } from 'styleguide/Row';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

const Container = styled(View)`
  padding-top: ${Spacing.md}px;
`;

const TitleRow = styled(FlexRow)`
  gap: ${Spacing.md}px;
`;

const ExerciseTitleContainer = styled(FlexRow)`
  gap: ${Spacing.md}px;
  flex: 1;
  justify-content: space-between;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${Spacing.xs / 2}px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-top: ${Spacing.md}px;
`;

const Column1 = styled.div`
  display: flex;
  flex: 0.2;
  justify-content: left;
  padding-left: ${Spacing.md}px;
`;

const Column2 = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SetContainer = styled.div`
  display: flex;
  padding: ${Spacing.sm}px 0;
`;

export const Exercise = ({
  supersetId,
  image,
  title,
  notes,
  secondColumnHeader,
  sets,
  exerciseDetailURL,
}: ExerciseProps) => {
  return (
    <Container>
      {supersetId !== undefined && (
        <View style={{ flexDirection: 'row', marginBottom: Spacing.md }}>
          <SupersetChicklet id={supersetId} />
        </View>
      )}
      <TitleRow>
        <ExerciseImage source={image} diameter={40} />
        <ExerciseTitleContainer>
          <TextMD>{title}</TextMD>
          <AnchorText text="See statistics" URL={exerciseDetailURL} target="_blank" />
        </ExerciseTitleContainer>
      </TitleRow>
      {!!notes && <TextSMRegular style={{ marginTop: Spacing.sm }}>{notes}</TextSMRegular>}
      <HeaderContainer>
        <Column1>
          <TextXSMedium>SETS</TextXSMedium>
        </Column1>
        <Column2>
          <TextXSMedium>{secondColumnHeader}</TextXSMedium>
        </Column2>
      </HeaderContainer>
      {sets.map((s, index) => (
        <SetContainer key={s.key} style={{ backgroundColor: getSimpleSetBackgroundColor(index) }}>
          <Column1>
            <StaticSetIndicator indicator={s.indicator} />
          </Column1>
          <Column2>
            <FlexRow>
              <TextSMMedium>{s.value}</TextSMMedium>
              {s.rpe && <TextXSMedium>&nbsp;@ {s.rpe} rpe</TextXSMedium>}
            </FlexRow>
            <FlexRow style={{ gap: Spacing.md, paddingRight: Spacing.md, flexWrap: 'wrap' }}>
              {s.prs.map((pr, index) => {
                return (
                  <FlexRow key={index}>
                    <CustomizableIcon
                      type="pr"
                      tint={''}
                      style={{ marginRight: Spacing.xs }}
                    ></CustomizableIcon>
                    <TextXSRegular style={{ color: colors.neutral500 }}>
                      {prTitleForPRType(pr.type)}
                    </TextXSRegular>
                  </FlexRow>
                );
              })}
            </FlexRow>
          </Column2>
        </SetContainer>
      ))}
    </Container>
  );
};

const getSimpleSetBackgroundColor = (index: number): string => {
  const oddSetColor = colors.neutral100;

  return index % 2 === 0 ? colors.transparent : oddSetColor;
};
