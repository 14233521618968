import React, { useState } from 'react';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { modal } from './Modals/ModalManager';
import { colors } from 'styleguide/colors';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { Card } from 'styleguide/Card';
import PlayIconSvg from 'styleguide/assets/play.svg';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { SecondaryButton } from 'styleguide/Buttons';
import { getYoutubeThumbnailURL } from 'utils/youtube';
import { sendEvent } from 'utils/analyticsEvents';
import { FlexRow } from 'styleguide/Row';
import { zIndexes } from 'styleguide/zIndex';

const PlayButtonContainer = styled(FlexRow)`
  aspect-ratio: 16/9;
  justify-content: center;
  width: 218px;
  z-index: ${zIndexes.aboveSurface};
`;

const Container = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px;
  margin-bottom: 0;
  flex: 1;
  background-color: ${colors.neutral50};
  flex-wrap: wrap;
`;

interface ExerciseVideoThumbnailCellProps {
  videoAttachment: VideoAttachment;
  onRemoveVideo?: () => void;
  exerciseTitle: string;
}

export const ExerciseVideoThumbnailCell = ({
  videoAttachment,
  onRemoveVideo,
  exerciseTitle,
}: ExerciseVideoThumbnailCellProps) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Container>
      <Card
        onClick={() => {
          sendEvent('exerciseVideoThumbnail_clicked', {
            videoAttachmentType: videoAttachment.type,
          });

          modal.openVideoModal(videoAttachment.url, exerciseTitle);
        }}
        style={{
          aspectRatio: 16 / 9,
          cursor: 'pointer',
          width: 218,
          backgroundColor: colors.neutral150,
          borderRadius: CornerRadius.md,
          padding: Spacing.sm,
          marginBottom: 0,
        }}
      >
        <View
          style={{ position: 'relative' }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {videoAttachment.type === 'youtube' ? (
            <img
              src={getYoutubeThumbnailURL(videoAttachment.url)}
              width="100%"
              style={{
                aspectRatio: 16 / 9,
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: isHovering ? 0.8 : 1,
              }}
            ></img>
          ) : (
            <video
              key={videoAttachment.url}
              width="100%"
              style={{
                aspectRatio: 16 / 9,
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: isHovering ? 0.8 : 1,
              }}
              controls={true}
            >
              <source src={videoAttachment.url} type="video/mp4" />
            </video>
          )}
          <PlayButtonContainer>
            <PlayIconSvg />
          </PlayButtonContainer>
        </View>
      </Card>
      {!!onRemoveVideo && (
        <SecondaryButton iconType="trash" title="Remove" onClick={onRemoveVideo}></SecondaryButton>
      )}
    </Container>
  );
};
