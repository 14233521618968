import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { Select } from 'components/Select';
import { PrimaryButton } from 'styleguide/Buttons';
import {
  TextLG,
  TextMDRegular,
  TextSMMedium,
  TextSMRegular,
  TextXSRegular,
} from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { Plan } from 'hevy-shared';
import { Card } from 'styleguide/Card';
import {
  HideOnPhoneWrapper,
  MobileFriendlyRowWrapper,
  PHONE_WIDTH_BREAKPOINT,
  ShowOnPhoneWrapper,
} from 'styleguide/Breakpoints';
import { UpgradePlanViewModel } from '../UpgradePlanViewModel';
import { ClipLoader } from 'react-spinners';
import { DividerLine } from 'styleguide/DividerLine';
import { WhatsIncludedView } from './WhatsIncludedView';

const PlanCard = styled(Card)`
  width: 95%;

  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    width: unset;
    padding: ${Spacing.md}px;
  }
`;
const PlanInfoContainer = styled(MobileFriendlyRowWrapper)`
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  gap: ${Spacing.lg * 2}px;
  padding: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    gap: ${Spacing.lg}px;
    padding: ${Spacing.sm}px;
    flex-direction: column-reverse !important;
    max-width: 400px;
  }
`;

const PlansContainer = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  gap: ${Spacing.lg}px;
  width: 100%;
`;

export const PromoBadge = (props: { title: string; style?: CSSProperties }) => {
  return (
    <View
      style={{
        backgroundColor: colors.primary50,
        borderRadius: 4,
        padding: Spacing.xs,
        paddingLeft: Spacing.sm,
        paddingRight: Spacing.sm,
        marginBottom: Spacing.md,
        justifyContent: 'center',
        alignItems: 'center',
        ...props.style,
      }}
    >
      <TextSMMedium style={{ color: colors.primary500 }}>{props.title}</TextSMMedium>
    </View>
  );
};

interface ProPlanCardProps {
  availablePlans?: Plan[];
  onSelectPlan?: (plan: Plan) => void;
  onClickStartFreeTrial?: () => void;
  isUpgradingPlan: boolean;
  defaultPlan?: Plan;
  discountPercent?: number;
  recommendedPlan?: Plan;
  enableFreeTrial: boolean;
}

const ProPlansSelector = ({
  availablePlans,
  onSelectPlan,
  isUpgradingPlan,
  defaultPlan,
  discountPercent,
  enableFreeTrial,
  onClickStartFreeTrial,
}: ProPlanCardProps) => {
  const [isSelectPlanEnabled, setIsSelectPlanEnabled] = useState(!!defaultPlan);
  const [selectedPlan, setSelectedPlan] = useState({
    label: defaultPlan?.title ?? '',
    value: defaultPlan?.id ?? '',
  });

  const getSelectedPlan = () => {
    return availablePlans?.find(plan => {
      return plan.id === selectedPlan.value;
    });
  };

  const priceFormatter = new Intl.NumberFormat(navigator.language ?? 'en-US', {
    style: 'currency',
    currency: getSelectedPlan()?.renewal_currency ?? 'USD',
  });

  let originalPrice = Number(getSelectedPlan()?.renewal_price ?? 0);
  let discountedPrice = undefined;
  // Right now we "fake" the discount by multiplying the Paddle plan price by the discount percent
  if (discountPercent) {
    discountedPrice = originalPrice;
    originalPrice *= 100 / discountPercent;
  }

  return (
    <View
      style={{
        marginLeft: Spacing.sm,
        marginRight: Spacing.sm,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <TextLG style={{ paddingBottom: Spacing.md }}>Pro</TextLG>
        {discountPercent && <PromoBadge title={`${discountPercent}% OFF`} />}
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingBottom: Spacing.md,
        }}
      >
        <TextMDRegular style={{ fontSize: 20, display: 'inline', marginRight: Spacing.xs }}>
          {priceFormatter.format(discountedPrice ?? originalPrice)}
        </TextMDRegular>

        <TextSMRegular
          style={{
            color: colors.neutral500,
            display: 'inline',
          }}
        >
          USD / month
        </TextSMRegular>
      </View>

      <Select
        styles={{
          menuList: () => {
            return { maxHeight: 200, overflowY: 'scroll' };
          },
        }}
        options={availablePlans?.map(plan => {
          return { value: plan.id, label: plan.title };
        })}
        defaultValue={selectedPlan}
        onChange={option => {
          setSelectedPlan(option as { label: string; value: string });
          setIsSelectPlanEnabled(true);
        }}
      />

      {enableFreeTrial ? (
        <View style={{ alignItems: 'center' }}>
          <PrimaryButton
            style={{ width: '100%', marginTop: Spacing.md }}
            title="Start 30 day free Trial"
            enabled={true}
            loading={isUpgradingPlan}
            onClick={onClickStartFreeTrial}
          />
          <TextXSRegular style={{ paddingTop: Spacing.sm }}>No credit card required</TextXSRegular>
        </View>
      ) : (
        <PrimaryButton
          style={{ width: '100%', marginTop: Spacing.md }}
          title="Select"
          enabled={isSelectPlanEnabled}
          loading={isUpgradingPlan}
          onClick={() => {
            const plan = availablePlans?.find(plan => {
              return plan.id === selectedPlan.value;
            });
            if (!plan) {
              return;
            }
            onSelectPlan?.(plan);
          }}
        />
      )}
    </View>
  );
};

export const UpgradePlanView = observer(({ vm }: { vm: UpgradePlanViewModel }) => {
  return (
    <>
      {vm.isLoading && (
        <View
          style={{
            minHeight: 100,
            padding: Spacing.lg,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ClipLoader />
        </View>
      )}
      {!vm.isLoading && !vm.plan && <TextMDRegular>Error loading plan data.</TextMDRegular>}
      {!vm.isLoading && vm.plan && (
        <View style={{ alignItems: 'center' }}>
          {vm.hasExeededClientLimit && !!vm.recommendedPlan && (
            <View
              style={{
                backgroundColor: colors.neutral100,
                borderRadius: CornerRadius.sm,
                padding: Spacing.sm,
                marginBottom: Spacing.md,
                marginRight: Spacing.lg,
                marginLeft: Spacing.lg,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextSMRegular style={{ color: colors.neutral900 }}>
                {/* Only show the number of clients if they have more than 1, otherwise it looks
                a little weird */}
                {`To keep access to your ${
                  vm.clientCount > 1 ? `${vm.clientCount} ` : ' '
                }clients, we recommend selecting the `}
                <TextSMMedium
                  style={{ display: 'inline' }}
                >{`${vm.recommendedPlan?.title}`}</TextSMMedium>{' '}
                plan 🚀
              </TextSMRegular>
            </View>
          )}

          <PlanCard>
            <PlanInfoContainer gap={Spacing.lg}>
              <WhatsIncludedView />
              <HideOnPhoneWrapper
                style={{ backgroundColor: colors.neutral150, width: 1, alignSelf: 'stretch' }}
              />
              <ShowOnPhoneWrapper style={{ width: '100%' }}>
                <DividerLine />
              </ShowOnPhoneWrapper>
              <PlansContainer>
                <ProPlansSelector
                  isUpgradingPlan={vm.isUpgradingPlan}
                  discountPercent={vm.discountPercent}
                  availablePlans={vm.availablePlans}
                  onSelectPlan={plan => {
                    vm.onSelectPlanClicked(plan);
                  }}
                  defaultPlan={vm.defaultPlan}
                  recommendedPlan={vm.recommendedPlan}
                  enableFreeTrial={vm.enableFreeTrial}
                  onClickStartFreeTrial={vm.onFreeTrialClicked}
                />
              </PlansContainer>
            </PlanInfoContainer>
          </PlanCard>
        </View>
      )}
    </>
  );
});
