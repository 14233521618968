import dayjs from 'dayjs';
import {
  num,
  oneRepMax,
  roundToOneDecimal,
  UserExerciseSet,
  WeightUnit,
  setVolume,
  DistanceUnit,
  userExerciseSetWeight,
  DistanceUnitShort,
  roundToWholeNumber,
  secondsToWordFormat,
  ExerciseType,
} from 'hevy-shared';
import groupBy from 'lodash/groupBy';
import { localStorageStores } from 'state/localStorageStores';
import { userPace, userWeight } from 'utils/units';

const DATE_FORMAT = 'MMM D, YYYY';

/**
 * Heaviest Weight
 */

export const crunchHeaviestWeightData = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return userExerciseSetWeight(s2, exerciseStore) - userExerciseSetWeight(s1, exerciseStore);
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: roundToOneDecimal(userWeight(userExerciseSetWeight(s, exerciseStore), weightUnit)),
      workoutId: s.workout_id,
    }));
};

export const findHeaviestWeight = (sets: UserExerciseSet[], weightUnit: WeightUnit): number => {
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const sorted = sets.slice().sort((s1, s2) => {
    return userExerciseSetWeight(s2, exerciseStore) - userExerciseSetWeight(s1, exerciseStore);
  });

  return roundToOneDecimal(userWeight(userExerciseSetWeight(sorted[0], exerciseStore), weightUnit));
};

/**
 * One Rep Max
 */

export const crunchOneRepMaxData = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return (
        oneRepMax(userExerciseSetWeight(s2, exerciseStore), num(s2.reps)) -
        oneRepMax(userExerciseSetWeight(s1, exerciseStore), num(s1.reps))
      );
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: roundToOneDecimal(
        userWeight(oneRepMax(userExerciseSetWeight(s, exerciseStore), num(s.reps)), weightUnit),
      ),
      workoutId: s.workout_id,
    }));
};

export const findBestOneRepMax = (sets: UserExerciseSet[], weightUnit: WeightUnit): number => {
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const sorted = sets.slice().sort((s1, s2) => {
    return (
      oneRepMax(userExerciseSetWeight(s2, exerciseStore), num(s2.reps)) -
      oneRepMax(userExerciseSetWeight(s1, exerciseStore), num(s1.reps))
    );
  });

  return roundToOneDecimal(
    userWeight(
      oneRepMax(userExerciseSetWeight(sorted[0], exerciseStore), num(sorted[0]?.reps)),
      weightUnit,
    ),
  );
};

/**
 * Best Set Volume
 */

export const crunchBestSetVolumeData = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return (
        setVolume(userExerciseSetWeight(s2, exerciseStore), num(s2.reps)) -
        setVolume(userExerciseSetWeight(s1, exerciseStore), num(s1.reps))
      );
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: roundToOneDecimal(
        userWeight(setVolume(userExerciseSetWeight(s, exerciseStore), num(s.reps)), weightUnit),
      ),
      workoutId: s.workout_id,
    }));
};

export const findBestSetVolume = (sets: UserExerciseSet[], weightUnit: WeightUnit): number => {
  const exerciseStore = localStorageStores.exerciseTemplates.exercises;

  const sorted = sets.slice().sort((s1, s2) => {
    return (
      setVolume(userExerciseSetWeight(s2, exerciseStore), num(s2.reps)) -
      setVolume(userExerciseSetWeight(s1, exerciseStore), num(s1.reps))
    );
  });

  return roundToOneDecimal(
    userWeight(
      setVolume(userExerciseSetWeight(sorted[0], exerciseStore), num(sorted[0]?.reps)),
      weightUnit,
    ),
  );
};

export const crunchShortDistanceWeightBestSetVolumeData = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return (
        setVolume(num(s2.weight_kg), num(s2.distance_meters)) -
        setVolume(num(s1.weight_kg), num(s1.distance_meters))
      );
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: roundToOneDecimal(
        userWeight(setVolume(num(s.weight_kg), num(s.distance_meters)), weightUnit),
      ),
      workoutId: s.workout_id,
    }));
};

export const findShortDistanceWeightBestSetVolume = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): number => {
  const sorted = sets.slice().sort((s1, s2) => {
    return (
      setVolume(num(s2.weight_kg), num(s2.distance_meters)) -
      setVolume(num(s1.weight_kg), num(s1.distance_meters))
    );
  });

  return roundToOneDecimal(
    userWeight(setVolume(num(sorted[0]?.weight_kg), num(sorted[0]?.distance_meters)), weightUnit),
  );
};

/**
 * Most Reps
 */

export const crunchMostRepsData = (
  sets: UserExerciseSet[],
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return num(s2.reps) - num(s1.reps);
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: num(s.reps),
      workoutId: s.workout_id,
    }));
};

export const findMostReps = (sets: UserExerciseSet[]): number => {
  const sorted = sets.slice().sort((s1, s2) => {
    return num(s2.reps) - num(s1.reps);
  });

  return num(sorted[0]?.reps);
};

/**
 * Best Time
 */

export const crunchBestTimeData = (
  sets: UserExerciseSet[],
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  const bestSets = groupedSets.map(workoutSets => {
    const sorted = workoutSets.slice().sort((s1, s2) => {
      return num(s2.duration_seconds) - num(s1.duration_seconds);
    });

    return sorted[0];
  });

  return bestSets
    .slice()
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: num(s.duration_seconds),
      workoutId: s.workout_id,
    }));
};

export const findBestTime = (sets: UserExerciseSet[]): number => {
  const sorted = sets.slice().sort((s1, s2) => {
    return num(s2.duration_seconds) - num(s1.duration_seconds);
  });

  return num(sorted[0]?.duration_seconds);
};

/**
 * Total Time
 */

export const crunchTotalTimeData = (
  sets: UserExerciseSet[],
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  return groupedSets
    .filter(setGroup => setGroup.length > 0)
    .sort(
      (setGroup1, setGroup2) =>
        dayjs.unix(setGroup1[0].start_time).unix() - dayjs.unix(setGroup2[0].start_time).unix(),
    )
    .map(setGroup => {
      const totalTime = setGroup.reduce((accu, s) => {
        return accu + num(s.duration_seconds);
      }, 0);

      const firstSet = setGroup[0];
      return {
        date: dayjs.unix(firstSet.start_time).format(DATE_FORMAT),
        value: totalTime,
        workoutId: firstSet.workout_id,
      };
    });
};

export const findBestTotalTime = (sets: UserExerciseSet[]): number => {
  const totalTimes = crunchTotalTimeData(sets)
    .slice()
    .sort((s1, s2) => {
      return num(s2.value) - num(s1.value);
    });

  return num(totalTimes[0]?.value);
};

/**
 * Best Pace
 */

export const crunchBestPaceData = (
  sets: UserExerciseSet[],
  distanceUnit: DistanceUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  return groupedSets
    .map(setGroup => {
      const bestSet = setGroup.slice().sort((e1, e2) => {
        const e1Pace = userPace(num(e1.distance_meters), num(e1.duration_seconds), distanceUnit);
        const e2Pace = userPace(num(e2.distance_meters), num(e2.duration_seconds), distanceUnit);

        return e1Pace - e2Pace;
      })[0];

      return bestSet;
    })
    .sort((s1, s2) => dayjs.unix(s1.start_time).unix() - dayjs.unix(s2.start_time).unix())
    .map(s => ({
      date: dayjs.unix(s.start_time).format(DATE_FORMAT),
      value: roundToOneDecimal(
        userPace(num(s.distance_meters), num(s.duration_seconds), distanceUnit) / 60,
      ),
      workoutId: s.workout_id,
    }));
};

export const findBestPace = (sets: UserExerciseSet[], distanceUnit: DistanceUnit): number => {
  const bestSet = sets.slice().sort((e1, e2) => {
    const e1Pace = userPace(num(e1.distance_meters), num(e1.duration_seconds), distanceUnit);
    const e2Pace = userPace(num(e2.distance_meters), num(e2.duration_seconds), distanceUnit);

    return e1Pace - e2Pace;
  })[0];

  if (!bestSet) return 0;

  return userPace(num(bestSet.distance_meters), num(bestSet.duration_seconds), distanceUnit);
};

/**
 * Total Volume
 */

export const crunchShortDistanceWeightTotalVolumeData = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): Array<{
  date: string;
  value: number;
  workoutId: string;
}> => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  const allVolumes = groupedSets
    .filter(sets => sets.length > 0)
    .map(sets => {
      const volume = sets.reduce(
        (accu, s) => accu + setVolume(num(s.weight_kg), num(s.distance_meters)),
        0,
      );

      return {
        workoutId: sets[0]?.workout_id || '',
        startTime: sets[0]?.start_time || 0,
        volume,
      };
    });

  return allVolumes
    .slice()
    .sort((s1, s2) => s1.startTime - s2.startTime)
    .map(s => ({
      date: dayjs.unix(s.startTime).format(DATE_FORMAT),
      value: roundToOneDecimal(userWeight(s.volume, weightUnit)),
      workoutId: s.workoutId,
    }));
};

export const findShortDistanceWeightBestTotalVolume = (
  sets: UserExerciseSet[],
  weightUnit: WeightUnit,
): number => {
  const groupedSetsMap = groupBy(sets, 'workout_id');
  const groupedSets = Object.values(groupedSetsMap);

  const allVolumes = groupedSets.map(sets => {
    return sets.reduce((accu, s) => accu + setVolume(num(s.weight_kg), num(s.distance_meters)), 0);
  });

  const bestVolume = Math.max(...allVolumes);

  return roundToOneDecimal(userWeight(bestVolume, weightUnit));
};

export const getLineGraphProps = (
  exerciseType: ExerciseType,
  weightUnit: WeightUnit,
  distanceUnit: DistanceUnit,
  distanceUnitShort: DistanceUnitShort,
  setData: UserExerciseSet[],
): any[] => {
  switch (exerciseType) {
    case 'bodyweight_assisted_reps':
      return [
        {
          graphTitle: 'Reps',
          bestValueLabel: 'Most Reps',
          bestValue: `${findMostReps(setData)} reps`,
          data: crunchMostRepsData(setData),
          valueLabel: 'reps',
        },
        {
          graphTitle: 'Set Volume',
          bestValueLabel: `Best Set Volume`,
          bestValue: `${findBestSetVolume(setData, weightUnit)} ${weightUnit}`,
          data: crunchBestSetVolumeData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
      ];
    case 'bodyweight_reps':
      return [
        {
          graphTitle: 'Weight',
          bestValueLabel: `Heaviest Weight`,
          bestValue: `${findHeaviestWeight(setData, weightUnit)} ${weightUnit}`,
          data: crunchHeaviestWeightData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'Set Volume',
          bestValueLabel: `Best Set Volume`,
          bestValue: `${findBestSetVolume(setData, weightUnit)} ${weightUnit}`,
          data: crunchBestSetVolumeData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
      ];
    case 'weight_reps':
      return [
        {
          graphTitle: 'Weight',
          bestValueLabel: `Heaviest Weight`,
          bestValue: `${findHeaviestWeight(setData, weightUnit)} ${weightUnit}`,
          data: crunchHeaviestWeightData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'One Rep Max',
          bestValueLabel: `Best One Rep Max`,
          bestValue: `${findBestOneRepMax(setData, weightUnit)} ${weightUnit}`,
          data: crunchOneRepMaxData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'Set Volume',
          bestValueLabel: `Best Set Volume `,
          bestValue: `${findBestSetVolume(setData, weightUnit)} ${weightUnit}`,
          data: crunchBestSetVolumeData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
      ];
    case 'reps_only':
      return [
        {
          graphTitle: 'Reps',
          bestValueLabel: 'Most Reps',
          bestValue: `${findMostReps(setData)} reps`,
          data: crunchMostRepsData(setData),
          valueLabel: 'reps',
        },
      ];
    case 'duration':
      return [
        {
          graphTitle: 'Time',
          bestValueLabel: 'Best Time',
          bestValue: secondsToWordFormat(findBestTime(setData)),
          data: crunchBestTimeData(setData),
          valueLabel: 'seconds',
          formatAxisAsTime: true,
        },
        {
          graphTitle: 'Total Time',
          bestValueLabel: 'Best Total Time',
          bestValue: secondsToWordFormat(findBestTotalTime(setData)),
          data: crunchTotalTimeData(setData),
          valueLabel: 'seconds',
          formatAxisAsTime: true,
        },
      ];
    case 'distance_duration':
      return [
        {
          graphTitle: 'Pace',
          bestValueLabel: 'Best Pace',
          bestValue: `${secondsToWordFormat(
            roundToWholeNumber(findBestPace(setData, distanceUnit)),
          )}/${distanceUnitShort}`,
          data: crunchBestPaceData(setData, distanceUnit),
          valueLabel: `mins/${distanceUnitShort}`,
        },
      ];
    case 'short_distance_weight':
      return [
        {
          graphTitle: 'Weight',
          bestValueLabel: 'Heaviest Weight',
          bestValue: `${findHeaviestWeight(setData, weightUnit)} ${weightUnit}`,
          data: crunchHeaviestWeightData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'Set Volume',
          bestValueLabel: 'Best Set Volume',
          bestValue: `${findShortDistanceWeightBestSetVolume(setData, weightUnit)} ${weightUnit}`,
          data: crunchShortDistanceWeightBestSetVolumeData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'Total Volume',
          bestValueLabel: 'Best Total Volume',
          bestValue: `${findShortDistanceWeightBestTotalVolume(setData, weightUnit)} ${weightUnit}`,
          data: crunchShortDistanceWeightTotalVolumeData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
      ];
    case 'weight_duration':
      return [
        {
          graphTitle: 'Weight',
          bestValueLabel: 'Heaviest Weight',
          bestValue: `${findHeaviestWeight(setData, weightUnit)} ${weightUnit}`,
          data: crunchHeaviestWeightData(setData, weightUnit),
          unit: weightUnit,
          valueLabel: 'weight',
        },
        {
          graphTitle: 'Time',
          bestValueLabel: 'Best Time',
          bestValue: secondsToWordFormat(findBestTime(setData)),
          data: crunchBestTimeData(setData),
          valueLabel: 'seconds',
          formatAxisAsTime: true,
        },
        {
          graphTitle: 'Total Time',
          bestValueLabel: 'Best Total Time',
          bestValue: secondsToWordFormat(findBestTotalTime(setData)),
          data: crunchTotalTimeData(setData),
          valueLabel: 'seconds',
          formatAxisAsTime: true,
        },
      ];
  }
};
