import { Routine, RoutineFolder } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import {
  MY_ROUTINES_FOLDER_ID,
  MyRoutinesFolder,
  ROUTINES_WITHOUT_FOLDERS_FOLDER_ID,
  createNewHevyCoachCustomExercisesForRoutines,
  replaceCustomExercisesWithHevyCoachCustomExercises,
  showProgramImportedToast,
} from './utils';
import { dateOlderThanHevy } from 'utils/dateAndTimeUtils';
import { sortAscending } from 'utils/pureUtils';

export class ImportFromHevyViewModel {
  onClose: () => void;
  searchText: string = '';
  folders: RoutineFolder[] = [];
  private _routines: Routine[] = [];

  importedFolders: RoutineFolder[] = [];
  importingFolderId: number | undefined = undefined;

  isFetching: boolean = false;

  constructor({ onClose }: { onClose: () => void }) {
    makeAutoObservable(this);
    this.onClose = onClose;
  }

  /**
   * We don't understand the reason but calling `fetchHevyRoutines` on constructor causes an infinite loop,
   * we suspect it is probably because the function is recursive.
   * For some reason, having these two functions awaited one after another
   * and calling that function on constructor seems to be fine.
   */
  fetch = async () => {
    this.isFetching = true;
    try {
      await this.fetchHevyFolders();
      await this.fetchHevyRoutines();
    } catch {
      toast.error('Failed to fetch routines and folders from Hevy, please try again later.');
    } finally {
      this.isFetching = false;
    }
  };

  fetchHevyFolders = async () => {
    const response = await API.getHevyRoutineFolders();
    this.folders = response.data;
    this.folders.push(MyRoutinesFolder);
    this.folders.sort((a, b) => sortAscending(a.index, b.index));
  };

  fetchHevyRoutines = async () => {
    const routineIdUpdatedAtMap: { [routineId: string]: string } = {};

    this._routines.forEach(r => {
      routineIdUpdatedAtMap[r.id] = r.updated_at || dateOlderThanHevy.toISOString();
    });

    const response = await API.getHevyRoutinesSync(routineIdUpdatedAtMap);

    const newRoutines = this._routines.slice();
    const changes = response.data;

    changes.deleted.forEach(deletedId => {
      const indexToDelete = newRoutines.findIndex(r => r.id === deletedId);

      if (indexToDelete > -1) {
        newRoutines.splice(indexToDelete, 1);
      }
    });

    changes.updated.forEach(updatedRoutine => {
      const indexToUpdate = newRoutines.findIndex(w => w.id === updatedRoutine.id);

      if (indexToUpdate > -1) {
        newRoutines[indexToUpdate] = updatedRoutine;
        return;
      }

      newRoutines.push(updatedRoutine);
    });

    if (changes.updated.length || changes.deleted.length) {
      this._routines = newRoutines;
    }

    if (response.data.isMore) {
      await this.fetchHevyRoutines();
    }
  };

  get coach() {
    return localStorageStores.account;
  }

  get routines() {
    // We have to filter out coach routines here since it does not come filtered from the API
    return this._routines.filter(r => !r.program_id);
  }

  get isSearching() {
    return this.searchText.length > 0;
  }

  // Filter out empty folders and return folders that match the search text
  get filteredFolders() {
    return this.folders.filter(folder => {
      return (
        folder.title.toLowerCase().includes(this.searchText.toLowerCase()) &&
        this.routinesForFolder(folder.id).length
      );
    });
  }

  get customExerciseTemplatesInHevyCoach() {
    return localStorageStores.exerciseTemplates.customExercises;
  }

  onSearchTextChange = (text: string) => {
    this.searchText = text;
  };

  hasFolderBeenImported = (folder: RoutineFolder) => {
    return this.importedFolders.some(importedFolder => importedFolder.id === folder.id);
  };

  routinesForFolder = (folderId: number | null): Routine[] => {
    return this.routines.filter(routine => {
      return (
        routine.folder_id === folderId ||
        (folderId === MY_ROUTINES_FOLDER_ID &&
          routine.folder_id === ROUTINES_WITHOUT_FOLDERS_FOLDER_ID)
      );
    });
  };

  postNewProgramFromFolder = async (folder: RoutineFolder, routines: Routine[]) => {
    const programId = (
      await API.postProgram({
        title: folder.title,
        template_program_id: null,
        client_id: null,
        folder_id: null,
        notes: null,
        duration_days: null,
        start_date: null,
      })
    ).data;

    if (!programId) {
      throw new Error('Failed to create program');
    }

    for (const routine of routines) {
      await API.postCoachRoutine({
        title: routine.title,
        program_id: programId,
        folder_id: null,
        notes: routine.notes,
        exercises: routine.exercises,
        index: routine.index ?? 0,
        coach_force_rpe_enabled: localStorageStores.coachConfig.isRpeEnabledForNewRoutines,
      });
    }

    await fireAndForget([
      localStorageStores.programs.fetch(),
      memoryStores.libraryRoutines.fetch(),
    ]);

    return programId;
  };

  onImportFolderClick = async (folder: RoutineFolder) => {
    if (!!this.importingFolderId) return;

    sendEvent('imporFromHevyModal_importFolderAsProgramClick', {
      folderId: folder.id,
      folderTitle: folder.title,
    });

    let programId: string | undefined = undefined;
    try {
      this.importingFolderId = folder.id;

      /**
       * Firstly, we need to create any custom exercises in the folder
       * That does not match any custom exercises in HevyCoach
       */
      const routinesForFolder = this.routinesForFolder(folder.id);
      await createNewHevyCoachCustomExercisesForRoutines(
        routinesForFolder,
        this.customExerciseTemplatesInHevyCoach,
        this.coach.id,
      );

      /**
       * Then we need to replace any custom exercises in the routine in the folder
       * That matches the FIRST custom exercise in HevyCoach with the same title and exercise type
       */
      const updatedRoutinesForFolder = replaceCustomExercisesWithHevyCoachCustomExercises(
        routinesForFolder,
        this.customExerciseTemplatesInHevyCoach,
      );

      /**
       * Finally, after we've created and replaced custom exercises
       * We just need to create the program and add the routines to it
       */
      programId = await this.postNewProgramFromFolder(folder, updatedRoutinesForFolder);
      this.importedFolders.push(folder);

      showProgramImportedToast(programId, folder.title, this.onClose);
      sendEvent('imporFromHevyModal_importFolderAsProgram_success', {
        folderId: folder.id,
        folderTitle: folder.title,
        programId,
      });
    } catch {
      toast.error('Failed to import folder as program, please try again later.');
      sendEvent('imporFromHevyModal_importFolderAsProgram_failure', {
        folderId: folder.id,
        folderTitle: folder.title,
        programId,
      });
      // If we failed to import the folder as a program at any point, we need to delete the program
      //!! However, we do not delete the custom exercises we created that didn't existed in HevyCoach
      if (!!programId) await API.deleteProgram(programId);
    } finally {
      this.importingFolderId = undefined;
    }
  };
}
