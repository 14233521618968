import { Link } from 'components/Link';
import { HevyCoachLogo } from 'components/Logo/HevyCoachLogo';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import ReactModal from 'react-modal';
import { Icon } from 'styleguide/Icon';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { NavBarUserSearchViewModel } from 'components/NavBar/components/NavBarSearchViewModel';
import { NavBarMainContent } from 'components/NavBar/components/NavBarMainContent';
import { useWindowSize } from 'utils/useSize';
import { zIndexes } from 'styleguide/zIndex';

const NavBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 256px;
  background-color: ${colors.sideMenuBackground};
  height: 100%;
  position: sticky;
  top: 0;
  bottom: 0;
`;

const HevyCoachLogoContainer = styled.div`
  padding-top: ${Spacing.lg}px;
  padding-left: ${Spacing.sm}px;
  padding-bottom: ${Spacing.lg}px;
`;

const modalStyle: CSSProperties = {
  top: 0,
  left: 0,
  borderRadius: 0,
  height: '100svh',
  maxHeight: '100vh',
  margin: 0,
  padding: 0,
  width: '256px',
  border: '0px',
  backgroundColor: 'transparent',
};

const overlayStyle: CSSProperties = {
  backgroundColor: 'rgba(16, 18, 19, 0.3)',
  zIndex: zIndexes.mobileNavBar,
};

export interface MobileNavBarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MobileNavBarModal = ({ isOpen, onClose }: MobileNavBarProps) => {
  const searchVm = new NavBarUserSearchViewModel();
  return <MobileNavBarModalInner isOpen={isOpen} onClose={onClose} searchVm={searchVm} />;
};
const MobileNavBarModalInner = observer(
  ({ isOpen, onClose, searchVm }: MobileNavBarProps & { searchVm: NavBarUserSearchViewModel }) => {
    const pathname = useRouter().pathname;

    const windowSize = useWindowSize();

    const slideIn = {
      hidden: { x: '-100vw', opacity: 0 },
      visible: {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      },
      exit: { x: '100vw', opacity: 0 },
    };

    return (
      <ReactModal
        isOpen={isOpen && windowSize.width <= 1024}
        onRequestClose={onClose}
        style={{
          content: {
            ...modalStyle,
            overflow: 'visible',
          },
          overlay: overlayStyle,
        }}
      >
        <NavBarContainer
          style={{ height: windowSize.height }}
          variants={slideIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <HevyCoachLogoContainer onClick={onClose}>
              <Link href="/">
                <HevyCoachLogo />
              </Link>
            </HevyCoachLogoContainer>
            <View onClick={onClose} style={{ marginRight: Spacing.md, cursor: 'pointer' }}>
              <Icon type="xClose"></Icon>
            </View>
          </View>

          <NavBarMainContent vm={searchVm} pathname={pathname} />
        </NavBarContainer>
      </ReactModal>
    );
  },
);
