import { ProgramFolder } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { localStorageStores } from 'state/localStorageStores';

const PROGRAM_FOLDERS_LOCAL_STORAGE_KEY = 'PROGRAM_FOLDERS_LOCAL_STORAGE_KEY';
const COLLAPSED_MEMBER_FOLDER_IDS_LOCAL_STORAGE_KEY =
  'COLLAPSED_MEMBER_FOLDER_IDS_LOCAL_STORAGE_KEY';

export class ProgramFolders {
  DEFAULT_FOLDER_NAME = 'My Programs';
  DEFAULT_FOLDER_ID = null;

  private _programFolders: ProgramFolder[] = [];
  get myProgramFolders(): ProgramFolder[] {
    return this._programFolders.filter(pf => {
      return pf.coach_id === localStorageStores.account.id;
    });
  }

  foldersForMember = (memberId: string): ProgramFolder[] => {
    return this._programFolders.filter(pf => {
      return pf.coach_id === memberId;
    });
  };

  collapsedFolders: { [coachId: string]: (number | null)[] } = {};

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const programFoldersJSON = window.localStorage.getItem(PROGRAM_FOLDERS_LOCAL_STORAGE_KEY);
    if (programFoldersJSON) {
      this._programFolders = JSON.parse(programFoldersJSON);
    }

    const collapsedFolderIdsJSON = window.localStorage.getItem(
      COLLAPSED_MEMBER_FOLDER_IDS_LOCAL_STORAGE_KEY,
    );

    if (collapsedFolderIdsJSON) {
      this.collapsedFolders = JSON.parse(collapsedFolderIdsJSON);
    }
  };

  isFetching = false;
  hasFetched = false;
  fetch = async () => {
    if (this.isFetching) {
      return;
    }
    this.isFetching = true;
    try {
      const programs = await API.getProgramFolders();
      programs.data.sort((p1, p2) => {
        return p1.index - p2.index;
      });
      this._programFolders = programs.data;
      this.persist();
    } catch (error) {
      captureException(error);
      throw error;
    } finally {
      this.hasFetched = true;
      this.isFetching = false;
    }
  };

  reorderFolder = async (movedFolder: ProgramFolder, newIndex: number) => {
    let programFolders = this._programFolders.slice();
    programFolders = programFolders.filter(folder => folder.id !== movedFolder.id);
    programFolders.splice(newIndex, 0, movedFolder);
    programFolders = programFolders.map((folder, index) => {
      return { ...folder, index: index };
    });
    this._programFolders = programFolders;

    const programUpdates = this._programFolders.map((pf, index) => {
      return { index, folder_id: pf.id, title: pf.title };
    });
    this.persist();
    await API.updateProgramFolderLocations(programUpdates);
  };

  toggleCollapseFolder = (folderId: number | null, collapse: boolean, memberId: string) => {
    if (!this.collapsedFolders[memberId]) {
      this.collapsedFolders[memberId] = [];
    }
    if (collapse) {
      this.collapsedFolders[memberId].push(folderId);
    } else {
      this.collapsedFolders[memberId] = this.collapsedFolders[memberId].filter(
        id => id !== folderId,
      );
    }
    this.persist();
  };

  folderWithId = (folderId: number) => {
    return this._programFolders.find(folder => {
      return folder.id === folderId;
    });
  };

  removeFolderWithId = (folderId: number, coachId: string) => {
    this._programFolders = this._programFolders.filter(folder => folder.id !== folderId);
    this.collapsedFolders[coachId] = (this.collapsedFolders[coachId] ?? []).filter(
      id => id !== folderId,
    );
    this.persist();
  };

  persist = () => {
    window.localStorage.setItem(
      PROGRAM_FOLDERS_LOCAL_STORAGE_KEY,
      JSON.stringify(this._programFolders),
    );

    window.localStorage.setItem(
      COLLAPSED_MEMBER_FOLDER_IDS_LOCAL_STORAGE_KEY,
      JSON.stringify(this.collapsedFolders),
    );
  };

  clearData = () => {
    this._programFolders = [];
    window.localStorage.removeItem(PROGRAM_FOLDERS_LOCAL_STORAGE_KEY);
    window.localStorage.removeItem(COLLAPSED_MEMBER_FOLDER_IDS_LOCAL_STORAGE_KEY);
  };
}
