import { ProfileImage } from 'components/ProfileImage';
import router from 'next/router';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { TextMD, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface UserHeaderProps {
  username: string;
  id: string;
  date: string;
  profileImage?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const UserHeader = ({
  username,
  date,
  profileImage,
  style,
  onClick,
  id,
}: UserHeaderProps) => {
  return (
    <Container
      style={style}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.();
        router.push(`/clients/${id}`);
      }}
    >
      <ProfileImage source={profileImage} diameter={55} />
      <View style={{ marginLeft: Spacing.md }}>
        <TextMD>{username}</TextMD>
        <TextSMMedium style={{ color: colors.neutral500, marginTop: Spacing.xs }}>
          {date}
        </TextSMMedium>
      </View>
    </Container>
  );
};
