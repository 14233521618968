import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { CoachAccount, CoachTeam, CoachRole } from 'hevy-shared';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { fireAndForget } from 'utils/async';
import { sendEvent } from 'utils/analyticsEvents';

const ORGANIZATION_LOCAL_STORAGE_KEY = 'ORGANIZATION_LOCAL_STORAGE_KEY';

export class Team {
  private _team?: CoachTeam = undefined;
  isFetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    this.isFetching = true;
    try {
      const result = await API.getTeam();
      this._team = result.data;
      window.localStorage.setItem(ORGANIZATION_LOCAL_STORAGE_KEY, JSON.stringify(this._team));
      this.isFetching = false;
    } catch (error) {
      this.isFetching = false;
      captureException(error);
      throw error;
    }
  };

  update = async (title: string, imageUrl?: string) => {
    if (!this._team) {
      throw new Error('TeamNotLoaded');
    }
    await API.updateTeam(title, imageUrl);
    this._team.title = title;
    this._team.image_url = imageUrl;
    window.localStorage.setItem(ORGANIZATION_LOCAL_STORAGE_KEY, JSON.stringify(this._team));
  };

  owners = (): CoachAccount[] => {
    return (
      this._team?.members?.filter(member => {
        return member.role === 'owner';
      }) ?? []
    );
  };

  get id() {
    return this._team?.id;
  }

  get hasMoreThanOneMember() {
    if (!this._team) {
      return true;
    }
    return this._team.members.length > 1;
  }

  get members() {
    return this._team?.members ?? [];
  }

  get title() {
    return this._team?.title ?? '';
  }

  get imageUrl() {
    return this._team?.image_url;
  }

  get numberOfMembers() {
    return this.members.length;
  }

  get enabledAt() {
    return this._team?.enabled_at;
  }

  hydrate = () => {
    const accountJSON = window.localStorage.getItem(ORGANIZATION_LOCAL_STORAGE_KEY);
    if (accountJSON) {
      this._team = JSON.parse(accountJSON);
    }
  };

  clearData = () => {
    window.localStorage.removeItem(ORGANIZATION_LOCAL_STORAGE_KEY);
    this._team = undefined;
  };

  teamMemberWithId = (userId: string): CoachAccount | undefined => {
    return this._team?.members?.find(member => {
      return member.id === userId;
    });
  };

  updateRoleForCoach = async ({ coachId, newRole }: { coachId: string; newRole: CoachRole }) => {
    const coach = this.teamMemberWithId(coachId);
    if (!coach) {
      return;
    }
    try {
      await API.postCoachRole({ targetCoachId: coachId, targetCoachRole: newRole });
      await fireAndForget([localStorageStores.team.fetch(), localStorageStores.account.fetch()]);
      toast.success('Coach permissions updated.');
    } catch {
      toast.error("Unable to update the coach's permission. Please try again later.");
    }
  };

  removeCoachFromTeam = async ({ coachId }: { coachId: string }): Promise<boolean> => {
    const coach = this.teamMemberWithId(coachId);
    if (!this._team || !coach) {
      return false;
    }
    try {
      this._team.members = this._team.members.filter(member => member.id !== coachId);
      await API.postRemoveCoachFromTeam({ targetCoachId: coachId });
      sendEvent('teamMembers_coachRemoved');
      return true;
    } catch {
      toast.error('Unable to remove the coach from the team. Please try again later.');
      this._team.members.push(coach);
      return false;
    }
  };

  teamMemberWithUsername = ({ username }: { username: string }): CoachAccount | undefined => {
    return this._team?.members?.find(member => {
      return member.username === username;
    });
  };

  disableTeam = async () => {
    await API.deleteTeam();
    this.fetch();
  };
}
