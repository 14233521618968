import { AmplitudeClient } from 'amplitude-js';
import { env } from 'env';
import { log } from './log';
import { HEVY_COACH_EVENT_PREFIX } from './analyticsEvents';

export const amplitudeEvent = (name: string, params?: {}) => {
  if (typeof window) {
    amplitude?.getInstance().logEvent(name, params);
  }
};
export const amplitudePageView = (pagePath: string) => {
  if (env.env !== 'production') {
    log(` 👣 amplitude - Logging page view for path: ${pagePath}`);
    return;
  }

  if (typeof window) {
    amplitude?.getInstance().logEvent(`${HEVY_COACH_EVENT_PREFIX}pageView`, { pagePath });
  }
};

/**
 * Amplitude
 */

let amplitude: { getInstance: () => AmplitudeClient } | undefined;
export const initAmplitude = () => {
  if (typeof window) {
    log('initAmplitude called');
    amplitude = require('amplitude-js');
    amplitude?.getInstance().init('57b5a5fe4eae0e7b251e6b5daccf0480');
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (env.env !== 'production') {
    log(` 👣 amplitude - Setting user ID: ${userId}`);
    return;
  }

  if (typeof window) {
    amplitude?.getInstance().setUserId(userId);
  }
};

export const setAmplitudeUserProps = (userProperties: any) => {
  if (env.env !== 'production') {
    log(` 👣 amplitude - Setting User Properties: ${JSON.stringify(userProperties)}`);
    return;
  }
  if (typeof window) {
    amplitude?.getInstance().setUserProperties(userProperties);
  }
};
