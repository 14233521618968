import { Preferences } from 'components/WorkoutCell/utils';
import { WeightUnit, DistanceUnit, DistanceUnitShort, BodyMeasurementUnit } from 'hevy-shared';
import { localStorageStores } from 'state/localStorageStores';

class CoachUnitsAggregatorClass {
  get weightUnit(): WeightUnit {
    return (
      localStorageStores.coachConfig.config.weight_unit ||
      localStorageStores.userPreferences.weightUnit ||
      'kg'
    );
  }

  get distanceUnit(): DistanceUnit {
    return (
      localStorageStores.coachConfig.config.distance_unit ||
      localStorageStores.userPreferences.distanceUnit ||
      'kilometers'
    );
  }

  get distanceUnitShort(): DistanceUnitShort {
    return this.distanceUnit === 'kilometers' ? 'km' : 'mi';
  }

  get bodyMeasurementUnit(): BodyMeasurementUnit {
    return (
      localStorageStores.coachConfig.config.body_measurement_unit ||
      localStorageStores.userPreferences.bodyMeasurementUnit ||
      'cm'
    );
  }

  get preferences(): Preferences {
    return {
      distanceUnitShort: this.distanceUnitShort,
      weightUnit: this.weightUnit,
      distanceUnit: this.distanceUnit,
      defaultRestTimerSeconds: localStorageStores.coachConfig.defaultRestTimerSeconds,
    };
  }
}

export const CoachUnitsAggregator = new CoachUnitsAggregatorClass();
