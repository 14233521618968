import { captureException } from '@sentry/nextjs';
import { CoachTeamInvite, CoachRole } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import API from 'utils/API';
import { fireAndForget } from 'utils/async';
import { sendEvent } from 'utils/analyticsEvents';

const ORGANIZATION_INVITES_LOCAL_STORAGE_KEY = 'ORGANIZATION_INVITES_LOCAL_STORAGE_KEY';

interface TeamInvite {
  inviteeId?: string;
  inviterCoachId: string;
  createdAt: Date;
  inviteeEmail: string;
  inviteeUsername?: string;
  inviteeProfilePic?: string;
  inviteeFullName?: string;
  inviteeRole: CoachRole;
  shortId: string;
  declinedAt?: Date;
}

export class TeamInvites {
  invites: TeamInvite[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const invitesJSON = window.localStorage.getItem(ORGANIZATION_INVITES_LOCAL_STORAGE_KEY);
    if (invitesJSON) {
      this.invites = JSON.parse(invitesJSON);

      window.localStorage.setItem(
        ORGANIZATION_INVITES_LOCAL_STORAGE_KEY,
        JSON.stringify(this.invites),
      );
    }
  };

  updateModel = (invites: CoachTeamInvite[]) => {
    this.invites = invites.map(invite => ({
      inviteeId: invite.invitee_user_id ?? undefined,
      inviterCoachId: invite.inviter_user_id,
      createdAt: invite.created_at,
      inviteeEmail: invite.invitee_email,
      inviteeUsername: invite.invitee_username ?? undefined,
      inviteeProfilePic: invite.invitee_profile_pic ?? undefined,
      inviteeFullName: invite.invitee_full_name ?? undefined,
      shortId: invite.short_id,
      declinedAt: invite.declined_at ?? undefined,
      inviteeRole: invite.invitee_role,
    }));
    window.localStorage.setItem(
      ORGANIZATION_INVITES_LOCAL_STORAGE_KEY,
      JSON.stringify(this.invites),
    );
  };

  clearData = () => {
    this.invites = [];
    window.localStorage.removeItem(ORGANIZATION_INVITES_LOCAL_STORAGE_KEY);
  };

  deleteInvite = async (shortId: string) => {
    const existingInvite = this.invites.find(invite => invite.shortId === shortId);
    if (!existingInvite) {
      return;
    }
    this.invites = this.invites.filter(invite => invite.shortId !== shortId);
    try {
      await API.deleteOrgInvite({ inviteShortId: shortId });
      sendEvent('teamMembers_inviteDeleted');
    } catch {
      this.invites.push(existingInvite);
      toast.error('Unable to delete invite, please try again');
    }
  };

  resendInvite = async (shortId: string) => {
    const existingInvite = this.invites.find(invite => invite.shortId === shortId);
    if (!existingInvite) {
      return;
    }
    try {
      await API.deleteOrgInvite({ inviteShortId: shortId });
      await API.createOrgInvite({
        inviteeRole: existingInvite.inviteeRole,
        inviteeEmail: existingInvite.inviteeEmail,
        inviteeUsername: existingInvite.inviteeUsername,
      });
      sendEvent('teamMembers_inviteResent');
      toast.success('Invititation email was re-sent.');
    } catch (e) {
      toast.error('Unable to resend invite, please try again');
    } finally {
      fireAndForget([this.fetch()]);
    }
  };

  hasFetched = false;
  isFetchingInvites = false;
  fetch = async () => {
    if (this.isFetchingInvites) {
      return;
    }
    this.isFetchingInvites = true;
    try {
      const response = await API.getOrgInvites();
      this.updateModel(response.data?.invites);
    } catch (e) {
      captureException(e);
      this.clearData();
      throw e;
    } finally {
      this.hasFetched = true;
      this.isFetchingInvites = false;
    }
  };
}
