import {
  Workout,
  workoutDurationSeconds,
  workoutReps,
  workoutDistanceMeters,
  WeightUnit,
  DistanceUnitShort,
  roundToWholeNumber,
  roundToTwoDecimal,
  secondsToWordFormatMinutes,
  numberWithCommas,
  DistanceUnit,
} from 'hevy-shared';
import { userWeight, userDistance } from 'utils/units';
import { StatProps, WorkoutContentProps } from './types';
import { sum } from 'lodash';

export interface Preferences {
  weightUnit: WeightUnit;
  distanceUnitShort: DistanceUnitShort;
  defaultRestTimerSeconds: number;
  distanceUnit: DistanceUnit;
}

export const calculateWorkoutStats = (workout: Workout, preferences: Preferences): StatProps[] => {
  const volumeKg = workout.estimated_volume_kg;
  const durationSeconds = workoutDurationSeconds(workout);
  const reps = workoutReps(workout);
  const distanceMeters = workoutDistanceMeters(workout);

  const stats: StatProps[] = [
    {
      title: 'Duration',
      value: secondsToWordFormatMinutes(durationSeconds),
      isPr: false,
    },
  ];

  const userVolume = userWeight(volumeKg, preferences.weightUnit);
  const distance = userDistance(distanceMeters, preferences.distanceUnitShort);

  const personalRecordCount: number = workout.exercises.reduce((accu, e) => {
    return accu + sum(e.sets.map(s => s.prs?.length || 0));
  }, 0);

  if (volumeKg > reps) {
    stats.push({
      title: 'Volume',
      value: `${numberWithCommas(roundToWholeNumber(userVolume))} ${preferences.weightUnit}`,
      isPr: false,
    });
  } else if (reps > distanceMeters) {
    stats.push({ title: 'Reps', value: `${reps}`, isPr: false });
  } else if (distance > 0) {
    stats.push({
      title: 'Distance',
      value: `${roundToTwoDecimal(distance)} ${preferences.distanceUnitShort}`,
      isPr: false,
    });
  }

  if (personalRecordCount) {
    stats.push({
      title: 'Records',
      value: `${personalRecordCount}`,
      isPr: true,
    });
  }

  return stats;
};

export const setsPluralized = (setsCount: number): string => {
  return setsCount === 1 ? 'set' : 'sets';
};

export const calculateWorkoutContent = (workout: Workout): WorkoutContentProps => {
  const exercises = workout.exercises.map((e, index) => {
    const setPluralized = setsPluralized(e.sets.length);
    const title = `${e.sets.length} ${setPluralized} ${e.title}`;

    return {
      key: `${index}`,
      superset: e.superset_id ?? undefined,
      title,
      image: e.thumbnail_url ?? undefined,
    };
  });

  const truncatedExerciseCount = exercises.length - 3;
  const exercisePluralized = truncatedExerciseCount === 1 ? 'exercise' : 'exercises';
  const truncatedExercisesTitle =
    truncatedExerciseCount > 0
      ? `See ${truncatedExerciseCount} more ${exercisePluralized}`
      : undefined;

  return {
    images: workout.image_urls || [],
    exercises: exercises.slice(0, 3),
    truncatedExercisesTitle,
  };
};

export const calculateLikeCount = (workout: Workout): string => {
  if (workout.like_count === 1) return '1 like';

  return `${workout.like_count} likes`;
};

export const calculateCommentCount = (workout: Workout): string => {
  if (workout.comment_count === 1) return '1 comment';

  return `${workout.comment_count} comments`;
};
