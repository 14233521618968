import React, { CSSProperties, useState } from 'react';
import { ProgressPictureCellSelected } from '../ProgressPictureModalViewModel';
import dayjs from 'dayjs';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import {
  BodyMeasurementWithImage,
  measurementToString,
} from 'screens/ClientDetail/Tabs/ProgressPictures/utils';

export const ProgressPictureCell = ({
  measurement,
  selected,
  onClick,
  style,
}: {
  measurement: BodyMeasurementWithImage;
  selected: ProgressPictureCellSelected;
  onClick: () => void;
  style?: CSSProperties;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <FlexRow
      key={measurement.id}
      style={{
        cursor: 'pointer',
        backgroundColor:
          !isHovering && selected !== 'single-selected' ? undefined : colors.neutral100,
        borderRadius: CornerRadius.md,
        padding: Spacing.sm,
        ...style,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
    >
      <View style={{ position: 'relative', marginRight: Spacing.md }}>
        <img
          src={measurement.picture_url}
          style={{
            height: 80,
            width: 'auto',
            borderRadius: CornerRadius.sm,
          }}
        />
        {(selected === 'after-selected' || selected === 'before-selected') && (
          <View
            style={{
              position: 'absolute',
              borderRadius: CornerRadius.sm,
              width: '100%',
              height: '100%',
              backgroundColor: `${colors.neutral900}6E`,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextSMMedium style={{ color: colors.white, fontSize: 12, opacity: 1.0 }}>
              {selected === 'after-selected' ? 'After' : 'Before'}
            </TextSMMedium>
          </View>
        )}
      </View>

      <View>
        <TextSMMedium>{dayjs(measurement.date).format('DD MMM, YYYY')}</TextSMMedium>
        <TextSMRegular>
          {measurementToString(measurement, CoachUnitsAggregator.weightUnit)}
        </TextSMRegular>
      </View>
    </FlexRow>
  );
};
