import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { zIndexes } from 'styleguide/zIndex';

const Container = styled(View)`
  width: 400px;
  border-radius: ${CornerRadius.md}px;
  padding: ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

const UpperContainer = styled(View)``;

export const MaintenanceModeModal = () => {
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        // Nothing to do
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayStyle={{ zIndex: zIndexes.alertModal }}
    >
      <Container>
        <UpperContainer>
          <TextLG style={{ marginBottom: Spacing.xs }}>Server Maintenance 🛠️</TextLG>

          <TextSMRegular style={{ color: colors.neutral500 }}>
            Our servers are getting a little sprucing up at the moment. Please try again in a few
            minutes.
          </TextSMRegular>
          <FlexRow style={{ marginTop: Spacing.md, justifyContent: 'center' }}>
            <ClipLoader size={25} color={colors.neutral400} />
          </FlexRow>
        </UpperContainer>
      </Container>
    </Modal>
  );
};
