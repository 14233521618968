import styled from 'styled-components';
import { colors } from './colors';

export const DividerLine = styled.div`
  background-color: ${colors.neutral150};
  width: 100%;
  height: 1px;
`;
export const VerticalDividerLine = styled.div`
  background-color: ${colors.neutral150};
  height: 100%;
  width: 1px;
`;
