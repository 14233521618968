import { ProfileImage } from 'components/ProfileImage';
import { HideOnDesktopDiv, ShowOnDesktopDiv } from 'components/mobileVsDesktopShowHideHelpers';
import React from 'react';
import { StyledCheckbox } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';

export const ClientSelectionRow = ({
  isSelected,
  onSelected,
  coachName,
  coachImageUrl,
  clientImageUrl,
  clientName,
  clientEmail,
}: {
  isSelected: boolean;
  onSelected: () => void;
  coachName?: string;
  coachImageUrl?: string;
  clientImageUrl?: string;
  clientName: string;
  clientEmail: string;
}) => {
  return (
    <FlexRow
      style={{ gap: Spacing.md, cursor: 'pointer' }}
      onClick={e => {
        onSelected();
        e.preventDefault();
      }}
    >
      <FlexRow style={{ justifyContent: 'space-between', flex: 1, flexWrap: 'wrap' }}>
        <FlexRow style={{ gap: Spacing.sm, flex: 1.5, alignItems: 'flex-start' }}>
          <StyledCheckbox checked={isSelected} style={{ paddingTop: 12 }} />
          <ProfileImage source={clientImageUrl} diameter={40} />
          <View>
            <TextMD style={{ textAlign: 'left', lineHeight: 'unset' }}>{clientName}</TextMD>
            <TextSMRegular style={{ textAlign: 'left' }}>{clientEmail}</TextSMRegular>
            {!!coachName && (
              <HideOnDesktopDiv
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: Spacing.sm,
                  paddingTop: Spacing.xs,
                }}
              >
                <TextSMRegular style={{ textAlign: 'left' }}>Coached by </TextSMRegular>
                <ProfileImage source={coachImageUrl} diameter={20} />
                <View>
                  <TextSMRegular style={{ textAlign: 'left' }}>{coachName}</TextSMRegular>
                </View>
              </HideOnDesktopDiv>
            )}
          </View>
        </FlexRow>
        {coachName && (
          <ShowOnDesktopDiv
            style={{
              gap: Spacing.sm,
              flex: 1,
              minWidth: 150,
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <ProfileImage source={coachImageUrl} diameter={20} />
            <View>
              <TextSMRegular style={{ textAlign: 'left' }}>{coachName}</TextSMRegular>
            </View>
          </ShowOnDesktopDiv>
        )}
      </FlexRow>
    </FlexRow>
  );
};
