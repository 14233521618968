import React from 'react';
import { ScaleType } from 'recharts/types/util/types';
import { colors } from 'styleguide/colors';
import fonts from 'styleguide/fonts';
import { Spacing } from 'styleguide/spacing';

interface AxisLabelProps {
  x?: number;
  y?: number;
  payload?: any;
  fill?: string;
  formatter?: (value: any) => string;
}

export const CustomizedAxisLabel = ({ x = 0, y = 0, payload, fill, formatter }: AxisLabelProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="middle"
        fill={fill || colors.neutral500}
        fontFamily={fonts.regular.family}
        fontWeight={fonts.regular.weight}
        fontSize={12}
      >
        {!!formatter ? formatter(payload.value) : payload.value}
      </text>
    </g>
  );
};

export const defaultYAxisProps = {
  scale: 'linear' as ScaleType,
  axisLine: false,
  tickLine: false,
  tickMargin: Spacing.md,
  allowDecimals: false,
  tick: <CustomizedAxisLabel />,
};

export const defaultXAxisProps = {
  color: colors.neutral500,
  dataKey: 'date',
  minTickGap: 0,
  tickLine: false,
  axisLine: { strokeWidth: 1, stroke: colors.neutral200 },
  tickMargin: Spacing.md,
  tick: <CustomizedAxisLabel />,
};
