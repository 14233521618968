import styled from 'styled-components';
import React from 'react';
import { colors } from 'styleguide/colors';
import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius } from 'styleguide/spacing';

export interface ToggleProps {
  isOn: boolean;
  loading?: boolean;
  onClick: () => void;
  enabled?: boolean;
}

const Container = styled(FlexRow)`
  height: 20px;
  width: 36px;
  min-width: 36px;
  border-radius: ${CornerRadius.sm * 2}px;
  cursor: pointer;
`;
const Knob = styled(FlexRow)`
  width: 16px;
  height: 16px;
  border-radius: ${CornerRadius.md}px;
  background-color: ${colors.white};
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06))
    drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1));
  justify-content: center;
`;

export const Toggle = ({ isOn, loading, onClick, enabled }: ToggleProps) => {
  return (
    <Container
      onClick={onClick}
      style={{
        backgroundColor:
          enabled === false ? colors.neutral300 : isOn ? colors.primary500 : colors.neutral300,
      }}
    >
      <motion.div
        initial={false}
        animate={{ marginLeft: isOn ? 18 : 2 }}
        transition={{ duration: 0.1, ease: 'easeInOut' }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Knob>{loading && <ClipLoader size={6} color={colors.neutral300} />}</Knob>
      </motion.div>
    </Container>
  );
};
