import { CoachAccount, SetPersonalRecordType } from 'hevy-shared';
import { Client } from 'types/client';

export const urlBase64ToUint8Array = (base64String: String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

/**
 * Compares clients alphabetically by their full name (if they have one) or username.
 **/
export const compareClientsAlphabetically = (a: Client, b: Client) => {
  return (a.fullName ?? a.username).localeCompare(b.fullName ?? b.username);
};

export const compareCoachesAlphabetically = (a: CoachAccount, b: CoachAccount) => {
  return (a.full_name ?? a.username).localeCompare(b.full_name ?? b.username);
};

export const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const sortDescending = (a: number, b: number) => {
  return b - a;
};

export const sortAscending = (a: number, b: number) => {
  return a - b;
};

export const clientMatchesSearch = (
  client: {
    fullName?: string | null;
    username: string;
    email: string;
  },
  search: string,
) => {
  const lowercaseSearch = search.toLocaleLowerCase();
  return (
    client.fullName?.toLowerCase().includes(lowercaseSearch) ||
    client.username.toLowerCase().includes(lowercaseSearch) ||
    client.email.toLowerCase().includes(lowercaseSearch)
  );
};

export const coachMatchesSearch = (coach: CoachAccount, search: string | undefined) => {
  if (!search) return true;

  const lowercaseSearch = search.toLocaleLowerCase();
  return (
    coach.full_name?.toLowerCase().includes(lowercaseSearch) ||
    coach.username.toLowerCase().includes(lowercaseSearch)
  );
};

export const randomizeArrayElements = (array: any[]) => [...array].sort(() => Math.random() - 0.5);

/**
 * Returns a promise that resolves after the given amount of milliseconds.
 * Good for debugging purposes.
 */
export const sleep = async (milliseconds: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(undefined);
    }, milliseconds);
  });
};

export const humanReadableStringList = (items: string[]) => {
  if (items.length === 0) {
    return '';
  } else if (items.length === 1) {
    return items[0];
  } else if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  } else {
    const lastItem = items.pop();
    return `${items.join(', ')}, and ${lastItem}`;
  }
};

export const prTitleForPRType = (prType: SetPersonalRecordType) => {
  switch (prType) {
    case 'best_1rm':
      return 'Best 1 Rep Max';
    case 'best_distance':
      return 'Best Distance';
    case 'best_duration':
      return 'Best Duration';
    case 'best_volume':
      return 'Best Volume';
    case 'best_weight':
      return 'Best Weight';
    case 'best_reps':
      return 'Best Reps';
  }
};
