import { ProfileImage } from 'components/ProfileImage';
import React from 'react';
import { StyledCheckbox } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Client } from 'types/client';

interface ClientSelectionCardProps {
  client: Client;
  onSelectClient: (id: string) => void;
  isSelected: boolean;
  programTitle?: string;
}

export const ClientSelectionCard = ({
  client,
  onSelectClient,
  isSelected,
  programTitle,
}: ClientSelectionCardProps) => {
  return (
    <FlexRow
      style={{ cursor: 'pointer', marginBottom: Spacing.md - Spacing.xs }}
      onClick={() => onSelectClient(client.id)}
    >
      <StyledCheckbox
        style={{ margin: Spacing.md }}
        checked={isSelected}
        onChange={() => onSelectClient(client.id)}
      />
      <ProfileImage source={client.profilePic} diameter={40} />
      <View style={{ justifyContent: 'center', marginLeft: Spacing.md }}>
        <TextMD>{client.fullName || client.username}</TextMD>
        {programTitle && <TextSMRegular>{programTitle}</TextSMRegular>}
      </View>
    </FlexRow>
  );
};
