import { AnalyticsUserProperties } from './userPropertiesListener';
import dayjs from 'dayjs';

export const INTERCOM_APP_ID = 'dqp6ccfs';

export const setIntercomIdentity = (userProps: AnalyticsUserProperties) => {
  if ((window as any).Intercom === undefined) {
    console.warn('Intercom not defined.');
  }
  (window as any).Intercom?.('boot', {
    app_id: INTERCOM_APP_ID,
    user_id: userProps.userId,
    email: userProps.email,
    users_name: userProps.fullName ?? userProps.username,
    users_first_name_or_username: userProps.personalName,
    number_of_clients: userProps.clientsCount,
    number_of_program_templates: userProps.programTemplatesCount,
    number_of_assigned_programs: userProps.assignedProgramsCount,
    number_of_programs_assigned_to_real_clients: userProps.programsAssignedToRealClientsCount,
    is_paying_coach: userProps.isPaidCoach,
    current_plan: userProps.coachPaymentPlan,
    created_at: userProps.accountCreatedAt,
    became_coach_at: !!userProps.becameCoachAt ? dayjs(userProps.becameCoachAt).unix() : undefined,
    trial_expiry_date: userProps.trialExpiryDate,
    is_trialing: userProps.isTrialing,
    is_coaching_theirself: userProps.isCoachingTheirself,
  });
};
