import router from 'next/router';
import React from 'react';
import { ClickableText } from 'styleguide/Buttons';
import { TextSMMedium } from 'styleguide/Texts';
import { Client } from 'types/client';

export const ClientNowAvailableToast = (client: Client) => {
  return (
    <span>
      <TextSMMedium style={{ display: 'inline' }}>Your client </TextSMMedium>
      <ClickableText
        text={client.fullName || client.username}
        onClick={() => router.push(`/clients/${client.id}`)}
      />{' '}
      <TextSMMedium style={{ display: 'inline' }}>is now available!</TextSMMedium>
    </span>
  );
};
