import { reaction } from 'mobx';
import { BackendReachability } from 'state/backendReachability';
import { refreshUserContent } from './fetchUserContent';

export const maintenenceModeListener = () => {
  reaction(
    () => BackendReachability.backendReachabilityState,
    currentReachability => {
      if (currentReachability === 'maintenance-mode') {
        // When we detect a change to maintenance mode, keep requesting a refresh
        // of required data so we know we're reachable
        refreshUserContent();
      }
    },
  );
};
