import dayjs, { Dayjs } from 'dayjs';
import {
  MuscleGroup,
  RoutineSet,
  SetPersonalRecord,
  Workout,
  WorkoutExerciseSet,
  workoutDurationSeconds,
} from 'hevy-shared';
import { flatten } from 'lodash';
import { SetCountPerMuscleGroupGraphDataPoint } from 'screens/ClientDetail/Tabs/AdvancedStats/types';

export const calculateSetCount = (workout: Workout) => {
  return workout.exercises.reduce((prev, curr) => prev + curr.sets.length, 0);
};

export const workoutPrs = (workout: Workout): SetPersonalRecord[] => {
  return flatten(
    workout.exercises.map(e =>
      flatten(
        e.sets.map(s =>
          s.prs.map(pr => ({
            exercise_template_id: e.exercise_template_id,
            type: pr.type,
            record: pr.value,
          })),
        ),
      ),
    ),
  );
};

export const calculateTotalWorkoutVolumeKg = (workouts: Workout[]): number => {
  const totalVolumeKg = workouts.reduce((accu, workout) => {
    return accu + workout.estimated_volume_kg;
  }, 0);

  return totalVolumeKg;
};

export const calculateTotalWorkoutTimeSeconds = (workouts: Workout[]): number => {
  const totalWorkoutTime = workouts.reduce((accu, w) => {
    return accu + workoutDurationSeconds(w);
  }, 0);

  return totalWorkoutTime;
};

export const calculateTotalWorkoutSets = (workouts: Workout[]): number => {
  const totalSets = workouts.reduce((accu, w) => {
    const sets = w.exercises.reduce((accu, e) => {
      return accu + e.sets.length;
    }, 0);
    return accu + sets;
  }, 0);

  return totalSets;
};

export const filterWorkoutsByStartEndDate = (
  workouts: Workout[],
  startDate: Dayjs,
  endDate: Dayjs,
): Workout[] => {
  return workouts.filter(w => {
    const workoutDate = dayjs.unix(w.start_time);
    return (
      (workoutDate.isAfter(startDate) || workoutDate.isSame(startDate)) &&
      workoutDate.isBefore(endDate)
    );
  });
};

/**
 * Calculates the number of sets per muscle group for a given array of workouts or routines.
 * Primary and secondary muscles are both counted as 1 set. Note that
 * this means that if an exercise has abdominals as both the primary
 * and secondary muscle group, one set will show as 2 sets for abdominals.
 * Warmup sets are excluded.
 */
export const calculateSetCountPerMuscleGroup = (
  workoutsOrRoutines: {
    exercises: { muscle_group: MuscleGroup; other_muscles: MuscleGroup[]; sets: any[] }[];
  }[],
): Omit<SetCountPerMuscleGroupGraphDataPoint, 'date'> => {
  const muscleGroupSetCount = workoutsOrRoutines.reduce((accu, w) => {
    w.exercises.forEach(e => {
      const sets = e.sets as (WorkoutExerciseSet | RoutineSet)[];
      const workingSetCount = sets.filter(set => set.indicator !== 'warmup').length;
      accu[e.muscle_group] = accu[e.muscle_group] || 0;
      accu[e.muscle_group] += workingSetCount;

      e.other_muscles.forEach(mg => {
        accu[mg] = accu[mg] || 0;
        accu[mg] += workingSetCount;
      });
    });

    return accu;
  }, {} as { [muscleGroup in MuscleGroup]: number });

  return {
    abdominals: muscleGroupSetCount['abdominals'] || 0,
    abductors: muscleGroupSetCount['abductors'] || 0,
    adductors: muscleGroupSetCount['adductors'] || 0,
    biceps: muscleGroupSetCount['biceps'] || 0,
    calves: muscleGroupSetCount['calves'] || 0,
    chest: muscleGroupSetCount['chest'] || 0,
    forearms: muscleGroupSetCount['forearms'] || 0,
    glutes: muscleGroupSetCount['glutes'] || 0,
    hamstrings: muscleGroupSetCount['hamstrings'] || 0,
    lats: muscleGroupSetCount['lats'] || 0,
    lower_back: muscleGroupSetCount['lower_back'] || 0,
    upper_back: muscleGroupSetCount['upper_back'] || 0,
    neck: muscleGroupSetCount['neck'] || 0,
    quadriceps: muscleGroupSetCount['quadriceps'] || 0,
    shoulders: muscleGroupSetCount['shoulders'] || 0,
    triceps: muscleGroupSetCount['triceps'] || 0,
    traps: muscleGroupSetCount['traps'] || 0,
    cardio: muscleGroupSetCount['cardio'] || 0,
    full_body: muscleGroupSetCount['full_body'] || 0,
    other: muscleGroupSetCount['other'] || 0,
  };
};
