import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { TextXSRegular } from 'styleguide/Texts';
import { TinyOverlappingProfileImages } from './WorkoutCell/components/TinyOverlappingProfileImages';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LikeCountContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface WorkoutSocialPreviewProps {
  likeCount: string;
  commentCount: string;
  likeImages: string[];
  style?: CSSProperties;
  onCommentClick?: () => void;
}

export const WorkoutSocialPreview = ({
  likeCount,
  likeImages,
  commentCount,
  style,
  onCommentClick,
}: WorkoutSocialPreviewProps) => {
  return (
    <Container style={style}>
      <LikeCountContainer>
        {!!likeImages.length && (
          <TinyOverlappingProfileImages style={{ marginRight: 8 }} images={likeImages} />
        )}
        <TextXSRegular>{likeCount}</TextXSRegular>
      </LikeCountContainer>
      <TextXSRegular style={{ cursor: 'pointer' }} onClick={onCommentClick}>
        {commentCount}
      </TextXSRegular>
    </Container>
  );
};
