'use client';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine, VerticalDividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextMD, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { AssignProgramToMultipleClientsViewModel } from './AssignProgramToMultipleClientsViewModel';
import { ClientSelectionCard } from './ClientSelectionCard';
import { SearchInput } from 'styleguide/Inputs';
import { localStorageStores } from 'state/localStorageStores';
import { CloseButton } from 'components/CloseButton';
import { FlexRow } from 'styleguide/Row';
import { ProgramDurationSelector } from 'components/ProgramScheduling/ProgramDurationSelector';
import { ProgramCalendar } from 'components/ProgramScheduling/ProgramCalendar/ProgramCalendar';
import { Toggle } from 'components/Toggle';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { HideOnPhoneDiv, ShowOnPhoneDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { ProgramStartDateSelector } from 'components/ProgramScheduling/ProgramStartDateSelector';
import { EmptyState } from 'components/EmptyState';

const Container = styled(View)`
  width: 996px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(View)`
  margin: ${Spacing.lg}px;
`;

const TitleContainer = styled(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${Spacing.md}px;
`;

const MiddleContainer = styled(FlexRow)`
  align-items: flex-start;
  overflow-y: auto;
  height: 100%;
  min-height: 40vh;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ClientSelectionContainer = styled(View)`
  overflow-y: auto;
  padding: ${Spacing.md}px;
  box-sizing: border-box; // to include padding in width and height
  height: 100%;
  width: 100%;
  min-height: 516px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    min-height: unset;
  }
`;

const MobileScheduleContainer = styled(View)`
  padding: ${Spacing.md}px;
  padding-top: 0px;
  gap: ${Spacing.lg}px;
  width: 100%;
  box-sizing: border-box; // to include padding in width and height
  @media (min-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const ScheduleContainer = styled(View)`
  min-width: 332px;
  padding: ${Spacing.lg}px;
  gap: ${Spacing.lg}px;
  box-sizing: border-box; // to include padding in width and height
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: ${Spacing.md}px ${Spacing.lg}px;
`;

const NoClientsContainer = styled(View)`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-top: ${Spacing.lg}px;
  padding-bottom: ${Spacing.lg}px;
`;

export interface AssignProgramToMultipleClientsModalProps {
  isOpen: boolean;
  onClose: () => void;
  vm: AssignProgramToMultipleClientsViewModel;
}

export const AssignProgramToMultipleClientsModal = observer(
  ({ isOpen, onClose, vm }: AssignProgramToMultipleClientsModalProps) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container>
          <UpperContainer>
            <TitleContainer>
              <TextLG>Copy Workout Program to Clients</TextLG>
              <CloseButton onClick={onClose} />
            </TitleContainer>
            {vm.allClients.length !== 0 && (
              <SearchInput
                value={vm.searchText}
                placeholder={'Search Client'}
                onChange={vm.onSearchTextChange}
                style={{ backgroundColor: colors.neutral100 }}
              ></SearchInput>
            )}
          </UpperContainer>
          <HideOnPhoneDiv>
            <DividerLine />
          </HideOnPhoneDiv>
          <MiddleContainer>
            {vm.filteredClients.length === 0 ? (
              <NoClientsContainer>
                <EmptyState
                  title="No Clients"
                  subtitle={
                    !vm.searchText
                      ? "You don't have any clients yet"
                      : `Couldn't find any clients matching "${vm.searchText}"`
                  }
                  iconType="check"
                />
              </NoClientsContainer>
            ) : (
              <>
                <MobileScheduleContainer>
                  <FlexRow style={{ justifyContent: 'space-between' }}>
                    <TextMD>Schedule</TextMD>
                    <Toggle isOn={vm.isSchedulingEnabled} onClick={vm.onScheduleToggle} />
                  </FlexRow>
                  {vm.isSchedulingEnabled && (
                    <FlexRow style={{ justifyContent: 'center', gap: Spacing.md }}>
                      <View style={{ gap: Spacing.sm, flex: 1 }}>
                        <TextSMMedium>Duration</TextSMMedium>
                        <ProgramDurationSelector
                          selectedDuration={vm.selectedProgramDuration}
                          onSelectDuration={vm.onChangeDuration}
                        ></ProgramDurationSelector>
                      </View>
                      <View style={{ gap: Spacing.sm, flex: 1 }}>
                        <TextSMMedium>Start Date</TextSMMedium>
                        <ProgramStartDateSelector
                          startDate={vm.selectedProgramStartDate}
                          onSelectStartDate={vm.onChangeStartDate}
                          durationDays={vm.selectedProgramDuration}
                          firstWeekday={vm.coachFirstWeekday}
                        ></ProgramStartDateSelector>
                      </View>
                    </FlexRow>
                  )}
                </MobileScheduleContainer>
                <ShowOnPhoneDiv style={{ width: '100%' }}>
                  <DividerLine />
                </ShowOnPhoneDiv>

                <ClientSelectionContainer>
                  <TextSMRegular style={{ color: colors.neutral500, marginBottom: Spacing.md }}>
                    Copying “{vm.program.title}” to a client will create individual copies of the
                    program for each client. This copy of the program can be edited through the
                    client’s program page.
                  </TextSMRegular>
                  {vm.filteredClientsWithNoPrograms.length !== 0 && (
                    <>
                      <TextSMMedium style={{ marginBottom: Spacing.md }}>
                        Clients without an assigned Program
                      </TextSMMedium>
                      {vm.filteredClientsWithNoPrograms?.map(client => {
                        return (
                          <ClientSelectionCard
                            key={client.id}
                            client={client}
                            onSelectClient={vm.onSelectedClient}
                            isSelected={vm.isClientSelected(client.id)}
                          />
                        );
                      })}
                    </>
                  )}
                  {vm.filteredClientsWithPrograms.length !== 0 && (
                    <>
                      <TextSMMedium style={{ marginBottom: Spacing.md }}>
                        Clients with an assigned Program
                      </TextSMMedium>
                      {vm.filteredClientsWithPrograms?.map(client => {
                        return (
                          <ClientSelectionCard
                            key={client.id}
                            client={client}
                            onSelectClient={vm.onSelectedClient}
                            isSelected={vm.isClientSelected(client.id)}
                            programTitle={
                              localStorageStores.programs.programAssignedToClient(client.id)?.title
                            }
                          />
                        );
                      })}
                    </>
                  )}
                </ClientSelectionContainer>
                <HideOnPhoneDiv style={{ height: '100%' }}>
                  <VerticalDividerLine />
                </HideOnPhoneDiv>
                <ScheduleContainer>
                  <FlexRow style={{ justifyContent: 'space-between', flex: 1 }}>
                    <TextMD>Schedule</TextMD>
                    <Toggle isOn={vm.isSchedulingEnabled} onClick={vm.onScheduleToggle} />
                  </FlexRow>
                  {vm.isSchedulingEnabled && (
                    <>
                      <View style={{ gap: Spacing.sm }}>
                        <TextSMMedium>Duration</TextSMMedium>
                        <ProgramDurationSelector
                          selectedDuration={vm.selectedProgramDuration}
                          onSelectDuration={vm.onChangeDuration}
                        ></ProgramDurationSelector>
                      </View>
                      <View style={{ gap: Spacing.sm }}>
                        <TextSMMedium>Start Date</TextSMMedium>
                        <ProgramCalendar
                          durationDays={vm.selectedProgramDuration}
                          selectedDate={vm.selectedProgramStartDate}
                          onSelectDate={vm.onChangeStartDate}
                          firstWeekday={vm.coachFirstWeekday}
                        ></ProgramCalendar>
                      </View>
                    </>
                  )}
                </ScheduleContainer>
              </>
            )}
          </MiddleContainer>
          <DividerLine />
          {vm.filteredClients.length !== 0 && (
            <LowerContainer>
              <PrimaryButton
                style={{ marginLeft: Spacing.md }}
                title={
                  vm.selectedClientIds.length === 0
                    ? 'Copy Program'
                    : vm.selectedClientIds.length === 1
                    ? `Copy ${vm.selectedClientIds.length} Program`
                    : `Copy ${vm.selectedClientIds.length} Programs Seperately`
                }
                onClick={vm.confirmAssignProgram}
                enabled={vm.isCopyProgramsButtonEnabled}
                loading={vm.isCopying}
              />
            </LowerContainer>
          )}
        </Container>
      </Modal>
    );
  },
);
