import React, { ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';

/**
 * This is a wrapper around Recharts' ResponsiveContainer.
 * It's used to make the graph responsive.
 * It's a separate component because it fixes a bug with the graph getting cut off.
 */
export const ResponsiveGraphContainer = ({
  children,
  height,
}: {
  children: ReactElement;
  height?: number;
}) => {
  return (
    <ResponsiveContainer
      /**
       * https://github.com/recharts/recharts/issues/1423
       * When resizing the windows, chart was getting cut off weirdly.
       * They suggest using 99% width to fix it in the issue and it works.
       */
      width="99%"
      height={height || '100%'}
    >
      {children}
    </ResponsiveContainer>
  );
};
