import { CoachAccount } from 'hevy-shared';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { SearchInput, StyledCheckbox } from 'styleguide/Inputs';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { DividerLine } from 'styleguide/DividerLine';
import { Client } from 'types/client';
import { Toggle } from 'components/Toggle';
import { EmptyState } from 'components/EmptyState';
import { observer } from 'mobx-react-lite';
import { ModalHeader } from '../Components/ModalHeader';
import { TransferClientsFromCoachViewModel } from './TransferClientsFromCoachViewModel';
import { CircleNumber } from 'styleguide/CircleNumber';
import { colors } from 'styleguide/colors';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { CoachSelectionRow } from './components/CoachSelectionRow';
import { CardNoPadding } from 'styleguide/Card';
import { ClientSelectionRow } from './components/ClientSelectionRow';
import { SingleCircledIcon } from 'styleguide/CircledIcons';

const Container = styled(View)`
  width: 600px;
  max-height: 80vh;
  border-radius: ${CornerRadius.md}px;
`;

const MiddleContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  overflow-y: auto;
  height: 40vh;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.lg}px;
`;

const EmptyStateContainer = styled(View)`
  flex: 1;
  justify-content: center;
`;

export interface TransferClientsToCoachModalProps {
  clients: Client[];
  closeModal: () => void;
  isOpen: boolean;
  onCoachSelected: (coachId: string, keepProgram: boolean) => void;
  coachOptions: CoachAccount[];
}

const StepCircle = ({
  number,
  isCurrentStep,
  isCompleted,
}: {
  number: number;
  isCurrentStep: boolean;
  isCompleted?: boolean;
}) => {
  if (isCompleted) {
    return (
      <SingleCircledIcon
        tint={colors.white}
        type="check"
        diameter={20}
        containerStyle={{ backgroundColor: colors.primary500 }}
      ></SingleCircledIcon>
    );
  }
  return (
    <CircleNumber
      textColor={isCurrentStep ? colors.primary500 : colors.neutral500}
      backgroundColor={isCurrentStep ? colors.primary50 : colors.neutral150}
      number={number}
    />
  );
};

export const TransferClientsFromCoachModal = observer(() => {
  const vm = TransferClientsFromCoachViewModel;
  const { currentCoach } = vm;
  if (!currentCoach) return <></>;
  return (
    <Modal
      isOpen={vm.isOpen}
      onClose={vm.onClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Container>
        <ModalHeader text={vm.title} onClose={vm.onClose} />
        <FlexRow
          style={{
            paddingLeft: Spacing.lg,
            paddingRight: Spacing.lg,
            paddingBottom: Spacing.md,
            gap: Spacing.sm,
          }}
        >
          <StepCircle
            number={1}
            isCurrentStep={vm.currentStep === 'select-clients'}
            isCompleted={vm.isCompletedSelectingClients}
          />
          <TextSMMedium style={{ fontWeight: vm.currentStep === 'select-clients' ? '500' : '400' }}>
            Select Clients
          </TextSMMedium>

          <View style={{ paddingLeft: Spacing.xs, paddingRight: Spacing.xs }}>
            <CustomizableIcon type="chevron-right" tint={colors.neutral400} />
          </View>

          <StepCircle number={2} isCurrentStep={vm.currentStep === 'select-coach'} />
          <TextSMMedium style={{ fontWeight: vm.currentStep === 'select-coach' ? '500' : '400' }}>
            Select Coach
          </TextSMMedium>
        </FlexRow>

        <View style={{ marginLeft: Spacing.lg, marginRight: Spacing.lg, marginBottom: Spacing.md }}>
          <SearchInput
            value={vm.searchValue}
            placeholder={vm.searchPlaceholder}
            onChange={vm.onSearchTextUpdated}
            style={{ backgroundColor: colors.neutral100, width: '100%' }}
          />
        </View>

        {vm.currentStep === 'select-clients' && vm.anySelectedClientHasAssignedProgram && (
          <>
            <DividerLine />
            <CardNoPadding style={{ margin: `${Spacing.md}px ${Spacing.lg}px` }}>
              <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
                <View>
                  <TextSMMedium>Keep current workout program?</TextSMMedium>
                  <TextSMRegular>
                    Continue with the client's current program, routines, and schedule.
                  </TextSMRegular>
                </View>
                <Toggle isOn={vm.keepAssignedProgram} onClick={vm.onChangeKeepAssignedProgram} />
              </FlexRow>
            </CardNoPadding>
          </>
        )}

        <DividerLine />

        {vm.currentStep === 'select-clients' ? (
          <MiddleContainer>
            {vm.filteredClientOptions.length === 0 ? (
              <EmptyStateContainer>
                <EmptyState title={'No clients found'} iconType={'search'} />
              </EmptyStateContainer>
            ) : (
              <>
                {!vm.isSearching && (
                  <FlexRow
                    style={{ gap: Spacing.md, cursor: 'pointer' }}
                    onClick={vm.onSelectAllClientsChecked}
                  >
                    <StyledCheckbox
                      checked={vm.areAllClientsChecked()}
                      onChange={vm.onSelectAllClientsChecked}
                    />
                    <FlexRow style={{ justifyContent: 'space-between', flex: 1, flexWrap: 'wrap' }}>
                      <TextSMMedium>Select All</TextSMMedium>
                    </FlexRow>
                  </FlexRow>
                )}

                {vm.filteredClientOptions.map(clientOption => {
                  return (
                    <ClientSelectionRow
                      key={clientOption.id}
                      isSelected={vm.isClientSelected(clientOption)}
                      onSelected={() => vm.onSelectedClient(clientOption)}
                      clientName={clientOption.fullName ?? clientOption.username}
                      clientEmail={clientOption.email}
                      clientImageUrl={clientOption.profilePic}
                    />
                  );
                })}
              </>
            )}
          </MiddleContainer>
        ) : (
          // Select coach step
          <MiddleContainer>
            {vm.filteredCoachOptions.length === 0 ? (
              <EmptyStateContainer>
                <EmptyState title={'No coaches found'} iconType={'search'} />
              </EmptyStateContainer>
            ) : (
              <>
                {vm.filteredCoachOptions.map(coach => {
                  return (
                    <CoachSelectionRow
                      key={coach.id}
                      isSelected={vm.isCoachSelected(coach)}
                      onSelected={() => vm.onSelectedCoach(coach)}
                      coachName={coach.full_name ?? coach.username}
                      profilePic={coach.profile_pic}
                      role={coach.role}
                      numberOfClients={vm.clientsForCoach(coach.id).length}
                    />
                  );
                })}
              </>
            )}
          </MiddleContainer>
        )}

        <DividerLine />
        <LowerContainer>
          <SecondaryButton title="Cancel" onClick={vm.onClose} />
          <PrimaryButton
            title={'Continue'}
            enabled={vm.isContinueEnabled()}
            onClick={vm.onContinue}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});
