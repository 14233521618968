import dayjs from 'dayjs';
import { configure } from 'mobx';
import relativeTime from 'dayjs/plugin/relativeTime';
import { env } from 'env';
import { localStorageStores } from 'state/localStorageStores';
import { initAmplitude } from './amplitude';
import { log } from './log';
import { refreshUserContent } from './fetchUserContent';
import { userPropertiesListener } from './userPropertiesListener';
import { initPaddle } from './paddle';
import { initHotjar, startHotjarRecording } from './hotjar';
import { sendEvent } from './analyticsEvents';
import { HevyWebsocketClient } from './globals/HevyWebsocketClient';
import API from './API';
import { fireAndForget } from './async';
import { initAppleLogin } from './appleLogin';
import { maintenenceModeListener } from './maintenenceModeListener';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { initAPInterceptors } from './APInterceptors';
import { PushNotifications } from './globals/pushNotifications';

dayjs.extend(relativeTime);

configure({
  enforceActions: 'never',
});

const hevyEmployeeUsernames = [
  'ryan',
  'hevy_coach',
  'desmond',
  'guillemros',
  'gymrat',
  'hevyapp',
  'testbloop1',
  'testbloop2',
  'onboarding',
  'gui',
  'seasidecode',
  'aabbccddcc',
  'batu',
  'ryno2019',
  'ryantest',
];

const initializeServices = () => {
  initAPInterceptors();
  initPaddle();
  initAppleLogin();
  initHotjar();
  initAmplitude();
  userPropertiesListener();
  maintenenceModeListener();
};

export const bootstrap = async () => {
  log('🥾 bootstrapping');
  log(`🔗 api: `, env.apiUrl);

  const path = window.location.pathname.split('/')[1];
  if (!localStorageStores.auth.isAuthenticated) {
    localStorageStores.lifecycle.setBootstrapComplete();
    initializeServices();
    sendEvent('appLaunch', { authenticated: localStorageStores.auth.isAuthenticated, path });
    startHotjarRecording();
    return;
  }

  log('🥾 hydrating disk-stored data');
  localStorageStores.hydrateAll();

  PushNotifications.initialize();

  HevyWebsocketClient.startTryingToConnect();
  initializeServices();
  await refreshUserContent();
  PlanAggregator.handleInvalidPlanIfNeeded();
  localStorageStores.lifecycle.setBootstrapComplete();
  if (
    (localStorageStores.account.username ?? '' === '') ||
    !hevyEmployeeUsernames.includes(localStorageStores.account.username)
  ) {
    startHotjarRecording();
  }
  sendEvent('appLaunch', { authenticated: localStorageStores.auth.isAuthenticated, path });
  fireAndForget([API.postAuthenticatedBootstrapComplete()]);
};
