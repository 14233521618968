import { modal } from 'components/Modals/ModalManager';
import { observer } from 'mobx-react-lite';
import { TextButton } from 'styleguide/Buttons';
import { sendEvent } from 'utils/analyticsEvents';
import React from 'react';
import { localStorageStores } from 'state/localStorageStores';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';

const RedCircle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${colors.error500};
  margin-left: -${Spacing.xs}px;
  margin-top: -${Spacing.lg}px;
`;

export const WhatsNewButton = observer(() => {
  return (
    <FlexRow>
      <TextButton
        title="What's New"
        onClick={() => {
          sendEvent('desktop_navBar_whatsNew_click');
          modal.isMobileNavBarModalOpen = false;
          modal.openWhatsNewModal();
        }}
      ></TextButton>
      {!localStorageStores.whatsNew.hasUserSeenLatestWhatsNew && <RedCircle />}
    </FlexRow>
  );
});
