import styled from 'styled-components';
import { PHONE_WIDTH_BREAKPOINT, TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';

export const HideOnMobileDiv = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HideOnDesktopDiv = styled.div`
  @media (min-width: ${TABLET_WIDTH_BREAKPOINT + 1}px) {
    display: none !important;
  }
`;

export const HideOnTabletDiv = styled.div`
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

export const ShowOnDesktopDiv = styled.div`
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    display: none !important;
  }
`;

export const ShowOnMobileDiv = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export const ShowOnPhoneDiv = styled.div`
  display: none;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: block;
  }
`;

export const HideOnPhoneDiv = styled.div`
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;
