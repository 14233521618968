import { Modal } from 'styleguide/Modal';
import { View } from 'styleguide/View';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { TextLG } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { getYoutubeEmbedURL } from 'utils/youtube';
import { CloseButton } from 'components/CloseButton';
import { zIndexes } from 'styleguide/zIndex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - ${Spacing.lg * 2}px);
  padding: ${Spacing.lg}px;
`;

const MiddleContainer = styled(View)`
  max-height: 80vh;
  max-width: 80vw;
  width: 100%;
  overflow-y: auto;
  align-items: center;
  padding: ${Spacing.lg}px;
`;

export interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  videoURL: string;
}

export const VideoModal = ({ isOpen, onClose, title, videoURL }: VideoModalProps) => {
  const youtubeEmbedURL = getYoutubeEmbedURL(videoURL);
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={{ zIndex: zIndexes.videoModal }}
    >
      <Container>
        <UpperContainer>
          <TextLG>{title}</TextLG>
          <CloseButton onClick={onClose} />
        </UpperContainer>
        <DividerLine />
        <MiddleContainer>
          {youtubeEmbedURL ? (
            <iframe
              height="100%"
              width="100%"
              style={{ aspectRatio: 16 / 9 }}
              src={youtubeEmbedURL}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <>
              <video autoPlay height="100%" width="100%" controls style={{ aspectRatio: 16 / 9 }}>
                <source src={videoURL} type="video/mp4" />
              </video>
            </>
          )}
        </MiddleContainer>
      </Container>
    </Modal>
  );
};
