import { EmptyState } from 'components/EmptyState';
import { ResponsiveGraphContainer } from 'components/ResponsiveGraphContainer';
import dayjs from 'dayjs';
import React from 'react';
import { BarChart, XAxis, YAxis, LineChart, Line } from 'recharts';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { zIndexes } from 'styleguide/zIndex';
import { CustomizedAxisLabel, defaultXAxisProps, defaultYAxisProps } from './Axis';
import { colors } from 'styleguide/colors';
import { CustomBar } from './Bar';
import { CustomCartesianGrid } from './Grid';

const EmptyGraphContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const EmptyStateContainer = styled(View)`
  position: absolute;
  align-items: center;
  z-index: ${zIndexes.aboveSurface};
  padding: ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

interface EmptyBarGraphProps {
  title?: string;
  subtitle?: string;
  chartHeight?: number;
  xAxisHeight: number;
  barSize?: number;
  barStyle?: React.CSSProperties;
  button?: React.ReactNode;
}

export const EmptyBarGraph = ({
  title,
  subtitle,
  chartHeight,
  xAxisHeight,
  barSize = 20,
  barStyle,
  button,
}: EmptyBarGraphProps) => {
  return (
    <EmptyGraphContainer>
      <EmptyStateContainer>
        <EmptyState
          title={title || 'No data'}
          subtitle={subtitle || ''}
          iconType="empty-graph"
        ></EmptyState>
        {button}
      </EmptyStateContainer>

      <ResponsiveGraphContainer height={chartHeight}>
        <BarChart
          data={emptyStateData}
          style={barStyle}
          barSize={barSize}
          margin={{ top: Spacing.lg, right: Spacing.lg, left: -Spacing.sm - Spacing.xs }}
        >
          <CustomCartesianGrid />
          <XAxis
            {...defaultXAxisProps}
            height={xAxisHeight}
            tick={<CustomizedAxisLabel x={0} y={0} payload={undefined} fill={colors.neutral300} />}
            axisLine={{ strokeWidth: 1, stroke: colors.neutral200 }}
            tickFormatter={value => dayjs(value).format('MMM D')}
            color={colors.neutral500}
          />
          <YAxis
            {...defaultYAxisProps}
            scale={'linear'}
            domain={[() => 0, (dataMax: number) => dataMax]}
            tick={<CustomizedAxisLabel x={0} y={0} payload={undefined} fill={colors.neutral300} />}
          />
          <CustomBar fill={colors.neutral100} />
        </BarChart>
      </ResponsiveGraphContainer>
    </EmptyGraphContainer>
  );
};

interface EmptyLineGraphProps {
  title?: string;
  subtitle?: string;
  chartHeight?: number;
  xAxisHeight: number;
  lineStyle?: React.CSSProperties;
  minDomainValue?: 0 | 'dataMin';
  customEmptyData?: {
    date: string;
    value: number;
  }[];
  hideYAxisLabels?: boolean;
}

export const EmptyLineGraph = ({
  title,
  subtitle,
  chartHeight,
  xAxisHeight,
  lineStyle,
  customEmptyData,
  minDomainValue,
  hideYAxisLabels,
}: EmptyLineGraphProps) => {
  return (
    <EmptyGraphContainer>
      <EmptyStateContainer>
        <EmptyState
          title={title || 'No data'}
          subtitle={subtitle || ''}
          iconType="empty-graph"
        ></EmptyState>
      </EmptyStateContainer>

      <ResponsiveGraphContainer height={chartHeight}>
        <LineChart
          data={customEmptyData ?? emptyStateData}
          style={lineStyle}
          margin={{ top: Spacing.lg, right: Spacing.lg, left: -Spacing.sm - Spacing.xs }}
        >
          <CustomCartesianGrid />
          <XAxis
            {...defaultXAxisProps}
            height={xAxisHeight}
            dataKey="date"
            tick={<CustomizedAxisLabel fill={colors.neutral300} />}
            tickFormatter={value => dayjs(value).format('MMM D')}
          />
          <YAxis
            {...defaultYAxisProps}
            domain={[
              (dataMin: number) => (minDomainValue === 'dataMin' ? dataMin : 0),
              (dataMax: number) => dataMax,
            ]}
            tick={
              <CustomizedAxisLabel
                fill={colors.neutral300}
                formatter={hideYAxisLabels ? () => '' : undefined}
              />
            }
          />
          <Line
            dataKey="value"
            fill={colors.neutral100}
            isAnimationActive={true}
            stroke={colors.primary50}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveGraphContainer>
    </EmptyGraphContainer>
  );
};

const emptyStateData = [
  { date: 'Oct 5', value: 2 },
  { date: 'Dec 14', value: 3 },
  { date: 'Jan 9', value: 4 },
  { date: 'Feb 16', value: 7 },
  { date: 'Apr 17', value: 10 },
  { date: 'May 17', value: 9 },
  { date: 'Jul 3', value: 12 },
  { date: 'Jul 12', value: 15 },
  { date: 'Jul 20', value: 17 },
  { date: 'Jul 28', value: 13 },
  { date: 'Aug 12', value: 10 },
  { date: 'Aug 24', value: 11 },
];
