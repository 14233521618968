import { ProfileImage } from 'components/ProfileImage';
import React from 'react';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { isACoachInviteSearchUser } from '../types';
import { colors } from 'styleguide/colors';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { View } from 'styleguide/View';
import { CoachInviteSearchUser } from 'hevy-shared';

const SearchResultRowContainer = styled(FlexRow)<{ isHovering: boolean }>`
  gap: ${Spacing.sm}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  cursor: pointer;
  background-color: ${props => (props.isHovering ? colors.neutral100 : undefined)};
  border-radius: ${CornerRadius.sm}px;
`;

export const SearchResultRow = ({
  result,
  onPress,
}: {
  result: CoachInviteSearchUser | string;
  onPress: () => void;
}) => {
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <SearchResultRowContainer
      onClick={onPress}
      isHovering={isHovering}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isACoachInviteSearchUser(result) ? (
        <>
          <ProfileImage diameter={40} source={result.profile_pic} />
          <View>
            <FlexRow style={{ gap: Spacing.xs }}>
              <TextSMMedium>{result.username}</TextSMMedium>
              {!!result.full_name && <TextSMRegular>({result.full_name})</TextSMRegular>}
            </FlexRow>
            {!!result.email && <TextSMRegular>{result.email}</TextSMRegular>}
          </View>
        </>
      ) : (
        <>
          <SingleCircledIcon type="email" diameter={40} />
          <TextSMMedium>{result}</TextSMMedium>
        </>
      )}
    </SearchResultRowContainer>
  );
};
