import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { colors } from 'styleguide/colors';
import { TextLG, TextMDRegular } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 682px;
  flex-direction: column;
  padding: 24px 24px 24px 8px;
  transition: background-color 0.25s ease-in-out;
  background-color: ${colors.neutral100};
  border-top: 1px solid ${colors.neutral100};
  border-radius: ${CornerRadius.md}px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const CardLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CardRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 32px;
`;

const CardBody = styled.div`
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
`;

const CardText = styled(TextMDRegular)`
  margin-left: ${Spacing.md}px;
  margin-right: ${Spacing.md * 2}px;
  margin-top: ${Spacing.lg}px;
  margin-bottom: ${Spacing.xs}px;
  text-align: left;
  transition: opacity 0.25s ease-in-out;
`;

interface FAQCardProps {
  title: string;
  text: string;
  isFirst: boolean;
  isOpened: boolean;
  onPress: () => void;
}

const ExpandButton = ({ isOpened }: { isOpened: boolean }) => (
  <div style={{ position: 'relative', display: 'flex', width: Spacing.md, height: Spacing.md }}>
    <svg
      style={{ position: 'absolute' }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999878 8H14.9999"
        stroke={colors.primary500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      style={{
        position: 'absolute',
        transition: 'transform .25s ease-in-out',
        ...(isOpened ? { transform: 'scaleY(0)' } : { transform: 'scaleY(1)' }),
      }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.999878V14.9999"
        stroke={colors.primary500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const FAQCard = observer(({ title, text, isFirst, isOpened, onPress }: FAQCardProps) => {
  return (
    <Container
      style={{
        backgroundColor: isOpened ? undefined : colors.transparent,
        borderTop: isFirst ? colors.transparent : undefined,
      }}
      onClick={onPress}
    >
      <CardHeader>
        <CardLeft>
          <TextLG style={{ fontWeight: 500, marginLeft: Spacing.md }}>{title}</TextLG>
        </CardLeft>
        <CardRight>
          <ExpandButton isOpened={isOpened} />
        </CardRight>
      </CardHeader>
      <CardBody
        style={{
          maxHeight: isOpened ? 150 : 0,
        }}
      >
        <CardText
          style={{
            opacity: isOpened ? 1 : 0,
          }}
        >
          {text}
        </CardText>
      </CardBody>
    </Container>
  );
});
