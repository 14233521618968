import { Modal } from 'styleguide/Modal';
import { View } from 'styleguide/View';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { TextLG, TextMD, TextSMRegular } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { localStorageStores } from 'state/localStorageStores';
import dayjs from 'dayjs';
import { TextButton } from 'styleguide/Buttons';
import { modal } from './ModalManager';
import { CloseButton } from 'components/CloseButton';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { FlexRow } from 'styleguide/Row';

const Container = styled(View)`
  width: 500px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(View)`
  margin: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
  }
`;

const MiddleContainer = styled(View)`
  max-height: 55vh;
  overflow-y: auto;
  padding: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

export interface WhatsNewModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const WhatsNewModal = ({ isOpen, onClose }: WhatsNewModalProps) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <UpperContainer>
          <FlexRow style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <View>
              <TextLG>What's New</TextLG>
              <TextButton
                onClick={() => {
                  onClose();
                  modal.openFeedbackModal();
                }}
                title="Send feedback"
              />
            </View>
            <CloseButton onClick={onClose} />
          </FlexRow>
        </UpperContainer>
        <DividerLine />
        <MiddleContainer>
          {localStorageStores.whatsNew.orderedUpdates.map(update => {
            return (
              <div key={update.date.toString()} style={{ marginBottom: Spacing.md }}>
                <TextMD>{dayjs(update.date).format('MMMM D, YYYY')}</TextMD>
                <ul>
                  {update.features.map((feature, index) => {
                    return (
                      <li key={index} style={{ color: colors.neutral500 }}>
                        <TextSMRegular>{feature}</TextSMRegular>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </MiddleContainer>
      </Container>
    </Modal>
  );
};
