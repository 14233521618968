import { makeAutoObservable } from 'mobx';
import { Program, CoachesRoutine, RoutineExercise } from 'hevy-shared';
import programData from 'state/static/programData.json';

export class BundledProgramLibrary {
  routines: CoachesRoutine[] = [];
  programs: Program[] = [];

  constructor() {
    makeAutoObservable(this);
    this.programs = Object.values(programData.programs);
    this.routines = Object.values(programData.routines).map(r => {
      return {
        ...r,
        profile_pic: null,
        exercises: r.exercises as RoutineExercise[],
      };
    });
  }

  getProgramRoutines = (programId: string): CoachesRoutine[] => {
    return this.routines.filter(r => r.program_id === programId);
  };
}
