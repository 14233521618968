import dayjs, { Dayjs } from 'dayjs';

export const messageTimestamp = (date: Dayjs) => {
  return date.format('HH:mm');
};

export const messageDate = (dateString: string) => {
  const date = dayjs(dateString);

  if (date.isSame(dayjs(), 'day')) {
    return 'Today';
  } else if (date.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  } else {
    return date.format('MMM D, YYYY');
  }
};
