import React, { useState } from 'react';
import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { WorkoutComment } from 'hevy-shared';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { TextSMMedium, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { CommentOptionsMenu } from './CommentOptionsMenu';
import { localStorageStores } from 'state/localStorageStores';
import { Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
`;

interface CommentsProps {
  comments: WorkoutComment[];
  onCommentDelete: (commentId: number) => void;
  disableTruncateComments?: boolean;
}

export const Comments = ({ comments, onCommentDelete, disableTruncateComments }: CommentsProps) => {
  const [showAllComments, setShowAllComments] = useState(!!disableTruncateComments);
  const commentsToShow = showAllComments ? comments : comments.slice(0, 2);
  return (
    <Container>
      {commentsToShow.map(c => (
        <Comment key={c.id} c={c} onCommentDelete={() => onCommentDelete(c.id)} />
      ))}
      {comments.length > 2 && !showAllComments && (
        <TextSMRegular
          style={{ marginTop: Spacing.md, cursor: 'pointer' }}
          onClick={() => setShowAllComments(true)}
        >
          View all {comments.length} comments
        </TextSMRegular>
      )}
    </Container>
  );
};

interface CommentProps {
  c: WorkoutComment;
  onCommentDelete: () => void;
}

const Comment = ({ c, onCommentDelete }: CommentProps) => {
  return (
    <CommentContainer key={`${c.id}`}>
      <ProfileImage
        source={c.profile_pic}
        diameter={50}
        style={{
          borderRadius: 25,
          borderStyle: 'solid',
          borderColor: colors.neutral150,
          borderWidth: 1,
        }}
      />

      <View style={{ flex: 1, justifyContent: 'center', marginLeft: Spacing.md }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <TextSMMedium style={{ marginBottom: Spacing.xs }}>{c.username}</TextSMMedium>
          <TextXSRegular>{getRelativeDateWithTime(dayjs(c.created_at))}</TextXSRegular>
        </View>

        <View
          style={{
            flex: 1,
            height: 30,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextSMRegular>{c.comment}</TextSMRegular>
          {c.username === localStorageStores.account.username && (
            <View>
              <CommentOptionsMenu onDeleteClick={onCommentDelete} />
            </View>
          )}
        </View>
      </View>
    </CommentContainer>
  );
};
