import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { CoachAccount, CoachRole } from 'hevy-shared';

const ACCOUNT_LOCAL_STORAGE_KEY = 'ACCOUNT_LOCAL_STORAGE_KEY';

export class Account implements CoachAccount {
  id: string = '';
  username: string = '';
  email: string = '';
  full_name?: string = undefined;
  profile_pic?: string;
  created_at: string = '';
  last_workout_at: string = '';
  accepted_terms_and_conditions: boolean = false;
  became_coach_at?: string | undefined;
  has_hevy_routine: boolean = false;
  role: CoachRole = 'admin';
  last_app_launch_at: Date | null = null;
  join_form_message?: string;
  join_form_banner_image_url?: string;

  constructor() {
    makeAutoObservable(this);
  }

  get isAdminOrOwner() {
    return this.role === 'admin' || this.role === 'owner';
  }

  fetch = async () => {
    try {
      const result = await API.getAccount();
      this.updateModel(result.data);
      window.localStorage.setItem(ACCOUNT_LOCAL_STORAGE_KEY, JSON.stringify(result.data));
    } catch (error) {
      captureException(error);
      throw error;
    }
  };

  hydrate = () => {
    const accountJSON = window.localStorage.getItem(ACCOUNT_LOCAL_STORAGE_KEY);
    if (accountJSON) {
      this.updateModel(JSON.parse(accountJSON));
    }
  };

  clearData = () => {
    window.localStorage.removeItem(ACCOUNT_LOCAL_STORAGE_KEY);
    const emptyAccount: CoachAccount = {
      id: '',
      username: '',
      email: '',
      full_name: '',
      profile_pic: undefined,
      created_at: '',
      last_workout_at: '',
      accepted_terms_and_conditions: false,
      became_coach_at: undefined,
      has_hevy_routine: false,
      role: 'admin',
      last_app_launch_at: null,
      join_form_message: undefined,
      join_form_banner_image_url: undefined,
    };

    this.updateModel(emptyAccount);
  };

  get personalCoachName() {
    const capitilizeFirstChar = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    if (!!this.full_name) {
      return capitilizeFirstChar(this.full_name.split(' ')[0]);
    }
    return this.username;
  }

  private updateModel = (account: CoachAccount) => {
    this.id = account.id;
    this.username = account.username;
    this.email = account.email;
    this.full_name = account.full_name;
    this.profile_pic = account.profile_pic;
    this.created_at = account.created_at;
    this.last_workout_at = account.last_workout_at;
    this.became_coach_at = account.became_coach_at;
    this.accepted_terms_and_conditions = account.accepted_terms_and_conditions;
    this.has_hevy_routine = account.has_hevy_routine;
    this.role = account.role;
    this.last_app_launch_at = account.last_app_launch_at;
    this.join_form_message = account.join_form_message;
    this.join_form_banner_image_url = account.join_form_banner_image_url;
  };
}
