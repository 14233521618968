import dayjs from 'dayjs';
import { CoachSocialLoginResult } from 'hevy-shared';
import Cookies from 'js-cookie';
import router from 'next/router';
import API from 'utils/API';
import { refreshUserContent } from 'utils/fetchUserContent';
import { HevyWebsocketClient } from 'utils/globals/HevyWebsocketClient';

export class Auth {
  private get adminAuthToken() {
    return Cookies.get('admin-auth-token');
  }

  get isImpersonating() {
    return !!this.adminAuthToken;
  }

  get isAuthenticated(): boolean {
    return !!Cookies.get('auth-token');
  }

  get authToken(): string | undefined {
    return Cookies.get('auth-token');
  }

  login = async (
    usernameOrEmail: string,
    password: string,
    inviteShortId?: string,
  ): Promise<{ is_first_login_to_coach_platform: boolean }> => {
    const result = await API.login(usernameOrEmail, password, inviteShortId);
    const authToken = result.data.auth_token;
    this.setAuthToken(authToken);
    return { is_first_login_to_coach_platform: result.data.is_first_login_to_coach_platform };
  };

  loginWithTempToken = async (
    tempToken: string,
  ): Promise<{ is_first_login_to_coach_platform: boolean }> => {
    const result = await API.loginWithTempToken(tempToken);
    const authToken = result.data.auth_token;
    this.setAuthToken(authToken);
    return { is_first_login_to_coach_platform: result.data.is_first_login_to_coach_platform };
  };

  impersonateUser = async (authToken: string): Promise<void> => {
    const result = await API.validateAuthToken(authToken);

    const currentAuthToken = Cookies.get('auth-token');

    if (!currentAuthToken) {
      return;
    }

    Cookies.set('admin-auth-token', currentAuthToken, {
      expires: dayjs().add(10, 'year').toDate(),
    });

    this.setAuthToken(result.data.auth_token);
  };

  stopImpersonatingUser = async (): Promise<void> => {
    const authToken = this.adminAuthToken;

    if (!authToken) {
      this.logout();
      return;
    }

    Cookies.remove('admin-auth-token');

    const result = await API.validateAuthToken(authToken);

    this.setAuthToken(result.data.auth_token);
    await refreshUserContent();
    router.reload();
  };

  signup = async (
    username: string,
    email: string,
    password: string,
    isTermsAccepted: boolean,
    inviteShortId?: string,
  ) => {
    const result = await API.signup(username, email, password, isTermsAccepted, inviteShortId);
    const authToken = result.data.auth_token;
    this.setAuthToken(authToken);
  };

  signupWithTempToken = async (tempToken: string) => {
    const response = await API.signupFromTemporaryToken(tempToken);
    this.setAuthToken(response.data.auth_token);
  };

  loginWithGoogle = async (
    email: string,
    userId: string,
    idToken: string,
    inviteShortId?: string,
  ): Promise<CoachSocialLoginResult> => {
    const result = await API.signInWithGoogle(email, userId, idToken, inviteShortId);
    this.setAuthToken(result.data?.auth_token);
    return result.data;
  };

  loginWithApple = async (
    identityToken: string,
    email?: string,
    inviteShortId?: string,
  ): Promise<CoachSocialLoginResult> => {
    const result = await API.signInWithApple(identityToken, email, inviteShortId);
    this.setAuthToken(result.data?.auth_token);
    return result.data;
  };

  logout = () => {
    API.setAuthToken(undefined);
    HevyWebsocketClient.authToken = undefined;
    Cookies.remove('auth-token');
  };

  private setAuthToken = (newToken: string) => {
    if (!newToken) return;

    API.setAuthToken(newToken);
    HevyWebsocketClient.authToken = newToken;
    Cookies.set('auth-token', newToken, { expires: dayjs().add(10, 'year').toDate() });
  };
}
