import React from 'react';
import { Modal } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { observer } from 'mobx-react-lite';
import { ProgressPictureModalViewModel } from '../ProgressPictureModalViewModel';
import { DividerLine } from 'styleguide/DividerLine';
import styled from 'styled-components';
import { ProgressPictureCell } from './ProgressPictureCell';
import { ModalHeader } from 'components/Modals/Components/ModalHeader';
import { PrimaryButton } from 'styleguide/Buttons';
import { FlexRow } from 'styleguide/Row';

const MiddleContainer = styled(View)`
  overflow-y: scroll;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px;
  height: 100%;
  flex: 1;
`;

const LowerContainer = styled(View)`
  padding: ${Spacing.md}px;
`;

const Container = styled(View)`
  width: 100%;
`;

export const PictureSelectionModal = observer(({ vm }: { vm: ProgressPictureModalViewModel }) => {
  return (
    <Modal
      isOpen={vm.isMobilePictureSelectionModalOpen}
      onClose={vm.onCloseMobilePictureSelectionModal}
      contentStyle={{ width: 900 }}
    >
      <Container>
        <ModalHeader
          text={`Select ${
            vm.activePicture ? (vm.activePicture === 'after' ? 'After ' : 'Before ') : ''
          }Picture`}
          onClose={vm.onCloseMobilePictureSelectionModal}
        />
        <DividerLine />
        <MiddleContainer>
          {vm.measurements.map(measurement => {
            return (
              <FlexRow key={measurement.id}>
                <ProgressPictureCell
                  style={{ width: '100%' }}
                  measurement={measurement}
                  selected={vm.selectionModeForMeasurementId(measurement.id)}
                  onClick={() => vm.onMeasurementClicked(measurement.id)}
                />
              </FlexRow>
            );
          })}
        </MiddleContainer>
        <DividerLine />
        <LowerContainer>
          <PrimaryButton
            title="Select"
            onClick={vm.onCloseMobilePictureSelectionModal}
            style={{ width: '100%' }}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
});
