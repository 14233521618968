'use client';
import { ClientProgramSelectionCard, ProgramSelectionCard } from 'components/ProgramSelectionCard';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextMD, TextSMRegular } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { SearchInput } from 'styleguide/Inputs';
import { CloseButton } from 'components/CloseButton';
import { EmptyState } from 'components/EmptyState';
import { FlexRow } from 'styleguide/Row';
import { useWindowSize } from 'utils/useSize';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { CopyRoutineViewModel } from './CopyRoutineViewModel';

const Container = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
  margin: ${Spacing.lg}px;
  margin-bottom: 0px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
    margin-bottom: 0px;
  }
`;

const MiddleContainer = styled(View)`
  max-height: 50vh;
  overflow-y: auto;
  align-items: flex-start;
  padding-left: ${Spacing.md}px;
  padding-right: ${Spacing.md}px;
  align-items: stretch;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  margin: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    margin: ${Spacing.md}px;
  }
`;

const NoProgramsContainer = styled(View)`
  align-items: center;
  justify-content: center;
  padding: ${Spacing.lg}px;
`;

const SearchInputContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px ${Spacing.md}px;
  }
`;

export interface CopyRoutineModalProps {
  vm: CopyRoutineViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const CopyRoutineModal = observer(({ isOpen, onClose, vm }: CopyRoutineModalProps) => {
  const windowSize = useWindowSize();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <TextLG>{`Choose a program to copy "${vm.routine.title}" routine into`}</TextLG>
          <CloseButton onClick={onClose} />
        </UpperContainer>
        <SearchInputContainer>
          <SearchInput
            style={{ backgroundColor: colors.neutral100 }}
            value={vm.searchText}
            unfocusedBorderColor="transparent"
            onChange={vm.onSearchTextChanged}
            placeholder={'Search programs'}
          />
        </SearchInputContainer>
        <DividerLine />
        <MiddleContainer>
          {vm.filteredPrograms?.length === 0 ? (
            <NoProgramsContainer>
              <EmptyState
                title={'No Programs Found'}
                subtitle={'No programs matched your search'}
                iconType="folder"
              ></EmptyState>
            </NoProgramsContainer>
          ) : (
            <View style={{ marginBottom: Spacing.md, marginTop: Spacing.md }}>
              <TextSMRegular style={{ color: colors.neutral500 }}>
                Copying “{vm.routine.title}” to another program will create individual copies of the
                routine for each program.
              </TextSMRegular>

              {vm.programFolders.map(folder => (
                <View key={folder.id}>
                  <FlexRow
                    style={{
                      marginBottom: Spacing.md,
                      marginTop:
                        windowSize.width < PHONE_WIDTH_BREAKPOINT ? Spacing.md : Spacing.lg,
                      gap: Spacing.sm,
                    }}
                  >
                    <CustomizableIcon type="folder-solid" tint={colors.neutral400} />
                    <TextMD>{folder.title}</TextMD>
                  </FlexRow>
                  <View
                    style={{
                      gap:
                        windowSize.width < PHONE_WIDTH_BREAKPOINT
                          ? Spacing.sm + Spacing.xs
                          : Spacing.md,
                    }}
                  >
                    {vm.programsForFolder(folder.id).map(p => (
                      <ProgramSelectionCard
                        key={p.id}
                        checkbox={true}
                        program={p}
                        onSelectProgram={vm.onSelectProgram}
                        isSelected={vm.selectedProgramIds.includes(p.id)}
                      />
                    ))}
                  </View>
                </View>
              ))}
              {vm.filteredClientPrograms.length > 0 && (
                <View>
                  <FlexRow
                    style={{
                      marginBottom: Spacing.md,
                      marginTop:
                        windowSize.width < PHONE_WIDTH_BREAKPOINT ? Spacing.md : Spacing.lg,
                    }}
                  >
                    <TextMD>Client Programs</TextMD>
                  </FlexRow>
                  <View
                    style={{
                      gap:
                        windowSize.width < PHONE_WIDTH_BREAKPOINT
                          ? Spacing.sm + Spacing.xs
                          : Spacing.md,
                    }}
                  >
                    {vm.filteredClientPrograms.map(p => (
                      <ClientProgramSelectionCard
                        key={p.id}
                        program={p}
                        onSelectProgram={vm.onSelectProgram}
                        isSelected={vm.selectedProgramIds.includes(p.id)}
                      />
                    ))}
                  </View>
                </View>
              )}
            </View>
          )}
        </MiddleContainer>
        <DividerLine />
        {vm.filteredPrograms.length !== 0 && (
          <LowerContainer>
            <PrimaryButton
              title={
                vm.selectedProgramIds.length > 1
                  ? 'Copy Routine to Programs'
                  : 'Copy Routine to Program'
              }
              onClick={vm.onCopyRoutinesToProgramClick}
              enabled={vm.isCopyRoutineToProgramButtonEnabled}
              loading={vm.isCopyingPrograms}
              style={{ width: windowSize.width < PHONE_WIDTH_BREAKPOINT ? '100%' : 'auto' }}
            />
          </LowerContainer>
        )}
      </Container>
    </Modal>
  );
});
