import { CollapseChevron } from 'components/CollapseChevron';
import React from 'react';
import { HevyTooltip } from 'styleguide/HevyTooltip';
import { FlexRow } from 'styleguide/Row';
import { TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { NewChatButton } from './NewChatButton';
import { colors } from 'styleguide/colors';
import styled from 'styled-components';
import { CircleNumber } from 'styleguide/CircleNumber';
import { ChatSettingsButton } from './ChatSettingsButton';

const Header = styled(FlexRow)<{ collapsable: boolean }>`
  justify-content: space-between;
  padding: ${Spacing.sm + Spacing.xs}px;
  cursor: ${({ collapsable }) => (collapsable ? 'pointer' : 'default')};
`;

const SmallRedCircle = styled(View)`
  background-color: ${colors.error500};
  height: ${Spacing.sm}px;
  width: ${Spacing.sm}px;
  border-radius: ${CornerRadius.xs}px;
  margin-left: ${Spacing.xs}px;
`;

interface ChatsHeaderProps {
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  isChatOnline: boolean;
  onNewChatClick: () => void;
  style?: React.CSSProperties;
  unreadMessagesCount: number;
}

export const OfflineIndicator = () => {
  return (
    <HevyTooltip
      direction="top"
      text={"You're offline. New messages will be delivered when you reconnect"}
    >
      <SmallRedCircle />
    </HevyTooltip>
  );
};

export const ChatsHeader = ({
  isCollapsed,
  toggleCollapse,
  isChatOnline,
  onNewChatClick,
  style,
  unreadMessagesCount,
}: ChatsHeaderProps) => {
  return (
    <Header onClick={toggleCollapse} collapsable={!!toggleCollapse} style={style}>
      <FlexRow style={{ gap: Spacing.xs }}>
        <TextMD style={{ userSelect: 'none', WebkitUserSelect: 'none' }}>Chats</TextMD>

        {!isChatOnline && <OfflineIndicator />}
      </FlexRow>
      <FlexRow style={{ gap: Spacing.xs }}>
        {unreadMessagesCount > 0 && (
          <CircleNumber
            number={unreadMessagesCount}
            style={{ marginLeft: Spacing.xs, marginTop: 1 }}
          />
        )}

        <HevyTooltip direction="top" text="Chat Settings" menuStyle={{ minWidth: 20 }}>
          <ChatSettingsButton />
        </HevyTooltip>
        <HevyTooltip direction="top" text="New Chat" menuStyle={{ minWidth: 20 }}>
          <NewChatButton onClick={onNewChatClick} />
        </HevyTooltip>

        {!!toggleCollapse && isCollapsed !== undefined && (
          <CollapseChevron isCollapsed={isCollapsed} onClick={toggleCollapse} />
        )}
      </FlexRow>
    </Header>
  );
};
