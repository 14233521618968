'use client';
import { Program } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import API from 'utils/API';
import { modal } from '../ModalManager';
import { sendEvent } from 'utils/analyticsEvents';
import { memoryStores } from 'state/memoryStores';
import { fireAndForget } from 'utils/async';
import { captureException } from '@sentry/nextjs';
import dayjs from 'dayjs';

const MY_PROGRAMS_FOLDER_ID = Number.MAX_SAFE_INTEGER;

export class AssignProgramViewModel {
  _programs: Program[] = [];
  selectedProgram?: Program = undefined;
  clientId: string;
  onClose: () => void;
  isAssigningProgram = false;
  isCreatingProgram = false;
  programToReplace?: Program;
  searchText = '';

  isSchedulingEnabled = false;
  selectedProgramStartDate: string | null = null;
  selectedProgramDuration: number | null = null;
  scheduleError: string | undefined;

  constructor(
    programs: Program[],
    onClose: () => void,
    clientId: string,
    programToReplace?: Program,
  ) {
    makeAutoObservable(this);
    this._programs = programs;
    this.onClose = onClose;
    this.clientId = clientId;
    this.programToReplace = programToReplace;
  }

  get filteredPrograms() {
    return this._programs.filter(program => {
      return (
        !this.searchText || program.title.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  get coachFirstWeekday() {
    return localStorageStores.coachConfig.firstWeekday;
  }

  get isSearching() {
    return !!this.searchText;
  }

  get programFolders() {
    return [
      {
        title: localStorageStores.programFolders.DEFAULT_FOLDER_NAME,
        id: MY_PROGRAMS_FOLDER_ID,
        index: 0,
      },
      ...localStorageStores.programFolders.myProgramFolders.map(folder => {
        return {
          title: folder.title,
          id: folder.id ?? MY_PROGRAMS_FOLDER_ID,
          index: folder.index + 1,
        };
      }),
    ]
      .slice()
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      })

      .filter(folder => {
        return this.programsForFolder(folder.id).length > 0;
      });
  }

  onSearchChanged = (searchText: string) => {
    this.searchText = searchText;
  };

  programsForFolder = (folderId: number) => {
    return this.filteredPrograms
      .filter(program => {
        return folderId === MY_PROGRAMS_FOLDER_ID
          ? program.folder_id === null
          : program.folder_id === folderId;
      })
      .sort((p1, p2) => {
        return (p1.index ?? 0) - (p2.index ?? 0);
      });
  };

  assignProgramClicked = async () => {
    if (!this.selectedProgram) {
      return;
    }

    sendEvent('assignProgramModal_assign_clicked', {
      clientId: this.clientId,
      program: this.selectedProgram?.title,
      replacedProgram: this.programToReplace?.title,
    });
    if (this.programToReplace) {
      modal.openAlertModal({
        title: 'Overwrite the assigned program?',
        body: `Are you sure you want to remove "${this.programToReplace.title}" and replace it with "${this.selectedProgram.title}"? The current program and any assigned routines will be deleted.`,
        confirmButtonTitle: 'Replace',
        cancelButtonTitle: 'Cancel',
        confirmButtonStyle: 'destructive',
        handleAlertConfirm: async () => {
          this.assignProgram();
        },
      });
    } else {
      this.assignProgram();
    }
  };

  assignProgram = async () => {
    if (!this.selectedProgram || !this.clientId) {
      return;
    }

    const clientUser = memoryStores.clients.clientForUserId(this.clientId);
    if (!clientUser) {
      toast.error('Unable to find client.');
      return;
    }

    this.isAssigningProgram = true;
    try {
      if (this.programToReplace) {
        await API.deleteProgram(this.programToReplace.id);
      }
      await API.assignProgramToClient({
        title: this.selectedProgram.title,
        notes: this.selectedProgram.notes,
        duration_days: this.selectedProgramDuration,
        start_date: this.selectedProgramStartDate,
        client_ids: [this.clientId],
        template_program_id: this.selectedProgram.id,
      });
      sendEvent('assignProgramModal_assignProgram_complete', {
        program_id: this.selectedProgram.id,
        is_scheduling_enabled: this.isSchedulingEnabled,
        program_duration: this.isSchedulingEnabled ? `${this.selectedProgramDuration} days` : null,
        program_start_date: this.selectedProgramStartDate,
        isReplacingProgram: !!this.programToReplace,
      });
      toast.success('Program succesfully assigned.');
      await localStorageStores.programs.fetch();
    } catch (error) {
      sendEvent('assignProgramModal_assign_error', { error: JSON.stringify(error) });
      toast.error('Unable to assign program.');
    }

    this.isAssigningProgram = false;
    this.onClose();
  };

  // We decided to create new program with default name "Untitled Program"
  // Rather than prompt the user for a name
  createProgramClicked = async () => {
    sendEvent('assignProgramModal_createProgram_clicked', {
      clientId: this.clientId,
    });
    if (this.programToReplace) {
      modal.openAlertModal({
        title: 'Overwrite the assigned program?',
        body: `Are you sure you want to remove "${this.programToReplace.title}" and replace it with a new one? The current program and any assigned routines will be deleted.`,
        confirmButtonTitle: 'Replace',
        cancelButtonTitle: 'Cancel',
        confirmButtonStyle: 'destructive',
        handleAlertConfirm: async () => {
          this.createNewProgram();
        },
      });
    } else {
      this.createNewProgram();
    }
  };

  createNewProgram = async () => {
    this.isCreatingProgram = true;
    if (!!this.programToReplace) {
      await fireAndForget([API.deleteProgram(this.programToReplace.id)]);
    }
    await this.onAddProgram('Untitled Program');
    this.isCreatingProgram = false;
    this.onClose();
  };

  onAddProgram = async (programName: string) => {
    try {
      const editProgramID = (
        await API.postProgram({
          title: programName,
          notes: '',
          client_id: this.clientId,
          template_program_id: null,
          folder_id: null,
          duration_days: null,
          start_date: dayjs().format('YYYY-MM-DD'), // Default to today
        })
      ).data;
      sendEvent('assignProgram_createProgram_complete', { clientId: this.clientId });
      await localStorageStores.programs.fetch();
      router.push(`/programs/${editProgramID}?isNewProgram=true`);
      modal.isTextInputModalOpen = false;
    } catch (error) {
      captureException(error);
      toast.error('Unknown error creating program.');
    }
  };

  onSelectProgram = (id: string) => {
    const program = this._programs.find(p => p.id === id);
    if (program?.id === this.selectedProgram?.id) return;

    this.selectedProgram = program;
    this.scheduleError = undefined;

    if (this.selectedProgram) {
      this.isSchedulingEnabled = !!this.selectedProgram.duration_days;
      this.selectedProgramStartDate =
        this.selectedProgram.start_date ?? dayjs().format('YYYY-MM-DD'); // Default to today
      this.selectedProgramDuration = this.selectedProgram.duration_days;
    }
  };

  get isAssignProgramButtonEnabled() {
    return this.selectedProgram !== undefined;
  }

  onScheduleToggle = () => {
    if (!this.selectedProgram) {
      this.scheduleError = 'Please select a program first.';
      return;
    }

    sendEvent('assignProgramModal_scheduleToggle_click', {
      clientId: this.clientId,
      program: this.selectedProgram?.title,
      is_scheduling_enabled: !this.isSchedulingEnabled,
    });

    this.scheduleError = undefined;
    this.isSchedulingEnabled = !this.isSchedulingEnabled;

    if (!this.isSchedulingEnabled) {
      this.selectedProgramStartDate = null;
      this.selectedProgramDuration = null;
    } else {
      this.selectedProgramStartDate = dayjs().format('YYYY-MM-DD'); // Default to today
      this.selectedProgramDuration = this.selectedProgram?.duration_days ?? null;
    }
  };

  onChangeStartDate = (date: string | null) => {
    this.selectedProgramStartDate = date ?? dayjs().format('YYYY-MM-DD'); // Default to today
  };

  onChangeDuration = (duration: number | null) => {
    this.selectedProgramDuration = duration;
  };
}
