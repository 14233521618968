import React from 'react';
import styled from 'styled-components';
import { Modal } from 'styleguide/Modal';
import { ModalHeader } from '../Components/ModalHeader';
import { View } from 'styleguide/View';
import { localStorageStores } from 'state/localStorageStores';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { ClickableText, PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { LabelledFormInput } from 'styleguide/Inputs';
import { colors } from 'styleguide/colors';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { TeamImage } from 'components/ProfileImage';
import { EnableTeamViewModel } from './EnableTeamViewModel';
import { CropImage } from '../CropImage/CropImage';
import { DividerLine } from 'styleguide/DividerLine';
import { observer } from 'mobx-react-lite';

const Container = styled(View)`
  width: 684px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.lg}px;
`;

const Label = styled.label`
  color: ${colors.primary500};
  cursor: pointer;
`;

const CropContainer = styled(View)`
  width: 684px;
  height: 500px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: flex-end;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

export interface EnableTeamModalProps {
  vm: EnableTeamViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const EnableTeamModal = observer(({ vm, isOpen, onClose }: EnableTeamModalProps) => {
  if (localStorageStores.account.role !== 'owner') {
    return null;
  }

  if (localStorageStores.team.enabledAt) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {vm.imageUrlToCrop ? (
        <CropContainer>
          <CropImage
            imageUrl={vm.imageUrlToCrop}
            onImageCropped={vm.onUploadImage}
            onCancel={vm.onCancelImageCrop}
          />
        </CropContainer>
      ) : (
        <Container>
          <ModalHeader text="Create a Team" onClose={onClose} />
          <DividerLine />
          <MiddleContainer>
            <View style={{ alignItems: 'center', overflow: 'visible' }}>
              <Label htmlFor="file-upload">
                <ImageComponent isLoading={vm.isUploadingImage} imageUrl={vm.imageUrl} />
              </Label>
              <Label htmlFor="file-upload" style={{ marginTop: Spacing.md }}>
                <input
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    const file = e?.target.files?.[0];
                    if (file) {
                      vm.onFileSystemImageSelected(file);
                    }
                  }}
                />
                <ClickableText text={vm.imageUrl ? 'Change Logo' : 'Add logo'} />
              </Label>
            </View>

            <LabelledFormInput
              autoFocus={true}
              label="Team Name"
              placeholder={vm.titlePlaceholder}
              value={vm.title}
              onChange={e => vm.onTeamTitleChange(e.target.value)}
              errorMessage={vm.titleError}
            />
            <LabelledFormInput
              label="Owner"
              value={`${vm.coach.full_name || vm.coach.username} (you)`}
              style={{ backgroundColor: colors.neutral50 }}
              disabled={true}
            />
          </MiddleContainer>
          <DividerLine />

          <LowerContainer>
            <SecondaryButton title="Cancel" onClick={onClose} />
            <PrimaryButton
              title="Create Team"
              enabled={vm.isCreateEnabled}
              onClick={vm.onCreateTeamClick}
            />
          </LowerContainer>
        </Container>
      )}
    </Modal>
  );
});

const ImageComponent = ({ isLoading, imageUrl }: { isLoading: boolean; imageUrl?: string }) => {
  if (isLoading) {
    return <MediumHevyActivityIndicator />;
  }

  return <TeamImage source={imageUrl} diameter={128} />;
};
