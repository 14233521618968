import styled from 'styled-components';
import { Spacing } from './spacing';

export const View = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MarginView = styled(View)`
  margin: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    margin: ${Spacing.lg / 2}px;
  }
`;
