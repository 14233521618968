import { CloseButton } from 'components/CloseButton';
import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { SearchInput } from 'styleguide/Inputs';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { TextLG } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { ClientListViewModel } from './ClientListViewModel';
import { observer } from 'mobx-react-lite';
import { EmptyState } from 'components/EmptyState';
import { ClientCell } from './components/ClientCell';

const Container = styled(View)`
  border-radius: ${CornerRadius.md}px;
  width: 684px;
  min-height: 500px;
`;

const UpperContainer = styled(View)`
  padding: ${Spacing.lg}px ${Spacing.lg}px ${Spacing.md}px ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

const ClientList = styled(View)`
  flex: 1;
  overflow-y: auto;
  padding: 0px ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: 0px ${Spacing.md}px;
  }
`;

export interface ClientListModalProps {
  title: string;
  vm: ClientListViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const ClientListModal = observer(({ vm, title, isOpen, onClose }: ClientListModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <TextLG>{title}</TextLG>
            <CloseButton onClick={onClose} />
          </FlexRow>
          <SearchInput
            placeholder="Search for a client"
            value={vm.searchText}
            onChange={vm.onSearchTextChange}
          />
        </UpperContainer>
        <DividerLine />
        <ClientList>
          {vm.filteredClients.length > 0 ? (
            vm.filteredClients.map(client => (
              <ClientCell
                key={client.id}
                client={client}
                onClick={onClose}
                subtitle={vm.clientSubtitleFromClientId(client.id)}
                onMessageClick={vm.onMessageClientClick}
                isOpeningChat={vm.isOpeningChat}
              />
            ))
          ) : (
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <EmptyState
                title={`Couldn't find "${vm.searchText}"`}
                subtitle={''}
                iconType={'clients'}
              />
            </View>
          )}
        </ClientList>
      </Container>
    </Modal>
  );
});
