import { captureException } from '@sentry/nextjs';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { daysToWeeks } from 'utils/dateAndTimeUtils';

const Text = styled(TextSMRegular)<{ isPastProgram?: boolean }>`
  color: ${props => (!!props.isPastProgram ? colors.error500 : colors.neutral500)};
  white-space: nowrap;
`;

export const ProgramScheduleText = ({
  durationDays,
  startDate,
  style,
}: {
  durationDays?: number | null;
  startDate?: string | null;
  style?: React.CSSProperties;
}) => {
  if (!durationDays && !startDate) return null;

  // If the program is a template program, so it has no start date but has a duration
  if (!startDate && durationDays) {
    return <Text style={style}>{`Duration ${daysToWeeks(durationDays)} weeks`}</Text>;
  }

  // If the program has not started yet
  if (dayjs().isBefore(startDate, 'day')) {
    return (
      <Text style={style}>{`Starts on ${dayjs(startDate).format('dddd')} - ${dayjs(
        startDate,
      ).format('MMM D, YYYY')}`}</Text>
    );
  }

  // If the program has started but has no duration
  if (!durationDays) {
    return <Text style={style}>{`Started on ${dayjs(startDate).format('MMM D, YYYY')}`}</Text>;
  }

  // If the program has started and has a duration
  const weeks = dayjs().diff(startDate, 'weeks') + 1; // Add 1 to account for the current week

  if (isNaN(weeks)) {
    captureException(new Error('Invalid start date'));
    return null;
  }

  const totalWeeks = daysToWeeks(durationDays);
  const isPastProgram = weeks > totalWeeks;
  return <Text isPastProgram={isPastProgram}>{`Week ${weeks} out of ${totalWeeks}`}</Text>;
};
