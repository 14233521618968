import React from 'react';
import { ProfileImage } from 'components/ProfileImage';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { Invitee } from '../types';
import { View } from 'styleguide/View';

export const InviteeView = ({ invitee }: { invitee: Invitee }) => {
  return (
    <FlexRow style={{ gap: Spacing.sm, flexWrap: 'wrap' }}>
      {invitee.type === 'email' && (
        <>
          <SingleCircledIcon type="email" diameter={40} />
          <TextSMMedium>{invitee.email}</TextSMMedium>
        </>
      )}
      {invitee.type === 'user' && (
        <>
          <ProfileImage diameter={40} source={invitee.userData.profile_pic} />
          <View>
            <FlexRow style={{ gap: Spacing.xs, flexWrap: 'wrap' }}>
              <TextSMMedium>{invitee.userData.username}</TextSMMedium>
              {!!invitee.userData.full_name && (
                <TextSMRegular>({invitee.userData.full_name})</TextSMRegular>
              )}
            </FlexRow>
            {!!invitee.userData.email && <TextSMRegular>{invitee.userData.email}</TextSMRegular>}
          </View>
        </>
      )}
    </FlexRow>
  );
};
