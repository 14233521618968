import { Line } from 'recharts';
import { colors } from 'styleguide/colors';

export class CustomLine extends Line {
  static defaultProps = {
    ...Line.defaultProps,
    //custom props
    typetype: 'linear',
    dataKey: 'value',
    stroke: colors.primary500,
    strokeWidth: 2,
    isAnimationActive: false,
  };
}

export const defaultActiveDot = {
  stroke: colors.white,
  strokeWidth: 3,
  fill: colors.primary500,
  r: 8,
};

export const defaultDot = {
  fill: colors.primary500,
  r: 3.5,
};
