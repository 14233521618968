import { makeAutoObservable } from 'mobx';
import { BodyMeasurementWithImage } from 'screens/ClientDetail/Tabs/ProgressPictures/utils';
import { fireAndForget } from 'utils/async';
import toast from 'react-hot-toast';
import API from 'utils/API';
import { isPlanLimitErrorResponse } from 'utils/axiosErrors';

export type ProgressPictureCellSelected =
  | 'not-selected'
  | 'single-selected'
  | 'after-selected'
  | 'before-selected';

export type PictureViewOption = 'single' | 'comparison';
export type PictureType = 'before' | 'after';

export class ProgressPictureModalViewModel {
  isOpen = false;
  isLoading = false;
  clientId: string;
  isMobilePictureSelectionModalOpen = false;

  measurements: BodyMeasurementWithImage[] = [];

  pictureViewMode: PictureViewOption = 'single';
  singleSelectedMeasurementId?: number;
  beforeSelectedMeasurementId?: number;
  afterSelectedMeasurementId?: number;

  activePicture: PictureType | undefined = undefined;

  constructor(clientId: string) {
    makeAutoObservable(this);
    this.clientId = clientId;
  }

  onClose = () => {
    this.isOpen = false;
  };

  onSelectPictureViewMode = (mode: PictureViewOption) => {
    if (this.pictureViewMode === mode) return;

    if (mode === 'comparison') {
      this.activePicture = 'before';
      this.afterSelectedMeasurementId = this.singleSelectedMeasurementId;
      this.singleSelectedMeasurementId = undefined;
    } else {
      this.activePicture = undefined;
      this.singleSelectedMeasurementId = this.afterSelectedMeasurementId;
      this.afterSelectedMeasurementId = undefined;
      this.beforeSelectedMeasurementId = undefined;
    }
    this.pictureViewMode = mode;
  };

  get singleSelectedMeasurement() {
    return this.measurements.find(m => m.id === this.singleSelectedMeasurementId);
  }
  get beforeSelectedMeasurement() {
    return this.measurements.find(m => m.id === this.beforeSelectedMeasurementId);
  }
  get afterSelectedMeasurement() {
    return this.measurements.find(m => m.id === this.afterSelectedMeasurementId);
  }

  open = (selectedMeasurementId?: number, measurements?: BodyMeasurementWithImage[]) => {
    this.isLoading = true;
    if (!!measurements) {
      this.measurements = measurements;
      this.isLoading = false;
    } else {
      fireAndForget([this.fetch()]);
    }
    this.singleSelectedMeasurementId = selectedMeasurementId || this.measurements[0]?.id;
    this.pictureViewMode = 'single';
    this.activePicture = undefined;
    this.afterSelectedMeasurementId = undefined;
    this.beforeSelectedMeasurementId = undefined;
    this.isOpen = true;
  };

  fetch = async () => {
    this.isLoading = true;
    try {
      const result = await API.getClientBodyMeasurements(this.clientId);
      this.measurements = result.data
        .filter(m => !!m.picture_url)
        .sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        }) as BodyMeasurementWithImage[];
      this.singleSelectedMeasurementId =
        this.singleSelectedMeasurementId ?? this.measurements[0]?.id;
      this.isLoading = false;
    } catch (error) {
      if (!isPlanLimitErrorResponse(error)) {
        toast.error('Unable to load progress pictures, please try again.');
      }
    }
  };

  onMeasurementClicked = (measurementId: number) => {
    if (this.pictureViewMode === 'single') {
      this.singleSelectedMeasurementId = measurementId;
    } else if (this.pictureViewMode === 'comparison') {
      if (this.activePicture === 'after') {
        this.afterSelectedMeasurementId = measurementId;
      } else {
        this.beforeSelectedMeasurementId = measurementId;
      }
    }
  };

  selectionModeForMeasurementId = (measurementId: number): ProgressPictureCellSelected => {
    if (this.singleSelectedMeasurementId === measurementId) {
      return 'single-selected';
    } else if (this.beforeSelectedMeasurementId === measurementId) {
      return 'before-selected';
    } else if (this.afterSelectedMeasurementId === measurementId) {
      return 'after-selected';
    }

    return 'not-selected';
  };

  onLargePictureClicked = (pictureType: PictureType) => {
    this.activePicture = pictureType;
  };

  onCloseMobilePictureSelectionModal = () => {
    this.isMobilePictureSelectionModalOpen = false;
  };

  onChangePictureClick = (pictureType?: PictureType) => {
    this.activePicture = pictureType;
    this.isMobilePictureSelectionModalOpen = true;
  };
}
