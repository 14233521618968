import API from './API';
import { dataURLtoFile } from './imageUtils';

export const uploadImage = async (fileName: string, imageUrl: string): Promise<{ url: string }> => {
  const file = dataURLtoFile(imageUrl, fileName);

  const result = await API.getPresignedUrl(file.name);
  const presignedUrl = result.data.presigned_url;
  await API.uploadFileWithPresignedUrl({ presignedUrl: result.data.presigned_url, file });

  const url = presignedUrl.split('?')[0];

  return { url };
};

export const uploadVideo = async (fileName: string, videoUrl: string): Promise<{ url: string }> => {
  const file = dataURLtoFile(videoUrl, fileName);

  const result = await API.getPresignedExerciseVideoUrl();
  const presignedUrl = result.data.presigned_url;
  await API.uploadFileWithPresignedUrl({ presignedUrl: result.data.presigned_url, file });

  const url = presignedUrl.split('?')[0];

  return { url };
};

export const uploadChatFile = async (
  fileName: string,
  fileUrl: string,
  contentType: string,
  fileExtension: string,
): Promise<{ url: string }> => {
  const file = dataURLtoFile(fileUrl, fileName);

  const result = await API.getPresignedChatFileUrl({
    content_type: contentType,
    file_extension: fileExtension.toLowerCase(),
  });
  const presignedUrl = result.data.presigned_url;
  await API.uploadFileWithPresignedUrl({ presignedUrl: result.data.presigned_url, file });

  const url = presignedUrl.split('?')[0];

  return { url };
};
