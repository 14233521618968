import React, { DragEvent } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextMD, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { CardNoPadding } from 'styleguide/Card';
import { IconFormInput } from 'styleguide/Inputs';
import { DividerLine } from 'styleguide/DividerLine';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { VideoUploadCellViewModel } from './VideoUploadCellViewModel';
import { observer } from 'mobx-react-lite';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const Container = styled(View)`
  padding: ${Spacing.md}px;
  margin-bottom: ${Spacing.md}px;
  align-items: center;
`;

const Label = styled.label`
  color: ${colors.primary500};
  cursor: pointer;
`;

const ButtonsContainer = styled(View)`
  margin-bottom: ${Spacing.sm}px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
  display: flex;
`;

const LoaderContainer = styled(View)`
  justify-content: center;
  align-items: center;
  padding-top: ${Spacing.md}px;
  padding-bottom: ${Spacing.xs}px;
`;

interface VideoUploadCellProps {
  isUploadingVideo: boolean;
  onAddVideoAttachment: (attachment: VideoAttachment) => void;
  vm: VideoUploadCellViewModel;
}

export const VideoUploadCell = observer(({ isUploadingVideo, vm }: VideoUploadCellProps) => {
  const [isDragActive, setIsDragActive] = React.useState(false);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true);
    } else {
      setIsDragActive(false);
    }
  };

  // Not sure why "e" is a "DragEvent", but that's how this callback is defined
  const handleDrop = (e: DragEvent) => {
    setIsDragActive(false);
    e.preventDefault();
    e.stopPropagation();
    vm.youtubeVideoURL = '';
    const file = e.dataTransfer?.files?.[0];
    if (file) {
      vm.onFileDropped(file);
    }
  };

  return (
    <form
      onDragEnter={handleDrag}
      onDragExit={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDragEnd={handleDrag}
      onDrop={handleDrop}
      onSubmit={e => {
        // We're using a form here to capture files, but we don't want buttons inside the form to submit it,
        // we'll handle submission via JavaScript
        e.preventDefault();
      }}
    >
      <CardNoPadding
        style={{ backgroundColor: isDragActive ? colors.neutral100 : colors.neutral50 }}
      >
        <Container>
          {isUploadingVideo ? (
            <>
              <LoaderContainer>
                <MediumHevyActivityIndicator />
              </LoaderContainer>
            </>
          ) : (
            <>
              <Label
                htmlFor="video-upload"
                style={{ cursor: isUploadingVideo ? 'default' : 'pointer' }}
              >
                <DoubleCircledIcon type="plus" />
              </Label>
              <View style={{ marginLeft: Spacing.md }}>
                <ButtonsContainer>
                  <Label htmlFor="video-upload" style={{ flex: 1 }}>
                    <input
                      style={{ display: 'none' }}
                      id="video-upload"
                      type="file"
                      accept="video/mp4,video/quicktime"
                      onChange={e => {
                        const file = e.target?.files?.[0];
                        if (file) {
                          vm.onSelectedFile?.(file);
                        }
                      }}
                    />
                    <TextMD>Drop a video attachment here</TextMD>
                  </Label>
                </ButtonsContainer>
                <TextSMRegular>
                  You can upload MP4 videos with a maximum size of 100 MB.
                </TextSMRegular>
                {vm.customVideoErrorMessage && (
                  <TextSMMedium
                    style={{
                      color: colors.error500,
                      paddingBottom: Spacing.md,
                      paddingLeft: Spacing.md,
                      paddingTop: Spacing.xs,
                      textAlign: 'center',
                    }}
                  >
                    {vm.customVideoErrorMessage}
                  </TextSMMedium>
                )}
              </View>
            </>
          )}
        </Container>

        {!isUploadingVideo && (
          <>
            <DividerLine></DividerLine>
            <View style={{ alignItems: 'center' }}>
              <TextSMRegular
                style={{
                  position: 'relative',
                  bottom: 20,
                  padding: 10,
                  backgroundColor: isDragActive ? colors.neutral100 : colors.neutral50,
                }}
              >
                or
              </TextSMRegular>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <IconFormInput
                iconType="link"
                iconTint={colors.neutral400}
                placeholder="Paste a YouTube video link"
                value={vm.youtubeVideoURL}
                onChange={vm.onYouTubeLinkChange}
                focusedBorderColor={vm.hasYouTubeError ? colors.error500 : undefined}
                disabled={isUploadingVideo}
                style={{
                  backgroundColor: colors.white,
                  margin: Spacing.md,
                  marginBottom: Spacing.lg,
                  marginTop: 0,
                  flex: 1,
                }}
              />
            </View>
            {vm.hasYouTubeError && (
              <TextSMMedium
                style={{
                  color: colors.error500,
                  paddingBottom: Spacing.md,
                  paddingLeft: Spacing.md,
                }}
              >
                Invalid YouTube link
              </TextSMMedium>
            )}
          </>
        )}
      </CardNoPadding>
    </form>
  );
});
