import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { OnboardingNav } from './components/OnboardingNav';
import { PlanWarningsView } from 'components/PlanWarnings/PlanWarningsView';
import { PlanWarningsViewModel } from 'components/PlanWarnings/PlanWarningsViewModel';
import { DesktopVerticalNavBar, MobileHorizontalNavBar } from './NavBars';
import { ImpersonationWarningBanner } from './components/ImpersonationWarning';
import { ChatsWidget } from 'components/Chat/ChatsWidget';
import { GlobalDragAndDropManager } from '../../utils/globals/GlobalDragAndDropManager';
import { zIndexes } from 'styleguide/zIndex';
import { TextMD } from 'styleguide/Texts';
import { UnauthenticatedNav } from './components/UnauthenticatedNav';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';
import { ReachabilityWarningBanner } from './components/ReachabilityWarningBanner';
import { Spacing } from 'styleguide/spacing';

interface AppNavigationWrapperProps {
  isAuthenticated: boolean;
  children: JSX.Element | JSX.Element[];
}

const AuthenticatedContainer = styled(View)`
  flex: 1;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const NavBarPageContainer = styled(View)`
  width: 100%;
  height: 100vh;
  height: 100svh;

  @media (max-width: 1024px) {
    // Account for the navigation bar taking space at the top
    height: calc(100vh - 64px);
    height: calc(100svh - 64px);
  }
`;

const NoNavPageContainer = styled(View)`
  width: 100%;
  height: 100vh;
  height: 100svh;
`;

const ScreenContainer = styled(View)`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  // Add some padding so the chat widget doesn't intefere with screen content
  padding-bottom: ${Spacing.lg}px;
  // our content should never overflow horizontally
  // and we don't want to show a scrollbar for that
  // It also fixes issue with the tooltip insivisibly going off screen
  // that is beacuse of how we position the tooltip (relative)
  overflow-x: hidden;
  background-color: ${colors.neutral50};
`;

const DropOverlayContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0 px;
  left: 0 px;
  right: 0 px;
  bottom: 0 px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: ${zIndexes.dragAndDropOverlay};
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const DropView = () => {
  return (
    <DropOverlayContainer>
      <View style={{ alignItems: 'center' }}>
        <TextMD>Drop Files Here</TextMD>
      </View>
    </DropOverlayContainer>
  );
};

export const AppNavigationWrapper = observer(
  ({ isAuthenticated, children }: AppNavigationWrapperProps) => {
    const router = useRouter();
    const appState = getAppState(isAuthenticated, router.route);

    // Because the _document doesn't scroll, route changes can result
    // in the user being confusingly left in the middle of a new page
    // when they probably expect to be at the top.
    // Here we programatically scroll our container to the top
    // when the route changes
    const screenContainer = useRef<HTMLDivElement | null>(null);

    const scrollToTop = () => {
      screenContainer.current?.scrollTo(0, 0);
    };

    useEffect(() => {
      router.events.on('routeChangeComplete', scrollToTop);

      return () => {
        router.events.off('routeChangeComplete', scrollToTop);
      };
    }, []);

    switch (appState) {
      case 'authenticated':
        return (
          <AuthenticatedContainer
            onDragOver={GlobalDragAndDropManager.handleDragOver}
            onDragLeave={GlobalDragAndDropManager.handleDragLeave}
            onDrop={GlobalDragAndDropManager.handleDrop}
          >
            {GlobalDragAndDropManager.isDragging && <DropView />}
            <DesktopVerticalNavBar pathname={router.pathname} />
            <MobileHorizontalNavBar />
            <NavBarPageContainer>
              <ReachabilityWarningBanner />
              <PlanWarningsView vm={new PlanWarningsViewModel()} />
              <ImpersonationWarningBanner />

              <ScreenContainer
                ref={screenContainer}
                // Padding is used to avoid the chat widget blocking any content behind it
                // We can hide the padding on the chat screen because the chat widget will be hidden
                style={router.pathname.startsWith('/chat') ? { paddingBottom: 0 } : undefined}
              >
                {children}
              </ScreenContainer>
            </NavBarPageContainer>

            {!router.pathname.startsWith('/chat') && <ChatsWidget />}
          </AuthenticatedContainer>
        );
      case 'checkout':
        return <View style={{ height: '100vh' }}>{children}</View>;
      case 'onboarding':
        return (
          <BootstrapLoadingWrapper>
            <OnboardingNav
              screenName={router.query.screenName as string}
              includeUsernameStep={(router.query.includeUsernameStep as string) === 'true'}
            />
            {children}
          </BootstrapLoadingWrapper>
        );
      case 'unauthenticated':
        return (
          <>
            <UnauthenticatedNav />
            {children}
          </>
        );
      case 'coach-signup':
        return (
          <NoNavPageContainer>
            <ScreenContainer ref={screenContainer}>{children}</ScreenContainer>
          </NoNavPageContainer>
        );
      default:
        return <></>;
    }
  },
);

type AppState = 'unauthenticated' | 'authenticated' | 'onboarding' | 'checkout' | 'coach-signup';

const getAppState = (isAuthenticated: boolean, route: string): AppState => {
  const isOnboarding =
    route === '/onboarding/[screenName]' || route === '/coach/onboarding/[screenName]';

  if (isOnboarding) {
    return 'onboarding';
  }

  const isCheckout = route === '/checkout';
  if (isCheckout) {
    return 'checkout';
  }

  if (isAuthenticated) {
    return 'authenticated';
  }

  if (route.includes('/join')) {
    return 'coach-signup';
  }

  return 'unauthenticated';
};
