import React from 'react';
import { Spacing } from 'styleguide/spacing';
import { LineGraphProps, LineGraph } from 'screens/ClientDetail/Tabs/Exercise/components/LineGraph';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
`;

const ChartsContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: ${Spacing.lg}px;
`;

export const ClientStatisticsView = ({
  chartData,
  isLoading,
}: {
  chartData: LineGraphProps[];
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <FlexRow style={{ flex: 1, justifyContent: 'center' }}>
        <MediumHevyActivityIndicator />
      </FlexRow>
    );
  }
  return (
    <ChartsContainer>
      {chartData.map(c => (
        <ChartContainer key={c.graphTitle}>
          <LineGraph {...c} />
        </ChartContainer>
      ))}
    </ChartsContainer>
  );
};
