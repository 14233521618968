import { CoachLinkButton } from 'components/CoachLink';
import { ProfileImage } from 'components/ProfileImage';
import router from 'next/router';
import React from 'react';
import { localStorageStores } from 'state/localStorageStores';
import styled from 'styled-components';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { SecondaryButton, PrimaryButton } from 'styleguide/Buttons';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { Client } from 'types/client';
import { useWindowSize } from 'utils/useSize';

const ClientCellContainer = styled(FlexRow)`
  padding: ${Spacing.md}px 0px;
  gap: ${Spacing.sm}px;
  justify-content: space-between;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ClientCell = ({
  client,
  onClick,
  subtitle,
  onMessageClick,
  isOpeningChat,
}: {
  client: Client;
  onClick: () => void;
  subtitle: string;
  onMessageClick: (client: Client, windowSize: number, onClose: () => void) => Promise<void>;
  isOpeningChat: boolean;
}) => {
  const { width } = useWindowSize();
  const clientsCoach = localStorageStores.team.teamMemberWithId(client.coachId);

  return (
    <ClientCellContainer>
      <FlexRow
        style={{ gap: Spacing.sm, flex: 1, cursor: 'pointer' }}
        onClick={() => {
          onClick();
          router.push(`/clients/${client.id}`);
        }}
      >
        <ProfileImage diameter={48} source={client.profilePic} />
        <View>
          <div style={{ maxWidth: 250 }}>
            <TextSMMedium style={{ display: 'inline' }}>
              {client.username}
              {` `}
            </TextSMMedium>
            {client.fullName && (
              <TextSMRegular style={{ display: 'inline' }}>{`(${client.fullName})`}</TextSMRegular>
            )}
          </div>
          <TextSMRegular>{subtitle}</TextSMRegular>
        </View>
      </FlexRow>

      <FlexRow style={{ gap: Spacing.md, flex: 1, justifyContent: 'flex-end', width: '100%' }}>
        {client.coachId === localStorageStores.account.id ? (
          <>
            {client.configuration.is_chat_enabled && (
              <SecondaryButton
                iconType="message"
                title="Send Message"
                loading={isOpeningChat}
                onClick={() => onMessageClick(client, width, onClick)}
                style={{ whiteSpace: 'nowrap', width: '100%' }}
              />
            )}
          </>
        ) : (
          <>
            {!!clientsCoach && (
              <CoachLinkButton
                style={{ whiteSpace: 'nowrap', width: '100%' }}
                onClick={onClick}
                coach={clientsCoach}
              />
            )}
          </>
        )}

        <PrimaryButton
          title="See Profile"
          onClick={() => {
            onClick();
            router.push(`/clients/${client.id}`);
          }}
          style={{
            whiteSpace: 'nowrap',
            width: width < PHONE_WIDTH_BREAKPOINT ? '100%' : 'auto',
          }}
        />
      </FlexRow>
    </ClientCellContainer>
  );
};
