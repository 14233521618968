import { HevyCoachLogo } from 'components/Logo/HevyCoachLogo';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { FlexRow } from 'styleguide/Row';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { sendEvent } from 'utils/analyticsEvents';
import { scrollToAnchor } from 'utils/scrollToAnchor';

const Container = styled.nav`
  display: flex;
  align-items: center;
  height: 61px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.neutral150};
`;

const OnboardingNavContainer = styled(FlexRow)`
  flex: 1;
  justify-content: flex-start;
  margin-left: ${Spacing.md}px;
`;

export const UnauthenticatedNav = () => {
  const router = useRouter();
  const showLearnMoreButton = router.asPath.includes('signup-ad');
  return (
    <Container>
      <OnboardingNavContainer style={{ justifyContent: 'space-between' }}>
        <HevyCoachLogo fill={colors.black} />
        {showLearnMoreButton && (
          <PrimaryButton
            title="Learn More"
            style={{ marginRight: Spacing.md }}
            onClick={() => {
              sendEvent('signup-ad-learnMore-clicked');
              // Specifically for the signup-ad page (rendered in SignupScreen.tsx)
              scrollToAnchor('learn-more', 'start');
            }}
          />
        )}
      </OnboardingNavContainer>
    </Container>
  );
};
