import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius } from 'styleguide/spacing';

const DownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${colors.neutral100};
  align-self: center;
`;

export const CustomTooltipContainer = ({
  isMouseInsideContainer,
  payload,
  label,
  width,
  style,
  children,
  downTriangle,
}: {
  isMouseInsideContainer: boolean;
  payload?: any;
  label?: string;
  width: number;
  style?: React.CSSProperties;
  children: React.ReactElement;
  downTriangle?: boolean;
}) => {
  // Okay, so Recharts clears the data when the mouse goes outside the chart, but we want
  // to keep rendering the tooltip till the mouse goes outside the CONTAINER. We keep track
  // of the last value here so we can render until isMouseInsideContainer goes false.
  const [lastPayload, setLastPayload] = useState<any>({});

  const tooltipRef = React.useRef<any>(null);

  useEffect(() => {
    // clear the last payload when the mouse leaves the container
    if (!isMouseInsideContainer) {
      setLastPayload(null);
    } else if (payload && payload.length > 0) {
      setLastPayload(payload);
    }
  }, [payload, isMouseInsideContainer]);

  const boundingBox = tooltipRef.current?.getBoundingClientRect();
  const offsetRight = window.innerWidth - boundingBox?.right - 20 < 0 ? 50 : undefined;
  const offsetLeft = boundingBox?.left - 20 < 0 ? 50 : undefined;

  if (lastPayload && lastPayload.length && isMouseInsideContainer) {
    return (
      <View
        ref={tooltipRef}
        onClick={event => {
          // Ensure clicking here doesn't select another point on the chart
          event.stopPropagation();
        }}
      >
        <View
          style={{
            backgroundColor: colors.neutral100,
            borderRadius: CornerRadius.md,
            minWidth: width,
            position: 'relative',
            right: offsetRight,
            left: offsetLeft,
            ...style,
          }}
        >
          {React.cloneElement(children, { payload: lastPayload[0].payload, label })}
        </View>
        {downTriangle && <DownTriangle />}
      </View>
    );
  }

  return null;
};

export const defaultTooltipProps = {
  cursor: false,
  trigger: 'hover' as 'hover' | 'click' | undefined,
  allowEscapeViewBox: { x: true, y: true },
};
