import React, { CSSProperties, ReactElement } from 'react';
import { makeAutoObservable } from 'mobx';
import { OkModal, OkModalProps } from './OkModal';
import { observer } from 'mobx-react-lite';
import { AssignProgramModal, AssignProgramModalProps } from './AssignProgram/AssignProgramModal';
import {
  ExerciseTemplate,
  Program,
  CoachsShallowLibraryRoutine,
  BaseRoutine,
  CoachRole,
  CoachAccount,
} from 'hevy-shared';
import { WorkoutDetailModal, WorkoutDetailModalProps } from './WorkoutDetail/WorkoutDetailModal';
import { AssignProgramViewModel } from './AssignProgram/AssignProgramViewModel';
import { SelectRoutineModal, SelectRoutineModalProps } from './Routine/SelectRoutineModal';
import { SelectRoutineViewModel } from './Routine/SelectRoutineViewModel';
import { AlertModal, AlertModalProps } from './AlertModal';
import {
  AssignProgramToMultipleClientsModal,
  AssignProgramToMultipleClientsModalProps,
} from './AssignProgramToMultipleClients/AssignProgramToMultipleClientsModal';
import { AssignProgramToMultipleClientsViewModel } from './AssignProgramToMultipleClients/AssignProgramToMultipleClientsViewModel';
import { TextInputModal, TextInputModalProps } from './TextInput/TextInputModal';
import { TextInputViewModel } from './TextInput/TextInputViewModel';
import { FeedbackModal, FeedbackModalProps } from './FeedbackModal';
import { MobileNavBarModal, MobileNavBarProps } from './MobileNavBarModal';
import { EditExerciseViewModel } from './EditExercise/EditExerciseViewModel';
import { EditExerciseModal, EditExerciseModalProps } from './EditExercise/EditExerciseModal';
import { UpgradePlanModal, UpgradePlanModalProps } from './Plan/UpgradePlanModal';
import { UpgradePlanViewModel } from './Plan/UpgradePlanViewModel';
import { ExerciseLibraryViewModel } from 'components/ExerciseLibrary/ExerciseLibraryViewModel';
import { ExerciseLibraryModal, ExerciseLibraryModalProps } from './ExerciseLibraryModal';
import {
  ExerciseDetailModal,
  ExerciseDetailModalProps,
} from './ExerciseDetail/ExerciseDetailModal';
import { ExerciseDetailViewModel } from './ExerciseDetail/ExerciseDetailViewModel';
import { WhatsNewModal, WhatsNewModalProps } from './WhatsNewModal';
import { localStorageStores } from 'state/localStorageStores';
import { VideoModal, VideoModalProps } from './VideoModal';
import { VideoUploadCellViewModel } from './EditExercise/components/VideoUploadCell/VideoUploadCellViewModel';
import { WorkoutDetailViewModel } from './WorkoutDetail/WorkoutDetailViewModel';
import { CommentModal, CommentModalProps } from './Comment/CommentModal';
import { CommentModalViewModel } from './Comment/CommentModalViewModel';
import { FreeTrialEndModal, FreeTrialEndModalProps } from './FreeTrialEndModal';
import {
  MoveProgramToFolderModal,
  MoveProgramToFolderProps,
} from './MoveProgramToFolder/MoveProgramToFolderModal';
import { MoveProgramToFolderViewModel } from './MoveProgramToFolder/MoveProgramToFolderViewModel';
import { ClientListModal, ClientListModalProps } from './ClientList/ClientListModal';
import { Client } from 'types/client';
import { ClientListViewModel } from './ClientList/ClientListViewModel';
import { GenericModal, GenericModalProps } from './GenericModal';
import { ClientInviteViewModel } from './Invite/ClientInvite/ClientInviteViewModel';
import { ProgramDetailModal, ProgramDetailModalProps } from './ProgramDetailModal';
import { CopyRoutineViewModel } from './CopyRoutine/CopyRoutineViewModel';
import { CopyRoutineModal, CopyRoutineModalProps } from './CopyRoutine/CopyRoutineModal';
import { BackendReachability } from 'state/backendReachability';
import { MaintenanceModeModal } from './MaintenanceModeModal';
import {
  TrialStartedModal,
  TrialStartedModalProps,
} from './FreePlanNotAvailable/TrialStartedModal';
import { ViewRoutineExercise } from 'screens/Routines/types';
import {
  RoutineSummaryModal,
  RoutineSummaryModalProps,
} from './RoutineSummary/RoutineSummaryModal';
import { RoutineSummaryViewModel } from './RoutineSummary/RoutineSummaryModalViewModel';
import {
  ImportFromHevyModal,
  ImportFromHevyModalProps,
} from './ImportFromHevy/ImportFromHevyModal';
import { ImportFromHevyViewModel } from './ImportFromHevy/ImportFromHevyViewModel';
import { ExerciseTemplateType } from 'types/exerciseTemplateType';
import { ProgramScheduleModal, ProgramScheduleModalProps } from './ProgramScheduleModal';
import { CoachRoleModal, CoachRoleModalProps } from './CoachRoleModal';
import {
  ReassignClientModal,
  ReassignClientModalProps,
} from './ClientTransfer/ReassignClientModal';
import { TransferClientsToCoachModal } from './ClientTransfer/TransferClientsToCoachModal';
import { TransferClientsFromCoachModal } from './ClientTransfer/TransferClientsFromCoachModal';
import { ClientInviteModal, ClientInviteModalProps } from './Invite/ClientInvite/ClientInviteModal';
import { CoachInviteModal, CoachInviteModalProps } from './Invite/CoachInvite/CoachInviteModal';
import { CoachInviteViewModel } from './Invite/CoachInvite/CoachInviteViewModel';
import { EnableTeamModal, EnableTeamModalProps } from './EnableTeam/EnableTeamModal';
import { EnableTeamViewModel } from './EnableTeam/EnableTeamViewModel';
import { ProgressPictureModal } from 'components/Modals/ProgressPictures/ProgressPictureModal';
import { ProgressPictureModalViewModel } from 'components/Modals/ProgressPictures/ProgressPictureModalViewModel';
import { PictureSelectionModal } from './ProgressPictures/components/PictureSelectionModal';

class ModalManagerState {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * UpgradePlan Modal
   */

  openUpgradePlanModal = ({
    onClose,
    isDissmisable = true,
    title = 'Upgrade your plan to invite more clients',
    source,
  }: {
    onClose?: (didUpgradePlan: boolean) => void;
    isDissmisable?: boolean;
    title?: string;
    source: string;
  }) => {
    this.onUpgradePlanClosed = onClose;
    this.isUpgradePlanModalOpen = true;
    this.isUpgradePlanModalDismissable = isDissmisable;
    this.upgradePlanModalTitle = title;
    this.upgradePlanModalSource = source;
    this.upgradePlanViewModel = new UpgradePlanViewModel({
      onPlanSelected: this.dismissUpgradePlanModal,
      onStartedCheckout: () => {
        this.isUpgradePlanModalOpen = false;
      },
      source: `upgradeViewModel_${this.upgradePlanModalSource}`,
    });
    this.upgradePlanViewModel.fetchInitial();
  };

  private dismissUpgradePlanModal = (didUpgradePlan: boolean) => {
    this.onUpgradePlanClosed?.(didUpgradePlan);
    this.isUpgradePlanModalOpen = false;
    this.onUpgradePlanClosed = undefined;
  };

  isUpgradePlanModalDismissable = true;
  isUpgradePlanModalOpen = false;
  onUpgradePlanClosed?: (didUpgradePlan: boolean) => void;
  upgradePlanModalTitle = '';
  upgradePlanModalSource: string = '';
  upgradePlanViewModel?: UpgradePlanViewModel;

  get upgradePlanModalProps(): UpgradePlanModalProps | undefined {
    if (!this.upgradePlanViewModel) return;
    return {
      isOpen: this.isUpgradePlanModalOpen,
      onClose: () => {
        this.dismissUpgradePlanModal(false);
      },
      vm: this.upgradePlanViewModel,
      isDismissable: this.isUpgradePlanModalDismissable,
      title: this.upgradePlanModalTitle,
    };
  }

  /**
   * OkModal
   */
  isOkModalOpen = false;
  okTitle = '';
  okSubtitle = '';
  okSubmitTitle = '';
  get okProps(): OkModalProps {
    return {
      title: this.okTitle,
      subtitle: this.okSubtitle,
      submitButtonTitle: this.okSubmitTitle,
      isOpen: this.isOkModalOpen,
      onClose: this.dismissOkModal,
    };
  }

  openOkModal = (props: Omit<OkModalProps, 'isOpen' | 'onClose'>) => {
    this.okTitle = props.title;
    this.okSubtitle = props.subtitle;
    this.okSubmitTitle = props.submitButtonTitle;
    this.isOkModalOpen = true;
  };

  private dismissOkModal = () => {
    this.isOkModalOpen = false;
  };

  /**
   * MobileNavBarModal
   */
  isMobileNavBarModalOpen = false;
  get mobileNavBarModalProps(): MobileNavBarProps {
    return {
      isOpen: this.isMobileNavBarModalOpen,
      onClose: this.dismissMobileNavBarModal,
    };
  }

  openMobileNavBarModal = () => {
    this.isMobileNavBarModalOpen = true;
  };

  private dismissMobileNavBarModal = () => {
    this.isMobileNavBarModalOpen = false;
  };

  /**
   * WhatsNewModal
   */
  isWhatsNewModalOpen = false;
  get whatsNewModalProps(): WhatsNewModalProps {
    return {
      isOpen: this.isWhatsNewModalOpen,
      onClose: this.dismissWhatsNewModal,
    };
  }

  openWhatsNewModal = () => {
    this.isWhatsNewModalOpen = true;
    localStorageStores.whatsNew.handledUserOpenedWhatsNew();
  };

  private dismissWhatsNewModal = () => {
    this.isWhatsNewModalOpen = false;
  };

  /**
   * FreeTrialEndModal
   */
  isFreeTrialEndModalOpen = false;
  freeTrialModalUpgradeVm = new UpgradePlanViewModel({
    source: 'freeTrialEndedModal',
    onPlanSelected: () => {
      this.dismissFreeTrialEndModal();
    },
    onStartedCheckout: () => {
      this.dismissFreeTrialEndModal();
    },
  });

  get freeTrialEndModalProps(): FreeTrialEndModalProps {
    return {
      isOpen: this.isFreeTrialEndModalOpen,
      onClose: this.dismissFreeTrialEndModal,
      upgradeVM: this.freeTrialModalUpgradeVm,
    };
  }

  openFreeTrialEndModal = () => {
    this.freeTrialModalUpgradeVm.fetchInitial();
    this.isFreeTrialEndModalOpen = true;
  };

  private dismissFreeTrialEndModal = () => {
    this.isFreeTrialEndModalOpen = false;
  };

  /**
   * FeedbackModal
   */
  isFeedbackModalOpen = false;
  get feedbackModalProps(): FeedbackModalProps {
    return {
      isOpen: this.isFeedbackModalOpen,
      onClose: this.dismissFeedbackModal,
    };
  }

  openFeedbackModal = () => {
    this.isFeedbackModalOpen = true;
  };

  private dismissFeedbackModal = () => {
    this.isFeedbackModalOpen = false;
  };

  /**
   * VideoModal
   */
  isVideoModalOpen = false;
  videoUrl = '';
  videoModalTitle = '';
  get videoModalProps(): VideoModalProps {
    return {
      isOpen: this.isVideoModalOpen,
      onClose: this.dismissVideoModal,
      title: this.videoModalTitle,
      videoURL: this.videoUrl,
    };
  }

  openVideoModal = (videoUrl: string, title: string) => {
    this.videoUrl = videoUrl;
    this.videoModalTitle = title;
    this.isVideoModalOpen = true;
  };

  private dismissVideoModal = () => {
    this.isVideoModalOpen = false;
    this.videoUrl = '';
    this.videoModalTitle = '';
  };

  /**
   * GenericModal
   */
  isGenericModalOpen = false;
  renderGenericModalContent?: () => React.ReactNode = undefined;
  get genericModalProps(): GenericModalProps | undefined {
    if (!this.renderGenericModalContent) return;
    return {
      isOpen: this.isGenericModalOpen,
      onClose: this.dismissGenericModal,
      renderContent: this.renderGenericModalContent,
    };
  }

  openGenericModal = (renderContent: () => React.ReactNode) => {
    this.renderGenericModalContent = renderContent;
    this.isGenericModalOpen = true;
  };

  private dismissGenericModal = () => {
    this.isGenericModalOpen = false;
    this.renderGenericModalContent = undefined;
  };

  /**
   * TextInputModal
   */
  isTextInputModalOpen = false;
  textInputModalTitle = '';
  textInputModalBody?: string = '';
  textInputSubmitButtonTitle = '';
  textInputCancelButtonTitle?: string = undefined;
  textInputPlaceholder = '';
  textInputLabel? = '';
  moreInfoText?: string = undefined;
  textInputDefaultText? = '';
  textInputHandleSubmit?: (text: string) => Promise<string | undefined> = undefined;
  textInputButtonStyle?: CSSProperties;
  get textInputModalProps(): TextInputModalProps {
    return {
      title: this.textInputModalTitle,
      body: this.textInputModalBody,
      submitButtonTitle: this.textInputSubmitButtonTitle,
      cancelButtonTitle: this.textInputCancelButtonTitle,
      placeholder: this.textInputPlaceholder,
      moreInfoText: this.moreInfoText,
      inputLabel: this.textInputLabel,
      vm: new TextInputViewModel(this.textInputDefaultText),
      isOpen: this.isTextInputModalOpen,
      onClose: this.dismissTextInputModal,
      handleSubmit: this.textInputHandleSubmit,
      buttonStyle: this.textInputButtonStyle,
    };
  }

  openTextInputModal = (props: Omit<TextInputModalProps, 'isOpen' | 'onClose' | 'vm'>) => {
    this.textInputModalTitle = props.title;
    this.textInputModalBody = props.body;
    this.textInputSubmitButtonTitle = props.submitButtonTitle;
    this.textInputCancelButtonTitle = props.cancelButtonTitle;
    this.isTextInputModalOpen = true;
    this.textInputPlaceholder = props.placeholder;
    this.textInputLabel = props.inputLabel;
    this.moreInfoText = props.moreInfoText;
    this.textInputDefaultText = props.defaultText;
    this.textInputHandleSubmit = props.handleSubmit;
    this.textInputButtonStyle = props.buttonStyle;
  };

  private dismissTextInputModal = () => {
    this.isTextInputModalOpen = false;
  };

  /**
   * ClientInviteModal
   */
  isClientInviteModalOpen = false;
  get clientInviteModalProps(): ClientInviteModalProps {
    return {
      vm: new ClientInviteViewModel(),
      isOpen: this.isClientInviteModalOpen,
      onClose: this.dismissClientInviteModal,
    };
  }

  openClientInviteModal = () => {
    this.isClientInviteModalOpen = true;
  };

  private dismissClientInviteModal = () => {
    this.isClientInviteModalOpen = false;
  };

  /**
   * CoachInviteModal
   */
  isCoachInviteModalOpen = false;
  get coachInviteModalProps(): CoachInviteModalProps {
    return {
      vm: new CoachInviteViewModel(),
      isOpen: this.isCoachInviteModalOpen,
      onClose: this.dismissCoachInviteModal,
    };
  }

  openCoachInviteModal = () => {
    this.isCoachInviteModalOpen = true;
  };

  private dismissCoachInviteModal = () => {
    this.isCoachInviteModalOpen = false;
  };

  /**
   * Assign program to multiple clients modal
   */
  isAssignToMultipleClientsModalOpen = false;
  assignToMultipleClientsProgram?: Program = undefined;
  assignProgramToMultipleClientsModalSource: string = '';

  openAssignProgramToMultipleClientsModal = (program: Program, source: string) => {
    this.assignToMultipleClientsProgram = program;
    this.isAssignToMultipleClientsModalOpen = true;
    this.assignProgramToMultipleClientsModalSource = source;
  };

  private dismissAssignProgramToMultipleClientsModal = () => {
    this.assignToMultipleClientsProgram = undefined;
    this.isAssignToMultipleClientsModalOpen = false;
  };

  get assignProgramToMultipleClientsModalProps():
    | AssignProgramToMultipleClientsModalProps
    | undefined {
    if (!this.assignToMultipleClientsProgram) return;

    return {
      vm: new AssignProgramToMultipleClientsViewModel(
        this.dismissAssignProgramToMultipleClientsModal,
        this.assignToMultipleClientsProgram,
        this.assignProgramToMultipleClientsModalSource,
      ),
      isOpen: this.isAssignToMultipleClientsModalOpen,
      onClose: this.dismissAssignProgramToMultipleClientsModal,
    };
  }

  /**
   * AlertModal
   */
  isAlertModalOpen = false;
  alertModalTitle = '';
  alertConfirmButtonStyle?: 'primary' | 'destructive';
  alertModalBody: string | undefined = undefined;
  alertBodyRenderComponent: (() => ReactElement) | undefined = undefined;
  alertConfirmButtonTitle = '';
  alertCancelButtonTitle?: string;
  alertLinkButtonTitle?: string;
  alertLinkButtonHref?: string;
  handleAlertConfirm?: () => Promise<{ error: string } | void>;
  get alertProps(): AlertModalProps {
    return {
      title: this.alertModalTitle,
      renderComponent: this.alertBodyRenderComponent,
      body: this.alertModalBody,
      confirmButtonTitle: this.alertConfirmButtonTitle,
      cancelButtonTitle: this.alertCancelButtonTitle,
      linkButtonTitle: this.alertLinkButtonTitle,
      linkButtonHref: this.alertLinkButtonHref,
      isOpen: this.isAlertModalOpen,
      closeModal: this.dismissAlertModal,
      handleAlertConfirm: this.handleAlertConfirm,
      confirmButtonStyle: this.alertConfirmButtonStyle,
    };
  }

  openAlertModal = (props: Omit<AlertModalProps, 'isOpen' | 'closeModal'>) => {
    this.alertModalTitle = props.title;
    this.alertModalBody = props.body;
    this.alertBodyRenderComponent = props.renderComponent;
    this.alertConfirmButtonStyle = props.confirmButtonStyle;
    this.alertConfirmButtonTitle = props.confirmButtonTitle;
    this.alertCancelButtonTitle = props.cancelButtonTitle;
    this.alertLinkButtonTitle = props.linkButtonTitle;
    this.alertLinkButtonHref = props.linkButtonHref;
    this.isAlertModalOpen = true;
    this.handleAlertConfirm = props.handleAlertConfirm;
  };

  private dismissAlertModal = () => {
    this.isAlertModalOpen = false;
    this.alertModalTitle = '';
    this.alertModalBody = undefined;
    this.alertBodyRenderComponent = undefined;
    this.alertConfirmButtonTitle = '';
    this.alertCancelButtonTitle = undefined;
    this.alertLinkButtonTitle = undefined;
    this.alertLinkButtonHref = undefined;
    this.handleAlertConfirm = undefined;
  };

  /**
   * CoachRoleModal
   */
  isCoachRoleModalOpen = false;
  initialCoachRoleModalRole: CoachRole = 'member';
  coachRoleModalCoachName: string = '';
  onSaveRolePressed?: (selectedRole: CoachRole) => void = undefined;

  get roleModalProps(): CoachRoleModalProps | undefined {
    if (!this.onSaveRolePressed) {
      return undefined;
    }

    return {
      initialRole: this.initialCoachRoleModalRole,
      isOpen: this.isCoachRoleModalOpen,
      closeModal: this.dissmissCoachRoleModal,
      onSavePressed: this.onSaveRolePressed,
      coachName: this.coachRoleModalCoachName,
    };
  }

  openCoachRoleModal = (props: Omit<CoachRoleModalProps, 'isOpen' | 'closeModal'>) => {
    this.initialCoachRoleModalRole = props.initialRole;
    this.onSaveRolePressed = props.onSavePressed;
    this.isCoachRoleModalOpen = true;
    this.coachRoleModalCoachName = props.coachName;
  };

  private dissmissCoachRoleModal = () => {
    this.isCoachRoleModalOpen = false;
    this.onSaveRolePressed = undefined;
    this.coachRoleModalCoachName = '';
    this.initialCoachRoleModalRole = 'member';
  };

  /**
   * ReassignClientModal
   */
  isReassignClientModalOpen = false;
  reassignModalClients: Client[] | undefined = undefined;
  reassignClientCoachOptions: CoachAccount[] = [];
  reassignClientModalCoachName: string = '';
  reassignClientOnCoachSelected:
    | ((coachId: string, keepProgram: boolean) => void)
    | undefined = undefined;
  reassignClientModalTitle: string = '';

  get reassignClientModalProps(): ReassignClientModalProps | undefined {
    if (!this.reassignModalClients || !this.reassignClientOnCoachSelected) {
      return undefined;
    }
    return {
      clients: this.reassignModalClients,
      isOpen: this.isReassignClientModalOpen,
      closeModal: this.dismissReassignClientModal,
      coachOptions: this.reassignClientCoachOptions,
      onCoachSelected: this.reassignClientOnCoachSelected,
      modalTitle: this.reassignClientModalTitle,
    };
  }

  openReassignClientModal = (props: Omit<ReassignClientModalProps, 'isOpen' | 'closeModal'>) => {
    this.reassignModalClients = props.clients;
    this.isReassignClientModalOpen = true;
    this.reassignClientCoachOptions = props.coachOptions;
    this.reassignClientOnCoachSelected = props.onCoachSelected;
    this.reassignClientModalTitle = props.modalTitle;
  };

  private dismissReassignClientModal = () => {
    this.reassignModalClients = undefined;
    this.isReassignClientModalOpen = false;
    this.reassignClientCoachOptions = [];
    this.reassignClientOnCoachSelected = undefined;
    this.reassignClientModalCoachName = '';
    this.reassignClientModalTitle = '';
  };

  /**
   * WorkoutDetailModal
   */
  isWorkoutModalOpen = false;
  workoutDetailWorkoutId: string | undefined = undefined;
  get workoutDetailProps(): WorkoutDetailModalProps | undefined {
    if (!this.workoutDetailWorkoutId) return;
    return {
      isOpen: this.isWorkoutModalOpen,
      onClose: this.dismissWorkoutModal,
      vm: new WorkoutDetailViewModel(this.workoutDetailWorkoutId),
    };
  }

  openWorkoutDetailModal = (workoutId: string) => {
    this.workoutDetailWorkoutId = workoutId;
    this.isWorkoutModalOpen = true;
  };

  private dismissWorkoutModal = () => {
    this.workoutDetailWorkoutId = undefined;
    this.isWorkoutModalOpen = false;
  };

  /**
   * SelectRoutineModal
   */
  isSelectRoutineModalOpen = false;
  maxNumberOfRoutinesAllowed?: number;
  onAddRoutinesClick?: (routines: CoachsShallowLibraryRoutine[]) => void;
  onCreateRoutineClick?: () => Promise<void>;
  get selectRoutineModalProps(): SelectRoutineModalProps | undefined {
    if (!this.onAddRoutinesClick || !this.onCreateRoutineClick) return;

    return {
      vm: new SelectRoutineViewModel({
        onClose: this.dismissSelectRoutineModal,
        onAddRoutinesClick: this.onAddRoutinesClick,
        maxRoutines: this.maxNumberOfRoutinesAllowed,
        onCreateRoutineClick: this.onCreateRoutineClick,
      }),

      isOpen: this.isSelectRoutineModalOpen,
      onClose: this.dismissSelectRoutineModal,
    };
  }

  openSelectRoutineModal = ({
    onAddRoutinesClick,
    onCreateRoutineClick,
    maxNumberOfRoutinesAllowed,
  }: {
    onAddRoutinesClick: (routines: CoachsShallowLibraryRoutine[]) => void;
    onCreateRoutineClick: () => Promise<void>;
    maxNumberOfRoutinesAllowed?: number;
  }) => {
    this.isSelectRoutineModalOpen = true;
    (this.maxNumberOfRoutinesAllowed = maxNumberOfRoutinesAllowed),
      (this.onAddRoutinesClick = onAddRoutinesClick);
    this.onCreateRoutineClick = onCreateRoutineClick;
  };

  private dismissSelectRoutineModal = () => {
    this.isSelectRoutineModalOpen = false;
    this.onAddRoutinesClick = undefined;
    this.onCreateRoutineClick = undefined;
  };

  /**
   * AssignProgramModal
   */
  isAssignProgramModalOpen = false;
  assignProgramPrograms: Program[] = [];
  assignProgramClientId?: string;
  assignProgramProgramToReplace?: Program;
  onAssignProgramModalClosed?: () => void;

  get assignProgramProps(): AssignProgramModalProps | undefined {
    if (!this.assignProgramClientId) return;
    return {
      programs: this.assignProgramPrograms,
      vm: new AssignProgramViewModel(
        this.assignProgramPrograms,
        this.dismissAssignProgramModal,
        this.assignProgramClientId,
        this.assignProgramProgramToReplace,
      ),
      isOpen: this.isAssignProgramModalOpen,
      onClose: () => {
        this.dismissAssignProgramModal();
      },
    };
  }

  openAssignProgramModal = (
    programs: Program[],
    clientId: string,
    programToReplace?: Program,
    onClose?: () => void,
  ) => {
    this.assignProgramPrograms = programs;
    this.isAssignProgramModalOpen = true;
    this.assignProgramClientId = clientId;
    this.assignProgramProgramToReplace = programToReplace;
    this.onAssignProgramModalClosed = onClose;
  };

  private dismissAssignProgramModal = () => {
    this.isAssignProgramModalOpen = false;
    this.onAssignProgramModalClosed?.();
  };

  /**
   * CopyRoutineModal
   */
  isCopyRoutineModalOpen = false;
  copyRoutineRoutine: BaseRoutine | undefined = undefined;

  get copyRoutineProps(): CopyRoutineModalProps | undefined {
    if (!this.copyRoutineRoutine) return;
    return {
      vm: new CopyRoutineViewModel({
        routine: this.copyRoutineRoutine,
        onClose: this.dismissCopyRoutineModal,
      }),
      isOpen: this.isCopyRoutineModalOpen,
      onClose: () => {
        this.dismissCopyRoutineModal();
      },
    };
  }

  openCopyRoutineModal = (routine: BaseRoutine) => {
    this.isCopyRoutineModalOpen = true;
    this.copyRoutineRoutine = routine;
  };

  private dismissCopyRoutineModal = () => {
    this.isCopyRoutineModalOpen = false;
  };

  /**
   * ProgressPictureModal
   */

  progressPictureModalVm: ProgressPictureModalViewModel | undefined = undefined;

  openProgressPictureModal = (clientId: string, measurementId?: number) => {
    this.progressPictureModalVm = new ProgressPictureModalViewModel(clientId);
    this.progressPictureModalVm.open(measurementId);
  };

  /**
   *EditExerciseModal
   */
  isEditExerciseModalOpen = false;
  editExerciseViewModel?: EditExerciseViewModel;

  get editExerciseModalProps(): EditExerciseModalProps | undefined {
    if (!this.editExerciseViewModel) {
      return undefined;
    }
    return {
      vm: this.editExerciseViewModel,
      isOpen: this.isEditExerciseModalOpen,
    };
  }

  openEditExerciseModal = ({
    onExerciseSaved,
    exerciseId,
    exerciseTitle,
  }: {
    onExerciseSaved?: (exerciseId: string) => void;
    exerciseId?: string;
    exerciseTitle?: string;
  }) => {
    this.editExerciseViewModel = new EditExerciseViewModel({
      onExerciseSaved: onExerciseSaved,
      exerciseId: exerciseId,
      exerciseTitle: exerciseTitle,
      videoCellVm: new VideoUploadCellViewModel(),
    });

    this.isEditExerciseModalOpen = true;
  };

  private dismisEditExerciseModal = () => {
    this.isEditExerciseModalOpen = false;
  };

  /**
   * ExerciseLibraryModal
   */
  isExerciseLibraryModalOpen = false;
  exerciseIdToBeReplaced?: string;
  hidePlusIcons?: boolean;
  clientId?: string;
  selectedExerciseId?: string;
  enableCreateExerciseButton?: boolean;
  enableCreateExerciseOnNoResults?: boolean;
  onExerciseSelected?: (exerciseId: string) => void;
  onExerciseLibraryClosed?: () => void;

  get ExerciseLibraryProps(): ExerciseLibraryModalProps {
    return {
      vm: this.clientId
        ? new ExerciseLibraryViewModel(this.clientId)
        : new ExerciseLibraryViewModel(),
      exerciseIdToBeReplaced: this.exerciseIdToBeReplaced,
      onSelectExercise: this.onExerciseSelected,
      isOpen: this.isExerciseLibraryModalOpen,
      hidePlusIcons: this.hidePlusIcons,
      selectedExerciseId: this.selectedExerciseId,
      enableCreateExerciseButton: this.enableCreateExerciseButton,
      enableCreateExerciseOnNoResults: this.enableCreateExerciseOnNoResults,
      onClose: () => {
        this.dismissExerciseLibraryModal();
      },
    };
  }

  openExerciseLibraryModal = ({
    exerciseIdToBeReplaced,
    hidePlusIcons,
    clientId,
    onExerciseSelected,
    onClose,
    selectedExerciseId,
    enableCreateExerciseButton,
    enableCreateExerciseOnNoResults,
  }: {
    exerciseIdToBeReplaced?: string;
    hidePlusIcons?: boolean;
    clientId?: string;
    onExerciseSelected?: (exerciseTemplateId: string) => void;
    onClose?: () => void;
    selectedExerciseId?: string;
    enableCreateExerciseButton?: boolean;
    enableCreateExerciseOnNoResults?: boolean;
  }) => {
    this.isExerciseLibraryModalOpen = true;
    this.exerciseIdToBeReplaced = exerciseIdToBeReplaced;
    this.hidePlusIcons = hidePlusIcons;
    this.clientId = clientId;
    this.selectedExerciseId = selectedExerciseId;
    this.enableCreateExerciseButton = enableCreateExerciseButton;
    this.enableCreateExerciseOnNoResults = enableCreateExerciseOnNoResults;

    this.onExerciseSelected = onExerciseSelected;
    this.onExerciseLibraryClosed = onClose;
  };

  private dismissExerciseLibraryModal = () => {
    this.isExerciseLibraryModalOpen = false;
    this.onExerciseLibraryClosed?.();
  };

  /**
   * ExerciseDetailModal
   */
  isExerciseDetailModalOpen = false;
  exerciseDetailViewModel?: ExerciseDetailViewModel;
  get ExerciseDetailModalProps(): ExerciseDetailModalProps | undefined {
    if (!this.exerciseDetailViewModel) return;
    return {
      vm: this.exerciseDetailViewModel,
      isOpen: this.isExerciseDetailModalOpen,
      onClose: this.dismissExerciseDetailModal,
    };
  }

  openExerciseDetailModal = ({
    exerciseTemplate,
    exerciseTemplateType,
    onExerciseUpdated,
    client,
  }: {
    exerciseTemplate: ExerciseTemplate;
    exerciseTemplateType: ExerciseTemplateType;
    onExerciseUpdated?: () => void;
    client?: Client;
  }) => {
    this.isExerciseDetailModalOpen = true;
    this.exerciseDetailViewModel = new ExerciseDetailViewModel(
      exerciseTemplate,
      exerciseTemplateType,
      onExerciseUpdated,
      client,
    );
  };

  private dismissExerciseDetailModal = () => {
    this.isExerciseDetailModalOpen = false;
  };

  /**
   * MoveProgramToFolderModal
   */
  isMoveProgramToFolderModalOpen = false;
  moveProgramToFolderViewModel?: MoveProgramToFolderViewModel;
  onAddFolderPressed?: () => void;
  get moveProgramToFolderModalProps(): MoveProgramToFolderProps | undefined {
    if (!this.moveProgramToFolderViewModel || !this.onAddFolderPressed) return;
    return {
      isOpen: this.isMoveProgramToFolderModalOpen,
      onClose: this.dismissMoveProgramToFolderModal,
      vm: this.moveProgramToFolderViewModel,
      onAddFolderPress: this.onAddFolderPressed,
    };
  }

  openMoveProgramToFolderModal = ({
    programToMove,
    onAddFolderPressed,
    targetCoachId,
  }: {
    programToMove: Program;
    onAddFolderPressed: () => void;
    targetCoachId: string;
  }) => {
    this.isMoveProgramToFolderModalOpen = true;
    this.moveProgramToFolderViewModel = new MoveProgramToFolderViewModel(
      programToMove,
      targetCoachId,
    );
    this.onAddFolderPressed = onAddFolderPressed;
  };

  private dismissMoveProgramToFolderModal = () => {
    this.isMoveProgramToFolderModalOpen = false;
  };

  /**
   *  CommentModal
   */
  isCommentModalOpen = false;
  commentModalWorkoutId: string | undefined;

  get commentModalProps(): CommentModalProps | undefined {
    if (!this.commentModalWorkoutId) return;
    return {
      isOpen: this.isCommentModalOpen,
      onClose: this.dismissCommentModal,
      vm: new CommentModalViewModel({ workoutId: this.commentModalWorkoutId }),
    };
  }

  openCommentModal = ({ workoutId }: { workoutId: string }) => {
    this.isCommentModalOpen = true;
    this.commentModalWorkoutId = workoutId;
  };

  private dismissCommentModal = () => {
    this.isCommentModalOpen = false;
    this.commentModalWorkoutId = undefined;
  };

  /**
   *  ClientListModal
   */
  isClientListModalOpen = false;
  clientListModalTitle = '';
  clients: Client[] = [];

  get clientListModalProps(): ClientListModalProps {
    return {
      isOpen: this.isClientListModalOpen,
      onClose: this.dismissClientListModal,
      title: this.clientListModalTitle,
      vm: new ClientListViewModel(this.clients),
    };
  }

  openClientListModal = ({ clients, title }: { title: string; clients: Client[] }) => {
    this.isClientListModalOpen = true;
    this.clients = clients;
    this.clientListModalTitle = title;
  };

  private dismissClientListModal = () => {
    this.isClientListModalOpen = false;
    this.clients = [];
    this.clientListModalTitle = '';
  };

  /**
   *  RoutineSummaryModal
   */
  isRoutineSummaryModalOpen = false;
  routineSummaryExercises: ViewRoutineExercise[] | undefined = undefined;
  routineSummaryClient?: Client;

  get routineSummaryModalProps(): RoutineSummaryModalProps | undefined {
    if (!this.routineSummaryExercises) {
      return undefined;
    }
    return {
      isOpen: this.isRoutineSummaryModalOpen,
      onClose: this.dismissRoutineSummaryModal,
      vm: new RoutineSummaryViewModel(this.routineSummaryExercises, this.routineSummaryClient),
    };
  }

  openRoutineSummaryModal = ({
    exercises,
    client,
  }: {
    exercises: ViewRoutineExercise[];
    client?: Client;
  }) => {
    this.isRoutineSummaryModalOpen = true;
    this.routineSummaryExercises = exercises;
    this.routineSummaryClient = client;
  };

  private dismissRoutineSummaryModal = () => {
    this.isRoutineSummaryModalOpen = false;
    this.routineSummaryExercises = undefined;
    this.routineSummaryClient = undefined;
  };

  /**
   *  Transition from deprecated free plan to trial modal
   */
  isTrialStartedModalOpen = false;

  get trialStartedModalProps(): TrialStartedModalProps | undefined {
    if (!this.isTrialStartedModalOpen) return;
    return {
      isOpen: this.isTrialStartedModalOpen,
      onClose: this.closeFreePlanNotAvailableModal,
    };
  }
  openTrialStartedModal = () => {
    this.isTrialStartedModalOpen = true;
  };

  private closeFreePlanNotAvailableModal = () => {
    this.isTrialStartedModalOpen = false;
  };

  /**
   *  ProgramDetailModal
   */
  isProgramDetailModalOpen = false;
  program: Program | undefined;
  routines: BaseRoutine[] = [];
  coach?: CoachAccount;
  onAddToMyLibraryClick?: (programId: string) => Promise<void>;
  onEditProgramClick?: (programId: string) => void;

  get programDetailModalProps(): ProgramDetailModalProps | undefined {
    if (!this.program || !this.onAddToMyLibraryClick) return;

    return {
      isOpen: this.isProgramDetailModalOpen,
      onClose: this.dismissProgramDetailModal,
      program: this.program,
      routines: this.routines,
      coach: this.coach,
      onAddToMyLibraryClick: this.onAddToMyLibraryClick,
      onEditProgramClick: this.onEditProgramClick,
    };
  }

  openProgramDetailModal = ({
    program,
    routines,
    coach,
    onAddToMyLibraryClick,
    onEditProgramClick,
  }: {
    program: Program;
    routines: BaseRoutine[];
    coach?: CoachAccount;
    onAddToMyLibraryClick: (programId: string) => Promise<void>;
    onEditProgramClick?: (programId: string) => void;
  }) => {
    this.isProgramDetailModalOpen = true;
    this.program = program;
    this.routines = routines;
    this.coach = coach;
    this.onAddToMyLibraryClick = onAddToMyLibraryClick;
    this.onEditProgramClick = onEditProgramClick;
  };

  private dismissProgramDetailModal = () => {
    this.isProgramDetailModalOpen = false;
    this.program = undefined;
    this.routines = [];
    this.coach = undefined;
    this.onAddToMyLibraryClick = undefined;
    this.onEditProgramClick = undefined;
  };

  /**
   * Import From Hevy Modal
   */

  isImportFromHevyModalOpen = false;

  // Initialized on first open
  importFromHevyViewModel: ImportFromHevyViewModel | undefined;

  get importFromHevyModalProps(): ImportFromHevyModalProps | undefined {
    if (!this.isImportFromHevyModalOpen || !this.importFromHevyViewModel) return;

    return {
      isOpen: this.isImportFromHevyModalOpen,
      onClose: this.closeImportFromHevyModal,
      vm: this.importFromHevyViewModel,
    };
  }

  openImportFromHevyModal = () => {
    if (!this.importFromHevyViewModel) {
      this.importFromHevyViewModel = new ImportFromHevyViewModel({
        onClose: this.closeImportFromHevyModal,
      });
    }
    this.isImportFromHevyModalOpen = true;
    this.importFromHevyViewModel.fetch();
  };

  private closeImportFromHevyModal = () => {
    this.isImportFromHevyModalOpen = false;
  };

  /**
   *  ProgramScheduleModal
   */
  isProgramScheduleModalOpen = false;
  startDate?: string | null;
  durationDays?: number | null;
  onStartDateChange?: (date: string | null) => void;
  onDurationDaysChange?: (days: number | null) => void;
  get programScheduleModalProps(): ProgramScheduleModalProps | undefined {
    if (
      this.startDate === undefined ||
      this.durationDays === undefined ||
      this.onStartDateChange === undefined ||
      this.onDurationDaysChange === undefined
    )
      return;

    return {
      isOpen: this.isProgramScheduleModalOpen,
      onClose: this.closeProgramScheduleModal,
      startDate: this.startDate,
      durationDays: this.durationDays,
      onStartDateChange: this.onStartDateChange,
      onDurationChange: this.onDurationDaysChange,
    };
  }

  openProgramScheduleModal = (
    startDate: string | null,
    durationDays: number | null,
    onStartDateChange: (date: string | null) => void,
    onDurationDaysChange: (days: number | null) => void,
  ) => {
    this.isProgramScheduleModalOpen = true;
    this.startDate = startDate;
    this.durationDays = durationDays;
    this.onStartDateChange = onStartDateChange;
    this.onDurationDaysChange = onDurationDaysChange;
  };

  private closeProgramScheduleModal = () => {
    this.isProgramScheduleModalOpen = false;
    this.startDate = undefined;
    this.durationDays = undefined;
  };

  /**
   *  EnableTeamModal
   */
  isEnableTeamModalOpen = false;
  get enableTeamModalProps(): EnableTeamModalProps {
    return {
      vm: new EnableTeamViewModel(this.dismissEnableTeamModal),
      isOpen: this.isEnableTeamModalOpen,
      onClose: this.dismissEnableTeamModal,
    };
  }

  openEnableTeamModal = () => {
    this.isEnableTeamModalOpen = true;
  };

  private dismissEnableTeamModal = () => {
    this.isEnableTeamModalOpen = false;
  };
}

export const modal = new ModalManagerState();

export const ModalManager = observer(() => {
  return (
    <>
      <OkModal {...modal.okProps} />
      <FreeTrialEndModal {...modal.freeTrialEndModalProps} />
      {modal.assignProgramProps && <AssignProgramModal {...modal.assignProgramProps} />}
      {!!modal.copyRoutineProps && <CopyRoutineModal {...modal.copyRoutineProps} />}
      {!!modal.workoutDetailProps && <WorkoutDetailModal {...modal.workoutDetailProps} />}
      {!!modal.selectRoutineModalProps && <SelectRoutineModal {...modal.selectRoutineModalProps} />}
      {!!modal.assignProgramToMultipleClientsModalProps && (
        <AssignProgramToMultipleClientsModal {...modal.assignProgramToMultipleClientsModalProps} />
      )}
      <TextInputModal {...modal.textInputModalProps} />
      <FeedbackModal {...modal.feedbackModalProps}></FeedbackModal>
      <MobileNavBarModal {...modal.mobileNavBarModalProps}></MobileNavBarModal>
      {modal.editExerciseModalProps && <EditExerciseModal {...modal.editExerciseModalProps} />}
      {modal.upgradePlanModalProps && <UpgradePlanModal {...modal.upgradePlanModalProps} />}
      <AlertModal {...modal.alertProps} />
      <WhatsNewModal {...modal.whatsNewModalProps} />
      <ExerciseLibraryModal {...modal.ExerciseLibraryProps} />
      <VideoModal {...modal.videoModalProps} />
      <ClientInviteModal {...modal.clientInviteModalProps} />
      <CoachInviteModal {...modal.coachInviteModalProps} />
      {modal.moveProgramToFolderModalProps && (
        <MoveProgramToFolderModal {...modal.moveProgramToFolderModalProps} />
      )}
      {!!modal.ExerciseDetailModalProps && (
        <ExerciseDetailModal {...modal.ExerciseDetailModalProps} />
      )}
      {!!modal.commentModalProps && <CommentModal {...modal.commentModalProps} />}
      {!!modal.genericModalProps && <GenericModal {...modal.genericModalProps} />}
      {<ClientListModal {...modal.clientListModalProps} />}
      {!!modal.programDetailModalProps && <ProgramDetailModal {...modal.programDetailModalProps} />}
      {BackendReachability.backendReachabilityState === 'maintenance-mode' && (
        <MaintenanceModeModal />
      )}
      {!!modal.trialStartedModalProps && <TrialStartedModal {...modal.trialStartedModalProps} />}
      {!!modal.routineSummaryModalProps && (
        <RoutineSummaryModal {...modal.routineSummaryModalProps} />
      )}
      {!!modal.importFromHevyModalProps && (
        <ImportFromHevyModal {...modal.importFromHevyModalProps} />
      )}
      {!!modal.programScheduleModalProps && (
        <ProgramScheduleModal {...modal.programScheduleModalProps} />
      )}
      {!!modal.roleModalProps && <CoachRoleModal {...modal.roleModalProps} />}
      {!!modal.reassignClientModalProps && (
        <ReassignClientModal {...modal.reassignClientModalProps} />
      )}
      <TransferClientsToCoachModal />
      <TransferClientsFromCoachModal />
      <EnableTeamModal {...modal.enableTeamModalProps} />
      {!!modal.progressPictureModalVm && <ProgressPictureModal vm={modal.progressPictureModalVm} />}
      {!!modal.progressPictureModalVm && (
        <PictureSelectionModal vm={modal.progressPictureModalVm} />
      )}
    </>
  );
});
