import { autorun, makeAutoObservable } from 'mobx';
import { Client } from 'types/client';
import toast from 'react-hot-toast';
import API from 'utils/API';
import { Conversation, Message } from 'hevy-shared';
import { HevyWebsocketClient } from 'utils/globals/HevyWebsocketClient';
import { sendEvent } from 'utils/analyticsEvents';
import { fireAndForget } from 'utils/async';
import { v4 as uuid } from 'uuid';
import {
  oldestMessageFirst,
  oldestMessageInConversation,
  latestMessageInConversation,
  isUniqueMessage,
} from 'utils/chatUtils';
import { ActiveConversation } from 'types/chat';
import { localStorageStores } from 'state/localStorageStores';

const CHATS_LOCAL_STORAGE_KEY = 'CHATS_LOCAL_STORAGE_KEY';
const CHATS_UNSENT_MESSAGES_LOCAL_STORAGE_KEY = 'CHATS_UNSENT_MESSAGES_LOCAL_STORAGE_KEY';

export class Chats {
  private _widgetActiveConversationID: string | undefined = undefined;
  private _screenActiveConversationID: string | undefined = undefined;

  private _chatData: {
    conversations: (Conversation & {
      canLoadMore: boolean;
      lastReadMessageId?: string;
      lastDeletedSyncTime?: Date;
    })[];
    messages: Message[];
  } = { conversations: [], messages: [] };

  unsentMessages: { [conversationId: string]: string | undefined } = {};

  get messages() {
    return this._chatData.messages;
  }

  get chatData() {
    return this._chatData;
  }

  private _persistToDisk = () => {
    localStorage.setItem(CHATS_LOCAL_STORAGE_KEY, JSON.stringify(this._chatData));
  };

  hydrate = () => {
    const dataString = localStorage.getItem(CHATS_LOCAL_STORAGE_KEY);
    if (dataString) {
      this._chatData = JSON.parse(dataString);
      for (const conversation of this._chatData.conversations) {
        conversation.lastDeletedSyncTime = !!conversation.lastDeletedSyncTime
          ? new Date(conversation.lastDeletedSyncTime as any)
          : undefined;
      }
    }

    this.hydrateUnsentMessages();
  };

  set widgetActiveConversationID(id: string | undefined) {
    if (!!id && this._widgetActiveConversationID !== id) {
      this.markMessagesReadForConversation(id);
    }
    this._widgetActiveConversationID = id;
  }

  get widgetActiveConversationID() {
    return this._widgetActiveConversationID;
  }

  set screenActiveConversationID(id: string | undefined) {
    if (!!id && this._screenActiveConversationID !== id) {
      this.markMessagesReadForConversation(id);
    }
    this._screenActiveConversationID = id;
  }

  get screenActiveConversationID() {
    return this._screenActiveConversationID;
  }

  private markMessagesReadForConversation = (conversationId: string) => {
    const latestMessage = latestMessageInConversation(conversationId, this.messages);
    if (!latestMessage) {
      return;
    }

    this.updateLastReadAtForConversation(conversationId, latestMessage.id);
  };

  private updateLastReadAtForConversation = (conversationId: string, messageId: string) => {
    this._chatData.conversations.forEach(conversation => {
      if (conversation.conversation_id === conversationId) {
        conversation.lastReadMessageId = messageId;
      }
    });
    fireAndForget([API.postSetConversationLastRead(conversationId, messageId)]);
    this._persistToDisk();
  };

  isFetchingOlderMessages = false;
  isFetchingLatestMessages = false;
  isLoadingConversations = false;
  hasFailedToIntialize = false;

  constructor() {
    makeAutoObservable(this);
    HevyWebsocketClient.messageEventEmitter?.on('new-message', this.handleNewMessage);
    HevyWebsocketClient.messageEventEmitter?.on('message-deleted', this.handleMessageDeleted);
    autorun(async () => {
      if (HevyWebsocketClient.isConnected && HevyWebsocketClient.hasDisconnectedUnexpectedely) {
        // Run in a setTimeout to avoid this autorun picking up vm changes
        setTimeout(() => {
          // A little extreme, but this is sorta an edge case. Basically
          // blow away all our local data, and refresh everything when
          // the webesocket reconnects to ensure we have the most recent messages
          fireAndForget([this.reset()]);
        }, 0);
      }
    });
  }

  async reset() {
    this._chatData.messages = [];
    this._chatData.conversations = [];
    this._persistToDisk();
    await this.fetchConversations();
  }

  clearData = () => {
    this._chatData.messages = [];
    this._chatData.conversations = [];
    this.hasFailedToIntialize = false;
    this._screenActiveConversationID = undefined;
    this._widgetActiveConversationID = undefined;
    this._persistToDisk();
  };

  latestMessageForConversation = (conversationId: string) => {
    return latestMessageInConversation(conversationId, this.messages);
  };

  onSend = async (message: string, conversationId: string, senderId: string) => {
    const tempMessageId = uuid();
    try {
      const unsentMessage: Message = {
        text: message,
        id: tempMessageId,
        created_at: new Date().toISOString(),
        conversation_id: conversationId,
        sender_user_id: senderId,
        type: 'text',
      };
      this.messages.push(unsentMessage);
      const messageId = (
        await API.createMessage({
          conversation_id: conversationId,
          data: { type: unsentMessage.type, text: unsentMessage.text },
        })
      ).data.message_id;
      this.messages.forEach(message => {
        if (message.id === tempMessageId) {
          message.id = messageId;
        }
      });
      sendEvent('coachChat_messageSent');
      this._persistToDisk();
    } catch (error) {
      this._chatData.messages = this.messages.filter(message => message.id !== tempMessageId);
      throw error;
    } finally {
    }
  };

  deleteMessage = async (messageId: string) => {
    const message = this.messages.find(m => m.id === messageId);
    if (!message) {
      return;
    }

    this.chatData.messages = this.messages.filter(m => m.id !== messageId);

    try {
      await API.deleteMessage(messageId);
      this._persistToDisk();
    } catch (error) {
      this.messages.push(message);
      this.messages.sort(oldestMessageFirst);
      throw error;
    }
  };

  onSendFileMessage = async (fileUrl: string, conversationId: string, type: 'image' | 'video') => {
    const tempMessageId = uuid();
    try {
      if (type === 'image') {
        const unsentMessage: Message = {
          id: tempMessageId,
          created_at: new Date().toISOString(),
          conversation_id: conversationId,
          sender_user_id: localStorageStores.account.id,
          type: type,
          image_url: fileUrl,
        };
        this.messages.push(unsentMessage);
        const messageId = (
          await API.createMessage({
            conversation_id: conversationId,
            data: { type: unsentMessage.type, image_url: unsentMessage.image_url },
          })
        ).data.message_id;
        this.messages.forEach(message => {
          if (message.id === tempMessageId) {
            message.id = messageId;
          }
        });
      } else {
        const unsentMessage: Message = {
          id: tempMessageId,
          created_at: new Date().toISOString(),
          conversation_id: conversationId,
          sender_user_id: localStorageStores.account.id,
          type: type,
          video_url: fileUrl,
        };
        this.messages.push(unsentMessage);
        const messageId = (
          await API.createMessage({
            conversation_id: conversationId,
            data: { type: unsentMessage.type, video_url: unsentMessage.video_url },
          })
        ).data.message_id;
        this.messages.forEach(message => {
          if (message.id === tempMessageId) {
            message.id = messageId;
          }
        });
      }
      sendEvent('coachChat_fileMessageSent', { type });
      this._persistToDisk();
    } catch (error) {
      this._chatData.messages = this.messages.filter(message => message.id !== tempMessageId);
      throw error;
    } finally {
    }
  };

  handleMessageDeleted = (messageId: string) => {
    this._chatData.messages = this._chatData.messages.filter(m => m.id !== messageId);
    this._persistToDisk();
  };

  handleNewMessage = (message: Message) => {
    this.messages.push(message);
    if (
      this._screenActiveConversationID === message.conversation_id ||
      this._widgetActiveConversationID === message.conversation_id
    ) {
      this.updateLastReadAtForConversation(message.conversation_id, message.id);
    }
    this._persistToDisk();
  };

  syncDeletedMessagesForConversation = async (conversationId: string) => {
    const conversation = this.conversationWithId(conversationId);
    if (!conversation) {
      return;
    }
    const response = await API.postGetDeletedMessagesInConversation(
      conversationId,
      conversation.lastDeletedSyncTime?.toISOString(),
    );

    this._chatData.messages = this._chatData.messages.filter(
      m => !response.data.deleted_message_ids.includes(m.id),
    );

    conversation.lastDeletedSyncTime = new Date(response.data.checked_at_time);
    this._persistToDisk();
  };

  fetchConversations = async () => {
    if (this.isLoadingConversations) {
      return;
    }
    this.isLoadingConversations = true;
    this.hasFailedToIntialize = false;

    try {
      const conversations = (await API.getConversations()).data.conversations;
      const newConversations = conversations.filter(c => {
        return !this._chatData.conversations.find(
          existingConversation => existingConversation.conversation_id === c.conversation_id,
        );
      });
      this._chatData.conversations = this._chatData.conversations.concat(
        newConversations.map(c => {
          return { ...c, canLoadMore: true };
        }),
      );

      // Filter removed conversations
      this._chatData.conversations = this._chatData.conversations.filter(c => {
        return conversations.find(serverConv => {
          return serverConv.conversation_id === c.conversation_id;
        });
      });

      // Filter messages for removed conversations
      this._chatData.messages = this._chatData.messages.filter(m => {
        return this._chatData.conversations.find(c => {
          return c.conversation_id === m.conversation_id;
        });
      });
      const response = await API.getLatestMessageFromClientConversations();
      const newerMessages: Message[] = [];
      for (const conversation of response.data.conversations) {
        // Check if we've seen this messages before
        if (
          latestMessageInConversation(conversation.conversation_id, this.messages)?.id !==
          conversation.latest_message.id
        ) {
          newerMessages.push(conversation.latest_message);
        }
        const lastRead = conversation.last_read_message_id;
        if (!!lastRead) {
          const c = this._chatData.conversations.find(c => {
            return c.conversation_id === conversation.conversation_id;
          });
          if (c) {
            c.lastReadMessageId = lastRead;
          }
        }
      }
      this._chatData.messages = this.messages.concat(newerMessages).filter(isUniqueMessage);
      this._persistToDisk();
    } catch (error) {
      this.hasFailedToIntialize = true;
      throw error;
    } finally {
      this.isLoadingConversations = false;
    }
  };

  conversationForClientId = (clientId: string): Conversation | undefined => {
    return this._chatData.conversations.find(c => {
      return c.user_ids.includes(clientId);
    });
  };
  conversationWithId = (
    conversationId: string,
  ):
    | (Conversation & {
        canLoadMore: boolean;
        lastReadMessageId?: string;
        lastDeletedSyncTime?: Date;
      })
    | undefined => {
    return this._chatData.conversations.find(c => {
      return c.conversation_id === conversationId;
    });
  };

  createNewConversation = async (client: Client, coachId: string): Promise<string> => {
    const conversation = await API.postCreateConversation(coachId, client.id);

    const newConversation = {
      conversation_id: conversation.data.conversation_id,
      user_ids: [coachId, client.id],
      canLoadMore: false,
    };

    if (
      !this._chatData.conversations.find(c => c.conversation_id === newConversation.conversation_id)
    ) {
      this._chatData.conversations.push(newConversation);
    }
    this._persistToDisk();
    return newConversation.conversation_id;
  };

  loadLatestMessagesForConversation = async (
    conversationId: string,
    activeConversations: ActiveConversation[],
  ) => {
    const conversation = activeConversations.find(c => c.conversation_id === conversationId);
    if (!conversation || this.isFetchingLatestMessages) {
      return;
    }
    this.isFetchingLatestMessages = true;
    try {
      const { messages, last_read_message_id } = (
        await API.postGetMessagesInConversation(100, conversation.conversation_id, undefined)
      ).data;

      const oldestMessageInResponse = messages.sort(oldestMessageFirst)[0];
      const newestMessageSoFar = latestMessageInConversation(conversationId, this.messages);
      if (
        oldestMessageInResponse &&
        newestMessageSoFar &&
        Number(oldestMessageInResponse.id) > Number(newestMessageSoFar.id)
      ) {
        // We have a gap, so...
        this._chatData.messages = this.messages
          // ....drop all the messages in this conversation...
          .filter(m => {
            return m.conversation_id !== conversationId;
          })
          // ...and add the new ones
          .concat(messages)
          .filter(isUniqueMessage);
      } else {
        // Just add the new unique new ones
        this._chatData.messages = this.messages
          .concat(messages)
          .sort(oldestMessageFirst)
          .filter(isUniqueMessage);
      }

      conversation.lastReadMessageId = last_read_message_id
        ? last_read_message_id
        : conversation.lastReadMessageId;

      conversation.unsentMessage = 'Test';

      this._persistToDisk();
    } catch (error) {
      toast.error('Error loading latest messages.');
    }
    this.isFetchingLatestMessages = false;
  };

  loadOlderMessagesForConversation = async (
    conversationId: string,
    activeConversations: ActiveConversation[],
  ) => {
    const conversation = activeConversations.find(c => c.conversation_id === conversationId);
    if (!conversation || !conversation.canLoadMore || this.isFetchingOlderMessages) {
      return;
    }
    this.isFetchingOlderMessages = true;
    try {
      const oldestMessage = oldestMessageInConversation(conversationId, this.messages);
      const { messages, has_more } = (
        await API.postGetMessagesInConversation(
          100,
          conversation.conversation_id,
          oldestMessage?.id,
        )
      ).data;
      conversation.canLoadMore = has_more;

      this._chatData.messages = this.messages
        .concat(messages)
        .sort(oldestMessageFirst)
        .filter(isUniqueMessage);
      this._persistToDisk();
    } catch (error) {
      toast.error('Error loading older messages.');
    }
    this.isFetchingOlderMessages = false;
  };

  updateUnsentMessage = (conversationId: string, message: string | undefined) => {
    this.unsentMessages[conversationId] = message;
    this.persistUnsentMessages();
  };

  persistUnsentMessages = () => {
    localStorage.setItem(
      CHATS_UNSENT_MESSAGES_LOCAL_STORAGE_KEY,
      JSON.stringify(this.unsentMessages),
    );
  };

  hydrateUnsentMessages = () => {
    const unsentMessages = localStorage.getItem(CHATS_UNSENT_MESSAGES_LOCAL_STORAGE_KEY);
    if (unsentMessages) {
      this.unsentMessages = JSON.parse(unsentMessages);
    }
  };
}
