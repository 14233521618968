import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import ProfilePlaceholderImage from './assets/profile-placeholder.svg';
import PlaceholderImage from './assets/file-placeholder.svg';

const StyledImage = styled.img<{ diameter: number }>`
  height: ${props => `${props.diameter}px`};
  width: ${props => `${props.diameter}px`};
  border-radius: 50%;
`;

const StyledProfilePlaceholder = styled(ProfilePlaceholderImage)<{ diameter: number }>`
  height: ${props => `${props.diameter}px`};
  width: ${props => `${props.diameter}px`};
  flex-shrink: 0;
  border-radius: 50%;
`;

const StyledImagePlaceholder = styled(PlaceholderImage)<{ diameter: number }>`
  height: ${props => `${props.diameter}px`};
  width: ${props => `${props.diameter}px`};
  flex-shrink: 0;
  border-radius: 50%;
`;

interface ImageProps {
  source?: string;
  diameter: number;
  style?: CSSProperties;
}

export const ProfileImage = ({ source, diameter, style }: ImageProps) => {
  const [isSourceBroken, setSourceBroken] = useState(false);

  useEffect(() => {
    setSourceBroken(isSourceBroken);
  }, [isSourceBroken]);

  return source && !isSourceBroken ? (
    <StyledImage
      style={style}
      diameter={diameter}
      src={source}
      onError={() => setSourceBroken(true)}
    />
  ) : (
    <StyledProfilePlaceholder style={style} diameter={diameter} />
  );
};

export const TeamImage = ({ source, diameter, style }: ImageProps) => {
  const [isSourceBroken, setSourceBroken] = useState(false);

  useEffect(() => {
    setSourceBroken(isSourceBroken);
  }, [isSourceBroken]);

  return source && !isSourceBroken ? (
    <StyledImage
      style={style}
      diameter={diameter}
      src={source}
      onError={() => setSourceBroken(true)}
    />
  ) : (
    <StyledImagePlaceholder style={style} diameter={diameter} />
  );
};
