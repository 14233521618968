import React from 'react';
import styled from 'styled-components';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';

const IconContainer = styled(View)`
  cursor: pointer;
  padding: ${Spacing.xs}px;
  border-radius: ${CornerRadius.xs}px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${colors.neutral100};
  }
  height: max-content;
`;

export const CloseButton = ({
  onClick,
  style,
}: {
  onClick: () => void;
  style?: React.CSSProperties;
}) => {
  return (
    <IconContainer
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      style={style}
    >
      <CustomizableIcon type="cross" tint={colors.neutral900}></CustomizableIcon>
    </IconContainer>
  );
};
