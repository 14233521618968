import React, { useState } from 'react';
import { Link } from 'components/Link';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextSMMedium } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { sendEvent } from 'utils/analyticsEvents';
import { modal } from 'components/Modals/ModalManager';
import { Badge } from 'styleguide/Badge';
import { FlexRow } from 'styleguide/Row';

interface NavBarRouteProps {
  title: string;
  getIcon: (tint: string) => void;
  isSelected: boolean;
  path:
    | '/dashboard'
    | '/clients'
    | '/programs'
    | '/exercises'
    | '/settings/profile'
    | '/grow'
    | '/'
    | '/chat'
    | '/team';
  badgeValue?: number;
  disabled?: boolean;
}

const Container = styled.div`
  cursor: pointer;
  background-color: ${props => props.color};
  margin: ${Spacing.xs}px;
  margin-left: ${Spacing.sm}px;
  margin-right: ${Spacing.sm}px;
  border-radius: 5px;
`;

const Content = styled(FlexRow)`
  flex: 1;
  justify-content: space-between;
  padding: ${Spacing.sm}px;
`;

const NavContent = ({
  icon,
  tint,
  badgeValue,
  title,
}: {
  icon: (tint: string) => void;
  tint: string;
  title: string;
  badgeValue?: number;
}) => {
  return (
    <Content>
      <FlexRow>
        {icon(tint)}
        <TextSMMedium style={{ color: tint, paddingLeft: Spacing.sm }}>{title}</TextSMMedium>
      </FlexRow>
      {!!badgeValue && <Badge number={badgeValue} />}
    </Content>
  );
};

export const NavBarRoute = observer(
  ({ title, isSelected, path, getIcon: icon, badgeValue, disabled }: NavBarRouteProps) => {
    const [isHovering, setIsHovering] = useState(false);
    const tint = isSelected ? colors.neutral50 : colors.neutral500;

    const isDisabled = disabled === true;
    return (
      <Container
        style={{ cursor: isDisabled ? 'unset' : undefined }}
        color={
          isSelected || (isHovering && !isDisabled)
            ? colors.sideMenuActiveNavigationItem
            : undefined
        }
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          modal.isMobileNavBarModalOpen = false;
          sendEvent('navBar_route_click', { route: title });
        }}
      >
        {!isDisabled ? (
          <Link href={path}>
            <NavContent icon={icon} tint={tint} title={title} badgeValue={badgeValue} />
          </Link>
        ) : (
          <NavContent icon={icon} tint={tint} title={title} badgeValue={badgeValue} />
        )}
      </Container>
    );
  },
);
