import axios from 'axios';

export const isPlanLimitErrorResponse = (error: any): boolean => {
  if (!axios.isAxiosError(error)) return false;

  if (error.response?.status === 403 && error.response?.data?.error === 'CoachBeyondPlanLimits')
    return true;

  return false;
};
