import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';

interface CommentModalViewModelProps {
  workoutId: string;
}

export class CommentModalViewModel {
  workoutId: string;
  private _commentsContainerRef?: HTMLDivElement;

  constructor({ workoutId }: CommentModalViewModelProps) {
    this.workoutId = workoutId;

    makeAutoObservable(this);
  }

  get workout() {
    return memoryStores.workouts.workouts[this.workoutId];
  }

  get data() {
    const { workout } = this;

    return {
      user_id: workout.user_id,
      username: workout.username,
      profileImage: workout.profile_image,
      date: dayjs.unix(workout.end_time).fromNow(),
      title: workout.name,
    };
  }

  get comments() {
    return this.workout.comments;
  }

  get profileImage() {
    return localStorageStores.account.profile_pic;
  }

  setCommentsContainerRef = (ref: HTMLDivElement) => {
    this._commentsContainerRef = ref;
  };

  onCommentSend = async (comment: string) => {
    if (comment.length === 0) return;

    await API.postWorkoutComment(this.workout.id, comment);
    await memoryStores.workouts.fetchWorkout(this.workoutId);

    setTimeout(() => {
      this._commentsContainerRef?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  onCommentDelete = async (commentId: number) => {
    await API.deleteWorkoutComment(commentId);
    await memoryStores.workouts.fetchWorkout(this.workoutId);
  };
}
